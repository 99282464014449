import {Box} from "@mui/material";
import React from "react";

const CustomerSupportButtons = (props: {handleSend: Function}) => {
  const  { handleSend} = props
  const style = {
    main: {
      height: '50px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '50px'
    }
  }
  return(
      <Box display={'flex'} sx={{...style.main}}>
        <Box
            component={'img'}
            src={'/images/icons/document_attach.svg'}
            sx={{
                marginX: '28px',
                height: '28px',
                width: '28px',
            }}

        />
        <Box
            component={'img'}
            src={'/images/icons/message_send.svg'}
            height={'20px'}
            width={'20px'}
            onClick={() => handleSend()}

        />
      </Box>
  )
}

export default (CustomerSupportButtons)

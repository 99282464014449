import {Box} from "@mui/material";
import Filters from "../filters/Filters";
import * as React from "react";
import {CheckedBoxList, MachinesSearch, ListingListTypes} from "../../../interfaces/backend";
import {PricesFilter} from "../filters/Prices";
import EmptySearchResults from "../quickSearch/EmptySearchResults";
import ListingList from "../ListingList";
import FilterHeader from "../filters/FilterHeader";
import { setCheckedBoxesList } from "../../../reducers/sparePart";
import { useAppDispatch } from "../../../hook";

interface MobileListingPageProps {
  showFilters: boolean,
  closeFilter: boolean,
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>,
  setSortValue: React.Dispatch<React.SetStateAction<string>>,
  sortValue: string
  machineSearch: MachinesSearch,
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>,
  type: ListingListTypes,
  setPricesFilter: React.Dispatch<React.SetStateAction<PricesFilter | undefined>>,
  pricesFilter: PricesFilter | undefined,
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>,
  selectedPage: number,
  setCloseOpenFilter: Function,
  checkedBoxesList: CheckedBoxList,
  handleFilter: Function,
  searchValue: any
}

export default function MobileView(props: MobileListingPageProps) {

  const {
    setSelectedItem,
    setSelectedPage,
    machineSearch,
    setPricesFilter,
    pricesFilter,
    closeFilter,
    type,
    setCloseOpenFilter,
    handleFilter,
    searchValue,
    sortValue,
    setSortValue
  } = props
  const dispatch = useAppDispatch()

  const clear = () => {
    dispatch(setCheckedBoxesList({}))
    setPricesFilter(undefined)
}
 
  return (
      <Box sx={{display: 'flex', flexDirection: 'column', margin: '15px', paddingY: '15px' }} >
        <Box sx={{
        }}>
          <FilterHeader isMobile={true} setCloseFilter={() => setCloseOpenFilter(closeFilter)}
                        clear={clear}
                        closeFilter={closeFilter} setSortValue={setSortValue}
                        sortValue={sortValue}/>
          {!closeFilter && <Filters closeFilter={closeFilter} setCloseFilter={() => setCloseOpenFilter(closeFilter)}
                   filters={machineSearch.filterResults?.attributes}
                   setSelectedItem={setSelectedItem} type={type}
                   setPriceFilter={setPricesFilter} pricesFilter={pricesFilter}
                   setSelectedPage={setSelectedPage}
                   isMobile={true}
                   handleFilter={handleFilter}
                   sortValue={sortValue}
                   setSortValue={setSortValue}
          />}
        </Box>
        {
            closeFilter && <Box
                sx={{
                  width: '100%',
                }}

            >
              {type === ListingListTypes.Search ?
                  (machineSearch && machineSearch.itemsCount === 0) ?
                      <EmptySearchResults/> : !searchValue ?
                          <EmptySearchResults/> :
                          <ListingList machineList={machineSearch} type={type} 
                          // isMobile={true}
                          />
                  : <ListingList machineList={machineSearch} type={type} 
                  // isMobile={true}
                  />}
            </Box>
        }

      </Box>
  )
}
import { Box, Typography } from "@mui/material";
import  { defaultFonts, primaryColor } from "../../../../theme";
import { useAppDispatch } from "../../../../hook";
import { resetSelectedTreeNode, setSparePartSchemaOpen } from "../../../../reducers/sparePart";
import { Schema } from "../../../../interfaces/backend";
import { useTranslation } from "react-i18next";
import ArrowButton from "../../../common/ArrowButton";

interface TableTitleProps {
  schema: Schema[];
}

const TableTitle = (props: TableTitleProps) => {
  const { schema } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation("spareParts");

 

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingY: {xs: "15px", md: "35px"},
        paddingX: '35px',
        color: primaryColor.treeItemsColor,
      }}>
 
      <Box
            onClick={() => dispatch(resetSelectedTreeNode()) }
            sx={{
              display: 'flex',
              width: 'fit-content',
              cursor: 'pointer',
              paddingY: "10px",
              marginLeft: {xs: '-5px', md: '-10px', lg: '-35px'},
            }}
          >
            <ArrowButton
              direction={"left"}
              color={primaryColor.arrowButtonColorSlider}
                width={35}
            />
      
      <Typography
        sx={{
          fontSize: defaultFonts,
          fontWeight: 300,
          paddingLeft:  {xs: '10px', lg: '30px'},
          marginRight: 'auto',
        }}
      >
        {t("partsListTableTitle")}
      </Typography>

      </Box>

      
      {schema?.length ? (
        <Box
          component={"img"}
          src={"/images/icons/productSchema.svg"}
          onClick={() => dispatch(setSparePartSchemaOpen(true))}
          sx={{
            cursor: "pointer",
            height: "25px",
          }}
        />
      ) : null}
    </Box>
  );
};

export default TableTitle;
import { Box, Typography, Grid } from "@mui/material";
import { defaultFonts, primaryColor } from "../../../theme";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { getMe } from "../../../reducers/auth";
import { formatMoney } from "../../../utils/localization";
import ShippingAddressComp from "../../Checkout/ShippingAddressComp";
import BillingAddress from "../../../components/myData/Addresses/BillingAddress";
import { getBillingAddress, getContactData } from "../../../reducers/address";
import Contacts from "../../../components/myData/contacts";
import { StyledTypography } from "../../../components/common/StyledComponents";

 
const MyData = () => {
  const { t } = useTranslation("myData")
  const me = useAppSelector((state) => state.auth.me);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMe());
    dispatch(getBillingAddress());
    dispatch(getContactData());

  }, [dispatch]);

 

  const CartLimitData = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ mb: "15px", fontSize: defaultFonts, fontWeight: 300 }}>
          {t("customerCartLimitation")}
        </Typography>

        <StyledTypography>
          {me?.cartLimit != null
            ? t("cartLimitMaximum") + " " + formatMoney(Number(me.cartLimit))
            : t("noCartLimit")}
        </StyledTypography>
      </Box>

    );
  };

  const CustomerCode = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "-25px",
        }}
      >
        <Typography sx={{ textTransform: "uppercase", mt: '5px', mb: "15px",  fontSize: defaultFonts, fontWeight: 300}}>
          {t("customerNumber")}
        </Typography>
        <StyledTypography >{me.customer && me.customer.code}</StyledTypography>
      </Box>
    );
  };
 
  return (
    <Grid
      container
      spacing={2}
      gap={3.25}
      sx={{
        mt: 0,
        mb: "15px",
        padding: {
          xs: '25px 40px',
          md: "35px 35px 35px 70px",
          lg: "35px 50px 35px 100px"
        },
        color: primaryColor.whiteText,
        "& .MuiGrid-item": { paddingTop: "25px" },
      }}
    >
      <Grid item xs={12} >
          <CustomerCode />
      </Grid>

      <Grid item xs={12}>
          <BillingAddress isMyAccountPage={true}/>
      </Grid>

      <Grid item xs={12}>
          <Typography
            sx={{  mt: "15px", fontSize: defaultFonts, fontWeight: 300 }}
          >
            {t("shippingAddress")}
        </Typography>
          <ShippingAddressComp isMyAccountPage={true}/>

      </Grid>

      <Grid item xs={12}>
          <Contacts />
      </Grid>

      <Grid item xs={12}>
          <CartLimitData />
      </Grid>
    </Grid>
  );
};

export default MyData;
import {Box} from "@mui/material";
import Logo from "../../../components/common/header/logo";
import {primaryColor} from "../../../theme";

export default function DesktopView() {
    return (
        <Box sx={{
            paddingY: 2,
            backgroundColor: primaryColor.headerColor,
            paddingLeft: 12
        }}>
            <Logo mobileView={false}/>
        </Box>
    )
}
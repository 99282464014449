import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { primaryColor } from "../../../../../theme";
import { useAppSelector } from "../../../../../hook";
import SelectLanguageMobile from "../../SelectLanguage/mobile";


interface CustomDrawerProps {
  open: boolean;
  onClose?: () => void;
  drawerMenuList: React.ReactNode;
}

const CustomDrawer = (props: CustomDrawerProps) => {
	const {open, onClose, drawerMenuList} = props;
	const serviceUserEmail = useAppSelector(
		(state) => state.auth.storedUserEmail
	  );

 const isSelectLanguageOpen = useAppSelector(
  (state) => state.styleControls.isSelectLanguageOpen
 );
  return (
    <Drawer
      anchor={"top"}
      open={open}
      onClose={() => onClose}
      sx={{
        "& .MuiPaper-root.MuiDrawer-paper": {
          paddingTop: serviceUserEmail ? "135px" : "60px",
          backgroundColor: primaryColor.background,
          width: "100%",
          overflowY: "auto"
        },
      }}
    >
      <Box role="presentation">{drawerMenuList}</Box>
       {isSelectLanguageOpen && <SelectLanguageMobile />}
    </Drawer>
  );
};

export default CustomDrawer;
import { Box, Typography } from "@mui/material";
import React from "react";
import { primaryColor } from "../../theme";
import { useTranslation } from "react-i18next";
import { getQuickSearchOptions } from "../../reducers/quickSearch";
import { useAppDispatch } from "../../hook";
import { useNavigate } from "react-router";
import { SearchSuggestionElement } from "../../interfaces/backend";
import {
  MiddleContent,
  NavigateButton,
  TopContent,
  commonStyles,
} from "./subComponents";

const QuickSearch = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("spareParts");

  const handleInputChange = (searchValue: string) => {
    dispatch(
      getQuickSearchOptions({
        searchValue: searchValue,
        onlyMachineParts: true,
      })
    );
  };

  const handleOnSelect = (item: SearchSuggestionElement) => {
    navigate(`/product/${item.alias}`);
  };

  return (
    <Box sx={commonStyles.card}>
      <TopContent
        numberContent={2}
        title={t("quickOrder")}
        description={t("quickOrderDescription")}
      />

      <MiddleContent
        handleInputChange={handleInputChange}
        handleOnSelect={handleOnSelect}
        placeHolder={t("searchParts")}
      />

      <Typography
        sx={{
          color: primaryColor.sparePartsText,
          fontSize: { xs: "1.5rem", lg: "2rem" },

          paddingY: {
            md: "25px",
            xl: "35px",
          },
          paddingX: "10px",
          height: {
            xs: "150px",
            lg: "190px",
          },
          display: "flex",
          alignItems: {
            xs: "center",
            md: "flex-end",
          },
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        {t("quickOrderInformation")}
      </Typography>

      <Box sx={commonStyles.buttons}>
        <NavigateButton
          content={t("toQuickOrder")}
          navigateTo={"/quick-order"}
        />
      </Box>
    </Box>
  );
};

export default QuickSearch;

import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import {Box} from "@mui/material";
import {primaryColor} from "../../../../../theme";
import {Order} from "../../../../../interfaces/backend";
import OrdersTableItemTablet from "./OrdersTableItemTablet";

interface OrdersTableProps {
  order: Order,
}

export default function OrdersTableTablet(props: OrdersTableProps) {
  const {items} = props.order
  return (
    <Box sx={{backgroundColor: primaryColor.orderHistoryTabletBackground, paddingX: {xs: '30px', md: '0px'}}}>
      <Table>
        <TableBody>
          {items.map(row => <OrdersTableItemTablet item={row} key={row.code}/>)}
        </TableBody>
      </Table>
    </Box>
  )
}
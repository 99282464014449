import {
  Box,
  BoxProps,
  styled,
  SxProps,
  Typography,
  TypographyProps,
  useMediaQuery,
} from "@mui/material";
import {StyledBoxButton, StyledSubmitButton} from "../../Cart/styleElements";
import {useAppSelector} from "../../../hook";
import { formatMoney } from "../../../utils/localization";
import theme, { defaultFonts, secondaryFonts, primaryColor } from "../../../theme";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutSummaryProps } from "./index";
import CheckoutDiscountsBox from "./CheckoutDiscountsBox";
import { RowFlexBox, CustomTypography } from "../styledElement";

interface ColumnFlexBoxProps {
  rowName: string;
  rowValue: string;
  customStyle?: SxProps;
}

export const CustomDivider = styled((props: BoxProps) => <Box {...props} />)(
  ({ theme }) => ({
    height: "22px",
    borderColor: primaryColor.shoppingCartTitle,
    borderStyle: "solid",
    marginTop: "13px",
    width: "100%",
  })
);





export const ColumnFlexBox = ({
  rowName,
  rowValue,
  customStyle,
}: ColumnFlexBoxProps) => {

  return (
    <RowFlexBox sx={{ ...customStyle }}>
      <CustomTypography
        sx={{
          color: primaryColor.shoppingCartTitle,
          fontSize: defaultFonts,
          paddingRight: '15px',
          textAlign: "start",
          ...customStyle,
        }}
        >
        {rowName}
      </CustomTypography>
      <CustomTypography
        sx={{
            color: primaryColor.shoppingCartTitle,
            textAlign: "end",
            fontSize: defaultFonts,
          ...customStyle,
        }}
      >
        {rowValue}
      </CustomTypography>
    </RowFlexBox>
  );
};

export default function CostArea ({handleClick, enableBuyButton, checkoutCart}: CheckoutSummaryProps) {
  const {t} = useTranslation('checkoutPage')
  const hasShipping = checkoutCart.shippingMethod?.usePrices
  const selectedMethod = useAppSelector(state => state.cart.checkoutCart.paymentMethod )
  const paymentIsLoading = useAppSelector(state => state.cart.paymentIsLoading)
  const isStripeExist = useAppSelector(state => state.cart.isStripeExist)
  const isElementsExist = useAppSelector(state => state.cart.isElementsExist)


  const mobileView = useMediaQuery(theme.breakpoints.down("md"));

  let totalSave: number = useMemo(() => {
    let temp = checkoutCart.discounts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.discountAmount,
      0
    );
    checkoutCart.items?.forEach((item) => {
      temp += item.discountSum * item.quantity;
    });
    return temp;
  }, [checkoutCart]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: { xs: "60px", md: "unset" },
        width: "100%",
        marginLeft: {xs: 0, md: '25px'},
        maxWidth: { xs: "100%", md: "450px" },
        padding: {
          xs: "0px 40px",
          md: 0,
        },
      }}
    >
      {checkoutCart.totalDiscount > 0 && (
        <CheckoutDiscountsBox checkoutCart={checkoutCart} />
      )}

      {checkoutCart.totalDiscount > 0 && !mobileView && (
        <CustomDivider sx={{ borderWidth: "0.5px 0 0 0" }} />
      )}

      <ColumnFlexBox
        rowName={t("subtotal")}
        rowValue={formatMoney(checkoutCart.total)}
      />


      {hasShipping && (
        <ColumnFlexBox
          rowName={t("Shipping Cost")}
          rowValue={formatMoney(checkoutCart.shippingMethod.price)}
        />
      )}

      <ColumnFlexBox
        rowName={t("taxValue")}
        rowValue={formatMoney(checkoutCart.totalTax)}
      />
      
      <CustomDivider
        sx={{
          borderWidth: "2px 0 0 0",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            minWidth: "unset",
          },
        }}
      />

      <ColumnFlexBox
        rowName={t("totalCost")}
        rowValue={formatMoney(checkoutCart.totalWithTaxAndShipping)}
        customStyle={{ fontWeight: mobileView ? 700 : 400 }}
      />

      {totalSave > 0 && (
        <CustomTypography
          sx={{
            width: "100%",
            marginLeft: 'auto',
            color: primaryColor.discountCheckoutPage,
            fontSize: secondaryFonts

          }}
        >
          {t("You save {{money}}", { money: formatMoney(totalSave) })}
        </CustomTypography>
      )}
      <Box
        sx={{
          mt: "47px",

        }}
      >
        {selectedMethod && selectedMethod.paymentProvider === 'payment-stripe' ?
            <StyledSubmitButton
                labelButton={t('buyNow')}
                disabled={paymentIsLoading || !isStripeExist || !isElementsExist}
                form={'payment-form'}
            /> : <StyledBoxButton
                labelButton={t('buyNow')}
                disabled = {!enableBuyButton}
                handleClick = {handleClick}
            />}
      </Box>
      <RowFlexBox
        sx={{
          mt: "13px",
        }}
      >
        <CustomTypography
          sx={{
            width: "100%",
            marginLeft: {md: 'auto'},
            color: primaryColor.discountCheckoutPage,

          }}
        >
          {t("pleaseFill")} 
        </CustomTypography>
      </RowFlexBox>
    </Box>
  );
}


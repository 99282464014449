import * as React from 'react';
import {Dispatch, ReactNode} from 'react';
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {primaryColor} from "../../../theme";

interface modalProps {
    title: string,
    closeAction?: () => void,
    closeState?: Dispatch<React.SetStateAction<boolean>>,
    children: ReactNode
}

export default function CommonDialog(props: modalProps) {
    const {title, closeAction, closeState, children} = props
    return (
        <Dialog
            open={true}
            onClose={() => {
               closeAction ? closeAction() : closeState && closeState(false)
            }}
            sx={{
                '& .MuiPaper-root.MuiDialog-paper': {
                    borderRadius: '0',
                    width: {
                        xs: '90%',
                        sm: '100%'
                    },
                    overflowX: 'hidden',
                    minHeight: '300px',
                    backgroundColor: primaryColor.backgroundOverlay,

                }
            }}
            fullWidth={true}
        >
            <Box
                sx={{
                    alignItems: 'start',
                    display: 'flex',
                
                    padding: {
                        xs: '20px',
                        md: '30px 50px',
                    },
                    flexDirection: 'column',
                    color: primaryColor.whiteText,
                }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '45px',
                        paddingX: 'unset',
                        fontSize:  "2.5rem",
                        fontWeight: 700,
                        width: '100%',
                        wordBreak: "break-all",
                    }}
                >
                    {title}
                    <Box
                        component={'img'}
                        src='/images/clearIcon.svg'
                        onClick={() => {
                        closeAction ? closeAction() : closeState && closeState(false)

                        }}
                        sx={{
                            height: '2rem',
                            cursor: 'pointer',
                            ml: '30px'
                        }}
                    />
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: 'unset',
                        width: '100%'
                    }}
                >
                    {children}
                </DialogContent>
            </Box>
        </Dialog>
    );
}
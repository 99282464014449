import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import React from "react";
import {getLocaleDateString, getLocaleTimeString} from "../../../utils/localization";
import {useTranslation} from "react-i18next";

export interface MessageProps {
  text: string,
  isInput: boolean,
  createdAt: string,
  isMobile?: boolean,
}

const Message = (props: MessageProps) => {
  const {t} = useTranslation('myAccount')
  const {text, isInput, createdAt, isMobile} = props
  const textLinesArray = text.split("<br />")
  const style = {
    mainColor: isInput?{
      backgroundColor: primaryColor.outputMessage,
    }:
    {
      backgroundColor: primaryColor.inputMessage,

    },
    main: {
      width: isMobile?'90%':'40%',
      borderRadius: '20px',
      opacity: '0.7',
      alignSelf: isInput? 'none':'flex-end'
    },
    name: {
      paddingLeft: '17px',
      color: primaryColor.messageText,
      fontSize: '1.4rem'
    },
    boxStyle: {
      borderRadius: '20px',
      fontSize: '1.4rem',
      padding: '15px 44px 15px 17px',
      color: primaryColor.messageText,
      wordWrap: 'break-word'
    }

  }
  return(
      <Box sx={{...style.main}} >
        <Typography sx={{...style.name}}>{isInput?t('support'):t('you')}</Typography>
        <Box sx={{ ...style.mainColor, ...style.boxStyle}}>
          {textLinesArray.map(line =>
              <Box>
                {line}
              </Box>)}
          <Box sx={{textAlign: 'end'}}>
            {getLocaleDateString(createdAt)} {getLocaleTimeString(createdAt)}
          </Box>
        </Box>
      </Box>
  )

}

export default (Message)

import { Box, Tooltip, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../hook";
import { useTranslation } from "react-i18next";
import { setOpenedDrawerID } from "../../../../../../reducers/static";
import { setMyDataOpened } from "../../../../../../reducers/styleControls";
import MenuListItem from "./menuListItem";
import {AccountDrawerIcon, accountDrawerList} from "../../drawerListItem";
import theme from "../../../../../../theme";
import {useNavigate} from "react-router";
import {useLocation} from "react-router";


const AccountMenu = () => {
  const isMyDataOpened = useAppSelector(
    (state) => state.styleControls.isMyDataOpened
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMyAccountPage = location.pathname === '/my-account';
  const { t } = useTranslation("common");
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = ( ) => {
    if(!mobileView){
      //in myAccount triggered open/close menu
      dispatch(setMyDataOpened(!isMyDataOpened));
      navigate("/my-account/my-data");
    }else{
      navigate('/my-account')
    }
  };

  const handleMouseEnter = () => {
    if(!mobileView){
      dispatch(setMyDataOpened(true));
      dispatch(setOpenedDrawerID("account-menu"));
    }
  };

  const handleMouseLeave = () => {
    if(!mobileView){
    dispatch(setMyDataOpened(false));
    dispatch(setOpenedDrawerID(""));
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        lineHeight: 0 //remove additional vertical space
      }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <Tooltip title={t("account")}>
        <Box component={"span"}
        sx={{
          cursor: "pointer"
        }}
        onClick={handleClick}
      >
        <AccountDrawerIcon isOpen={isMyDataOpened} isMyAccountPage={isMyAccountPage}/>
      </Box>
      </Tooltip>

      {isMyDataOpened && <Box
        sx={{
          position: "absolute",
          right: {xs: "-74px",lg: "-85px", '2xl': '-64px'},
          width: '550px',
          paddingTop: '45px'
        }}
      >
        <MenuListItem listItems={accountDrawerList} />
      </Box>}
    </Box>
  );
};

export default AccountMenu;
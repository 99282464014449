import {Box, Button } from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {addCheckoutVoucher} from "../../../reducers/cart";
import {useAppDispatch, useAppSelector} from "../../../hook";
import VoucherInfo from "./VoucherInfo";
import { defaultFonts } from "../../../theme";
import { StyledBox } from "../../../components/common/StyledComponents";



export default function VoucherAreaForm() {
  const {t} = useTranslation('checkoutPage')
  const [discountCode, setDiscountCode] = useState('')
  const dispatch = useAppDispatch()
  const checkoutCart = useAppSelector(state => state.cart.checkoutCart)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(addCheckoutVoucher({code: discountCode}))
    setDiscountCode("")
  };

  return (
    <>

   <form onSubmit={handleSubmit}>

     <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, mt: '18px'}}>
         <StyledBox
              label={t("discountCode")}
              style={{
                width: '100%',
                fontSize: defaultFonts,
                minWidth: '250px',
              }}
              required={false}
              name="discountCode"
              handleChange={handleChange}
              value={(discountCode || "")}
            />

    <Box sx={{mt: '16px', marginX: {md: '10px'}}}>
     <Button
          type={'submit'}
          variant="contained"
          disabled={!discountCode}
        >
          {t('apply')}
        </Button>
    </Box>

       </Box>
    </form> 

    <Box sx={{mt: '18px'}}>
        {checkoutCart.discounts.map((item, index) =>
          <VoucherInfo key={index} discount={item.discount} setDiscountCode={setDiscountCode}/>
        )}
      </Box>
    </>
  )
}
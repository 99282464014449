import React from "react";
import {TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledHeadTableCell, StyledTableCell} from "../../../pages/Cart/styleElements";

const TableHeadComponent = () => {
    const {t} = useTranslation("quickOrder");
    const tableCols = [
        t("position"),
        t("description"),
        t("materialNumber"),
        t("productPrice"),
        t("quantity"),
    ];

    return (
        <TableRow>
            {tableCols.map((col, index) => (
                <StyledHeadTableCell key={index}>{col}</StyledHeadTableCell>
            ))}
            <StyledTableCell/>
        </TableRow>
    );
};

export default TableHeadComponent;

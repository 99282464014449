import {Box, Button, Grid} from "@mui/material";
import MyOrderItem from "../common/myOrders/MyOrderItem";
import OrdersAddress from "../common/myOrders/OrdersAddress";
import {primaryColor} from "../../../theme";
import React from "react";
import OrdersTableDesktop from "../common/myOrders/OrdersTableDesktop";
import {useTranslation} from "react-i18next";
import MyOrderFooter from "../common/myOrders/MyOrderFooter";
import {useAppDispatch} from "../../../hook";
import {Order} from "../../../interfaces/backend";
import OrderSummary from "../common/myOrders/OrderSummary";
import {useNavigate} from "react-router";
import { addCartItem, addCartMultipleItems } from "../../../reducers/cart";
import { reOrder } from "../../../components/myData/MyOrders/orderUtils";

export interface OrderDetailsProp {
  order: Order,
}

const MyOrderDetails = (props: OrderDetailsProp) => {
  const {order} = props
  const {t} = useTranslation('myAccount')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const confirmOrder = (order: Order) => {
    const items = order.items;
    const productsToAdd = reOrder(items);

    if (productsToAdd.length > 0) {
      dispatch(addCartMultipleItems({ items: productsToAdd }));
    }else{
      dispatch(addCartItem(items))
    }
  };
  return (
      <Box bgcolor={primaryColor.ordersListBackgroundColor} color={primaryColor.accountTextColor}>
        <Box
            component={'img'}
            src={'/images/icons/arrowCarouselLeft.svg'}
            alt='image'
            onClick={() => {

              navigate({
                pathname: '/my-account/my-orders',
                search: ``,
              })
            }
            }
            sx={{
              height: '34px',
              width: '17px',
              objectFit: 'cover',
              marginLeft: '49px',
              marginTop: '28px',
              position: 'absolute',
              cursor: 'pointer',
              color:primaryColor.accountTextColor
            }}/>
        <MyOrderItem order={order}/>

        <Box sx={{marginX: '10%'}}>

            <Grid sx={{display: 'flex'}} container>
              {order.billingAddress && <Grid item xs={6}>

                <OrdersAddress addressType={'billingAddress'} address={order.billingAddress} />
              </Grid>}
              {order.shippingAddress && <Grid item xs={6}>
                <OrdersAddress addressType={'shippingAddress'}
                               address={order.shippingAddress}/>
              </Grid>}
            </Grid>
          <Box sx={{
            display: 'flex',
            justifyContent: 'end'
          }}> <Button
              sx={{
                mt: '80px'
              }}
              variant="contained"
              onClick={() => {
                confirmOrder(order)
              }}>
                {t('reOrderProducts')}
                </Button>
                </Box>
          <Box mt={'70px'}>
            <OrdersTableDesktop order={order}/>
          </Box>
          <OrderSummary order={order} />
          <Box sx={{display: 'flex', paddingBottom: '53px'}}>
            <MyOrderFooter discounts={order.discounts} paymentMethod={order.paymentMethod}
                           internalOrderNumber={order.internalOrderNumber} documents={order.documents} comment={order.comment}/>
          </Box>
        </Box>
      </Box>
  )
}

export default (MyOrderDetails)

import React from "react";
import { Box, Typography } from "@mui/material";
import { CartItem } from "../../../interfaces/backend";
import { useAppDispatch } from "../../../hook";
import { deleteCartItem, updateCartItem } from "../../../reducers/cart";
import { defaultFonts, primaryColor } from "../../../theme";
import { TakeFirst2DImage } from "../../../utils/productFunctions";
import ProductQuantityPriceBox from "../../../components/quickOrder/ResultTable/ProductQuantityPriceBox";

const CartTableItemMobile = (props: {item: CartItem}) => {
  const { item } = props;
  const dispatch = useAppDispatch();
  const imageSrc = TakeFirst2DImage(item.product.image);

  const onChangeQuantity = (code: string, quantity: number) => {
    dispatch(
      updateCartItem({
        code,
        quantity: quantity,
      })
    );
  };

  const customStyles = {
      paddingLeft: '25px',
      width: '100%',
  }

  return (
    <>
        <Box
          sx={{
            backgroundColor: primaryColor.cartMobileTableItemBackground,
            mb: "15px",
			padding: '30px 20px', 
            display: "flex",
            alignItems: "center",
            flexDirection: "column",

          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: "0px 15px",
              minHeight: '100px',
              height: '100%',
              maxWidth: "450px",
            }}
          >
            <Box
              component={"img"}
              src={imageSrc}
              sx={{
                height: "77px",
				width: '123px'

              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: '10px',
                "& > *":{
                  fontSize: defaultFonts
                },
              }}
            >
              <Typography
                sx={{
                  padding: "0px 10px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.product.name}
              </Typography>

              <Typography
                sx={{
                  padding: "0px 10px",
                }}
              >
                {item.product.code}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
    			paddingX: '15px', 
              maxWidth: "450px",

            }}
          >
            <ProductQuantityPriceBox
              product={item}
              onChangeQuantity={(val: number) =>
                onChangeQuantity(item.code, val)
              }
              onDelete={() => dispatch(deleteCartItem(item))}
              customStyles={customStyles}
           
            />
          </Box>
        </Box>
    </>
  );
};

export default CartTableItemMobile;

import * as React from 'react';
import Modal from '@mui/material/Modal';
import {useAppDispatch, useAppSelector} from "../../../../hook";
import {openSearchBox} from "../../../../reducers/quickSearch";
import Search from "./Search";


export default function ModalSearchBox() {
  const searchBoxOpen = useAppSelector(state => state.quickSearch.searchBoxOpen)
  const dispatch = useAppDispatch()

  return (
      <Modal
          open={searchBoxOpen}
          onClose={() => dispatch(openSearchBox(!searchBoxOpen))}
          sx={{width: '100%', height: '100%'}}
      >
          <Search />
      </Modal>
  );
}
import {useMediaQuery} from "@mui/material"
import theme from "../../../theme";
import TopHeader from "../../common/header/topHeader";
import DesktopView from "./desktopView";
import MobileView from "../../common/header/BottomHeader/mobileView";

const LoginHeader = () => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            {!mobileView ?
                <>
                    <TopHeader/>
                    <DesktopView/>
                </>
                :
                <MobileView/>
            }
        </>
    )
}

export default (LoginHeader)
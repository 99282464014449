import React from "react";
import {
  ProductDetailsPageContent,
  SparePartsTreeSelectedNode,
} from "../../../../interfaces/backend";
import { Box, Typography, Container, Grid } from "@mui/material";
import { secondaryFonts, primaryColor } from "../../../../theme";
import { Availability } from "../../../common/general/availability";
import ProductDetailsOverview from "../../../common/general/productDetailsOverview";
import { useAppDispatch } from "../../../../hook";
import { addCartItem } from "../../../../reducers/cart";
import AddToCartButton from "../../../common/AddToCartButton";
import { useTranslation } from "react-i18next";
import PriceRendered from "../../../common/priceRendered";

interface TableProps {
  selectedSparePart: SparePartsTreeSelectedNode;
}

interface SparePartMobileRowProps {
  product: ProductDetailsPageContent;
  label?: string;
}

const StyledTableMobile = (props: TableProps) => {
  const { selectedSparePart } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation("spareParts");
 

  const SparePartMobileRow = ({ product, label }: SparePartMobileRowProps) => {
    return (
      <Container maxWidth={"2xl"}
        sx={{
          display: "flex",
          flexDirection: "column",
          color: primaryColor.treeItemsColor,
          borderBottom:
            selectedSparePart.product.name !== product.name
              ? `0.8px solid ${primaryColor.sparePartsTreeBorderBottom}`
              : null,
              mb: "20px",
          paddingX: {xs: '35px', md: '35px'},

        }}
      >
        {label ?
        <Typography
          sx={{
            marginY: "10px",
            fontSize: secondaryFonts
          }}
        >
           {`${t("position")}: ${label}`}
        </Typography>
        : 
        null
        }

        <Grid
          container
          sx={{
            justifyContent: "space-between",
              alignItems: "flex-end"
          }}
        >
          <Grid item xs={12} md={6} >
            <Typography
              sx={{
                marginTop: "10px",
                marginBottom: "2.5px",
                fontSize: secondaryFonts

              }}
            >
              {product.name}
            </Typography>

            <Typography
              sx={{
                marginTop: "2.5px",
                marginBottom: "10px",
                fontSize: secondaryFonts

              }}
            >
              {product.code}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                textAlign: 'end',
                justifyContent: {xs: "flex-start", md: 'flex-end'},
                alignItems: "center",
                paddingBottom: "10px",

              }}
            >
              <Availability
                iconSx={{ height: "22px", marginRight: "20px" }}
                textSx={{ display: "none" }}
                inStock={product.inStock}
                isOrderable={product.isOrderable}
              />

              <PriceRendered
                productPrice={product.price}
                productDiscountPrice={product.discountPrice}
                styles={{ fontSize: secondaryFonts}}
              />
            </Box>
          </Grid>
        </Grid>

        <AddToCartButton
          isDisabled={!product.isOrderable}
          buttonContent={t("addItem") as string}
          buttonStyles={{
            height: "55px",
            margin: '15px auto 10px',
            width: {xs: '100%', md: '80%'},
            borderRadius: '1.85px',
          }}
          onClick={() => {
            dispatch(
              addCartItem({
                code: product.code,
                quantity: 1,
              })
            );
          }}
        />
        <ProductDetailsOverview
          linkTo={product.alias}
          iconSx={{ height: "19px", marginRight: "15px" }}
          iconText={t("goToProductDetails") as string}
          containerSx={{
            height: "55px",
            justifyContent: "center",
            backgroundColor: primaryColor.sparePartsBackground,
            marginX: 'auto',
            marginBottom:
            selectedSparePart.product.name !== product.name ? "25px" : 0,
            width: {xs: '100%', md: '80%'},
            borderRadius: '1.85px',
          }}
        />
       </Container>
    );
  };

  return (
    <Box sx={{
      mt: '20px',
      mb: '100px',
    }}>
      <Box
        sx={{
          width: "100%",
          color: primaryColor.treeItemsColor,
          fontSize: secondaryFonts,

          paddingY: "10px",
          marginY: "20px",
          backgroundColor: primaryColor.sparePartsTreeParentBackground,
          

        }}
      >
 
          <SparePartMobileRow
            product={selectedSparePart.product}
            label={selectedSparePart.label}
          />
        </Box>

          {selectedSparePart.nonGroupChildren?.map((child, index) => (
          <SparePartMobileRow
            label={child.label}
            key={`${child.product.alias}-${index}`}
            product={child.product}
          />
        ))}
      </Box>
  );
};

export default StyledTableMobile;

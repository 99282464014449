import TopFooter from "./topFooter";
import BottomFooter from "./bottomFooter";
import React from "react";


const Footer = () => {
 
 
    return (
        <>
            <TopFooter/>
            <BottomFooter/>
        </>
    )
}

export default React.memo(Footer)
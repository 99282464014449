import {Box, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import React from "react";

import  {primaryColor} from "../../theme";
import ResetPasswordForm from "./resetPasswordForm";
import { PasswordActionType } from "../../interfaces/backend";

interface ResetPasswordAreaProps {
    type: string;
  }

export default function ResetPasswordArea(props: ResetPasswordAreaProps) {

    const {t} = useTranslation('startPage')
    const {type}=props;
    const title = type === PasswordActionType.New ? t('passwordNewTitle') : t('passwordResetTitle');

    return (
        <Grid
            item
            container
            xs={12}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            marginTop={{md:'60px', xs: '0px'}}
            paddingLeft={{md: '96px;'}}
        >
                    <Box component="span" mt={'30px'} width={'100%'}>
                        <Typography
                            sx={{
                                maxWidth: '620px',
                                wordBreak: "break-word",
                                fontSize: '4.5rem',
                                fontWeight: 700,
                                padding: {
                                    xs: '10px 30px',
                                    md: '0',
                                },
                                textAlign: 'left',
                                margin: {
                                    xs: '90px 0',
                                    md: '0 0 90px 0'
                                },
                                color: primaryColor.textTopHeader,
                            }}
                            variant='h3'>
                            {title}
                        </Typography>

                    </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'left',
                flexDirection: 'column'
            }}>
                <Box sx={{marginBottom: '30px', maxWidth: '350px'}}>
                    <Typography variant={'h20'}
                                sx={{color: primaryColor.textTopHeader}}>
                        {t('pleaseNewPass')}
                    </Typography>
                </Box>
                <Box sx={{ maxWidth: '350px'}}>
                    <Typography variant={'h20'}
                                sx={{color: primaryColor.textTopHeader}}>
                        {t('description')}
                    </Typography>
                </Box>

                <ResetPasswordForm/>
            </Box>
        </Grid>
    )
}

import {Box, BoxProps, Divider, DividerProps, Typography, TypographyProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {defaultFonts, primaryColor} from "../../theme";
import styled from "@emotion/styled";
import {useAppSelector} from "../../hook";
import {formatMoney} from "../../utils/localization";
import {useNavigate} from "react-router";
import {calculateCartPriceDetails} from "../../utils/productFunctions";
import { StyledTypography } from "../../components/common/StyledComponents";

const StyledBox = styled((props: BoxProps) => (
    <Box {...props}/>
  )
)
(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledDivider = styled((props: DividerProps) => (
    <Divider {...props}/>
  )
)
(({theme}) => ({
  borderColor: primaryColor.filterText,
  borderWidth: '1px',
  marginTop: '15px',
  marginBottom: '15px'
}))


const StyledTypographyDiscount = styled((props: TypographyProps) => (
  <Typography sx={{
    fontSize: defaultFonts,
    fontWeight: 400,
  }} {...props}/>
))
(({theme}) => ({
    color: primaryColor.filterText
}))
export default function OrderSummaryDesktop() {

  const {t} = useTranslation('checkoutPage')
  const order = useAppSelector(state => state.cart.orderResult)
  const settings = useAppSelector(state => state.staticContent.settings)
  const navigate = useNavigate()

  useEffect((() => {
    if (order.orderId === '') navigate('/cart')
  }), [order])

  return order.orderId ? (
    <Box sx={{
      textAlign: 'start',
      mt: {xs: '30px', sm: '50px'},
    }}>
      <StyledBox>
        <StyledTypography>{t('items')}</StyledTypography>
        <StyledTypography>{t('price')}</StyledTypography>
      </StyledBox>
      <StyledDivider/>
      {
        order.items.map((item, index) => {
            const {lastPrice} = calculateCartPriceDetails(item, settings);
            return (
              <StyledBox key={index} sx={{paddingY: '5px'}}>
                <StyledTypography sx={{ maxWidth: '80%'}}
                > {item.quantity} x {item.name}</StyledTypography>
                <StyledTypography>{formatMoney(lastPrice * item.quantity)}</StyledTypography>
              </StyledBox>
            )
          }
        )
      }
      <StyledDivider/>
      <StyledBox>
        <StyledTypography>{t('cart')}</StyledTypography>
        <StyledTypography>{formatMoney(order.totalWithoutDiscount)}</StyledTypography>
      </StyledBox>
      {order.totalDiscount > 0 && <StyledBox>
        <StyledTypographyDiscount>{t('discount')}</StyledTypographyDiscount>
         <StyledTypographyDiscount>{formatMoney(-order.totalDiscount)}</StyledTypographyDiscount>
      </StyledBox>}

      <StyledBox>
        <StyledTypography>{t('shippingCost')}</StyledTypography>
        <StyledTypography>{formatMoney(order.shippingMethod ? order.shippingMethod.price : 0)}</StyledTypography>
      </StyledBox>
      <StyledBox>
        <StyledTypography>{t('tax')}</StyledTypography>
        <StyledTypography>{formatMoney(order.totalTax)}</StyledTypography>
      </StyledBox>
      <StyledDivider/>
      <StyledBox>
        <StyledTypography style={{fontWeight: 600}}>{t('ordersum')}</StyledTypography>
        <StyledTypography style={{fontWeight: 600}}>{formatMoney(order.totalWithTaxAndShipping)}</StyledTypography>
      </StyledBox>
    </Box>
  ) : (<></>)
}

import React, {FormEvent, useEffect} from "react";
import {Box} from "@mui/material";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {StripePaymentElementOptions} from "@stripe/stripe-js";
import {
  order,
  setElementsExist,
  setPaymentIsLoading,
  setStripeExist
} from "../../../reducers/cart";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {addTask, deleteTask} from "../../../reducers/styleControls";
import {toastr} from "react-redux-toastr";
import {useTranslation} from "react-i18next";
import {PaymentIntentResult} from "@stripe/stripe-js/types/stripe-js/stripe";
interface CheckoutFormProps {
  paymentElementOptions: StripePaymentElementOptions,
  validate: () => boolean,
}
export default function CheckoutForm(props: CheckoutFormProps) {
  const {paymentElementOptions, validate} = props
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch()
  const {t} = useTranslation('checkoutPage')
  const internalOrderNumber = useAppSelector(state => state.cart.internalOrderNumber)
  const comment = useAppSelector(state => state.cart.comment)


  //const [message, setMessage] = useState<string>('');
  useEffect(() => {
    if (!stripe) {
      return;
    } else {
      dispatch(setStripeExist(true))
    }
    if (!elements) {
      return;
    } else {
      dispatch(setElementsExist(true))
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    /*stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}: any) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });*/
  }, [stripe]);

  const handleSubmit = async (e: FormEvent) => {


    e.preventDefault();

    if (!stripe || !elements || !validate()) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    addTask()
    setPaymentIsLoading(true)

    const results: PaymentIntentResult = await stripe.confirmPayment({
      elements,
/*      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
      },*/
      redirect: "if_required",

    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    deleteTask()


/*
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
*/


    if (results.error) {
      toastr.error(t('toastMessage:error'), results.error.message?results.error.message:t('messages:paymentFailed'));
    } else if (results.paymentIntent && results.paymentIntent.status === "succeeded") {
      toastr.info(t('toastMessage:info'), t('messages:paymentSucceeded'))
      dispatch(order({
        comment: comment,
        internalOrderNumber: internalOrderNumber
      }))
    } else {
      toastr.error(t('toastMessage:error'), t('messages:paymentFailed'));
    }
    setPaymentIsLoading(false)
  }

  return (
      <Box>
          <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
        </form>
      </Box>
  )
}
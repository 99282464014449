import React, {useEffect} from 'react';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { Box } from "@mui/material";
// import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hook";
import ReduxToastr, {toastr} from "react-redux-toastr";
import {closeSnack} from "../../reducers/start";
import { primaryColor } from '../../theme';

export default function ErrorSnackBar() {

    // const {t} = useTranslation('common')

    const errors = useAppSelector(state => state.start.error)
    const successMessage = useAppSelector(state => state.start.successMessage)
    const dispatch = useAppDispatch()
    const toastOptions = {
      onShowComplete: () => dispatch(closeSnack()),
      // showCloseButton: false,
    };
    const cleanSuccessMessage=()=>{
      toastr.clean();
    }
    useEffect(() => {
        if (errors) {
            errors.map(e => {
                if (e.message) toastr.error('', e.message);
                else if(!e.message && e) toastr.error('', e);
                return e
            })
        }
              setTimeout(cleanSuccessMessage, 5000);
    }, [errors])

    useEffect(()=>{
        if(successMessage !== ''){
            toastr.success('', successMessage, toastOptions)
            setTimeout(cleanSuccessMessage, 5000);
        }
        }, [successMessage]);

const boxStyles={
    '& .rrt-left-container, .rrt-right-container': {
        visibility: 'hidden',
      },
      '& .bottom-left': {
        width: '100%',
      },
      '& .redux-toastr .toastr': {
        width: {lg: 'calc(100vw / 3)'},
        maxWidth: {lg:'844px'},
        minHeight: '50px',
        margin: '0 auto 5px',
      },
      '& .toastr.rrt-success': {
        backgroundColor: primaryColor.successMessageBgColor,
      },
      '& .toastr.rrt-info': {
        backgroundColor: primaryColor.infoMessageBgColor,
      },
      '& .toastr.rrt-error': {
        backgroundColor: primaryColor.errorMessageBgColor,
      },
      '& .redux-toastr .toastr .rrt-middle-container': {
        textAlign: 'center',
        margin: '20px auto',
        width: '100%',
      },
    };

    return (
       <Box sx={boxStyles}>
        <ReduxToastr
            timeOut={5000}
            newestOnTop={false}
            preventDuplicates
            position='bottom-left'
            transitionIn='bounceIn'
            transitionOut='bounceOut'
            closeOnToastrClick
        />
        </Box>
    )
}

import * as React from 'react';
import {Dispatch} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Category, ListingListTypes} from "../../../interfaces/backend";
import {Box, Typography} from "@mui/material";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import {defaultFonts, primaryColor} from "../../../theme";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface TreeProps {
  category: Category,
  closeFilter?: boolean,
  name?: string
  setSelectedItem: Dispatch<React.SetStateAction<string>>,
  type?: ListingListTypes
}

const TreeItemStyle =
    {
      '& .MuiTreeItem-content': {
          background: primaryColor.treeItemBackgroundColor,
          marginY: '2px',
          height: '45px',
     
        flexDirection: 'row-reverse',
        opacity: 0.5,
        color: primaryColor.treeItemsColor,
        '&.Mui-expanded': {
          opacity:1,
          color: primaryColor.treeItemsColor,
          background: primaryColor.treeParentBackgroundColor

        },
        '&.Mui-selected': {
          opacity:1,
        },
        '.MuiTreeItem-iconContainer': {
          marginLeft: '20px',
          "svg":
          {
            fontSize: '25px'
          }
        },
        '.MuiTreeItem-label': {
          fontWeight: 400,
          fontSize: defaultFonts,
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }
      },
    }



const Tree = (params: TreeProps) => {
  const {category, closeFilter, setSelectedItem, name, type} = params

  const Node = (props: TreeProps) => {
    const {category} = props
    return (
        <TreeItem sx={TreeItemStyle} nodeId={category.alias} label={category.name}>
          {category.children.map(cat =>
              <Node category={cat} setSelectedItem={setSelectedItem} key={cat.alias}/>
          )}
        </TreeItem>
    )
  }
  return (
      <Box sx={{
        visibility: closeFilter ? 'hidden' : 'visible',
        // paddingRight: '80px',
        marginBottom: '20px'
      }}>
        <Typography
            variant={'h20'}
            sx={{
              // mt: '74px',
              // mb: '15px',
              // pl: '0px',
              fontWeight: 300,
              textTransform: 'uppercase',
              // paddingLeft: '8px',
              '&.MuiTypography-root':{
                pl: 0
              }
            }}
        >
          {name}
        </Typography>
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandLessIcon />}
            defaultExpandIcon={<ExpandMoreIcon/>}
            defaultSelected={type ? type : ""}
            defaultExpanded={[type ? type : ""]}
            onNodeSelect={(event: React.SyntheticEvent, nodeIds: string) => {
              setSelectedItem(nodeIds)
            }}
            sx={{
              flexGrow: 1, 
              maxWidth: '100%', 
              // overflowY: 'auto',
              '&.MuiTreeItem-root .MuiTreeItem-content': {
                padding: '0px'
              },

              '.MuiTreeItem-root .MuiTreeItem-content.Mui-selected':{
                opacity:0.9,
                background:  primaryColor.selectedTreeTab,
                '&:hover': {
                opacity:1,
                },
              },
            }}
        >
          <Node category={category} setSelectedItem={setSelectedItem}/>
        </TreeView>
      </Box>
  )
}

export default Tree
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormGroup,
  Typography
} from "@mui/material";
import {FilterAttribute} from "../../../interfaces/backend";
import FilterCheckBox from "../filters/FilterCheckBox";
import React from "react";
import {primaryColor} from "../../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function MobileFilter(props: {
  filter: FilterAttribute,
  handleCheckboxChange: (code: string, content: string) => void
}) {

  const {filter, handleCheckboxChange} = props

  return (
      <Box sx={{
        marginBottom: '30px',
        //width: 'fit-content',

      }}>
        <Accordion sx={{
            backgroundColor: 'transparent',
            color: primaryColor.mobileFilterColor,
          '& .MuiAccordionSummary-expandIconWrapper': {
              color: primaryColor.mobileFilterColor,
          },
          '& .MuiAccordionDetails-root': {
              padding: '0px'
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
            //transform: 'rotate(190deg)'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '0px'
          },
          '& .MuiAccordionSummary-content': {
            paddingRight: '10px',
            margin: '0px'
          },
          '& .MuiAccordionSummary-root': {
            minHeight: '10px',
            padding: '0px',
            width: 'fit-content',
          },

        }}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
            <Typography sx={{fontSize: '1.8rem'}}>
              {filter.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{fontSize: '1.5rem'}}>
            <FormGroup>
              {filter.values.map((item, key) =>
                  <FilterCheckBox
                      item={item}
                      key={key}
                      filterCode={filter.code}
                      handleCheckboxChange={handleCheckboxChange}
                      isMobile={true}
                  />
              )}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </Box>
  )
}
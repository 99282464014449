import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { NavigateButton } from "../SpareParts/subComponents";
import { primaryColor } from "../../theme";

interface NavigateItemProps {
  content: string;
  navigateTo: string;
  variant: "text" | "button";
}

const NavigateItem = (props: NavigateItemProps) => {
  const { content, navigateTo, variant } = props;

  const commonStyles = {
    width: "100%",
    margin: '10px auto' 
  };

  return (
    <>
      {variant === "text" ? (
        <Link to={navigateTo}>
          <Typography 
            sx={{
              color: primaryColor.badgeColor,
              fontSize: "2rem",
              ...commonStyles,
            }}
          >
            {content}
          </Typography>
        </Link>
      ) : (
        <Box sx={{ maxWidth: "288px", ...commonStyles }}>
          <NavigateButton
            content={props.content}
            navigateTo={props.navigateTo}
          />
        </Box>
      )}
    </>
  );
};

export default NavigateItem;

import {callHttp} from "../utils/api";
import {get} from "../utils/httpUtil";
import {GET_ARTICLES} from "../constants/api";
import {put, takeEvery} from "redux-saga/effects";
import {openErrorSnack, setArticles} from "../reducers/start";
import {getArticlesAction} from "../interfaces/actions";
import {Article} from "../interfaces/backend";

export function* getArticles(action: getArticlesAction) {
  const {perPage} = action.payload
  try {
    const articles: Array<Article> = yield callHttp(get, GET_ARTICLES({perPage}))
    yield put(setArticles(articles));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export default function* startSaga() {
  yield takeEvery('start/getArticles', getArticles)
}
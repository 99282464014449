import {put, takeEvery} from 'redux-saga/effects';

import {FIND_PRODUCT, FIND_QUICK} from '../constants/api';

import {setFindProducts, setQuickSearchOptions} from '../reducers/quickSearch';
import {callHttp, callHttpWithoutSpinner} from '../utils/api';
import {get} from '../utils/httpUtil';
import {openErrorSnack} from '../reducers/start';
import {Machine, SearchSuggestionElement} from "../interfaces/backend";
import {searchAction} from "../interfaces/actions";


export function* getQuickSearchOptions(action: searchAction) {
  try {
    const code: Array<SearchSuggestionElement> = yield callHttpWithoutSpinner(
      get,
      FIND_QUICK(action.payload)
    );
    yield put(setQuickSearchOptions(code));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getFindProducts(action: searchAction) {
  try {
    const results: Array<Machine> = yield callHttp(
      get,
      FIND_PRODUCT(action.payload)
    );
    yield put(setFindProducts(results));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}


export default function* quickSearchSaga() {
  yield takeEvery('quickSearch/getQuickSearchOptions', getQuickSearchOptions);
  yield takeEvery('quickSearch/getFindProducts', getFindProducts);
}
import {SvgIcon} from "@mui/material";

export default function CheckoutArrow(props: {  rotate?: boolean, fill?: string, opacity?: string }) {
    const { rotate, fill, opacity} = props


    return (
            <SvgIcon
                sx={
                    {
                        transform: rotate ? 'rotate(180deg)' : '',
                        transition: 'transform 500ms',
                        width: "27px",
                        height: "14px",
                        fill: fill ? fill : !rotate ? "#FF623E" : "white",
                        opacity: opacity
                    }
                }
                viewBox="0 0 27 14"
                // fill= rotate ? "#FF623E" : "white"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0366 0.520014C13.4703 0.519167 13.8906 0.670214 14.2246 0.946936L25.3617 10.2279C25.7407 10.5429 25.9791 10.9957 26.0244 11.4865C26.0696 11.9773 25.918 12.466 25.603 12.8451C25.2879 13.2241 24.8352 13.4625 24.3443 13.5078C23.8535 13.553 23.3648 13.4014 22.9858 13.0864L13.0366 4.77067L3.08747 12.7894C2.89761 12.9436 2.67914 13.0587 2.44463 13.1282C2.21012 13.1977 1.9642 13.2201 1.72098 13.1942C1.47777 13.1684 1.24207 13.0947 1.02743 12.9774C0.812782 12.8602 0.62343 12.7016 0.470253 12.511C0.300264 12.3201 0.17152 12.0962 0.0920888 11.8533C0.0126592 11.6103 -0.0157469 11.3536 0.00865004 11.0992C0.033045 10.8448 0.109717 10.5982 0.233864 10.3748C0.358012 10.1514 0.526961 9.95601 0.730122 9.80093L11.8672 0.835565C12.2108 0.602585 12.6225 0.491483 13.0366 0.520014Z"
                      // fill= "white"
              />
            </SvgIcon>
    )
}

import {all, fork} from 'redux-saga/effects';
import staticSaga from './staticSaga';
import quickSearchSaga from "./quicksearch";
import startSaga from "./startSaga";
import metadataSaga from "./metadataSaga";
import machineRootSaga from "./machineSaga";
import cartSaga from "./cartSaga";
import authSaga from "./authSaga";
import productDetailsSaga from "./productDetailsSaga";
import sparePartSaga from "./sparePartSaga";
import checkoutSaga from "./checkoutSaga";
import myAccountSaga from "./myAccountSaga";
import addressSaga from "./addressSaga";
import quickOrderSaga from "./quickOrderSaga";

export default function* saga() {
  yield all([
    fork(staticSaga),
    fork(quickSearchSaga),
    fork(startSaga),
    fork(metadataSaga),
    fork(machineRootSaga),
    fork(cartSaga),
    fork(authSaga),
    fork(productDetailsSaga),
    fork(sparePartSaga),
    fork(checkoutSaga),
    fork(myAccountSaga),
    fork(addressSaga),
    fork(quickOrderSaga)

  ])
}

import React from 'react';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Order} from "../../../../interfaces/backend";
import {StyledHeadTableCell} from "../../../Cart/styleElements";
import {countTotalItems} from "../../../../utils/productFunctions";
import OrdersTableItemDesktop from "./OrdersTableItemDesktop";

interface OrdersTableProps {
  order: Order
}

export default function OrdersTableDesktop(props: OrdersTableProps) {
  const {t} = useTranslation('myAccount')
  const {items} = props.order

  return (
    <Box sx={{
      width: {xs: 'auto', lg: 'calc(100% + 55px)'}
    }}>
      <Box sx={{fontSize: '2rem', fontWeight: '700',}}>{t('order')}</Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeadTableCell>
              {countTotalItems(items)} {t('items')}
            </StyledHeadTableCell>
            <StyledHeadTableCell>{t('data')}</StyledHeadTableCell>
            <StyledHeadTableCell sx={{textAlign: 'right'}}>
              {t('productPrice')}
            </StyledHeadTableCell>
            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('quantity')}</StyledHeadTableCell>
            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('subtotal')}</StyledHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(row => <OrdersTableItemDesktop item={row} key={row.code}/>)}
        </TableBody>
      </Table>
    </Box>
  )
}


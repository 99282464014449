import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {formatMoney, getLocaleDateString} from "../../../../utils/localization";
import * as React from "react";
import {Order} from "../../../../interfaces/backend";
import theme, {defaultFonts} from "../../../../theme";
import {useTranslation} from "react-i18next";
export interface ItemHeaderProps {
  order: Order,
  firstName: string,
  lastName: string
}
const ItemHeader = (props: ItemHeaderProps) => {
  const {order, firstName, lastName} = props
  const {t} = useTranslation('myAccount')
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const style = {
    element: {
      marginTop: mobileView?'0px':'10px',
      display: 'flex',
      flexWrap: 'wrap'
    },
    value: {
      fontWeight: 300,
      marginLeft: '6px',
      fontSize: defaultFonts
    },
    description: {
      fontWeight: 700
    }
  }
  return (
      <Grid container
            justifyContent='space-between'
            mt={'20px'}
      >
        <Grid item xs={12} md={6}>
          <Box display={'flex'}>{t('orderDate')}:
            <Typography
                sx={style.value}>
              {getLocaleDateString(order.createdAt)}
            </Typography>
          </Box>
          <Box sx={style.element}>
            <Box>
              {t('orderId')}:
            </Box>
            <Box>
              <Typography sx={style.value}>{order.orderId}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display={'flex'}>{t('orderedBy')}:
            <Typography sx={style.value}>{firstName} {lastName}</Typography>
          </Box>
          <Box sx={style.element}>{t('orderTotal')}:
            <Typography sx={style.value}>{formatMoney(order.total)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
  )
}
export default ItemHeader

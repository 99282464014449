import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hook";
import {forgotPassword} from "../../../reducers/auth";
import {isEmailValid} from "../../../utils/validation";

export default function PasswordResetRequirement() {
  const [email, setEmail] = useState<string>('')
  const {t} = useTranslation('startPage')
  const dispatch = useAppDispatch()
  const [enableButton, setEnableButton] = useState<boolean>(false)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(forgotPassword({email}))
  };

  useEffect(() => {
    setEnableButton(isEmailValid(email))
  }, [email])

  return (
    <>
      <Typography fontSize={"2rem"} mb='20px'>
        {t('resetPassContent')}
      </Typography>

      <Box component={"form"} sx={{width: {xs: "100%", sm: "60%"}}} onSubmit={handleSubmit}>
        <TextField
          label={t('email')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
        />

        <Divider sx={{height: "30px"}}></Divider>

        <Button
          type={"submit"}
          variant="contained"
          disabled={!enableButton}
          sx={{
            width: "100%"
          }}
        >
          {t('passwordResetTitle')}
        </Button>
      </Box>
    </>
  )
}
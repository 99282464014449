import {Grid, useMediaQuery} from "@mui/material";
import LoginHeader from "../components/login/header";
import LoginArea from "../components/login";
import Footer from "../components/common/footer";
import Articles from "../components/login/articles";
import {useAppSelector} from "../hook";
import theme from "../theme";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {selectLng} from "../utils/localization";
import {useRef} from "react";

const LoginPage = () => {
    const reCaptchaSiteKey = useAppSelector(state => state.staticContent.reCaptchaSiteKey);

    return (
        reCaptchaSiteKey ?
            <GoogleReCaptchaProvider
                reCaptchaKey={reCaptchaSiteKey}
                language={selectLng()}
            >
                <LoginPageContent/>
            </GoogleReCaptchaProvider>
            :
            <LoginPageContent/>
    )
}

const LoginPageContent = () => {
    const isRegistrationOpen = useAppSelector(state => state.start.isRegistrationOpen)
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const Ref = useRef<HTMLDivElement>(null);

    return (
        <>
            <Grid container ref={Ref}>
                <Grid item xs={12} md={5}>
                    <LoginHeader/>
                    <LoginArea containerRef={Ref}/>
                </Grid>
                {(!isRegistrationOpen || !mobileView) ?
                    <Grid item xs={12} md={7}>
                        <Articles/>
                    </Grid>
                    : <></>
                }
            </Grid>
            <Footer/>
        </>
    )
}

export default (LoginPage)
import {createTheme} from '@mui/material/styles';
import {createBreakpoints} from '@mui/system';
import React from "react";
import {Box} from "@mui/material";

export const primaryColor = {
    baseTextColor: '#F5F5F5',
    blue: '#0070b2',
    headerColor: '#1F1E1F',
    documentsFilter: '#404040',
    documentsFilterTextColor: '#fff',
    productDetailsBackground: '#1F1E1F',
    productDetailsHoverBackground: '#4F4B4F',
    productDetailsSelectedTabMarker: '#FF6550',
    productDetailsPriceInfo: '#F5F5F5',
    documentsBorder: 'rgba(255, 255, 255, 0.10)',
    textHeader: '#B6B6B6',
    badgeColor: '#D0573B',
    textTopHeader: '#F5F5F5',
    background: '#141414',
    iconColor: '#D0573B',
    whiteText: '#fff',
    drawerList: '#1F1E1F',
    drawerHover: '#D0573B',
    filterText: '#FF6550',
    buttonColor: '#FF6550',
    drawerBackground: '#222222',
    textDecorationColor: '#D0573B',
    searchBox: 'rgba(255, 255, 255, 0.2)',
    articleDescriptionBackground: '#141414',
    articlePageBackground: '#141414',
    staticPageBackground: '#1F1E1F',
    languageSelectTextMobile: 'rgba(255, 255, 255, 0.3)',
    drawerText: 'rgba(255, 255, 255, 0.8)',
    autoCompleteBackground: '#222222',
    autoCompleteItemColor: 'rgba(255, 255, 255, 0.2)',
    borderMiniCart: 'rgba(255, 255, 255, 0.2)',
    whiteIcon: '#fff',
    whiteTab: '#fff',
    linkColor: '#B6B6B6',
    activeTab: '#F5F5F5',
    pageTitle: '#F5F5F5',
    pdpLongDescription: '#F5F5F5',
    backgroundOverlay: '#1F1E1F',
    backgroundSchemaModalOverlay: '#FFFFFF',
    inputLabelTextColor: '#F5F5F5',
    inputTextColor: '#F5F5F5',
    machinePageText: 'rgba(255, 255, 255, 0.5)',
    addressCard: 'rgba(255, 255, 255, 0.05)',
    checkBox: 'rgba(255, 255, 255, 0.1)',
    machinePageDescriptionBackground: 'linear-gradient(322.46deg, #151515 2.62%, #1F1E1F 103.85%)',
    shoppingCartTitle: 'rgba(255, 255, 255, 0.95)',
    documentsCellBackground: '#1F1E1F',
    documentsCellColor: 'rgba(255, 255, 255, 0.95)',
    backgroundTextField: 'rgba(255, 255, 255, 0.1)',
    pdpImageBackground: 'rgba(255, 255, 255, 0.1)',
    discountCheckoutPage: "#FF623E",
    pdpTabFont: "#404040",
    asteriskColor: "#FF623E",
    breadcrumbsColor: 'rgba(255, 255, 255,  0.5)',
    miniCartBackground: 'rgba(255, 255, 255,  0.05)',
    documentsBackground: 'rgba(255, 255, 255,  0.05)',
    sparePartsBackground: '#2B2B2B',
    myAccountTabText: '#F5F5F5',
    pdpTitleText: '#F5F5F5',
    myAccountTabBorder: 'rgba(255, 255, 255, .3)',
    myAccountTabSelected: '#FF623E',
    myAccountDataBackground: '#1F1E1F',
    myAccountTabSelectedBackground: "#2B2B2B",
    myAccountColorLimitText: '#fff',
    sparePartsTop: '#FFFFFF',
    sparePartsText: '#F5F5F5',
    sparePartsButton: 'rgba(255, 255, 255, 0.95)',
    sparePartsSearchPlaceholderTextColor: '#FFFFFF80',
    lineThroughPrice: 'rgba(255, 255, 255, 0.95)',
    machineViewerPopupBackground:'linear-gradient(322.46deg, #151515 2.62%, #1F1E1F 103.85%)',
    machineViewerPopupTextColor: '#fff',
    machineViewerPopupGoToProductBackground: '#FFFFFF0D',
    machineViewerPopupCartBackground: '#D0573B',
    machineViewerPopupCloseIconColor: '#fff',
    carouselWhiteDots: '#fff',
    accountPhoneColor: '#FF6550',
    orangeHoverColor: '#FF6550',
    accountTextColor: '#FFFFFF',
    orderStatusColor: '#9AE053',
    ordersListBackgroundColor: '#1F1E1F',
    ordersListStatusBackgroundColor: '#FFFFFF',
    ordersListStatusRejectedBackgroundColor: '#B91C1A',
    successMessageBgColor: '#5C7F39',
    errorMessageBgColor: '#7F1A1B',
    infoMessageBgColor: '#0F4578',
    quickSearchTextBlackColor: '#F5F5F5',
    quickSearchTextWhiteColor: '#F5F5F5',
    quickSearchWhiteBackground: '#fff',
    grayBackgroundColor:'#353435',
    tableBottomBorderLineColor: "0.5px solid rgba(255, 255, 255, 0.5)",
    selectedPagination: '#FF6550',
    paginationColor: '#FFFFFF',
    treeItemsColor: '#FFFFFF',
    treeItemBackgroundColor: '#1F1F1F',
    treeParentBackgroundColor: '#2B2B2B',
    sparePartsTreeBackground: '#1F1E1F',
    sparePartsTreeParentBackground: '#555555',
    sparePartsTreeBorderBottom: 'rgba(255, 255, 255, 0.1)',
    sparePartsTreeExpandedChildBackgroundColor: '#2B2B2B',
    selectedTreeTab: '#FF6550',
    noDiscountPrice: '#F5F5F5',
    searchBackground: '#1F1E1F',
    searchSuggestHoveredBackground: "#535353",
    searchNotFoundColor: '#F5F5F5',
    blogPageTextColor: '#fff',
    relatedProductsTextColor: '#F5F5F5',
    relatedItemsCardBackground: "linear-gradient(318deg, #151515 0%, #1F1E1F 100%)",
    relatedItemsCardBoxShadow: "5px 5px 14px 0px rgba(0, 0, 0, 0.50), -6px -6px 10px 0px rgba(20, 20, 20, 0.50) inset, 6px 6px 10px 0px rgba(59, 59, 59, 0.25) inset" ,
    pdpBackground: "#2B2B2B",
    clearFilter: '#FFFFFF',
    mobileMenuGrayButton: '#555555',
    orderHistoryTabletBackground: '#2B2B2B',
    mobileFilterColor: '#F5F5F5',
    animatedMenuText: '#fff',
    animatedMenuBackGround: '#1F1E1F',
    animatedMenuArticleTitle: '#fff',
    animatedMenuReadMore: '#D0573B',
    homeWelcomeUserName: '#fff',
    homeMyMachineTitle: '#fff',
    homeGreetingText: '#fff',
    arrowButtonColorSlider:'#fff', 
    inputMessage: '#FF6550',
    outputMessage: 'var(--background-3, #555)',
    messageText: '#F5F5F5',
    textAreaBackground: '#2A292A',
    textAreaColor: '#FFF',
    chatButtonBackground: '#555',
    chatBackground: '#1F1E1F',
    chatImportColor: '#FF6550',
    // quantityChangeMobileArrowBackground: '#2B2B2B',
    quantityChangeMobileArrowBackground: '#555555',
    cartMobileTableItemBackground: '#2B2B2B',
    cartTableDividerColor: "#FFFFFF",
    technicalDataDividerColor: "#FFFFFF",
    userManagementTableMobileBackground: '#2B2B2B',
    addNewUserButtonBackground: '#555',
    userTableMobileItemBackground: "#404040" ,
    userTableMobileColor: "#fff" ,
    outlinedButtonColor: '#555',
    userManagementSortByColor: '#fff',
    userManagementTableItemActiveUser: "#9AE053",
    userManagementTableItemNotActiveUser: "#959595",
    
    sortItemsByDialogBackground: '#1F1E1F',
    sortItemsByDialogHoveredBackground: "#535353",
    sortItemsByTextColor: '#fff',
  
    passwordLengthNoteColor: '#FFFFFF',
  cancellationButtonBackground: '#555',
   
  machinePageDescriptionBackgroundShadow: '5px 5px 14px 0px #00000080,-6px -6px 10px 0px #14141480 inset, 6px 6px 10px 0px #3B3B3B40 inset',
  serviceCustomersListBackground: '#222222'
};

// const secondaryColor = {
//     yellow: '#DED47E',
//     ocher: '#DBCA9F',
//     brown: '#C58E4D',
//     darkTeal: '#9EC1C9',
//     lightTeal: '#E3EEF2',
//     rose: '#EC8972',
//     red: '#c03c12',
//     lightBlue: '#87CCD3',
//     mint: '#8EA998',
//     darkGrayBrown: '#362617',
//     middleGrayBrown: '#867D74',
//     lightGrayBrown: '#D7D4D1',
// };

export const defaultFonts = {
    xs: '1.5rem',
    sm: '1.6rem',
    md: '1.8rem',
    lg: '2.0rem',
}

export const secondaryFonts ={
    xs: '1.4rem',
    sm: '1.5rem',
    md: '1.6rem',
    lg: '1.8rem',
  }

  export const helperTextFonts ={
    xs: '1.1rem',
    sm: '1.4rem',
    md: '1.5rem',
    lg: '1.6rem',
  } 

  export const highlightedFonts ={
    xs: '1.8rem',
    sm: '2.0rem',
    md: '2.2rem',
    lg: '2.4rem',
  }

export const tableFontSize =  {
    contentSecondary: secondaryFonts,
   
    contentMain: defaultFonts
  }

const fontFamily = ['Inter', 'sans-serif'].join(',');

const breakpoints = createBreakpoints({})
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        '2xl': true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        h7: React.CSSProperties;
        h8: React.CSSProperties;
        h25: React.CSSProperties;
        h20: React.CSSProperties;
        h70: React.CSSProperties;
        h15: React.CSSProperties;
        h12: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        h7?: React.CSSProperties;
        h8?: React.CSSProperties;
        h25?: React.CSSProperties;
        h20?: React.CSSProperties;
        h70?: React.CSSProperties;
        h15?: React.CSSProperties;
        h12?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h7: true,
        h8: true,
        h25: true
        h20: true
        h70: true
        h15: true
        h12: true
    }
}


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            '2xl': 1768
        },
    },
    components: {
        MuiPaginationItem: {
          styleOverrides: {
            root: {
              color: primaryColor.paginationColor,
              fontWeight: 400,
              fontSize: '1.6rem',
              '&.Mui-selected': {
                color: primaryColor.selectedPagination
              },
            },
          },
        },
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                html: {
                    fontSize: "62.5%"
                },
                body: {
                    backgroundColor: primaryColor.background,
                    color: primaryColor.baseTextColor
                },
                a: {
                    textDecoration: 'none',
                    color: primaryColor.linkColor,
                    '&:hover': {
                        color: primaryColor.badgeColor
                    }
                }
            }),
        },
        // MuiInputAdornment: {
        //     styleOverrides: {
        //         root: {
        //             marginRight: 3
        //         }
        //     }
        // },
        // MuiAutocomplete: {
        //     styleOverrides: {
                // root: {
                //         //     '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon':
                //         //         {
                //         //             '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
                //         //                 padding: 'unset'
                //         //             }
                //         //         },
                //         //     '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
                //         //         padding: 0
                //         //     },
                //         //     '&.MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
                //         //         padding: 8
                //         //     }
                //         },
                //         inputRoot: {
                //             border: "1px solid #ced4da",
                //             paddingBottom: 0,
                //             '&.Mui-disabled': {
                //                 border: 0,
                //                 borderBottom: "1px solid #ced4da"
                //             }
                //         },
                // endAdornment: {
                //     paddingRight: '20px'
                // },
                //         popper: {
                //             WebkitBoxShadow: '0px 5px 16px 1px rgba(0,0,0,0.21)',
                //             MozBoxShadow: '0px 5px 16px 1px rgba(0,0,0,0.21)',
                //             boxShadow: '0px 5px 16px 1px rgba(0,0,0,0.21)',
                //         },
                //         popupIndicator: {
                //             marginRight: 0
                //         },
                //         input: {
                //             border: 0,
                //             padding: "8px 0 8px 8px!important",
                //             '&.Mui-disabled': {
                //                 paddingLeft: "0!important"
                //             }
                //         }
        //     }
        // },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         root: {
        //             // '&.MuiOutlinedInput-root .MuiAutocomplete-input': {
        //             //     paddingLeft: 0,
        //             // },
        //             '&:hover:not(.Mui-disabled):not(&.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        //                 borderColor: '#ced4da',
        //                 paddingLeft: '0px',
        //             },
        //             '&.Mui-disabled': {
        //                 '& fieldset.MuiOutlinedInput-notchedOutline': {
        //                     borderTop: '0 !important',
        //                     borderLeft: ' 0 !important',
        //                     borderRight: '0 !important',
        //                     borderColor: '#ced4da !important',
        //                 },
        //             },
        //             borderRadius: 0,
        //         },
        //         notchedOutline: {
        //             borderColor: '#ced4da',
        //         },
        //     },
        // },
        // PrivateSwitchBase: {
        //     styleOverrides: {
        //         root: {
        //             padding: '0px',
        //         },
        //     },
        // },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    padding: 'unset',
                    fontSize: '0.65rem',
                    backgroundColor: primaryColor.badgeColor
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    // flexGrow: '1',
                    // maxWidth: 'none',
                    // flexShrink: '1',
                    width: '345px',
                    color: primaryColor.linkColor,
                    backgroundColor: primaryColor.productDetailsBackground,
                    margin: '0 5px 25px',
                    fontSize: '2rem',
                    fontWeight: '400',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    '&:first-of-type': {
                        marginLeft: 0
                    },
                    '&:last-of-type': {
                        marginRight: 0
                    },
                    "&:hover": {
                        backgroundColor: primaryColor.productDetailsHoverBackground,
                        color: primaryColor.whiteText
                    },
                    "&.Mui-selected": {
                        backgroundColor: primaryColor.productDetailsBackground,
                        marginBottom: 0,
                        paddingTop: '15px',
                        paddingBottom: '40px',
                        color: primaryColor.activeTab
                    }
                },
                textColorInherit: {
                    color: primaryColor.linkColor
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: primaryColor.productDetailsSelectedTabMarker,
                    height: 3,
                    top: "0"
                },
            }
        },
        MuiButton: {
            variants:
                [
                    {
                        props: {variant: 'text'},
                        style: {
                            color: primaryColor.whiteText
                        },
                    },
                    {
                        props: {variant: 'contained'},
                        style: {
                            backgroundColor: primaryColor.buttonColor,
                            '&:hover': {
                                fontWeight: 700,
                                backgroundColor: primaryColor.buttonColor,
                            }
                        },
                        
                    },
                    {
                        props: {variant: 'outlined'},
                        style: {
                            backgroundColor: primaryColor.outlinedButtonColor,
                            border: 'none',
                            '&:hover': {
                                fontWeight: 700,
                                backgroundColor: primaryColor.outlinedButtonColor,
                                border: 'none',
                                transition: "none", 
                            }
                        },
                        
                    },
                ],

            styleOverrides: {
                root: {
                    // width: '100%',
                    // maxHeight: '43px',
                    fontWeight: 400,
                    fontSize: '2rem',
                    [breakpoints.down('lg')]: {
                        fontSize: '1.6rem',
                    },
                    [breakpoints.down('md')]: {
                        fontSize: '1.5rem',
                        width: '100%'
                    },
                    padding: '10px',
                    borderRadius: 'unset',
                    textTransform: 'none',
                    minWidth: '170px',
                    '&.Mui-disabled': {
                        opacity: 0.7,
                        backgroundColor: primaryColor.buttonColor,
                        color: primaryColor.textTopHeader,
                    },
                },
            }
        },
                // MuiDialog: {
                //     styleOverrides: {
                //         paper: {
                //             maxHeight: '92vh',
                //             overflowY: 'unset',
                //             borderTop: `5px solid ${primaryColor.buttonColor}`
                //         },
                //     },
                // },
                // MuiDialogTitle: {
                //     styleOverrides: {
                //         root: {
                //             padding: "8px 24px",
                //             fontSize: "1.2rem",
                //             textTransform: "uppercase"
                //         }
                //     },
                // },
                // MuiDialogContentText: {
                //     styleOverrides: {
                //         root: {
                //             color: 'rgba(0, 0, 0, 0.87)',
                //             padding: "10px 0 40px"
                //         }
                //     }
                // },
                // MuiDialogActions: {
                //     styleOverrides: {
                //         root: {
                //             padding: "10px 24px",
                //             display: "flex",
                //             justifyContent: "space-between"
                //         }
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                input: {
            color: primaryColor.inputLabelTextColor,
            fontSize: defaultFonts.lg,
            [breakpoints.down('lg')]: {
            fontSize: defaultFonts.md,
            },
            [breakpoints.down('md')]: {
            fontSize: defaultFonts.sm,
            },
            [breakpoints.down('sm')]: {
            fontSize: defaultFonts.xs,
            },

            // width: '215px',
            // height: '20px',
            // border: '3px solid #D0573B',
            // borderRadius: '2px',
            // backgroundColor: 'black',
            // color: primaryColor.whiteText,
            // padding: '15px 20px',
            // "&::placeholder": {
            //     color: primaryColor.whiteText,
            //     opacity: 0.75
            //   },
            //         backgroundColor: 'white',
            //         border: '1px solid #ced4da',
            //         fontSize: 16,
            //         fontWeight: '300',
            //         padding: '8px 8px',
            //         width: 'calc(100% - 16px)',
            //
            //         '&.Mui-disabled': {
            //             color: `${primaryColor.dark} !important`,
            //             paddingLeft: '0 !important',
            //             paddingRight: '16px !important',
            //             borderTop: '0 !important',
            //             borderLeft: '0 !important',
            //             borderRight: '0 !important',
            //         },
                },
            },
        },
        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             lineHeight: 'unset',
        //
        //             '&.Mui-disabled': {
        //                 color: `${primaryColor.dark} !important`,
        //                 paddingLeft: '0 !important',
        //             },
        //         },
        //     },
        // },
        // MuiFormControlLabel: {
        //     styleOverrides: {
        //         root: {
        //             marginLeft: '-10px',
        //         },
        //     },
        // },
        // MuiPaper: {
        //     styleOverrides: {
        //         elevation1: {
        //             boxShadow: 'none',
        //         },
        //         elevation2: {
        //             boxShadow: 'none',
        //         },
        //     },
        // },
        // MuiAccordionDetails: {
        //     styleOverrides: {
        //         root: {
        //             padding: '20px 0',
        //         },
        //     },
        // },
        // MuiTableRow: {
        //     styleOverrides: {
        //         head: {
        //             height: '24px',
        //         },
        //     },
        // },
        //
        // MuiTableCell: {
        //     styleOverrides: {
        //         root: {
        //             padding: '0 5px',
        //             whiteSpace: 'pre-wrap',
        //             '&:last-child': {
        //                 paddingRight: '5px',
        //             },
        //             color: primaryColor.fontColor,
        //         },
        //         head: {
        //             borderBottom: `2px solid ${primaryColor.buttonColor}`,
        //             color: primaryColor.fontColor,
        //         },
        //         paddingNone: {
        //             padding: '5px 0',
        //         },
        //     },
        // },
        // MuiAccordion: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: 'white',
        //             '&:before': {
        //                 backgroundColor: 'unset',
        //             },
        //         },
        //     },
        // },
        // MuiAccordionPanel: {
        //     styleOverrides: {
        //         root: {
        //             '&:before': {
        //                 height: '0',
        //             },
        //         },
        //     },
        // },

        // MuiAccordionSummary: {
        //     styleOverrides: {
        //         root: {
        //             color: primaryColor.buttonColor,
        //             '&.Mui-expanded': {
        //                 color: primaryColor.buttonColor,
        //             },
        //             padding: 0,
        //             minHeight: '36px !important',
        //             '&:before': {
        //                 bottom: '-1px',
        //                 left: 0,
        //                 right: 0,
        //                 height: '2px',
        //                 content: '""',
        //                 opacity: 1,
        //                 position: 'absolute',
        //                 transition:
        //                     'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        //                 backgroundColor: 'rgba(0, 0, 0, 0.12)',
        //             },
        //         },
        //         content: {
        //             margin: '12px 0 8px !important',
        //         },
        //         expandIcon: {
        //             '&.Mui-expanded': {
        //                 marginRight: '-5px',
        //             },
        //         },
        //     },
        // },
        MuiTypography: {
            variants: [
                {
                    props: {variant: 'h7'},
                    style: {
                        fontSize: '1.6rem',
                        fontWeight: '400',
                        color: primaryColor.textTopHeader
                    },
                },
                {
                  props: {variant: 'h8'},
                  style: {
                    fontSize: '1.8rem',
                    color: primaryColor.textTopHeader
                  },
                },
                {
                    props: {variant: 'h25'},
                    style: {
                        fontSize: '2.5rem',
                        fontWeight: '400',
                        color: primaryColor.textTopHeader,
                        lineHeight: '3.5rem'
                    },
                },
                {
                    props: {variant: 'h20'},
                    style: {
                        fontSize: '2rem',
                        fontWeight: '400',
                        color: primaryColor.textTopHeader,
                        lineHeight: '2.8rem'
                    },
                },
                {
                    props: {variant: 'h12'},
                    style: {
                        fontSize: '1.2rem',
                        fontWeight: '300',
                        color: primaryColor.whiteText,
                        lineHeight: '1.452rem'
                    },
                },
                {
                    props: {variant: 'h70'},
                    style: {
                        fontSize: '7rem',
                        fontWeight: '700',
                        lineHeight: '8.4rem'
                    },
                },
                {
                    props: {variant: 'h15'},
                    style: {
                        fontSize: '1.5rem',
                        fontWeight: '300',
                        lineHeight: '1.8rem'
                    },
                },
            ],
            styleOverrides: {
                body1: {
                    color: 'inherit',
                    //fontFamily: fontFamily,
                    fontWeight: '300',
                },
                body2: {
                    //fontFamily: fontFamily,
                },
                h2: {
                    fontWeight: 700,
                    fontSize: '5rem',
                    letterSpacing: '0.01em',
                    color: primaryColor.whiteText,
                    lineHeight: '61px',
                    [breakpoints.down('lg')]: {
                        fontSize: '1.8rem',
                        lineHeight: '2.1rem',
                    }
                },
                h3: {
                    fontWeight: 700,
                    fontSize: '4.5rem',
                    letterSpacing: '0.01em',
                    color: primaryColor.whiteText,
                    [breakpoints.down('sm')]: {
                        fontSize: '2.6rem',
                        lineHeight: '3.15rem',
                    }
                },
                h5: {
                    fontSize: '2.3rem'
                },
                h6: {
                    fontSize: '2rem',
                    lineHeight: '2.4rem',
                    fontWeight: 500,
                    //fontFamily:fontFamily,
                    fontStyle: 'normal',
                    [breakpoints.down('sm')]: {
                        fontSize: '1.5rem',
                        lineHeight: '1.8rem',
                    }
                },
                subtitle2: {
                    fontSize: '1.2rem',
                    fontWeight: 400,
                    //fontFamily:fontFamily,
                    fontStyle: 'normal',
                    lineHeight: '1.5rem',
                    // color: primaryColor.textTopHeader,
                    // [breakpoints.down('sm')]: {
                    //     fontSize: '0.8rem'
                    // }
                },
            },
        },
        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             margin: '5px 0 4px',
        //             height: '20px',
        //             padding: 0,
        //         },
        //         edgeEnd: {
        //             marginRight: '-5px',
        //         },
        //     },
        // },

        // MuiPrivateTabScrollButton: {
        //     styleOverrides: {
        //         root: {
        //             width: 'unset',
        //         },
        //     },
        // },
        // MuiPrivateTextarea: {
        //     styleOverrides: {
        //         textarea: {
        //             overflowY: 'hidden',
        //         },
        //     },
        // },
        // MuiMenuItem: {
        //     styleOverrides: {
        //         root: {
        //
        //             whiteSpace: 'wrap',
        //             minHeight: '24px',
        //             height: 'auto',
        //         },
        //     },
        //     variants:
        //         [
        //             {
        //                 props: {variant: 'suggestion'},
        //                 style: {
        //                     whiteSpace: 'pre-line',
        //                     wordBreak: 'break-word ',
        //                     overflowWrap: 'break-word',
        //                     hyphens: 'auto',
        //                     textAlign: 'justify',
        //                     fontSize: '16px',
        //                     padding: '4px 12px',
        //
        //                 },
        //             },
        //         ]
        // },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        backgroundColor: primaryColor.backgroundTextField,
                        border: 'unset',
                        '& .MuiInputBase-input.MuiOutlinedInput-input':{
                            color: primaryColor.whiteText
                        }
                    },
                },
            ],
            styleOverrides: {
                root: {
                    label: {
                        // paddingLeft: '20px',
                        color: primaryColor.inputLabelTextColor,
                        opacity: 0.5
                    },
                    width: '100%',
                    borderBottom: '1.5px solid' + primaryColor.iconColor,
                    backgroundColor: 'transparent',
                    '& .MuiInputBase-input.MuiInput-input': {
                        color: primaryColor.inputTextColor,

                        // paddingLeft: '16px',
                        // paddingRight: '16px',
                        "&:focus::placeholder": {
                            color: 'transparent'
                        },
                        "&::placeholder": {
                            opacity: 1,
                        },
                    },
                    "& .MuiInput-input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${primaryColor.background} inset`,
                        WebkitTextFillColor: primaryColor.whiteText,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                        color: primaryColor.inputLabelTextColor,
                        opacity: 1
                    },
                    // '& .MuiFormHelperText-root': {
                    //   },
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard"
            }
        },

        // MuiSwitch: {
        //     styleOverrides: {
        //         root: {
        //             width: 65
        //         },
        //         thumb: {
        //             width: 24,
        //             height: 23,
        //             transform: 'translateY(2.5px)',
        //             color: primaryColor.white
        //
        //         },
        //         track: {
        //             width: '100%',
        //             height: 24,
        //             backgroundColor: '#fafafa',
        //             borderRadius: 26 / 2,
        //             opacity: 1,
        //             border: '1px solid #bdbdbd',
        //         },
        //         switchBase: {
        //             transform: 'translateX(2px)',
        //             '&.Mui-checked + .MuiSwitch-track': {
        //                 backgroundColor: '#52d869',
        //                 opacity: 1
        //             },
        //             '&.Mui-checked': {
        //                 color: '#fafafa',
        //                 transform: 'translateX(20px)',
        //             }
        //         },
        //     },
        // },
        // MuiAvatar: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: "transparent"
        //         }
        //     },
        // },

        MuiCheckbox: {
            defaultProps: {
                icon:
                    <Box
                        component={'img'}
                        src={'/images/checkBox.svg'}
                        height={'20px'}
                    />
                ,
                checkedIcon:
                    <Box
                        component={'img'}
                        src={'/images/Union.svg'}
                        height={'20px'}
                        width={'20px'}
                        sx={{
                            backgroundColor: primaryColor.checkBox,
                        }}
                    >
                    </Box>
            }
        },
        MuiRadio: {
            defaultProps: {
                icon:
                    <Box
                        component={'img'}
                        src={'/images/icons/radioIcon.svg'}
                    />
                ,
                checkedIcon:
                    <Box
                        component={'img'}
                        src={'/images/icons/radioCheckedIcon_new.svg'}
                    />
            }
        },
        MuiFormLabel:{
            styleOverrides: {
                root: {
                      "&.Mui-focused": {
                        color: primaryColor.inputLabelTextColor,
                        opacity: 1
                    },
                }
            }
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: primaryColor.inputLabelTextColor,
                    fontSize: helperTextFonts.lg,

                    [breakpoints.down('lg')]: {
                    fontSize: helperTextFonts.md,
                    },
                    [breakpoints.down('md')]: {
                    fontSize: helperTextFonts.sm,
                    },
                    [breakpoints.down('sm')]: {
                    fontSize: helperTextFonts.xs,
                    },

                }
         }
        },
        MuiInputLabel: {
      styleOverrides: {
        root: {
            color: primaryColor.inputLabelTextColor,
            fontSize: secondaryFonts.lg,
            [breakpoints.down('lg')]: {
            fontSize: secondaryFonts.md,
            },
            [breakpoints.down('md')]: {
            fontSize: secondaryFonts.sm,
            },
            [breakpoints.down('sm')]: {
            fontSize: secondaryFonts.xs,
            },
        },
      },
       },
 
        MuiTooltip: {
          styleOverrides: {
            tooltip: {  
              backgroundColor:  primaryColor.grayBackgroundColor,
              fontSize: '1.2rem'
            // font size for tool tips content will be determined by project manager  
            },
          },
        },
     
    },
    typography: {
        htmlFontSize: 10,
        fontFamily: fontFamily,
        // useNextVariants: true,
        // title: {
        //     fontWeight: 'bold',
        //     color: '#575d6d',
        // },
        // headline: {
        //     fontWeight: 'bold',
        //     color: '#575d6d',
        // },
    },
    palette: {
        primary: {
            main: primaryColor.blue,
        },
        // secondary: {
        //     light: secondaryColor.red,
        //     main: primaryColor.buttonColor,
        //     dark: secondaryColor.red,
        //     lightTeal: secondaryColor.lightTeal,
        //     darkTeal: secondaryColor.darkTeal,
        //     disabled: secondaryColor.lightGrayBrown,
        // },
        // error: {
        //     light: secondaryColor.red,
        //     main: secondaryColor.red,
        //     dark: secondaryColor.red,
        // },
    },
});

export default theme;

import * as React from 'react';
import {Box, Typography} from "@mui/material";
import {useAppSelector} from "../../../hook";
import {primaryColor} from "../../../theme";
import {SettingElement} from "../../../interfaces/backend";
import {Link} from "react-router-dom";

export default function ContactPhone() {
    const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)
    const phoneNumber = settings.find(item => item.name === 'contact_phone' && item.value)
    return (

        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'start',
                mt: '20px'
            }}
        >
            <Box
                component={'img'}
                src='/images/phoneOrange.svg'
                height='24px'
                mr='5px'
            />
            <Link to={`tel:${phoneNumber?.value}`}>
                <Typography fontSize={'2rem'} color={primaryColor.badgeColor}>
                    {phoneNumber?.value}
                </Typography>
            </Link>
        </Box>
    )
}
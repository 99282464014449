import React, {ChangeEvent, useEffect, useState} from 'react';
import qs from 'qs';
import keycode from 'keycode';
import Downshift, {ControllerStateAndHelpers} from 'downshift';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// import {useLocation, useNavigate} from 'react-router';
//
// import {setSearchString} from '../../reducers/search';
import {openSearchBox, setQuickSearchOptions} from '../../reducers/quickSearch';
import theme, {primaryColor} from "../../theme";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {SearchSuggestionElement} from "../../interfaces/backend";
import {downShiftType, inputPropsType, renderSuggestionParams, searchBoxType} from "../../interfaces/general";
import {useAppDispatch, useAppSelector} from "../../hook";
import {useLocation, useNavigate} from "react-router";

function renderInput(inputProps: inputPropsType) {
    const {
        InputProps,
        placeholder,
        //deleteIconElement,
        //searchIconElement,
        dispatch,
        searchBoxOpen,
        mobileView,
        styleRoot,
        styleText,
        inputStyle,
        showClearIcon,
        ...other
    } = inputProps;


    const styledClearIcon = mobileView ?
        {
            marginTop: '12px',
            cursor: 'pointer'
        }
        :
        {
            marginTop: '12px',
            cursor: 'pointer',
            marginLeft: '23px',
        }

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            sx={
                styleRoot || {}
            }
        >
            <Box sx={styleText || {}}>
                <TextField
                    sx={inputStyle ? {
                            '&.MuiFormControl-root.MuiTextField-root .MuiInputBase-input.MuiInput-input':
                                inputStyle as Object
                        }
                        : {}}
                    InputProps={{
                        placeholder: placeholder,
                        // endAdornment: (
                        //     <InputAdornment
                        //         position='end'
                        //         disablePointerEvents={false}
                        //         style={{
                        //             zIndex: '1',
                        //         }}
                        //     >
                        //         {deleteIconElement}
                        //     </InputAdornment>
                        // ),
                        ...InputProps,
                    }}
                    {...other}
                />
            </Box>

            {showClearIcon && <Grid item xs={2} display={'flex'} justifyContent={'start'}>
                <Box
                    component={'img'}
                    src='/images/clearIcon.svg'
                    height='15px'
                    sx={styledClearIcon}
                    onClick={() => dispatch(openSearchBox(!searchBoxOpen))}
                />
            </Grid>}
        </Box>
    );
}

function renderSuggestion(params: renderSuggestionParams) {
    const {suggestion, index, itemProps, mobileView} = params
    const styledMenuItem = mobileView ?
        {
            // borderTop: '5px solid' + primaryColor.background,
            backgroundColor: primaryColor.drawerBackground,
            color: primaryColor.whiteText,
            '&:hover': {
                backgroundColor: primaryColor.drawerHover
            },
        } :
        {
            backgroundColor: primaryColor.drawerBackground,
            color: primaryColor.whiteText,
            '&:hover': {
                backgroundColor: primaryColor.drawerHover
            },
            paddingX: '55px'
        }
    return (
        <MenuItem
            {...itemProps}
            key={index}
            variant={'suggestion'}
            component="div"
            sx={styledMenuItem}
        >
            <Typography
                variant={'h7'}
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                    color: '#FFFFFF',
                    opacity: '0.5'
                }}
            >
                {`${suggestion.code}: ${suggestion.name}`}
            </Typography>
        </MenuItem>
    );
}

// function stateReducer(state: DownshiftState<string>, changes: StateChangeOptions<string>) {
//     switch (changes.type) {
//         case Downshift.stateChangeTypes.keyDownEnter:
//         case Downshift.stateChangeTypes.clickItem:
//             return {
//                 ...changes,
//                 isOpen: state.isOpen,
//                 highlightedIndex: null,
//             };
//         default:
//             return {...changes, selectedItem: changes.inputValue};
//     }
// }

export default function SearchBox(props: searchBoxType) {

    const suggestions: Array<SearchSuggestionElement> = useAppSelector(state => state.quickSearch.suggestions)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const searchBoxOpen = useAppSelector(state => state.quickSearch.searchBoxOpen)
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    // const handleSetPage = () => {
    //     // setPage(0)
    // }
    // const setSearchValueFunction = (value: string) => {
    //     // setSearchValue(value)
    // }

    const [state, setState] = useState<downShiftType>({
        inputValue: "",
        openFound: false
    });

    useEffect(() => {
        setState(prevState => ({...prevState, inputValue: props?.inputValue || ""}))
    }, [props?.inputValue])

    // const findProducts = (pattern: string | null) => {
    //     if (!pattern) return;
    //     dispatch(setSearchString(pattern))
    //     //setSearchValueFunction(pattern)
    //     const search = qs.parse(location.search.replace('?', ''))
    //     // If currently on search page, we need to change one search parameter
    //     const newSearch =
    //         location.pathname === '/search'
    //             ? {...search, searchValue: pattern, page: '1'}
    //             : {searchValue: pattern}
    //
    //     //set the page of the pagination in SearchPage = 0
    //     //handleSetPage()
    //
    //     // if(location.pathname === '/quick-order'){
    //     //     return
    //     // }
    //     navigate({
    //         pathname: '/search',
    //         search: `?${qs.stringify(newSearch)}`,
    //     });
    //     // findProducts(newSearch) => dispatch
    // }

    const handleKeyDown = (event: KeyboardEvent, open: boolean, selectedIndex: number | null) => {
        setState(prevState => ({...prevState, openFound: true}))

        if (keycode(event) === 'enter') {
                const search = qs.parse(location.search.replace('?', ''))
               const newSearch =
                   location.pathname === '/search'
                       ? {...search, searchValue: state.inputValue, page: '1'}
                      : {searchValue: state.inputValue}
               navigate({
                   pathname: '/search',
                   search: `?${qs.stringify(newSearch)}`,
               });
          openSearchBox(!searchBoxOpen)
          window.location.reload();
        }

        // If enter is pressed when list is open we just need to autocomplete instead of search
        /*if (!open || (open && selectedIndex === null))
            findProducts(state.inputValue)*/
        if (props.onKeyDown) {
            props.onKeyDown(event, state.inputValue)
        }

        //setState(prevState => ({...prevState, openFound: false}))
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({...prevState, inputValue: event.target.value}))
        //dispatch(getQuickSearchOptions({searchValue: event.target.value}))
        props.onInputChange(event.target.value)
    }

    // const handleChange = (item: string | null, stateAndHelpers: ControllerStateAndHelpers<string>) => {
    //     console.log("handleChange", item, stateAndHelpers)
    //     if (typeof item === "string") {
    //         localStorage.setItem('searchValue', item)
    //         // if(state.inputValue && !state.openFound){
    //         //     const foundSuggestion = suggestions.find(
    //         //         (suggestion) => suggestion.name === item
    //         //       );
    //         //       if (foundSuggestion) {
    //         //         props.handleInputChange(foundSuggestion.alias);
    //         //     }
    //         // }
    //     }
    //     setState(prevState => ({...prevState, inputValue: item}))
    // }

    const foundItemInSuggestions = (item: string): SearchSuggestionElement | undefined => suggestions?.find(
        (suggestion) => suggestion.name === item
    );

    const handleSelect = (selectedItem: string | null, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        setState(prevState => ({...prevState, inputValue: selectedItem}))
        if (props.onSelect && selectedItem) {
            const foundItem = foundItemInSuggestions(selectedItem);
            if (foundItem) {
                props.onSelect(foundItem)
            }
        }
    }

    // const deleteInputValue = () => {
    //     setState(prevState => ({...prevState, inputValue: ''}))
    // }

    return (
        <Downshift
            id='downshift-multiple'
            inputValue={state.inputValue}
            // onChange={handleChange}
            onSelect={handleSelect}
            isOpen={state.openFound}
            // stateReducer={stateReducer}
        >
            {(
                {
                    getInputProps,
                    getItemProps,
                    isOpen,
                    // inputValue,
                    highlightedIndex
                }
            ) => (
                <div style={{
                    flexGrow: 1,
                    position: 'relative',
                    // marginTop: '86px',
                }}>
                    {renderInput({
                        // fullWidth: true,
                        placeholder: props.placeholder,
                        dispatch: dispatch,
                        searchBoxOpen: searchBoxOpen,
                        InputProps: getInputProps({
                            onFocus: () => {
                                dispatch(setQuickSearchOptions([]));
                            },
                            onChange: handleInputChange,
                            onKeyDown: (e: KeyboardEvent) => {
                                handleKeyDown(e, isOpen, highlightedIndex)
                            },
                            placeholder: props.placeholder,
                        }),
                        showClearIcon: props.showClearIcon,
                        styleRoot: props.styleRoot,
                        styleText: props.styleText,
                        inputStyle: props.inputStyle,
                        mobileView: mobileView
                        // searchIconElement: (
                        //     <Box
                        //         component={'img'}
                        //         src={`/images/Search.svg`}
                        //         alt='Search'
                        //         onClick={() => {
                        //             setState(prevState => ({...prevState, openFound: false}))
                        //             findProducts(state.inputValue);
                        //         }}
                        //     />
                        // )
                    })}
                    {isOpen && (
                        <Box
                            sx={{
                                width: '100%',
                                // position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                zIndex: 1,
                                position: 'absolute'
                            }}
                        >
                            <ClickAwayListener
                                onClickAway={() => setState(prevState => ({...prevState, openFound: false}))}
                            >
                                <Paper
                                    // className ={classes.searchBarPaper}

                                    sx={props.styledPaper || {}}
                                    onClick={() => setState(prevState => ({...prevState, openFound: false}))}
                                    square
                                >
                                    {suggestions &&
                                        suggestions
                                            // .slice(0, 7)
                                            .map((suggestion: SearchSuggestionElement, index: number) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({item: suggestion.name}),
                                                    highlightedIndex,
                                                    mobileView
                                                })
                                            )}
                                </Paper>
                            </ClickAwayListener>
                        </Box>
                    )}
                </div>
            )}
        </Downshift>
    );
}
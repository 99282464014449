import theme, {primaryColor} from "../../../theme";

export const mobileSwiperStyles = {
    slider: {
        color: primaryColor.whiteTab,
        textAlign: 'center'
    },
    slider__swiper_container: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
      display: 'flex',
      alignItems: 'center'

    },
    slider__flex: {
       /* display: "flex",
        alignItems: "flex-start",*/
      textAlign: '-webkit-center',
      // marginBottom: '51px',
      display: "block",

    },
    slider__col: {
        display: "flex",
        //flexDirection: "column",
        //width: "calc(100vw - 102px)",
        //marginRight: "12px",
        marginTop: "27px",
        flexWrap: 'nowrap',
        justifyContent: 'center',
        maxWidth: '360px',
      [theme.breakpoints.down("md")]:{
        //maxWidth: '300px',
        width: 'calc(40vw)',
        justifyContent: 'center'

      }

    },
    slider__nav: {
        minWidth: '60px',
        cursor: "pointer",
        textAlign: "center",
        fontSize: "1.4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
        '&:focus': {
            outline: 'none'
        },
    },
    slider__thumbs_slider__image_swiper_slide_thumb_active: {
        WebkitFilter: "grayscale(0%)",
        filter: "grayscale(0%)",
        opacity: "1"
    },
    slider__thumbs_slider__image: {
        transition: "0.25s",
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)",
        opacity: "0.5",
        '&:hover': {
            opacity: "1"
        },
    },
    slider__thumbs: {
        //height: "calc(400px - 40px)",
        width: '100%',
        swiper_slide_thumb_active: {
            WebkitFilter: "grayscale(0%)",
            filter: "grayscale(0%)",
            opacity: "1",
          display: "flex",

          justifyContent: 'center',

        },
        height: {xs:'60px', sm: '90px', lg:'110px'},
/*
      [theme.breakpoints.down("sm")]:{
            height: '90px'
        },
          [theme.breakpoints.down("xs")]:{
            height: '50px'
}*/
    },
    slider__images: {
        width: "calc(50vw)",
        textAlign: '-webkit-center',
      backgroundColor: primaryColor.pdpImageBackground,

        //height: '500px',
  /*    [theme.breakpoints.down("sm")]:{
        height: '450px',
      }*/
     [theme.breakpoints.down("md")]:{
        width: "calc(90vw)",
      }

    },
    slider__images__slider__image: {
        width: '100%',
        '& img': {
          //background: primaryColor.pdpTabFont,
            display: "block",
            width: "100%",
            transition: '3s',
            objectFit: 'contain',
            cursor: 'pointer',
          //maxHeight: {xs: 'calc(100vw)',sm:'500px', md: '650px'},
          maxHeight:  'calc(70vw)'

       /*      [theme.breakpoints.down("sm")]:{
            height: '350px',
          }*/

        },
        '&:hover': {
            '& img': {
                transform: "scale(1.1)",

            }
        },
    },
    slider__image: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: 'center',
        '& img': {
            background: primaryColor.pdpTabFont,
            display: "block",
            width: "100%",
            height: "90%",
            objectFit: "cover",
            [theme.breakpoints.down("sm")]: {
                height: "90px",
            }
        }
    }
};

import {useAppDispatch, useAppSelector} from "../../../../../hook";
import {useTranslation} from "react-i18next";
import React from "react";
import {Box, BoxProps, Button, ButtonProps, styled, Typography, TypographyProps} from "@mui/material";
import {primaryColor} from "../../../../../theme";
import {formatMoney} from "../../../../../utils/localization";
import DesktopMiniCartTable from "./DesktopMiniCartTable";
import {useNavigate} from "react-router";
import {setMiniCartOpen} from "../../../../../reducers/styleControls";
import AuthDynamicComponent from "../../../../accessControl/AuthDynamicComponent";


const StyledButton = styled((props: ButtonProps) => (
    <Button {...props} variant={'contained'}/>
))(({theme}) => ({
    width: '241px',
    // maxHeight: '43px'
}));

const LeftBox = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
}));

const ButtonBox = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '21px',
    justifyContent: 'space-between'
}));

const QuantityTypography = styled((props: TypographyProps) => (
    <Typography {...props} variant={'h20'}/>
))(({theme}) => ({
    color: primaryColor.filterText,
    fontWeight: 300,
    paddingLeft: '16px',
    textTransform: 'uppercase'
}));


export default function MiniCart() {
    const dispatch = useAppDispatch()
    const {t} = useTranslation('checkoutPage')
    const cart = useAppSelector(state => state.cart.cart)
    const navigate = useNavigate()
    const isMiniCartOpened = useAppSelector(state => state.styleControls.isMiniCartOpened)


    const handleClick = () => {
        dispatch(setMiniCartOpen(false));
        const targetRoute = '/checkout'  
        navigate(targetRoute);
      };

    const renderButton = (content: string) => (
        <StyledButton onClick={handleClick}>
            {content}
        </StyledButton>
    );

    return (
        <>
        {isMiniCartOpened &&
         <Box sx = {{
            position: 'absolute',
            paddingTop: '45px',
            width: '752px',
            right: {xs: "-20px", "2xl": '0px'}
            }}>

            <Box
            sx={{
                maxHeight: '450px',
                padding: '22px 27px 30px 27px',
                backgroundColor: primaryColor.backgroundOverlay,
                width: '100%',
                mt: "18px"
            }}>
                {cart.items && cart.items.length > 0 && (
                    <>
                        <Box>
                            <QuantityTypography>{cart.itemsQuantity}</QuantityTypography>
                            <QuantityTypography sx ={{color: primaryColor.shoppingCartTitle}} >{t('itemsInCart')}</QuantityTypography>
                        </Box>

                        <DesktopMiniCartTable/>

                        <Box sx={{mt: '17px', width: '100%'}}>
                            <LeftBox>
                                <Typography variant={'h20'} sx={{mr: '40px'}}>{t('productCost')}: </Typography>
                                <Typography variant={'h20'}>{formatMoney(cart.total)}</Typography>
                            </LeftBox>
                            <LeftBox>
                                <Typography variant={'h7'} sx={{mt: '5px'}}>{t('taxInfo')}</Typography>
                            </LeftBox>
                        </Box>
                        <ButtonBox>
                            <StyledButton
                                onClick={() => {
                                    dispatch(setMiniCartOpen(false))
                                    navigate('/cart')
                                }}>
                                {t('toCart')}
                            </StyledButton>

                            
                    <AuthDynamicComponent 
                        children={renderButton(t("confirmation"))}
                        fallback={renderButton(t("toCheckoutNeedToLogin"))}
                    />
                        </ButtonBox>
                    </>
                )
            }
            </Box>
        </Box>
        }
    </>
        
    )
}


import * as React from 'react';
import {useRef} from 'react';
import {Box, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../../../theme"
import {Machine} from '../../../../interfaces/backend';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowButton, { arrowButtonEffects } from '../../ArrowButton';
import MachineCarouselItem, {slideMinWidthMobile, slideMinWidthDesktop} from "./MachineCarouselItem";
import BaseCarousel from '..';
import {styled} from '@mui/material/styles';

interface CarouselProps {
  machines: Machine[];
  curved: boolean;

}

const MachineCarousel = (props: CarouselProps) => {
  const {machines, curved} = props;
  const smView = useMediaQuery(theme.breakpoints.down('sm'))
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  //const lgView = useMediaQuery(theme.breakpoints.down('lg'))
  const xXlView = useMediaQuery(theme.breakpoints.down('2xl'))

  const sliderRef = useRef<Slider>(null);

  const handlePrevClick = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current?.slickNext();
  };

  const machinesElements = machines.map((machine, index) => {
    return (
      <MachineCarouselItem
        key={index}
        machine={machine}
      />
    );
  });

  const showArrows: boolean = (smView && machines.length > 1) || ((mobileView && machines.length > 2)) || machines.length >= 4;

  const clampValue = (max: number): number => {
    return Math.min(max, machines.length);
  }

  const setMaxWidth = (slideWidth: number): string => {
    const slidesToShow = clampValue(3)
    return `${slidesToShow * slideWidth}px`;
  }

  const maxWidthMobile: string = setMaxWidth(slideMinWidthMobile);
  const maxWidthDesktop: string = setMaxWidth(slideMinWidthDesktop);

  const slideNumber: number = clampValue(3);

  const SliderWrapper = styled(Box)({
    height: '310px',
    width: '100%',
    maxWidth: curved ? '100%' : (mobileView ? maxWidthMobile : maxWidthDesktop),
    minWidth: `${slideMinWidthMobile}px`,
    '& .slick-slider:after, & .slick-slider:before': {
      content: '""',
      height: '30px',
      width: '102%',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: curved ? primaryColor.background : 'transparent',
      left: -1,
      right: -1,
      zIndex: 1,
    },
    '& .slick-slider:before': {
      top: -1,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .slick-slider:after': {
      bottom: 55,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& .slideName': {
      opacity: showArrows ? 0 : 1,
    },
    '& .slick-active .slideName': {
      opacity: 1,
    },
    '& .slick-slide:hover .slideName': {
      color: primaryColor.badgeColor
    },
  });

  const arrowColor = primaryColor.arrowButtonColorSlider;
  const responsiveSettings = [{
    variableWidth: true,
    centerMode: true,
    breakpoint: theme.breakpoints.values.sm,
    settings: {
      slidesToShow: 1,
      centerPadding: '0px'

    }
  },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: clampValue(2),
        centerPadding: '0px'

      }
    },
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: slideNumber,
        centerPadding: '0px'

      }
    },
    {
      breakpoint: theme.breakpoints.values['xl'],
      settings: {
        slidesToShow: slideNumber,
        centerPadding: '80px'

      }
    },
    {
      breakpoint: theme.breakpoints.values['2xl'],
      settings: {
        slidesToShow: slideNumber,
        centerPadding: curved ? '200px' : '10px'
      },
    }
  ]

  const buttonPosition = smView ? '-10px'
    : xXlView ? '-20px'
      : '-55px';

  const arrowStyles = {
 
    height: {
      xs: "200px",
      lg: "220px"

    },
    padding: {
      xs: '90px 5px',
      md: '90px 8px',
    },
 
    zIndex: 2,
    position: 'absolute',
    top: {
      xs: 0,
      lg: 15
    },
   ...arrowButtonEffects
  };


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'bottom',
      position: 'relative',

    }}>

      {showArrows &&
          <Box onClick={handleNextClick}
               sx={{right: buttonPosition, ...arrowStyles}}
          >
              <ArrowButton
                  direction={"right"}
                  color={arrowColor}
                  width={40}
              />
          </Box>
      }

      <SliderWrapper>
        <BaseCarousel
          ref={sliderRef}
          carouselItems={machinesElements}
          responsive={responsiveSettings}
          centerPadding={curved ? '220px' : '10px'}
          slidesToShow={slideNumber}
          infinite={curved && showArrows}
        />
      </SliderWrapper>

      {showArrows &&
          <Box onClick={handlePrevClick} sx={{left: buttonPosition, ...arrowStyles}}>
              <ArrowButton
                  direction={"left"}
                  color={arrowColor}
                  width={40}
              />
          </Box>
      }
    </Box>
  );
}
export default MachineCarousel;
import { PageCategory } from "../../interfaces/backend";
import StaticArticlePage from "./StaticArticlePage";
import StaticTermsPage from "./StaticTermsPage";

interface staticPageProps {
  category: string;
}
 
const StaticPage =(props: staticPageProps) =>{

const {category} = props;

  switch (category) {
    case PageCategory.Article:
    case PageCategory.Page:
      return <StaticArticlePage category={category} />;
    case PageCategory.Term:
      return <StaticTermsPage />;
    default:
      return null;
  }
}

export default StaticPage;
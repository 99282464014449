import * as React from 'react';
import {MenuHomePage} from "../../interfaces/backend";
import {useAppSelector} from "../../hook";
import MenuBox from "./MenuBox";
import {Box} from "@mui/material";

export default function MobileHome() {
    const menus = useAppSelector(state => state.staticContent.menuHomePage)
    return (
        <>
            {
                menus.length >0 && <Box sx={{paddingY: 2}}>
                    {menus.map((menu: MenuHomePage, index: number) =>
                        <MenuBox key={index} menu={menu}/>
                    )}
                </Box>
            }
        </>
    )
}
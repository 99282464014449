import {useMediaQuery} from "@mui/material";
import theme from "../../../theme";
import CustomerSupportServiceDesktop from "../data/CustomerSupportServiceDesktop";
import MobileCustomerSupportService from "./mobile";
import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {getMyMessages, sendMyMessage, setMyMessages} from "../../../reducers/myAccount";
import initializeEventSource from "../../../utils/sensorSocket";
import {MY_MESSAGES_DISCOVER} from "../../../constants/api";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {MyMessage} from "../../../interfaces/backend";

export interface CustomerSupportServiceProps {
    setTextMessage: Function,
    handleSend: Function,
    textMessage: string,
    myMessages: MyMessage[],
    handleUIEvent: Function

}
const CustomerSupportService = () => {
    const MESSAGES_COUNT = 24
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const myMessages = useAppSelector(state => state.myAccount.myMessages)
    const dispatch = useAppDispatch()
    const initialized = useRef(false)
    const retrieved = useRef(false)
    const [textMessage, setTextMessage] = useState<string>("");
    const [height, setHeight] = useState<number>(0);
    const el = document.getElementById("messagesList")
    useEffect(() => {
            if (el && height!=0)
            {
                el.scrollTo(0, el.scrollHeight-height)
                setHeight(0)
            } else {
                el && el.scrollTo({top: el.scrollHeight, behavior: myMessages.length <= MESSAGES_COUNT?"auto":"smooth"})
            }
        }
        , [myMessages])

    const setData = (data: MyMessage[]) => {
        dispatch(setMyMessages({items: data, isNewMessage: true}))
    }
    const handleUIEvent = (e: SyntheticEvent) => {
        if(el && e.currentTarget.scrollTop === 0)
        {
            setHeight(el.scrollHeight)
            dispatch(getMyMessages({fromMessage: myMessages[myMessages.length - 1].code}))
        }
    };
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            initializeEventSource(
                MY_MESSAGES_DISCOVER,
                getData,
                setData
            );
        }
    }, []);

    const getData = () => {
        if(!retrieved.current)
        {
            retrieved.current = true
            dispatch(getMyMessages({}))
        }
    }

    const handleSend = () => {
        dispatch(sendMyMessage({message: textMessage}))
        setTextMessage("")

    }
    return mobileView ? <MobileCustomerSupportService setTextMessage={setTextMessage} handleSend={handleSend} textMessage={textMessage} myMessages={myMessages} handleUIEvent={handleUIEvent}/> :
        <CustomerSupportServiceDesktop setTextMessage={setTextMessage} handleSend={handleSend} textMessage={textMessage} myMessages={myMessages} handleUIEvent={handleUIEvent}/>
}

export default (CustomerSupportService)

import {useAppDispatch} from "../../../../hook";
import {useLocation, useNavigate} from "react-router";
import {useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../../../theme";
import SearchBox from "../../searchBox";
import {getQuickSearchOptions, openSearchBox} from "../../../../reducers/quickSearch";
import {SearchSuggestionElement} from "../../../../interfaces/backend";
import keycode from "keycode";
import qs from 'qs';
import {useTranslation} from "react-i18next";
import { forwardRef } from "react";

const Search = forwardRef(() => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const {t} = useTranslation('common')

    const styledPaper = mobileView ?
        {
            width: '100%',
            backgroundColor: primaryColor.drawerBackground,
        }
        :
        {
            width: '66.7%',
            marginTop: '20px',
            paddingY: '35px',
            backgroundColor: primaryColor.drawerBackground,
            maxHeight: '400px',
            overflow: 'scroll'
        }

    const handleInputChange = (searchValue: string) => dispatch(getQuickSearchOptions({searchValue: searchValue}));

    const handleOnSelect = (item: SearchSuggestionElement) => {
        dispatch(openSearchBox(false))
        navigate(`/product/${item.alias}`);
    }

    const handleKeyDown = (event: KeyboardEvent, inputValue: string | null) => {
        if (keycode(event) !== 'enter' || !inputValue) return;

        const search = qs.parse(location.search.replace('?', ''))
        // If currently on search page, we need to change one search parameter
        const newSearch =
            location.pathname === '/search'
                ? {...search, searchValue: inputValue, page: '1'}
                : {searchValue: inputValue}

        navigate({
            pathname: '/search',
            search: `?${qs.stringify(newSearch)}`,
        });
    }


    return (
        <SearchBox
            onInputChange={handleInputChange}
            onSelect={handleOnSelect}
            onKeyDown={handleKeyDown}
            showClearIcon={true}
            styleRoot={
                mobileView ?
                    {
                        marginTop: '90px',
                    }
                    :
                    {
                        marginTop: '270px',
                    }
            }
            styleText={{ml: '15%', width: '65%'}}
            placeholder={t('search') as string}
            styledPaper={styledPaper}
        />
    )
})

export default Search;
import React, {ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {addPaymentMethod, getPaymentMethods} from "../../reducers/cart";
import RadioGroup, {RadioGroupItem} from "./RadioGroup";
import {PaymentMethod} from "../../interfaces/backend";
import StripePayment from "../../components/checkout/StripePayment";
import {Box} from "@mui/material";

export interface PaymentMethodsProps {
  validate: () => boolean
}

const RadioGroupItemContent = (props: {
  data: PaymentMethod,
  validate?: () => boolean,
  selectedItem: PaymentMethod
}) => {
  const {id, name, paymentProvider} = props.data;
  const {validate, selectedItem} = props;

  return (
    <Box sx={{
      width: {
        xs: "100%",
        sm: "472px",
        md: "590px"
      }
    }}>
      {name}

      {validate && paymentProvider === 'payment-stripe' && id === selectedItem?.id &&
          <Box sx={{
            mt: "10px"
          }}>
              <StripePayment validate={validate}/>
          </Box>
      }
    </Box>
  )
}

export default function PaymentMethods(props: PaymentMethodsProps) {
  const dispatch = useAppDispatch()
  const {validate} = props
  const paymentMethods: PaymentMethod[] = useAppSelector(state => state.cart.paymentMethods)
  const selectedMethod = useAppSelector(state => state.cart.checkoutCart.paymentMethod)

  useEffect(() => {
    dispatch(getPaymentMethods())
  }, [])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(addPaymentMethod({method: event.target.value}))
  }

  const radioGroupOptions: RadioGroupItem[] = paymentMethods?.map(
    (option): RadioGroupItem => ({
      id: option.id.toString(),
      content: <RadioGroupItemContent data={option} validate={validate} selectedItem={selectedMethod}/>
    })
  ) || [];

  return (
    <RadioGroup
      options={radioGroupOptions}
      value={selectedMethod?.id?.toString()}
      handleChange={handleChange}
    />
  )
}
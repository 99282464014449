import {Details} from "../../../interfaces/backend";
import React from "react";
import {Box, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {primaryColor} from "../../../theme";


const TechnicalDetailsList = (props: { details: Details }) => {
    const {details} = props;
    return (
        <Box sx={{
            '& .MuiTableCell-root': {
                padding: '10px 10px 5px 10px',
                color: primaryColor.textHeader,
            },
            '& .MuiTableRow-root': {
                display: 'flex'
            },
            '& .MuiTableContainer-root': {
                paddingTop: '20px',

            }
        }}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {details?.technicalDetails && details.technicalDetails.map((techDetails, index) => {
                                return (
                                    <TableRow key={techDetails.attribute.code}>
                                        <TableCell sx={{
                                            width: '70%',
                                            fontWeight: '400',
                                            fontSize: '2rem'
                                        }}>{techDetails.attribute.name}</TableCell>
                                        <TableCell sx={{width: '30%', textAlign: 'end'}}>{techDetails.isHtml ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: techDetails.content,
                                                }}
                                            />
                                        ) : (
                                            techDetails.content ? techDetails.content : "-"
                                        )}</TableCell>
                                    </TableRow>
                                )
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default (TechnicalDetailsList)

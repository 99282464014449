import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {SettingElement} from "../../../interfaces/backend";
import {Box} from "@mui/material";
import React from "react";
import {setOpenedDrawerID} from "../../../reducers/static";


const Logo = (props: { mobileView: boolean }) => {
    const {mobileView} = props
    const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)
    const logo = settings?.find((x: { name: string }) => x.name === 'logo_url')?.value
    const dispatch = useAppDispatch()
    return (
        <Link to={'/'} onClick={() => dispatch(setOpenedDrawerID(''))}>
            <Box component={"img"}
                 src={logo}
                 alt={'logo'}
                 sx={{
                     width: !mobileView ? '100%' : 'unset',
                     maxWidth: '148px',
                     maxHeight: !mobileView ? '74px' : '36px',
                     display: 'flex',
                     alignItems: 'flex-start'
                 }}
            />
        </Link>
    )
}

export default React.memo(Logo)
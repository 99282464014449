import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {useEffect,  useState} from "react";
import { openEditUserForm, openNewUserForm, patchUser} from "../../../reducers/myAccount";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";
import {StyledTableCell} from "../../../pages/Cart/styleElements";
import {BrokenText, ContainerBox, TopBox} from "../../../pages/Account/UserManagement/style";
import {User} from "../../../interfaces/backend";
import {formatMoney} from "../../../utils/localization";
import EnhancedTableHead from '../../../pages/Account/UserManagement/EnhancedHeadTable';
import IOSSwitchComp from "../../../pages/Account/UserManagement/IOSSwitchComp";
import CustomPagination from "../../../pages/ListingPage/Pagination";
import theme, {primaryColor} from "../../../theme";
import TableCell from "@mui/material/TableCell";
import { useMediaQuery } from '@mui/material';
import { useUserTableData } from './userHelper';
import UserTableMobile from './userTableMobile';
import { getComparator, stableSort, Order, useConfirmationDialog } from './userHelper';
import ConfirmationDialog, { ConfirmationContent } from '../../common/Dialogs/ConfirmationDialog';
import { StyledTypography } from '../../common/StyledComponents';


export default function SortedTable({users}: { users: User[] }) {

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof User | string>('username');
    const [filteredData, setFilteredData] = useState<User[]>(users);
    const [page, setPage] = React.useState(0);
    //const [rowsPerPage, setRowsPerPage] = React.useState(4);
    const rowsPerPage = 10;
    const dispatch = useAppDispatch()
    const {t} = useTranslation('myAccount')
    const lgView = useMediaQuery(theme.breakpoints.down('lg'))
 
    const {
        isConfirmationOpen,
        handleDeleteIconClick,
        closeConfirmationDialog,
        onConfirm,
      } = useConfirmationDialog();

      
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof User | string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (newPage: number) => {
        setPage(newPage - 1);
    };

    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            // @ts-ignore
            stableSort(filteredData, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, filteredData],
    );
    const handleChange = (user: User) => {
        const temp = {...user, isEnabled: !user.isEnabled}
        dispatch(patchUser({user: temp}))
    };

    const { numberOfActiveUsers } = useUserTableData(
		users
	  );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        let nameFilter = e.target.value.toLowerCase();
        let searchedUsers = users.filter((x) =>
            (x.firstName + ' ' + x.lastName).toLowerCase().includes(nameFilter)
        );
        setFilteredData(searchedUsers)
    }

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const paginationArray = Array.from({ length: totalPages }, (_, i) => i + 1);
    

    useEffect(() => {
        setFilteredData(users)
    }, [users])


    return (
        <>{lgView ?
         <UserTableMobile /> 
               :
        <ContainerBox>
            <TopBox>
                <StyledTypography sx={{marginRight: '20px'}}>{users?.length} {t('allUsers')}</StyledTypography>
                <StyledTypography>{numberOfActiveUsers} {t('activeUsers')}</StyledTypography>
            </TopBox>
            {visibleRows &&
            <Box sx={{display: 'flex'}}>

                <TableContainer sx={{overflowX: 'unset'}}>
                    <Table
                        sx={{minWidth: 720}}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            handleSearch = {handleSearch}
                            setFilteredData={setFilteredData}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        <TableCell sx={{border: 'none'}}>
                                        </TableCell>
                                        <StyledTableCell sx={{paddingLeft: '3px', paddingRight: '0px'}}>
                                            <Box>
                                                <BrokenText>{row.firstName} {row.lastName}</BrokenText>
                                                <BrokenText>{row.email} </BrokenText>
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">

                                            <IOSSwitchComp
                                                checked={row.isEnabled}
                                                onChange={() => handleChange(row)}
                                            />

                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{textAlign: 'start'}}> {
                                        row.isAdmin ? 
                                        t("keyUser") : 
                                        t("user")
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.cartLimit === null ? t('unlimited') : formatMoney(parseInt(row.cartLimit as string))}
                                        </StyledTableCell>
                                        <TableCell sx={{
                                            borderBottom: 'none'
                                        }}>
                                            <Box
                                                component={'img'}
                                                alt='cart'
                                                onClick={() => dispatch(openEditUserForm(row))}
                                                src='/images/icons/editIcon.svg'
                                                sx={{
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{
                                            borderBottom: 'none'
                                        }}>
                                            <Box
                                                component={'img'}
                                                alt='cart'
                                                onClick={() => handleDeleteIconClick(row.id)
                                                }
                                                src='/images/user-delete.svg'
                                                sx={{
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            }

            <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <CustomPagination
                    pagination={paginationArray}
                    currentPage={page + 1}
                    setSelectedPage={handleChangePage}
                />
            </Box>


            <Box sx={{
                cursor: 'pointer',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                mt: '30px',
                marginLeft: '28px'
            }}
                 onClick={() => dispatch(openNewUserForm())}
            >
                <Box component={'img'}
                     src={'/images/icons/plus-Icon.svg'}
                     mr = '15px'
                />
                <Typography variant = "h20" color ={primaryColor.filterText}>{t('addUser')}</Typography>
            </Box>
           
            {isConfirmationOpen && 
   
            <ConfirmationDialog
                onConfirm={onConfirm}
                handleCancel={() => {
                    closeConfirmationDialog();
                }}
                modalContent={
                <ConfirmationContent dataContent={t("sureToDeleteUser")} />
                }
            />
            }
        
        </ContainerBox>
    }</>
    );
}
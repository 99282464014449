import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Article} from "../interfaces/backend";
import {initialStartState} from "../interfaces/initialStates";
import {sendOpenErrorSnackAction} from "../interfaces/actions";


const initialState: initialStartState = {
    // registrationEmail: "",
    isRegistrationOpen: false,
    isRegistrationSent: false,
    isForgotPassOpen: false,
    isForgotPassSent: false,
    error: [],
    articles: [],
    successMessage: "",
  
};

export const startSlice = createSlice(
    {
        name: "start",
        initialState: initialState,
        reducers: {
            openRegisterForm: (state: initialStartState, action: PayloadAction<boolean>) => {
                state.isRegistrationOpen = action.payload
            },
            receiveRegisterData: (state: initialStartState) => {
                state.isRegistrationSent = true
            },
            closeRegistrationModal: (state: initialStartState) => {
                state.isRegistrationSent = false
                state.isRegistrationOpen = false
            },

            receiveForgotPass:
                state => ({
                    ...state,
                    isForgotPassSent: true,
                    isForgotPassOpen: false
                }),
            openForgotPasswordModal: (state: initialStartState) => {
                state.isForgotPassOpen = true
            },
            closeForgotPasswordModal: (state: initialStartState) => {
                state.isForgotPassSent = false
                state.isForgotPassOpen = false
            },

            // openRegistration:
            // state => ({
            //     ...state,
            //     isRegistrationOpen: true
            // }),
            // closeRegistration:
            // state => ({
            //     ...state,
            //     isRegistrationSent: false,
            //     isRegistrationOpen: false
            // }),
            // receiveRegisterData:
            // state => ({
            //     ...state,
            //     isRegistrationSent: true
            // }),
            // setRegistrationEmail:
            // (state, email) => ({
            //     ...state,
            //     registrationEmail: email
            // }),
            openErrorSnack: (state, action: PayloadAction<sendOpenErrorSnackAction>) => {
                const {payload} = action
                let errors = [];

                if ((payload.code === 400) && (payload.fields && payload.fields.length && payload.fields.length !== 0)) {
                    errors = payload.fields
                } else if (payload.code === 500) {
                    errors.push({
                        name: '500 Error',
                        message: 'Internal Server Error'
                    });
                } else if (payload.code && payload.message) {
                    errors.push({
                        name: `${payload.code} Error`,
                        message: payload.message
                    });
                }
                else errors.push(payload)

                return {
                    ...state,
                    error: errors
                }
            },

            openSimpleErrorSnack:
                (state: initialStartState, action: PayloadAction<string>) => {
                    state.error = [{
                        name: 'Error',
                        message: action.payload
                    }]
                },
            closeSnack: (state: initialStartState) => {
                state.successMessage = ""
            },

            openSuccessSnack: (state: initialStartState, action: PayloadAction<string>) => {
                state.successMessage = action.payload
            },
            setArticles: (state: initialStartState, action: PayloadAction<Array<Article>>) => {
                state.articles = action.payload
            },
            getArticles: (state, action) => {
            },

        }
    }
)
export const {
    openErrorSnack,
    setArticles,
    getArticles,
    openRegisterForm,
    receiveRegisterData,
    closeRegistrationModal,
    closeSnack,
    openSimpleErrorSnack,
    openSuccessSnack,
    openForgotPasswordModal,
    closeForgotPasswordModal,
    receiveForgotPass,
} = startSlice.actions

export default startSlice.reducer
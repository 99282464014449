import { formatMoney } from "../../../utils/localization";
import  { primaryColor, secondaryFonts, defaultFonts } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hook";
import { DiscountValueType, Cart } from "../../../interfaces/backend";
import {  ColumnFlexBox } from "./CostArea";

interface CheckoutDiscountsBoxProps {
  checkoutCart: Cart;
}

const CheckoutDiscountsBox = (props: CheckoutDiscountsBoxProps) => {
  const { t } = useTranslation("checkoutPage");

  const { checkoutCart } = props;
  const settings = useAppSelector((state) => state.staticContent.settings);
  const showDiscountPerItem = settings.find(
    (x) => x.name === "show_discount_per_item" && parseInt(x.value) === 1
  );

  return (
    <>
      <ColumnFlexBox
        rowName={t("productCostWithoutDiscount")}
        rowValue={formatMoney(checkoutCart.totalWithoutDiscount)}
        customStyle={{ fontSize: defaultFonts }}
      />

      {checkoutCart.discounts.map(
        (item, index) =>
          (!showDiscountPerItem ||
            item.discount.valueType === DiscountValueType.CertainAmount) && (
            <ColumnFlexBox
              key={item.discount.discountCode}
              rowValue={formatMoney(-item.discountAmount)}
              rowName={item.discount.discountCode}
              customStyle={{
                color: primaryColor.discountCheckoutPage,
                fontSize: secondaryFonts,
              }}
            />
          )
      )}
    </>
  );
};

export default CheckoutDiscountsBox;

import React from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { primaryColor, defaultFonts } from "../../theme";
import { useTranslation } from "react-i18next";
import { TableSortColumnDefinition } from "../../interfaces/general";
import ArrowButton from "../common/ArrowButton";

export interface SortingProps {
  handleSortMenuItemClick: Function;
  sortValue: string;
  sortByItems: TableSortColumnDefinition[];
}

const SortByItemsMenu = (props: SortingProps) => {
  const { t } = useTranslation("myAccount");
  const { handleSortMenuItemClick, sortValue, sortByItems } = props;

  return (
    <Select
      value={sortValue}
      sx={{
        height: 20,
        paddingY: "5px",

        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
          border: "none",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
          backgroundColor: "transparent",
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - .3em)",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            "& .MuiList-root.MuiMenu-list": {
              paddingY: 0,
            },
            borderRadius: "unset",
            marginTop: "7px",
          },
        },
      }}
 
    IconComponent={(props) => (
      <Box
      sx={{mt: '-5px'}}  
      {...props}
    >
      <ArrowButton
          direction={"up"}
          color={primaryColor.arrowButtonColorSlider}
          width={20}
          
          />
    </Box>
    )}
    >
      {sortByItems.map((item, i) => (item.id &&
        <MenuItem
          key={item.id}
          value={item.id}
          sx={{
            paddingY: "10px",
            "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
              background: primaryColor.sortItemsByDialogHoveredBackground,
            },
            "&.MuiButtonBase-root.MuiMenuItem-root": {
              background: primaryColor.sortItemsByDialogBackground,
              height: "fit-content",
              display: sortValue === item.id ? 'none' : 'flex'
            },
          }}
          onClick={() => handleSortMenuItemClick(item.id)}
        >
          <Typography
            sx={{
              color: primaryColor.sortItemsByTextColor,
              fontSize: defaultFonts,

              marginRight: "15px",
            }}
          >
          
          {
            sortValue === item.id 
            ? t('sortBy' + item.label)
            : (t(item.label))
          }

          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SortByItemsMenu;

import {Box} from "@mui/material";
import TechnicalData from "./data/TechnicalData";
import DocumentsData from "./data/DocumentsData";
import * as React from "react";
import {ProductDetailsPageContent} from "../../../interfaces/backend";
import {useTranslation} from "react-i18next";
import {MobileInfoBox} from "./MobileInfoBox";
import RelatedItemsSlider from "../../../components/common/relatedItemsSlider";
import ProductCard from "../../../components/common/relatedItemsSlider/productSliderCard";

interface InfoProps {
  product: ProductDetailsPageContent,
}
export const MobileInfo = (props: InfoProps ) => {
  const { product } = props
  const {t} = useTranslation(['productPage']);

  const renderProductItem = (item: ProductDetailsPageContent) => {
    return <ProductCard product={item} />;
  };
  
  return (
      <Box mt={'33px'}>
        {product.details?.technicalDetails.length > 0 && <MobileInfoBox
            name={t('techData')} data={<TechnicalData technicalDetails={product.details.technicalDetails}/>} />}
        {product.details && product.details.shortDescription && <MobileInfoBox name={t('description')} data={ <Box
            dangerouslySetInnerHTML={{
              __html:
              product.details?.shortDescription
            }}
        />} />}
        {product.document?.length > 0 && <MobileInfoBox
            name={t('documents')} data={<DocumentsData documents={product.document}/>} />}
        {product.details?.longDescription && <MobileInfoBox name={t('longDescription')} data={ <Box
            dangerouslySetInnerHTML={{
              __html:
              product.details?.longDescription
            }}
        />} />}

        {/* <MobileRelatedProducts relatedProducts={product.relatedProducts} /> */}

        {product.relatedProducts.length > 0 && (
              <RelatedItemsSlider
                  relatedItems={product.relatedProducts}
                  itemsTitle={t('relatedProducts')}
                  renderItem={renderProductItem}
              />
          )}

          
      </Box>
  )
}

export default (MobileInfo)

import {Box, Button, keyframes, Typography} from "@mui/material";
import {defaultFonts, primaryColor} from "../../../theme";
import * as React from "react";
import {Dispatch, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Filter from "./Filter";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {CheckedBoxList, FilterAttribute, ListingListTypes} from "../../../interfaces/backend";
import {setCheckedBoxesList} from "../../../reducers/sparePart";
import Tree from "./Tree";
import Prices, {PricesFilter} from "./Prices";
import FilterHeader from "./FilterHeader";
import MobileFilter from "../mobile/MobileFilter";

const leftFlow = keyframes`
  to {
    transform: translateX(-200%);
  }
`

const rightFlow = keyframes`
from { transform: translateX(-100%);}
  to {
    transform: translateX(0);
  }
`

interface FiltersProps {
  closeFilter: boolean,
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>,
  filters: FilterAttribute[],
  setSelectedItem: Dispatch<React.SetStateAction<string>>,
  setSelectedPage: Dispatch<React.SetStateAction<number>>,
  setPriceFilter: Dispatch<React.SetStateAction<PricesFilter | undefined>>,
  type: ListingListTypes,
  pricesFilter: PricesFilter | undefined,
  isMobile?: boolean,
  handleFilter?: Function,
  setSortValue?: React.Dispatch<React.SetStateAction<string>>,
  sortValue?: string,
}



const transformProperties = '0.5s linear normal forwards'

const hideFilter = `${leftFlow} ${transformProperties}`
const showFilter = `${rightFlow} ${transformProperties}`


export default function Filters(props: FiltersProps ) {
    const {handleFilter, isMobile, closeFilter, setCloseFilter, filters, setSelectedItem, setSelectedPage, type ,setPriceFilter, pricesFilter, sortValue, setSortValue} = props;

    const {t} = useTranslation('searchPage')

  const [checkedBoxesArray, setCheckedBoxesArray] = useState<string[][]>([])
    const [filterAreaScrollable, setFilterAreaScrollable] = useState<boolean>(true)
    const dispatch = useAppDispatch()
    const categories = useAppSelector(state => state.sparePart.category )

    const checkedBoxesList = useAppSelector(state => state.sparePart.checkedBoxesList)

    const clear = () => {
        dispatch(setCheckedBoxesList({}))
        setPriceFilter(undefined)
    }
    useEffect(() => {
        let ar = []
        for (const [key, values] of Object.entries(checkedBoxesList)) {
            for (const value of values) {
                ar.push([key, value])
            }
        }
        setCheckedBoxesArray(ar)
    }, [checkedBoxesList])


    const handleCheckboxChange = (code: string, content: string) => {
        let checkedBoxes: CheckedBoxList = {...checkedBoxesList}
        setSelectedPage(1)
        if (!checkedBoxes.hasOwnProperty(code)) {
            checkedBoxes[code] = [content]
            dispatch(setCheckedBoxesList(checkedBoxes))
        } else {
            //uncheck a checkbox
            if (checkedBoxes[code] && checkedBoxes[code].includes(content)) {
                const index = checkedBoxes[code].indexOf(content)
                if (index > -1) {
                    const remainValues = checkedBoxes[code].filter((item: string) => item !== content)
                    const temporaryCheckedBoxes = Object.fromEntries(Object.entries(checkedBoxes).filter(([key]) => !key.includes(code)))
                    if (remainValues.length > 0) {
                        checkedBoxes[code] = remainValues
                        dispatch(setCheckedBoxesList(checkedBoxes))
                    } else {
                        dispatch(setCheckedBoxesList(temporaryCheckedBoxes))
                    }
                }
            } else {
                checkedBoxes[code] = [...checkedBoxes[code], content]
                dispatch(setCheckedBoxesList(checkedBoxes))
            }
        }
    }
    
    
    const checkDisabled = () => {
        //         return !(Object.entries(checkedBoxesList).length > 0);
        if (type !== ListingListTypes.MyMachine) {
                return !!(pricesFilter?.minPrice && pricesFilter?.maxPrice && pricesFilter.minPrice > pricesFilter.maxPrice);
            };
        } 

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textOverflow: 'hidden',
                fontWeight: '300px',
                fontSize: defaultFonts,
                marginRight: '15px'

            }}
        >
        
          {!isMobile && 
          <FilterHeader isMobile={isMobile} setCloseFilter={setCloseFilter} clear={clear} closeFilter={closeFilter} sortValue={sortValue} setSortValue={setSortValue}/>}


        {type !== ListingListTypes.MyMachine && 
                  <Prices 
                  setPricesFilter={setPriceFilter} 
                  closeFilter={closeFilter} 
                  pricesFilter={pricesFilter}
                  />
        }                        
        
        
        {type !== ListingListTypes.Search && 
        type !== ListingListTypes.MyMachine &&
        type !== ListingListTypes.StandardMachine && 
        categories.children.length > 0 &&
 
                <Tree category={categories} 
                closeFilter={closeFilter} 
                type={type}
                setSelectedItem={setSelectedItem} 
                name={t('category') as string}/>
        }
            <Box
                sx={{
                    width: isMobile ? '14%':'8%',
                    visibility: !closeFilter ? 'hidden' : 'visible',
                    position: 'absolute',
                }}
            >


                {!isMobile && checkedBoxesArray.map((item: string[], key: number) =>
                    <Box
                        key={key}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '70%',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '2rem',
                                color: primaryColor.whiteText
                            }}
                        >
                            {item[1]}
                        </Typography>
                        <Box
                            component={'img'}
                            src={'/images/cancel.svg'}
                            sx={{
                                cursor: 'pointer'
                            }}
                            onClick={() => handleCheckboxChange(item[0], item[1])}
                        />
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    animation: closeFilter ? hideFilter : showFilter,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingRight: !isMobile?'80px':'0px',
                    mt: !isMobile?'55px':'0px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: filterAreaScrollable && filters?.length > 5 ? '800px':'auto',
                        overflow: 'hidden',
                        '& :nth-of-type(1)': {
                            mt: '0'
                        },
                        mt: !isMobile?'20px':'0px'
                    }}
                >
                  {!isMobile ? filters && filters.map((item, key) =>
                      <Filter
                          key={key}
                          filter={item}
                          handleCheckboxChange={handleCheckboxChange}
                      />
                  ) :
                      filters && filters.map((item, key) =>
                          <MobileFilter
                              key={key}
                              filter={item}
                              handleCheckboxChange={handleCheckboxChange}
                          />
                      )
                  }
                </Box>
                <Box
                    component={'img'}
                    src={'/images/arrowDownFilter.svg'}
                    sx={{
                        display: filterAreaScrollable && filters?.length > 5 ?'flex': 'none' ,
                        alignSelf: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => setFilterAreaScrollable(false)}
                />
            </Box>
          {isMobile && !closeFilter && <Box>
            <Button
                variant="contained"
                onClick={() => {
                  if(handleFilter)
                  {
                    setCloseFilter(true)
                    handleFilter()
                  }
                }}
                disabled={checkDisabled()}
                sx={{
                  //width: 'fit-content',
                  // width: '100%',
                }}
            >
              {t('filter')}
            </Button>
          </Box>}
        </Box>
    )
}

import React from "react";
import {
  ProductDetailsPageContent,
  SparePartsTreeSelectedNode,
} from "../../../../interfaces/backend";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { primaryColor, secondaryFonts } from "../../../../theme";
import { StyledTableCell } from "../styledSparePartItems";
import { Availability } from "../../../common/general/availability";
import ProductDetailsOverview from "../../../common/general/productDetailsOverview";
import AddToCartIcon from "./addToCartIcon";
import { useAppDispatch } from "../../../../hook";
import { addCartItem } from "../../../../reducers/cart";
import PriceRendered from "../../../common/priceRendered";
import { useTranslation } from "react-i18next";
 

interface TableProps {
  selectedSparePart: SparePartsTreeSelectedNode;
}

interface TableRowProps {
  product: ProductDetailsPageContent;
  label?: string;
}

const StyledTable = (props: TableProps) => {
  const { selectedSparePart } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation("productPage");

  const StyledTableRow = ({ product, label }: TableRowProps) => (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: `0.8px solid ${primaryColor.sparePartsTreeBorderBottom}`,
        },
      }}
    >
      <StyledTableCell sx={{textAlign: "center"}} >{label}</StyledTableCell>
      <StyledTableCell sx={{padding:0, maxWidth: {lg: "220px", xl: "350px"}}}>
        {product.name}
      </StyledTableCell>
      <StyledTableCell sx={{ maxWidth: {lg: "150px", xl: "170px"}}}>
        {product.code}
      </StyledTableCell>
      <StyledTableCell>
        <Availability
          iconSx={{ height: {lg: "22px", xl: "25px"} }}
          textSx={{ display: "none" }}
          inStock={product.inStock}
          isOrderable={product.isOrderable}
        />
      </StyledTableCell>
      <StyledTableCell sx={{paddingX: '0', 
        maxWidth: {lg: "80px", xl:"100px"}, 
        }}>
        <PriceRendered productPrice={product.price} productDiscountPrice={product.discountPrice} styles={{ fontSize: secondaryFonts}}/>
      </StyledTableCell>
      <StyledTableCell>
        <ProductDetailsOverview
          linkTo={product.alias}
          iconSx={{ height: {lg: "22px", xl: "25px"}, margin: "auto" }}
        />
      </StyledTableCell>
      <StyledTableCell
        sx={{
          overflow: 'unset',
          "&:hover": {
            cursor: product.isOrderable ? "pointer" : "unset",
          },
        }}
      >
        <AddToCartIcon
          iconSx={{ height: {lg: "28px", xl: "30px", marginTop: '5px' }, opacity: product.isOrderable ? 1 : 0.7 }}
          toolTipTitle={product.isOrderable ? t("addItem") : null}
          onClick={() => { product.isOrderable &&
            dispatch(
              addCartItem({
                code: product.code,
                quantity: 1,
              })
            );
          }}
        />
      </StyledTableCell>
    </TableRow>
  );

  return (
    <TableContainer sx={{
      maxWidth: '95%',
      margin: 'auto',
    }}>
 
    <Table>
      <TableHead
        sx={{ backgroundColor: primaryColor.sparePartsTreeParentBackground }}
      >
        <StyledTableRow
          product={selectedSparePart.product}
          label={selectedSparePart.label}
        />
      </TableHead>

      <TableBody>
        { selectedSparePart.nonGroupChildren?.map((child, index) => (
              <StyledTableRow
                key={`${child.product.alias}-${index}`}
                product={child.product}
                label={child.label}
              />
            ))
          }
      </TableBody>
    </Table>
    </TableContainer>
  );
};

export default StyledTable;
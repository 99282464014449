import React from "react";
import { Cart, CartItem } from "../../../interfaces/backend";
import CartTableItemMobile from "./CartTableItemMobile";
interface CartTableMobileProps {
  data: Cart;
}

const CartTableMobile = (props: CartTableMobileProps) => {
  const { data } = props;

  return (
    <>
      {data?.items.map((item: CartItem, i: number) => (
        <CartTableItemMobile key={i} item={item} />
      ))}
    </>
  );
};

export default CartTableMobile;

import React, {useRef} from 'react';

import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../page";
import {primaryColor} from "../../theme";
import OrderSummaryDesktop from "./OrderSummaryDesktop";
import {useAppSelector} from "../../hook";
import ReactToPrint from "react-to-print";
import {NavigateButton} from '../SpareParts/subComponents';

export default function OrderCompletedPage() {

  const order = useAppSelector(state => state.cart.orderResult)
  // const componentRef = useRef();
  // const shopUsesPrices = (settings && settings.find(e => e.name === "shop_uses_prices" && e.value === "1")) || false
  const {t} = useTranslation('checkoutPage')
  const componentRef = useRef(null);

  const contentStyles = {
    fontSize: {
      xs: '1.5rem',
      sm: '1.6rem',
      md: '1.8rem',
      lg: '2.0rem'
    },
    paddingY: '3px',
    fontWeight: 300,
    lineHeight: '1.8rem',
  }

  const wrapperStyles = {
    width: '100%',
    maxWidth: {
      xs: '450px',
      md: 'unset',
    },
    paddingX: '25px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  }


  return (
    <Page>
      <Box ref={componentRef}>
        <Box
          sx={{
            ...wrapperStyles,
            color: primaryColor.shoppingCartTitle,
          }}
        >
          <Typography
            variant={'h70'}
            sx={{
              paddingY: {
                xs: '5px',
                sm: '10px',
                lg: '20px',
              },
              fontSize: {
                xs: '2.6rem',
                md: '3rem',
                lg: '7rem',
              }
            }}
          >
            {t('order')}
          </Typography>
          <Typography
            sx={{
              mt: {
                xs: '20px',
                sm: '40px',
                lg: '80px',
              },
              fontSize: {
                xs: '2rem',
                md: '2.5rem',
                lg: '3rem'
              },
              lineHeight: '2.4rem',
              fontWeight: 300,
              textTransform: 'uppercase'
            }}
          >{t('thanks for order')}
          </Typography>
          <Typography
            variant={'h20'}
            sx={{
              color: primaryColor.shoppingCartTitle,
              mt: {
                xs: '30px',
                sm: '40px',
                lg: '50px',
              },
              ...contentStyles
            }}
          >{t('your order number is')}
          </Typography>
          <Typography
            variant={'h20'}
            sx={{
              color: primaryColor.discountCheckoutPage,
              ...contentStyles,
            }}
          >{order.orderId}
          </Typography>
          <Typography
            variant={'h20'}
            sx={{
              color: primaryColor.shoppingCartTitle,
              ...contentStyles,
            }}
          >{t('make ready')}
          </Typography>

          <Box sx={{
            mt: '30px',
            mb: {
              xs: '30px',
              lg: '60px',
            },
            fontWeight: 400,
            width: {
              xs: "100%",
              sm: "auto"
            }

          }}>
            <NavigateButton
              content={t('back')}
              navigateTo={"/"}
            />
          </Box>
        </Box>

        <Box
          sx={{
            ...wrapperStyles,
            mb: '50px',
            backgroundColor: {
              lg: primaryColor.backgroundOverlay
            },
            "& > div": {
              width: {
                xs: '100%',
                md: '70%',
                lg: '55%'
              },
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <OrderSummaryDesktop/>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              lg: 'flex-start'
            },
          }}>
            <ReactToPrint
              trigger={() =>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginY: {
                      xs: '30px',
                      md: '25px',
                      lg: '53px'
                    },
                    width: '100%',
                    alignItems: 'center',
                    maxWidth: 'fit-content',
                    cursor: 'pointer',

                    backgroundColor: {
                      xs: primaryColor.buttonColor,
                      lg: 'unset'
                    },
                    paddingX: {
                      xs: '25px',
                      lg: 'unset'
                    },

                    height: '43px',
                    fontWeight: 400,
                  }}>
                  <Box
                    component={'img'}
                    src={'/images/icons/printIcon.svg'}
                    sx={{
                      width: {
                        xs: '24px',
                        md: '28px',
                        lg: '34px',
                      },
                      height: {
                        xs: '24px',
                        md: '28px',
                        lg: '34px',
                      },
                    }}
                  />
                  <Typography
                    variant="h20"
                    sx={{
                      color: primaryColor.shoppingCartTitle,
                      fontSize: contentStyles.fontSize,
                      ml: '10px',
                      width: '100%',
                    }}>
                    {t('printSummary')}
                  </Typography>
                </Box>
              }
              content={() => componentRef.current}
              documentTitle={order.orderId}
              copyStyles={false}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

import React, {useState, useEffect} from "react";
import {Box, useMediaQuery, Grid, SxProps} from "@mui/material";
import theme, {primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";
import SearchBox from "../../common/searchBox";
import TemplateBox from '../index';
import {useAppDispatch} from "../../../hook";
import {getQuickSearchOptions} from "../../../reducers/quickSearch";
import {SearchSuggestionElement} from "../../../interfaces/backend";
import {getBatchProducts} from "../../../reducers/quickOrder";
import { style } from "../../../pages/QuickOrder";

const QuickSearch = () => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const lgView = useMediaQuery(theme.breakpoints.down('lg'))
    
    const {t} = useTranslation("quickOrder")
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState<SearchSuggestionElement | null>(null);

    const inputStyle = {
        fontWeight: 300,
        fontSize: style.quickOrderSubContent,
        paddingLeft: {
            xs: '30px',
            md: '24px',
            '2xl': '8%'
        },
        marginTop: '10px',
        '&::placeholder': {opacity: 0.5},
        lineHeight: '24px',
        letterSpacing: '0.05em',
        flex: 1,
        backgroundColor: primaryColor.quickSearchWhiteBackground,
        color: 'unset',
        height: {
            xs: '45px',
            md: '60px',
            lg:'90px'},
      
    }

    const quickSearchPaper: SxProps = {
        
        marginTop: '20px',
        width: '100%',
        overflowY: 'scroll',
        textAlign: "left",
        backgroundColor: primaryColor.drawerBackground,
        maxHeight: mobileView ? '250px' : '400px',
    }

    const handleClickAdd = () => {
        searchValue && dispatch(getBatchProducts({
            products: [{code: searchValue.code, quantity: 1}],
            isInitialLoad: false
        }));
        setSearchValue(null);
    };

    useEffect(()=>{
        if (lgView) {
            handleClickAdd()
        }
    }, [searchValue])

    const handleInputChange = (inputValue: string) => {
        dispatch(getQuickSearchOptions({
            searchValue: inputValue,
            onlyOrderable: true
        }));
        if (!inputValue.trim()) {
            setSearchValue(null);
        }
    }

    const handleOnSelect = (item: SearchSuggestionElement) => setSearchValue(item);

 
    const searchBoxContent = (
        <Grid container sx={{
            paddingY: {
                xs: '5px',
                md: '10px',
                lg: '25px'},
          display: "flex",
          alignItems: "center",
          
        }}>

            <Grid item xs={12} lg={10} >
                <SearchBox
                    onInputChange={handleInputChange}
                    onSelect={handleOnSelect }
                    showClearIcon={false}
                    
                    styleText={{
                        width: '100%',
                        '& .MuiFormControl-root.MuiTextField-root': {
                            borderBottom: "unset",
                        }
                    }}
                    placeholder={t('searchParts') as string}
                    styledPaper={quickSearchPaper}
                    inputStyle={inputStyle}
                    inputValue={searchValue?.name || ""}
                    />

            </Grid>

            <Grid item lg={2}  >
            <Box
                component={'img'}
                src={`/images/icons/${searchValue ? 'quickOrderCartIconActive.svg' : 'quickOrderCartIcon.svg'}`}
                sx={{
                    cursor: searchValue ? 'pointer' : '',
                    display: {xs: 'none', lg: "inline"},
                }}
                onClick={handleClickAdd}
                alt={"QuickOrder cart icon"}
                />
            </Grid>

        </Grid>
    );

    return (
        <TemplateBox
            title={t('quickSearchTitle')}
            description={t('quickSearchDescription')}
            relatedContent={searchBoxContent}
        />
    )
}

export default QuickSearch
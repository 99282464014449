import {
  ContactData,
  Customer,
  Order,
  ProductDetailsPageContent,
  ProductTypeEnum,
  StripePaymentIntents, StripePublicData,
  User
} from "../interfaces/backend";

export const initialShippingMethod = {
    id: 0,
    name: '',
    price: 0,
    isAddressRequired: true,
    usePrices: false,
    description: ''
}

export const initialShippingAddress = {
    title: '',
    isDefault: false,
    firstName: '',
    lastName: '',
    companyName: '',
    streetName: '',
    streetNumber: '',
    additional: '',
    postalCode: '',
    city: '',
    country: '',
    phone: '',
}
export const initialBillingAddress = {
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
    companyName: '',
}

export const initialPaymentMethod = {
    id: 0,
    name: '',
    paymentProvider: ''
}

export const initialUser: User = {
    id: 0,
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    mobile: '',
    isAdmin: false,
    isEnabled: true,
    cartLimit: '',
    newSupportCenterMessages: 0
}

export const initialContactData: ContactData = {
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  phone: ''
}
export const initialCustomer: Customer = {
    companyName: '',
    code: '',
    vatId: '',
    additional: '',
    isEnabled: false,
    currency: '',
    cartMinimumSum: 0,
    user: initialUser,
    billingAddress: initialBillingAddress
}


export const initialOrderHistory = {
  currentPage: 1,
  items: [],
  itemsCount: 0,
  itemsTotal: 0,
  pagination: [],
  request: {
    page: 0,
    perPage: 10,
    orderBy: "createdAt",
    order: "desc"
  }
}
export const initialCart = {
    total: 0,
    tax: 0,
    totalTax: 0,
    shippingTax: 0,
    totalWithTax: 0,
    totalWithoutDiscount: 0,
    allTaxes: 0,
    totalWithTaxAndShipping: 0,
    shippingMethod: initialShippingMethod,
    shippingAddress: initialShippingAddress,
    paymentMethod: initialPaymentMethod,
    minimumSum: 0,
    orderOption: 'license',
    documents: [],
    discounts: [],
    totalDiscount: 0,
    items: [],
    itemsQuantity: 0,
}

export const initialUnit = {
    code: '',
    name: ''
}
export const initialProductDetail = {
    shortDescription: '',
    longDescription: '',
    technicalDetails: []
}

export const initialDocument = {
    name: '',
    url: '',
    title: '',
    size: 0,
    mimeType: ''
}
export const initialBilling = {
    billingID: '',
    generatedAt: ''
}
export const initialDiscount = {
  discount: {
    name: "",
    discountCode: "",
    value: 0,
    valueType: 0,
    code: "",
    description: '',
  },
  discountAmount: 0
}

export const initialProduct: ProductDetailsPageContent = {
    name: "",
    code: "",
    alias: "",
    price: 0,
    discountPrice: 0,
    discountValue: 0,
    priceType: "",
    inStock: true,
    isEnabled: true,
    deliveryTime: 0,
    isOrderable: true,
    isMachine: false,
    isIndividualMachine: false,
    quantity: 0,
    unit: initialUnit,
    details: initialProductDetail,
    image: [],
    image3d: {
        url: '',
        urn: '',
        thumbnail: ''
    },
    video: [],
    document: [],
    schema: [],
    relatedProducts: [],
    relatedToProducts: [],
    type: ProductTypeEnum.Product
}



export const initialStripePaymentIntents: StripePaymentIntents = {
  clientSecret: ""

}

export const initialStripePublicData: StripePublicData = {
  accountId: "",
  publicKey: ""
}
export const initialOrderResult: Order = {
    orderId: "",
    total: 0,
    tax: 0,
    totalTax: 0,
    totalWithTaxAndShipping: 0,
    items: [
        {
            name: "",
            price: 0,
            discountPrice: 0,
            discounts: [],
            quantity: 0,
            code: "",
            product: initialProduct,
            isOrderable: true,
            discountValue: 0,
            discountSum: 0,
            priceType: '',
            total: 0
        }
    ],
    shippingMethod: initialShippingMethod,
    paymentMethod: initialPaymentMethod,
    shippingAddress: initialShippingAddress,
    billingAddress: initialBillingAddress,
    user: {
        firstName: "",
        lastName: "",
        email: "@example.com",
        customerCode: "",
        customerCompanyName: ""
    },
    customer: initialCustomer,
    customerUser: initialUser,
    status: {
        name: '',
        code: '',
        description: ''
    },
    createdAt: "",
    comment: "",
    internalOrderNumber: "",
    currency: "EUR",
    orderOption: "licence",
    documents: [initialDocument],
    billings: [initialBilling],
    discounts: [initialDiscount],
    totalDiscount: 0,
    totalWithoutDiscount: 0
}




import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,} from "@mui/material";
import {useTranslation} from "react-i18next";
import {primaryColor} from "../../../theme";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {useEffect} from "react";
import {Customer} from "../../../interfaces/backend";
import {getServiceCustomers, getServiceUserFromStorage, storeServiceUserEmail,} from "../../../reducers/auth";
import ArrowButton from "../../common/ArrowButton";


const ServiceSelectUser = () => {
  const {t} = useTranslation("startPage");
  const dispatch = useAppDispatch();
  const serviceCustomers = useAppSelector(
    (state) => state.auth.serviceCustomers
  );

  const storedUserEmail = useAppSelector((state) => state.auth.storedUserEmail);
  const storedUser = serviceCustomers.find(
    (customer: Customer) => customer.user.email === storedUserEmail
  );

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedUserEmail = event.target.value;

    const clickedCustomer = serviceCustomers.find(
      (customer: Customer) => customer.user.email === selectedUserEmail
    );

    if (clickedCustomer?.user?.email) {
      dispatch(storeServiceUserEmail(clickedCustomer.user.email));
      window.location.reload();
    }
  };

  useEffect(() => {
    dispatch(getServiceCustomers());
    dispatch(getServiceUserFromStorage());
  }, [storedUserEmail]);

  return (
    <FormControl
      fullWidth
    >
      <InputLabel
        id={"serviceCustomersLabel"}
        sx={{
          marginLeft: "-15px"
        }}
      >
        {storedUser?.user?.id
          ? t("startPage:youAreLoggedInAs")
          : t("startPage:chooseCustomerSubTitle")}
      </InputLabel>
      <Select
        labelId="serviceCustomersLabel"
        onChange={handleChange}
        value={storedUser?.user.email || ""}
        sx={{
          borderBottom: "1px solid" + primaryColor.textDecorationColor,
        }}
        IconComponent={(props) => (
          <Box sx={{mt: "-5px"}} {...props}>
            <ArrowButton
              direction={"down"}
              color={primaryColor.arrowButtonColorSlider}
              width={15}
            />
          </Box>
        )}
        MenuProps={{
          MenuListProps: {
            sx: {
              paddingY: 0
            }
          },
          slotProps: {
            paper: {
              sx: {
                maxHeight: "216px",
                backgroundColor: primaryColor.serviceCustomersListBackground,
                borderRadius: "unset",
              }
            }
          }
        }}
      >
        {serviceCustomers?.map((customer: Customer) => (
          <MenuItem
            key={customer.user.email}
            value={customer.user.email}
            sx={{
              "&:hover": {
                backgroundColor: primaryColor.selectedTreeTab
              },
              display: customer.user.email === storedUser?.user.email ? "none" : "inherit",
              color: primaryColor.drawerText,
            }}
          >
            {customer.companyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ServiceSelectUser;
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { Schema } from "../../../../interfaces/backend";
import ArrowButton from "../../../common/ArrowButton";
import { primaryColor } from "../../../../theme";
 

interface SchemaSliderProps {
  items: Schema[];
}

const arrowStyles = {
  width: {
    xs: "25px",
    md: "40px",
  },
  position: "absolute",
  zIndex: 3,
  paddingY: "25%",
 
  marginX: {
    xs: "-35px",
    lg: "-50px",
  },
  opacity: 0.15,
  "&:hover": {
    opacity: 1,
  },
  "&:before": {
    display: "none",
  },
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0px',
  adaptiveHeight: true,
  nextArrow: (
    <Box
      sx={{
        ...arrowStyles,
      }}
    >
      <ArrowButton
        direction={"right"}
        color={primaryColor.arrowButtonColorSlider}
        sx={{
          right: "-60px",
        }}
      />
    </Box>
  ),

  prevArrow: (
    <Box
      sx={{
        ...arrowStyles,
      }}
    >
      <ArrowButton
        direction={"left"}
        color={primaryColor.arrowButtonColorSlider}
        sx={{
          left: "-60px",
        }}
      />
    </Box>
  ),

};

const SchemaSlider = (props: SchemaSliderProps) => {
  const { items } = props;


  return (
    <Slider   {...settings}>
      {items.map((schema, index) => (
          <Box 
            key={index}
            component={'img'}
            src={schema.url}
            alt={`Schema ${index}`}
            sx={{
              maxWidth: {
                xs: "95vw", 
                md: "90vw", 
                lg: "80vw", 
                xl: "60vw"
              },
              maxHeight: {
                xs: '45vh',
                md: '60vh',
                lg: "75vh"
              },
              margin: "auto",
              objectFit: "contain",  
              
              "& .slick-arrow": {
                display: 'none'
              }
    
            }}
          />
      ))}
    </Slider>
  );
};

export default SchemaSlider;

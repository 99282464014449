import {primaryColor} from "../../../theme";
import {SvgIcon} from "@mui/material";


export default function FilterArrow(props: { rotate: boolean, isMobile?: boolean }) {

    const {rotate, isMobile} = props
    return (
        <SvgIcon
            sx={{
                marginLeft: '25px',
                marginRight: '25px',
                height: isMobile?'16px':'26px',
                transform: rotate ? 'rotate(180deg)' : '',
                transition: 'transform 500ms',
            }}
            width="13" height="27" viewBox="0 0 13 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.00438919 12.9956C0.00354235 12.5619 0.154589 12.1416 0.431311 11.8077L9.71223 0.670551C10.0273 0.291487 10.48 0.0531076 10.9709 0.00785435C11.4617 -0.0373989 11.9504 0.114181 12.3295 0.429247C12.7085 0.744313 12.9469 1.19706 12.9921 1.68788C13.0374 2.1787 12.8858 2.6674 12.5708 3.04647L4.25505 12.9956L12.2738 22.9448C12.4279 23.1346 12.5431 23.3531 12.6126 23.5876C12.6821 23.8221 12.7045 24.068 12.6786 24.3112C12.6528 24.5545 12.5791 24.7902 12.4618 25.0048C12.3445 25.2194 12.186 25.4088 11.9953 25.562C11.8045 25.732 11.5806 25.8607 11.3376 25.9401C11.0947 26.0196 10.838 26.048 10.5836 26.0236C10.3292 25.9992 10.0825 25.9225 9.85914 25.7984C9.63574 25.6742 9.44038 25.5053 9.28531 25.3021L0.319941 14.165C0.0869602 13.8215 -0.0241419 13.4097 0.00438919 12.9956Z"
                fill={rotate ? primaryColor.drawerText : "#FF6550"}
            />
        </SvgIcon>
    )
}
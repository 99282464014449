import { useAppSelector} from "../../../hook";
import {primaryColor} from "../../../theme";
import {BoxProps, styled, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ShippingAddress} from "../../../interfaces/backend";
import React, {useEffect, useState} from "react";
 
 

const textStyling = {
    fontSize: '1.2rem',
    lineHeight: '20px',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    maxWidth: '15ch',
    overflowWrap: 'break-word'
}

type ShippingAddressCardProps = {
    address: ShippingAddress
}

interface ContainerBoxProps extends BoxProps {
}

export const ContainerBoxSmallCard = styled((props: ContainerBoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    width: '147px',
    backgroundColor: primaryColor.addressCard,
    marginRight: '26px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '23px'
}));
export const NameBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    paddingLeft: '20px',
    paddingBottom: '12px',
    display: 'flex',
    paddingTop: '12px',
    paddingRight: '12px',
    flexDirection: 'column'
}));

export default function ShippingAddressSmallCard(props: ShippingAddressCardProps) {

    const {address} = props
   
    const shippingAddress = useAppSelector(state => state.address.shippingAddress)


    const [isThisAddressSelected, setIsThisAddressSelected] = useState<boolean>(false)
 
    const isEditMode = useAppSelector(state => state.address.isEditMode)
 
    
    useEffect(() => {
     setIsThisAddressSelected(shippingAddress.id === address.id)

    }, [address.id, shippingAddress.id])


    return (
        <ContainerBoxSmallCard>
            <NameBox>
                <Typography sx={{
                    color: (isThisAddressSelected && isEditMode ) ? primaryColor.whiteText : primaryColor.machinePageText,
                    ...textStyling}}>
                        {address.firstName} {address.lastName} 
                        </Typography>
                <Typography sx={{
                    color: (isThisAddressSelected && isEditMode) ? 
                    primaryColor.whiteText : primaryColor.machinePageText,
                    ...textStyling}}>
                        {address.companyName}
                       </Typography>
            </NameBox>
        </ContainerBoxSmallCard>
    )
}

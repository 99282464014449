import theme, {primaryColor} from "../../../theme";
import SearchBox from "../../../components/common/searchBox";
import * as React from "react";
//import {useState} from "react";
import {getQuickSearchOptions} from "../../../reducers/quickSearch";
//import {SearchSuggestionElement} from "../../../interfaces/backend";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import {style} from "../../SparePartsPage";
import {Box, useMediaQuery} from "@mui/material";

export default function EmptySearchResults() {
  const dispatch = useAppDispatch()
  const {t} = useTranslation('common')
  //const [selectedSearchedElement, setSelectedSearchedElement] = useState<SearchSuggestionElement | null>(null)
  const handleInputChange = (searchValue: string) => dispatch(getQuickSearchOptions({searchValue: searchValue}));
  //const handleOnSelect = (item: SearchSuggestionElement) => setSelectedSearchedElement(item);
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const paperStyle = {
    tabsGrid: {
      backgroundColor: primaryColor.background,
      borderRadius: '0px',
      //minHeight: '767px',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '90px'
    },
    dataGrid: {
      backgroundColor: primaryColor.searchBackground,
      borderRadius: '0px',
      minHeight: '294px',
      /*padding: '75px 75px 95px 100px'*/
      padding: '76px 15% 75px 15%'

    },
    dataFrame: {
      margin: '0px 90px 0px 65px',
    },

  }
  const quickSearchPaper = mobileView ?
      {
        width: '100%',
        backgroundColor: primaryColor.drawerBackground,
      }
      :
      {
        width: '100%',
        marginTop: '20px',
        backgroundColor: primaryColor.drawerBackground,
        maxHeight: '400px',
        overflow: 'scroll'
      }
  return (
      <Paper sx={paperStyle.dataGrid}>
        <Box sx={{display: 'flex'}}>
          <Box
              component={'img'}
              src={'/images/icons/exclamationMark.svg'}
              alt='image'
              sx={{
                height: '48px',
                objectFit: 'cover',
                mr: '24px'
              }}/>
          <Box sx={{fontSize: '2rem',  color: primaryColor.searchNotFoundColor, display: 'flex', alignSelf: 'center'}}
              dangerouslySetInnerHTML={{
            __html: t('notFound')
          }}></Box>
          {/*          <Typography>
            {t('tryAgain')}
          </Typography>*/}
        </Box>
        <SearchBox
            onInputChange={handleInputChange}
            //onSelect={handleOnSelect}
            showClearIcon={false}
            styleText={{
              width: '100%',
              '& ::placeholder': {
                color: primaryColor.sparePartsSearchPlaceholderTextColor
              }
            }}
            placeholder={t('search') as string}
            styledPaper={quickSearchPaper}
            inputStyle={style.textStyle}
            styleRoot={{marginTop: '90px'}}
        />
      </Paper>
  )
}
import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import {Box, Divider} from "@mui/material";
import {primaryColor} from "../../../theme";
import Arrow from "../../../components/common/svg/arrow";

interface InfoBoxProps {
  name: string,
  data: any
}
export const MobileInfoBox = (props: InfoBoxProps ) => {
  const {name, data} = props
  const [rotate, setRotate] = useState(false)
  const [expanded, setExpanded] = useState<boolean>(false)
  const handleChange = () => {
    setExpanded(!expanded)
    setRotate(!rotate)
  }

  const imageBoxStyling =
      {
        width: '100%',
        //height: '145px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }

  const menuStyling =
      {
        height: '108px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        },
        backgroundColor: primaryColor.pdpBackground,
      }

  return (
      <Box>
        <Box
            sx={imageBoxStyling}
        />
        <Box
            sx={menuStyling}
            onClick={() => handleChange()}
        >
          <Typography variant={'h2'} fontWeight={300} mb={'15px'}>{name}</Typography>
          <Arrow fill={(expanded) ? primaryColor.badgeColor : primaryColor.whiteText}
                 rotate={rotate}/>
        </Box>

        <Box
            sx={{
              width: '100%',
              overflow: 'hidden',
              textAlign: 'center',
              fontSize: '1.5rem',
              color: primaryColor.textTopHeader,
              marginBottom: (expanded) ? '30px' : "15px",
              display: (expanded) ? 'inline-block' : 'none',
              transition: 'margin-bottom 500ms, max-height 500ms',
              margin: '-1px auto 0',
              backgroundColor: primaryColor.pdpBackground,
              paddingX: '30px',
              fontWeight: 400
            }}
        >
          {data}
        </Box>
        <Divider sx={{height: '26px'}}/>

      </Box>
  )
}
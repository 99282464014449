import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../hook";
import {SparePartsTreeSelectedNode } from "../../../../interfaces/backend";
import TableTitle from "./tableTitle";
import StyledTable from "./styledTable";
import theme from "../../../../theme";
import SparePartSchema from "../SparePartSchema";
import StyledTableMobile from "./styledTableMobile";

 

const SparePartChildrenTable = () => {
  const lgView = useMediaQuery(theme.breakpoints.down('lg'))
 
  const sparePartsTreeSelectedNode: SparePartsTreeSelectedNode = useAppSelector(
    (state) => state.sparePart.sparePartsTreeSelectedNode
  );

  const isSparePartSchemaOpen = useAppSelector(
    (state) => state.sparePart.isSparePartSchemaOpen
  );


  return (
    <>
      {isSparePartSchemaOpen ? (
        <Box sx={{
          height: 'fit-content',
          minHeight: '100vh',
        }}>
        <SparePartSchema schemas={sparePartsTreeSelectedNode.product.schema} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: {
              md: "35px 0",
              lg: "35px 50px 100px",
              xl: "35px 80px 140px",
            },
          }}
        >
        
          <Box
            sx={{
              flex: 1,
              maxWidth: '100%',
            }}
          >
 
            <TableTitle schema={sparePartsTreeSelectedNode.product.schema}/>

            {lgView ?
            <StyledTableMobile selectedSparePart={sparePartsTreeSelectedNode}/>
            : 
            <StyledTable selectedSparePart={sparePartsTreeSelectedNode} />
            }
  
          </Box>
        </Box>
      )}
    </>
  );
};

export default SparePartChildrenTable;
import { Box, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../hook";
import {
  setBillingFormOpen,
  getBillingAddress,
  setBillingAddressInput,
  setBillingCustomerInput,
  setBillingCountry,
} from "../../../reducers/address";
import ButtonsBox, { buttonsBoxMainWrapper } from "../../common/ButtonsBox";
import CustomAutocomplete from "../../common/customAutoComplete";
import {BillingAddress, Country} from "../../../interfaces/backend";
import { patchBillingAddress } from "../../../reducers/address";
import React, { useEffect, useState } from "react";
import { StyledContainer, StyledBox, GroupTwoBox } from "../../common/StyledComponents";

interface BillingAddressFormProps {
  billingAddress: BillingAddress;
}

const BillingAddressForm = (props: BillingAddressFormProps) => {
  const { t } = useTranslation("myData");
  const dispatch = useAppDispatch();
  const { billingAddress} = props;
  const countries = useAppSelector((state) => state.metadata.countries);
  const selectedCountry = countries.find(
    (v) => v.code === billingAddress?.country
  ) || null;

  const handleConfirm = () => {
    dispatch(patchBillingAddress({ billingAddress: billingAddress }));
    dispatch(setBillingFormOpen(false));
  };

  const handleCancel = () => {
    dispatch(setBillingFormOpen(false));
    dispatch(getBillingAddress());
  };

  const handleCountryChange = (country: Country | null) => {
    country &&
      dispatch(setBillingCountry({ country: country.code }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBillingAddressInput({ target: event.target }));
  };

  const handleCustomerInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBillingCustomerInput({ target: event.target }));
  };

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      if (!billingAddress || !billingAddress.customer) {
        return false;
      }

      if (
        !billingAddress.customer.companyName ||
        !billingAddress.customer.companyName.trim() ||
        !billingAddress.streetName ||
        !billingAddress.streetName.trim() ||
        !billingAddress.postalCode ||
        !billingAddress.postalCode.trim() ||
        !billingAddress.city ||
        !billingAddress.city.trim() ||
        !billingAddress.country ||
        !billingAddress.country.trim()
      ) {
        return false;
      } else {
        return true;
      }
    };
    setFormValid(validateForm());
  }, [billingAddress]);

  return (
    <FormControl sx={{
      margin: 'auto',
      width: '100%',
    }}
    >
      <form onSubmit={handleConfirm}>
      <Box
        sx={{
          display:   "flex" ,
          flexDirection: "column",
        }}
      >
        <StyledContainer>
          <StyledBox
            name="companyName"
            label={t("company")}
            required={true}
            value={(billingAddress.customer?.companyName || "")}
            handleChange={handleCustomerInputChange}
            disabled={false}
          />
          <StyledBox
            name="additional"
            label={t("additional")}
            value={(billingAddress.customer?.additional || "")}
            handleChange={handleCustomerInputChange}
            required={false}
            disabled={false}
          />
        </StyledContainer>

        <StyledContainer>
          <GroupTwoBox>
            <StyledBox
              name="streetName"
              label={t("street")}
              style={{
                width: "75%",
              }}
              value={(billingAddress?.streetName || "")}
              handleChange={handleInputChange}
              required={true}
              disabled={false}
            />
            <StyledBox
              name="streetNumber"
              label={t("no")}
              value={(billingAddress?.streetNumber || "")}
              handleChange={handleInputChange}
              style={{
                width: "20%",
              }}
              required={false}
              disabled={false}
            />
          </GroupTwoBox>

          <GroupTwoBox>
            <StyledBox
              name="postalCode"
              label={t("postalCode")}
              value={(billingAddress?.postalCode || "")}
              handleChange={handleInputChange}
              required={true}
              disabled={false}
              style={{
                width: "20%",
              }}
            />
            <StyledBox
              name="city"
              label={t("city")}
              value={(billingAddress?.city || "")}
              handleChange={handleInputChange}
              required={true}
              disabled={false}
              style={{
                width: "75%",
              }}
            />
          </GroupTwoBox>
        </StyledContainer>
        <StyledContainer>
          <Box sx={{ 
             width: {
              xs: '100%',
              lg: '48%'
            },
            }}>

          <CustomAutocomplete
            options={countries}
            handle={handleCountryChange}
            label={t("country")}
            required={true}
            value={selectedCountry}
          />
          </Box>
        </StyledContainer>
 
        <Box sx={{...buttonsBoxMainWrapper}}>
        <ButtonsBox
          handleCancel={handleCancel}
          confirmButtonText={t("confirmBillingAddress")}
          disabled={!formValid}
        />
          </Box>
      </Box>
    
      </form>
    </FormControl>
  );
};
export default BillingAddressForm;

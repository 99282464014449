import {useMediaQuery,} from "@mui/material"
import Page from "../page";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hook";
import React, {useEffect} from "react";
import {getProductDetails} from "../../reducers/productdetails";
import {ProductDetailsPageContent} from "../../interfaces/backend";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../utils/localization";
import {BreadCrumbsObject} from "../../interfaces/general";
import MobileProductDetails from "./mobile/MobileProductDetails";
import DesktopProductDetails from "./DesktopProductDetails";
import theme, { primaryColor } from "../../theme";
import TitlePdp from "./TitlePdp";

const ProductDetailsPage = () => {
  const {t} = useTranslation(['productPage']);
  const params = useParams()
  const dispatch = useAppDispatch()
  const productDetails: ProductDetailsPageContent = useAppSelector(state => state.productDetails.productDetails)
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  useEffect(() => {
    const alias = params.alias
    dispatch(getProductDetails({alias}))

  }, [dispatch, params.alias])



  const makeBread = (): Array<BreadCrumbsObject> => {
    const {name, code} = productDetails
    return !params.parent
        ? [
          {name: t('common:home'), link: '/'},
          params.category
              ? {
                name: capitalizeFirstLetter(name ? name : ''),
                link: `/${params.category}`,
              }
              : {name: t('common:standardMachines'), link: '/machine'},
          {name: code ? code : '', link: '#'},
        ]
        : [
          {name: t('common:home'), link: '/'},
          {name: t('common:standardMachines'), link: '/machine'},
          {
            name: params.parent,
            link: `/machine/${params.parent}`,
          },
          {name: code ? code : '', link: '#'},
        ];
  };

  return (
      <Page breadCrumbs={!mobileView ? makeBread() : []}>
        <TitlePdp title={t('productDetails')} subTitle={productDetails.name} withNavBack={true}/> 
        {mobileView ? <MobileProductDetails product={productDetails} backgroundColor={primaryColor.pdpBackground}/> : <DesktopProductDetails productDetails={productDetails} />}
      </Page>
  )
}

export default (ProductDetailsPage)

import {put, takeEvery} from 'redux-saga/effects';
import {CART_ITEMS, PRODUCTS} from '../constants/api';
import {emptyOrder, extendOrder} from '../reducers/quickOrder';
import {callHttp} from '../utils/api';
import {getArray, post} from '../utils/httpUtil';
import {Cart, ProductDetailsPageContent} from "../interfaces/backend";
import {addQuickOrderProductsAction, getQuickOrderBatchProductsAction} from "../interfaces/actions";
import {setCart} from '../reducers/cart';
import {openErrorSnack, openSuccessSnack} from '../reducers/start'
import i18next from 'i18next';
import {QuickOrderProduct} from "../interfaces/general";

i18next.loadNamespaces('messages');

export function* getBatchProducts(action: getQuickOrderBatchProductsAction) {
  try {
    const rawUploadData = action.payload.products;
    if (rawUploadData?.length) {
      const product: ProductDetailsPageContent[] = yield callHttp(
        getArray,
        PRODUCTS,
        rawUploadData.map((i) => i.code),
        'code'
      );

      const selectedProducts: QuickOrderProduct[] = product.map(p => {
        const matchedData = rawUploadData.find((data) => data.code === p.code);
        return {
          code: p.code,
          //alias: p.alias,
          name: p.name,
          quantity: matchedData?.quantity,
          price: p.price,
          discountPrice: p.discountPrice,
          // inStock: p.inStock,
          // isEnabled: p.isEnabled,
          // isOrderable: p.isOrderable,
        } as QuickOrderProduct
      });

      yield put(
        extendOrder({
          products: selectedProducts,
          //isCSVuploaded: action.payload.isCSVuploaded,
          isInitialLoad: action.payload.isInitialLoad
        })
      );
    }
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}


export function* addQuickOrderToCart(action: addQuickOrderProductsAction) {
  try {
    const products = action.payload.products.map(p =>
      ({
        product: p.code,
        quantity: p.quantity,
      }));
    const cart: Cart = yield callHttp(post, CART_ITEMS, products);
    yield put(setCart(cart));
    yield put(emptyOrder());
    yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export default function* quickOrderSaga() {
  yield takeEvery('quickOrder/addQuickOrderToCart', addQuickOrderToCart)
  yield takeEvery('quickOrder/getBatchProducts', getBatchProducts)
}
import {Box, Grid, keyframes, Typography, useMediaQuery} from "@mui/material"
import * as React from "react";
import {useEffect, useState} from "react";
import Sorting from "./Sorting";
import theme, {highlightedFonts, primaryColor} from "../../theme";
import Page from "../page";
import ListingList from "./ListingList";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hook";
import Filters from "./filters/Filters";
import {Link, useParams} from "react-router-dom";
import {
  Category,
  FilteredSparePart,
  ListingListTypes,
  MachinesSearch, MachineTypes
} from "../../interfaces/backend";
import {
  getMyMachinesSearch,
  getStandardMachines,
  getStandardMachinesSearch
} from "../../reducers/machine";
import CustomPagination from "./Pagination";
import {useLocation, useNavigate} from 'react-router';
import qs from 'qs';
import {isNumber} from "../../utils/validation";
import {findSpareParts, getCategory, setCheckedBoxesList} from "../../reducers/sparePart";
import {PricesFilter} from "./filters/Prices";
import {BreadCrumbsObject} from "../../interfaces/general";
import {getFindProducts} from "../../reducers/quickSearch";
import EmptySearchResults from "./quickSearch/EmptySearchResults";
import MobileView from "./mobile";

const leftFlow = keyframes`
  to {
    transform: translateX(-15%);
  }
`

interface TreeItemsProps {
  type: ListingListTypes,
}

interface FilterProps {
  page: number,
  perPage: number,
  order: string,
  attribute: {[key: string]: Array<string>},
  minPrice: number | undefined,
  maxPrice: number | undefined,
}

const rightFlow = keyframes`
  from {
    transform: translateX(-15%);
  }
  to {
    transform: translateX(0);
  }
`

const transformProperties = '0.5s linear normal forwards'
const machineListFlowLeft = `${leftFlow} ${transformProperties}`
const machineListFlowRight = `${rightFlow} ${transformProperties}`

const ListingPage = (props: TreeItemsProps) => {
  const {type} = props
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const {t} = useTranslation('searchPage')
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()


  const [sortValue, setSortValue] = useState<string>('sortAZ')
  const [closeFilter, setCloseFilter] = useState<boolean>(true)
  const [selectedItem, setSelectedItem] = useState<string>("")
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [pricesFilter, setPricesFilter] = useState<PricesFilter>()

  const checkedBoxesList = useAppSelector(state => state.sparePart.checkedBoxesList)
  const search = qs.parse(location.search.replace('?', ''))
  const isMyMachine: boolean = type === ListingListTypes.MyMachine
  const isStandardMachine: boolean = type === ListingListTypes.StandardMachine
  const machines: MachinesSearch = useAppSelector(state => state.machine.machinesSearch)
  const category: Category = useAppSelector(state => state.sparePart.category)
  const findProducts: MachinesSearch = useAppSelector(state => state.quickSearch.findProducts)
  const spareParts: FilteredSparePart = useAppSelector(state => state.sparePart.searchResult)

  const useData = (type: ListingListTypes) => {
    switch (type) {
      case ListingListTypes.MyMachine:
        return machines
      case ListingListTypes.Category:
        return spareParts
      case ListingListTypes.Search:
        return findProducts
      default:
        // starndard machines as default
        return machines
    }
  }
  const machineSearch = useData(type)
  const showFilters = machineSearch.itemsTotal === 0 && type === ListingListTypes.Search

  const makeBread = (): BreadCrumbsObject[] => [
    {name: t('common:home'), link: '/'},
    {name: type != ListingListTypes.Category?t(type):category.name, link: isMyMachine ? '/my/machines' : isStandardMachine?'/machines':`/c/${params.alias}`},
  ];

  useEffect(() => {
    if (!!search.page && isNumber(search.page) && selectedPage !== Number(search.page) && machineSearch.pagination.length >= Number(search.page)) {
      setSelectedPage(Number(search.page))
    }
    if (!(isMyMachine) && !(isStandardMachine) && type !== ListingListTypes.Search && type != ListingListTypes.Category) {
      dispatch(getCategory
      ({
        alias: type
      }))
    } 
  }, [])

  useEffect(() => {
    if (type === ListingListTypes.Category && params.alias) {
      dispatch(getCategory
      ({
        alias: params.alias
      }))
    }
  }, [params.alias,type])

  useEffect(() => {
    setSelectedPage(1)
  }, [selectedItem, pricesFilter])

  useEffect(() => {
    dispatch(setCheckedBoxesList({}))
    dispatch(getStandardMachines());

  }, [type])

  const setCloseOpenFilter = () => {
    setCloseFilter(!closeFilter)
  }


  const setupSearchAndNavigate = (path: string, queryParams?: Record<string, any>) => {
    const newSearch = { page: selectedPage, ...queryParams };
    const result = navigate({
      pathname: path,
      search: `?${qs.stringify(newSearch)}`,
    });
    return result;
  };

  const handleFilter = (commonParamsParam?: FilterProps) => {
    const commonParams = commonParamsParam || {
      page: selectedPage,
      perPage: 12,
      order: sortValue === 'sortAZ' ? 'asc' : 'desc',
      attribute: checkedBoxesList,
      minPrice: pricesFilter?.minPrice,
      maxPrice: pricesFilter?.maxPrice,
    };

    if (isMyMachine) {
      setupSearchAndNavigate('/my/machines', commonParams);
      dispatch(getMyMachinesSearch({ ...commonParams }));
    } else if (isStandardMachine) {
      setupSearchAndNavigate('/machines', commonParams);
      dispatch(getStandardMachinesSearch({ ...commonParams }));
    } else if (type === ListingListTypes.Search) {
      dispatch(getFindProducts({ ...commonParams, searchValue: search.searchValue || '' }));
    } else if (type === ListingListTypes.Category) {
      setupSearchAndNavigate(`/c/${params.alias}`, { ...commonParams, category: selectedItem || params.alias });
      dispatch(findSpareParts({ ...commonParams, category: selectedItem || params.alias }));

    }
  };

  useEffect(() => {
    const filter: FilterProps = {
        page: 1,
        perPage: 12,
        order: 'sortAZ',
        attribute: {},
        minPrice: undefined,
        maxPrice: undefined,
    }
    selectedPage != 1 && setSelectedPage(1)
    sortValue != 'sortAZ' && setSortValue('sortAZ')
    setSelectedItem("")
    dispatch(setCheckedBoxesList({}))
    setPricesFilter({minPrice: undefined, maxPrice: undefined})

    handleFilter(filter)
  }, [params.alias] )


  useEffect(() => {
    handleFilter()
  }, mobileView ? [selectedPage, sortValue, selectedItem, pricesFilter, type ] : [selectedPage, sortValue, checkedBoxesList, selectedItem, pricesFilter, type])

  return (
    <Page breadCrumbs={makeBread()} matchToHeaderWidth={true}>
        <Typography
          sx={{
            fontSize: mobileView ? '2.6rem' : '7rem',
            fontWeight: 700,
            lineHeight: '85px',
            color: primaryColor.textTopHeader,
            mt: '40px',
            textAlign: 'center'
          }}
        >
          {type != ListingListTypes.Category?t(type):category.name}
        </Typography>
        {mobileView ?
          <MobileView
            showFilters={showFilters}
            closeFilter={closeFilter}
            setCloseFilter={setCloseFilter}
            machineSearch={machineSearch}
            setSelectedItem={setSelectedItem}
            type={type}
            setPricesFilter={setPricesFilter}
            pricesFilter={pricesFilter}
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            setCloseOpenFilter={setCloseOpenFilter}
            checkedBoxesList={checkedBoxesList}
            handleFilter={handleFilter}
            searchValue={search.searchValue}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
          : <Grid container
                  sx={{
                    marginTop: '50px',
                  }}
          >
            <Grid xs={3} item display={'flex'}>
              {!showFilters && <Filters
                closeFilter={closeFilter}
                setCloseFilter={setCloseOpenFilter}
                filters={machineSearch.filterResults?.attributes}
                setSelectedItem={setSelectedItem}
                type={type}
                setPriceFilter={setPricesFilter}
                pricesFilter={pricesFilter}
                setSelectedPage={setSelectedPage}
              />}
            </Grid>
            <Grid xs={9} item container
                  sx={{
                    height: 'fit-content',
                    animation: closeFilter ? machineListFlowLeft : machineListFlowRight,
                    paddingLeft: closeFilter ? 0 : '25px'
                  }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '39px',
                  marginRight: '16px',
                  flexWrap: 'wrap',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography
                    // variant={'h6'}
                    sx={{
                      color: primaryColor.drawerText,
                      fontSize: highlightedFonts,
                      fontWeight: {xs: 400, md: 300},
                    }}
                  >
                    {t('sortBy')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Sorting sortValue={sortValue} setSortValue={setSortValue}/>
                  </Box>
                </Box>
                {type === ListingListTypes.Search &&
                <Typography
                sx={{
                  margin: 'auto',
                  paddingTop: {xs: '10px', lg: 0},
                  textAlign: 'center',
                  flex: {xs: '0 0 100%', lg: 1},
                  order: {xs: 2, lg: 0},
                  fontSize: highlightedFonts,
                }}
                >
                  {t('searchResult{{value}}', {value: !!search.searchValue ? search.searchValue : ''})}
                </Typography>

                }
                <Box>
                  <Typography
                  sx={{
                    fontSize: highlightedFonts,
                    color: primaryColor.drawerText,
                    fontWeight: {xs: 400, md: 300},
                  }}
                  >
                    {t('itemsTotal{{value}}', {value: machineSearch.itemsTotal ? machineSearch.itemsTotal : 0})}
                  </Typography>
                </Box>


              </Box>

               {machineSearch.itemsTotal > -1 && <Box
                sx={{
                  width: '100%',
                  fontSize: '2rem',
                }}
              >
                {/*{type === ListingListTypes.Search && ((machineSearch && machineSearch.itemsCount === 0) || !search.searchValue) ?
                            <EmptySearchResults/> :
                            <ListingList machineList={machineSearch} type={type}/>}*/}
                {type === ListingListTypes.Search ?
                  (machineSearch && machineSearch.itemsCount === 0) ?
                    <EmptySearchResults/> : !search.searchValue ?
                      <EmptySearchResults/> :
                      <ListingList machineList={machineSearch} type={type} closeFilter={closeFilter} />
                  : <ListingList machineList={machineSearch} type={type} closeFilter={closeFilter}/>}
              </Box>}

            </Grid>
          </Grid>}
        {/* {type===ListingListTypes.Search && machineSearch.itemsTotal === 0 && <EmptySearchResults />}*/}
      <Box
        sx={{
          color: primaryColor.drawerText,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          margin: '85px auto 126px',
          maxWidth: {xs: '50%', md: '70%'},
          minWidth: 'fit-content',
          position: 'relative'
        }}
      >
        {machineSearch.pagination?.length > 1 ?
          <CustomPagination
            pagination={machineSearch.pagination}
            currentPage={selectedPage}
            setSelectedPage={setSelectedPage}
          /> : null}
        {type === ListingListTypes.MyMachine && <Link to={`/machines`}>
          <Typography sx={{whiteSpace: 'nowrap', position: 'absolute', right: 0}} variant={'h6'}>
            {t('toStandardMachine')}
          </Typography>
        </Link>}
      </Box>
    </Page>
  )
}

export default (ListingPage)
import {callHttp, callHttpWithoutSpinner} from "../utils/api";
import {del, get, patch, post, put as putRequest} from "../utils/httpUtil";
import {put, takeEvery} from "redux-saga/effects";
import {openErrorSnack, openSuccessSnack} from "../reducers/start";
import {
  CANCEL_ORDER,
  CHANGE_PASSWORD,
  FILTER_ORDER,
  MY_MESSAGES,
  USER, USER_ORDER_DETAILS,
  USERS
} from "../constants/api";
import i18next from "i18next";
import {
  addUserAction,
  cancelOrderAction,
  deleteUserAction,
  filteredOrderHistoryAction, getMyMessagesAction, getOrderDetailsAction,
  patchUserAction,
  saveNewPassword,
  sendMyMessageAction
} from "../interfaces/actions";
import {MyMessage, Order, OrderHistory, User} from "../interfaces/backend";
import {
  getUsers,
  setMyMessages, setOrderDetails,
  setOrderHistory,
  setPasswordChangedSuccessfully,
  setUsers
} from "../reducers/myAccount";
import {RequestError} from "../utils/exceptions";

i18next.loadNamespaces('messages');

export function* changePassword(action: saveNewPassword) {
  try {
    const {oldPassword, newPassword} = action.payload
    yield callHttp(post, CHANGE_PASSWORD, {
      oldPassword,
      newPassword,
    });
    yield put(openSuccessSnack(i18next.t('messages:successChangedPassword')));
    yield put(setPasswordChangedSuccessfully(true))
  } catch (err: any) {
    if (err instanceof RequestError) {
      yield put(openErrorSnack({message: err.getErrors()}));
    }
  }
}

export function* getOrderHistory(action: filteredOrderHistoryAction) {
  try {
    const orderHistory: OrderHistory = yield callHttp(get, FILTER_ORDER(action.payload))
    yield put(setOrderHistory(orderHistory))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* getOrderDetails(action: getOrderDetailsAction) {
  try {
    const {orderId} = action.payload
    const orderDetails: Order = yield callHttp(get, USER_ORDER_DETAILS(orderId))
    yield put(setOrderDetails(orderDetails))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* cancelOrder(action: cancelOrderAction) {
  const {orderId, filtering} = action.payload

  try {
    yield callHttp(putRequest, CANCEL_ORDER(orderId))
    const orderHistory: OrderHistory = yield callHttp(get, FILTER_ORDER(filtering))
    yield put(setOrderHistory(orderHistory))
    yield put(openSuccessSnack(i18next.t('messages:orderCancelled{{orderId}}', {orderId: orderId})));
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* getUsersSaga() {
  try {
    const allUsers: User[] = yield callHttp(get, USERS);
    const userList = Object.values(allUsers);
    yield put(setUsers(userList))
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addUser(action: addUserAction) {
  const {user} = action.payload;
  const {id, ...newUser} = user
  try {
    yield callHttp(post, USERS, newUser);
    yield put(getUsers());
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* patchUser(action: patchUserAction) {
  const {user} = action.payload;
  try {
    yield callHttp(patch, USER(user.id), user)
    yield put(getUsers());
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* deleteUser(action: deleteUserAction) {
  try {
    const {id} = action.payload;
    yield callHttp(del, USER(id));
    yield put(getUsers())
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMyMessagesSaga(action: getMyMessagesAction) {
  const {fromMessage, perPage} = action.payload
  try {
    const myMessages: MyMessage[] = yield callHttp(get, MY_MESSAGES({fromMessage: fromMessage, perPage: perPage}))
    yield put(setMyMessages({items: myMessages, isNewMessage: fromMessage ? false: true}))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* sendMyMessageSaga(action: sendMyMessageAction) {
  const {message} = action.payload
  try {
    yield callHttpWithoutSpinner(post, MY_MESSAGES({}), {message: message})
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export default function* myAccountSaga() {
  yield takeEvery('myAccount/cancelOrder', cancelOrder)
  yield takeEvery('myAccount/changePassword', changePassword)
  yield takeEvery('myAccount/getOrderHistory', getOrderHistory)
  yield takeEvery('myAccount/getOrderDetails', getOrderDetails)
  yield takeEvery('myAccount/getUsers', getUsersSaga)
  yield takeEvery('myAccount/addUser', addUser)
  yield takeEvery('myAccount/patchUser', patchUser)
  yield takeEvery('myAccount/deleteUser', deleteUser)
  yield takeEvery('myAccount/getMyMessages', getMyMessagesSaga)
  yield takeEvery('myAccount/sendMyMessage', sendMyMessageSaga)
}

import {Box, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import theme, {primaryColor} from "../../../theme";
import React from "react";
import {SettingElement} from "../../../interfaces/backend";
import {useAppSelector} from "../../../hook";
import {Link} from "react-router-dom";

const ReturnOptions = () => {
  const {t} = useTranslation('myAccount')
  const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)
  const phone = settings.find(item => item.name === 'contact_phone' && item.value);
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  return (
      <Box sx={{color: primaryColor.accountTextColor,  fontWeight: mobileView?'500':'300', padding: '31px 0px 42px 118px'}}>
        <Box>{t('wantToReturn')}</Box>
        <Box sx={{mb: '22px'}}>{t('readyToHelp')}</Box>
        <Link to={`tel:${phone?.value}`}>
          <Box sx={{display: 'flex'}}>
            <Box
                component={"img"}
                alt={"phone"}
                src={'/images/phoneOrange.svg'}
                sx={{mr: '10px', fontSize: '1.6rem', fontWeight: '500'}}
            />
            <Box sx={{
              color: primaryColor.accountPhoneColor,
            }}>{phone?.value}</Box>
          </Box>
        </Link>

      </Box>
  )
}

export default React.memo(ReturnOptions)
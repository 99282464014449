import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Cart, CartItem, CheckoutStageType} from "../../../interfaces/backend";
import {StyledHeadTableCell} from "../styleElements";
import CartTableItemDesktop from "./CartTableItemDesktop";

export interface TableDesktopProps {
  stage: CheckoutStageType,
  data: Cart
}

export default function CartTableDesktop({stage, data}: TableDesktopProps) {
  const {t} = useTranslation('checkoutPage')

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeadTableCell>
              {t('position')}
            </StyledHeadTableCell>
            <StyledHeadTableCell/>
            <StyledHeadTableCell>{t('data')}</StyledHeadTableCell>
            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('productPrice')}</StyledHeadTableCell>
            <StyledHeadTableCell sx={{textAlign: 'center'}}>{t('quantity')}</StyledHeadTableCell>
            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('subtotal')}</StyledHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data?.items.map((row: CartItem, i: number) =>
              <CartTableItemDesktop
                key={i}
                position={i + 1}
                item={row}
                stage={stage}
              />
            )
          }
        </TableBody>
      </Table>
    </Box>
  )
}
import {  List, ListItem } from "@mui/material";
import { primaryColor } from "../../../../../../theme";
import { useAppDispatch } from "../../../../../../hook";
import { setMyDataOpened } from "../../../../../../reducers/styleControls";


interface MenuListItemProps {
  listItems: Array<React.ReactElement>;
}

const MenuListItem = (props: MenuListItemProps) => {
const {listItems} = props;
const dispatch = useAppDispatch();


  return (
        <List>
          {listItems.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              onClick={
                ()=>{dispatch(setMyDataOpened(false))}
              }
              sx={{
                borderTop: "4.5px solid" + primaryColor.background,
              }}
            >
              {item}
            </ListItem>
          ))}
        </List>
  );
};

export default MenuListItem;

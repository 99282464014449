import React, { useState } from "react";
import { Box, FormControl, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { useTranslation } from "react-i18next";
import { ContainerBox } from "../../../pages/Account/UserManagement/style";
import {
  StyledBox,
  StyledContainer,
  StyledTypography,
} from "../../common/StyledComponents";
import CustomAutocomplete from "../../common/customAutoComplete";
import ButtonsBox  from "../../common/ButtonsBox";

import {
  addUser,
  closeUserForm,
  patchUser,
  setNewUserInput,
  setNewUserRole,
  setUserTitle,
} from "../../../reducers/myAccount";
import { openErrorSnack } from "../../../reducers/start";
import { filterNonNumericChars, isEmailValid } from "../../../utils/validation";
import { parseLocaleFloat } from "../../../utils/localization";
import IOSSwitchComp from "../../../pages/Account/UserManagement/IOSSwitchComp";
import {Title } from "../../../interfaces/backend";
import { setOpenedDrawerID } from "../../../reducers/static";
import theme, { defaultFonts, primaryColor } from "../../../theme";
import {CodeName} from "../../../interfaces/general";

export default function UserEditForm() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("myAccount");

  const titles = useAppSelector((state) => state.metadata.titles);
  const user = useAppSelector((state) => state.myAccount.newUser);
  const isModeEdit = useAppSelector((state) => state.myAccount.isModeEdit);
  const lgView = useMediaQuery(theme.breakpoints.down("lg"));

  const userTypes: CodeName[] = [
    { code: 'keyUser' , name: t("keyUser") as string},
    { code: 'user' , name: t("user") as string }
  ];

  const userTypeItem = userTypes.find((item) => item.code === (user?.isAdmin ? 'keyUser' : 'user')) || null;
  const [selectedUserType, setSelectedUserType] = useState<CodeName|null>(userTypeItem)

  const title = titles.find(title => title.code === user?.title) || null
  const [selectedTitle, setSelectedTitle] = useState<Title|null>(title)
  

  const handleTitleChange = (title: Title | null) => {
    if (title) {
      dispatch(setUserTitle(title.code));
      setSelectedTitle(title);
    }
  };
  const handleRoleChange = (selectedRole: CodeName | null) => {
    if (selectedRole) {
      dispatch(setNewUserRole(selectedRole.code));
      setSelectedUserType(selectedRole);
    }
  };

  const validateForm = () => {
    let message = "";

    let isCartLimitValid = true;

    if (user.cartLimit) {
      try {
        parseLocaleFloat(user.cartLimit);
      } catch (e) {
        isCartLimitValid = false;
      }
    }

    if (!user.title) {
      message = t("Validation Messages:Salutation is Required!");
    } else if (!user.firstName) {
      message = t("Validation Messages:First Name is Required!");
    } else if (!user.lastName) {
      message = t("Validation Messages:Last Name is Required!");
    } else if (!user.email) {
      message = t("Validation Messages:Email is Required!");
    } else if (!isEmailValid(user.email)) {
      message = t("Validation Messages:Wrong email format!");
    } else if (user.isAdmin === null || user.isAdmin === undefined) {
      message = t("Validation Messages:User Role is Required!");
    } else if (!isCartLimitValid) {
      message = t("Validation Messages:Cart limit is in wrong format!");
    }
     else {
      return true;
    }
    dispatch(openErrorSnack({ message: message }));
    return false;
  };
  const submitForm = () => {
    
    const numericValue = filterNonNumericChars(user.cartLimit)

    const cartLimit = numericValue ? parseLocaleFloat(numericValue) : null;


    let userSample = {
      id: isModeEdit ? user.id : "",
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      isAdmin: user.isAdmin,
      isEnabled: user.isEnabled,
      cartLimit: cartLimit,
      mobile: "",
      phone: "",
    };
    isModeEdit
      ? dispatch(patchUser({ user: userSample }))
      : dispatch(addUser({ user: userSample }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    validateForm() && submitForm();
  };

  const commonStyles = {
    width: {
        xs: '100%',
        lg: "48%",
      },
      minWidth: "195px",
  };

  const responsiveStyles = {
    width: "100%",
    marginTop: "30px",
  };

 
  
  return (
    <ContainerBox>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          flexDirection: "row",
          width: "100%",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "start",

 
        }}
        onClick={() => dispatch(closeUserForm())}
      >
        <Box
          component={"img"}
          src={"/images/icons/arrowCarouselLeft.svg"}
          alt="image"
          onClick={() => {
            dispatch(setOpenedDrawerID("account-menu"));
          }}
          sx={{
            height: {md: "20px",  lg: '25px'},
            width: "auto",
            objectFit: "cover",
            cursor: "pointer",
            alignSelf: "center",
          }}
        />
        <StyledTypography
          sx={{
            textTransform: "uppercase",
            marginLeft: "30px",
            fontWeight: 400,
            fontSize: defaultFonts,
          }}
        >
          {t("back")}
        </StyledTypography>
      </Box>

      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              flexDirection: "column",
            
            }}
          >
            <StyledContainer>
              <Box
                sx={{
                  ...commonStyles,
                  paddingTop: "20px",
                }}
              >
        
          <CustomAutocomplete
            options={titles}
            handle={handleTitleChange}
            label={t("title")}
            required={true}
            value={selectedTitle}

          />
              </Box>
            </StyledContainer>
            <StyledContainer>
              <StyledBox
                label={t("firstName")}
                name="firstName"
                handleChange={(e) =>
                  dispatch(setNewUserInput({ target: e.target }))
                }
                required={true}
                value={user?.firstName || ""}
                style={{
                     width: '48%',
                  ...(lgView && responsiveStyles),
                }}
              />
              <StyledBox
                label={t("lastName")}
                name="lastName"
                handleChange={(e) =>
                  dispatch(setNewUserInput({ target: e.target }))
                }
                required={true}
                value={user?.lastName || ""}
                style={{
                     width: '48%',
                  ...(lgView && responsiveStyles),
                }}
              />
            </StyledContainer>

            <StyledContainer>
              <StyledBox
                label={t("email")}
                required={true}
                name="email"
                handleChange={(e) =>
                  dispatch(setNewUserInput({ target: e.target }))
                }
                value={user?.email || ""}
                style={{
                  width: '48%',
                  ...(lgView && responsiveStyles),
                }}
              />
              <Box
                sx={{
                  ...commonStyles,
                  paddingTop: {
                    xs: "20px",
                    lg: "unset",
                  },
                }}
              >
            <CustomAutocomplete
                options={userTypes}
                handle={handleRoleChange}
                label={t("typeOfUser")}
                required={true}
                value={selectedUserType}
              />
              </Box>
            </StyledContainer>

            <StyledContainer>
              <StyledBox
                label={t("cartLimit")}
                required={false}
                name="cartLimit"
                handleChange={(e) =>
                dispatch(setNewUserInput({
                target: {
                  value: filterNonNumericChars(e.target.value),
                  name: "cartLimit", 
                }
              }))

                }
                
                value={user.cartLimit}
                style={{
                  width: '48%',
                  ...(lgView && responsiveStyles),
                }}
              />
              </StyledContainer>

              <StyledContainer>

              <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: primaryColor.myAccountColorLimitText,
                    justifyContent: "space-between",
                    marginTop: '35px',
                  ...commonStyles,
                
                }}
              >
                <StyledTypography>
                  {t("status")}
                </StyledTypography>

                <IOSSwitchComp
                  checked={user?.isEnabled}
                  onChange={()=>
                   dispatch(
                      setNewUserInput({
                        target: {
                          name: 'isEnabled',
                          value: !user.isEnabled, 
                        },
                      })
                   )
                  }
                />
              </Box>

            <Box sx={{
              marginTop: '35px',
              ...commonStyles
            }}>
              <ButtonsBox
                handleCancel={() => {
                  dispatch(closeUserForm());
                }}
                confirmButtonText={
                  isModeEdit ? t("saveEditUser") : t("saveNewUSer")
                }
              />
            </Box>

            </StyledContainer>
            
          </Box>
        </form>
      </FormControl>
    </ContainerBox>
  );
}
import React from "react";
import { Box, Button, SxProps, Tooltip, Typography } from "@mui/material";
import { defaultFonts } from "../../../theme";

export interface BaseProps {
  buttonContent?: string;
  buttonStyles?: SxProps;
  onClick: () => void;
  isDisabled?: boolean;
  variant?: "contained" | "text";
}

interface ButtonWithIconProps {
  icon: string;
  baseProps: BaseProps;
}
interface TooltipWrapperProps {
  children: React.ReactNode;
}

const ButtonWithIcon = (props: ButtonWithIconProps) => {
  const { buttonContent, onClick, isDisabled, buttonStyles, variant } =
    props.baseProps;
  const { icon } = props;

  const TooltipWrapper = ({ children} : TooltipWrapperProps ) => {
    if (isDisabled || !buttonContent) {
      return <>{children}</>;  
    } else {
      return (
        <Tooltip title={buttonContent}>{children as React.ReactElement}</Tooltip>
      );
    }
  };


  return (
    <TooltipWrapper>
    <Button
      sx={buttonStyles}
      onClick={onClick}
      disabled={isDisabled}
      variant={variant}
    >
      <Box
        component={"img"}
        sx={{ paddingRight: buttonContent ? "21px" : 0 }}
        alt={buttonContent}
        src={icon}
      />
      <Typography sx={{fontSize: defaultFonts}}>
      {buttonContent}
      </Typography>
    </Button>
    </TooltipWrapper>


  );
};

export default React.memo(ButtonWithIcon);

import {Box, BoxProps, styled, SxProps, Typography, TypographyProps} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";


interface FieldProps {
    type?: string,
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    label?: string | null,
    required?: boolean,
    style?: SxProps,
    value?: string,
    disabled?: boolean,
    name?: string
}

export const StyledBox = styled((props: FieldProps) => (
    <Box
        mt={2}
        sx={{
            width: '48%',

            ...props.style
        }}
    >
        <StyledTextField
            handleChange={props.handleChange}
            label={props.label}
            required={props.required}
            value={props.value}
            disabled={props.disabled}
            name = {props.name}
        />
    </Box>
))(({theme}) => ({}))


export const StyledTextField = styled((props: FieldProps) => (
    <TextField
        required={props.required}
        label={props.label}
        name={props.name}
        type={props.type}
        value = {props.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.handleChange && props.handleChange(event)
        }
    />
))(({theme}) => ({}))

export const RowFlexBox = styled((props: BoxProps) => <Box {...props} />)(
    ({ theme }) => ({
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      margin: "auto",
    })
  );

  export const CustomTypography = styled((props: TypographyProps) => (
    <Typography {...props} />
  ))(({ theme }) => ({
      width: "100%",
      maxWidth: "fit-content",
      padding: "3.5px 0px",
      display: "-webkit-box",  
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    [theme.breakpoints.down("md")]: {
      overflow: "unset",
      padding: "2px 0",
    },
  }));
import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from 'react-i18next'
import {TRANSLATIONS} from "./constants/api";
import {selectLng} from "./utils/localization";

const translationBuild: string | null = localStorage.getItem('translationsBuild');
const saveMissing: boolean = true;//process.env.NODE_ENV !== 'production';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        backend: {
            // translation file path
            //  loadPath: "/assets/i18n_translation/{{lng}}.json",
            loadPath: TRANSLATIONS + '?ns={{ns}}' + (translationBuild ? '&t=' + translationBuild : ''),
            addPath: TRANSLATIONS,
            parsePayload: (namespace: string, key: string, fallbackValue?: string) => {
                return {name: key, namespace: namespace}
            },
        },
        fallbackLng: "de",
        saveMissing: saveMissing,
        lng: selectLng(),
        debug: false,
        ns: ['common'],
        keySeparator: '<->', // we use content as keys. Added a fake separator just to exclude space as sa separator

        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        initImmediate: false,
        react: {
            // wait: true,
            //withRef: false,
            bindI18n: 'languageChanged loaded',
            //bindStore: 'added removed',
            nsMode: 'default',
            //omitBoundRerender: false,
            //useSuspense: false
        }
    }, undefined);

export default i18n;
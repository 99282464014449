import React, {useEffect} from 'react';

import Term from './Term';
import {useAppDispatch, useAppSelector} from "../../hook";
import {FrontendTerm} from "../../interfaces/backend";
import {getTerms} from "../../reducers/cart";
import {termsPropsType} from "../../interfaces/general";
import {Box, BoxProps, styled} from "@mui/material";
import {primaryColor} from "../../theme";
import Typography from "@mui/material/Typography";

const TermContainer = styled((props: BoxProps) => (
    <Box {...props}  />
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
}));



type TermTypographyProps = {
    category: string;
};
const TermTypography = styled(Typography)<TermTypographyProps>(({ theme, category }) => ({
    fontSize: '2rem',
    color: primaryColor.asteriskColor,
    marginRight: '4px',
    width: 'fit-content',
    opacity: category === 'checkout' ? 1 : 0
}));


const Terms = (props: termsPropsType) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getTerms({category: props.category}))
    }, [])
    const terms: Array<FrontendTerm> = useAppSelector(state => state.cart.terms)

    return (
        <>
            {terms?.map((term: FrontendTerm, i: number) => (
                <TermContainer key={i}>
                {props.type === 'checkbox' &&
                   <TermTypography category ={props.category} 
                    sx={{

                        opacity: term.isMandatory  ? 1 : 0,
                    }}>
                        *
                    </TermTypography>
                }
                    <Term key={i} index={i} term={term} type={props.type}/>
                </TermContainer>

            ))}
        </>
    )
}

export default Terms


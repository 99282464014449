import React from 'react';
import {useTranslation} from 'react-i18next';
import {formatMoney} from "../../utils/localization";
import {useAppSelector} from "../../hook";
import {Box, Typography} from "@mui/material";
import {primaryColor, tableFontSize} from "../../theme";
import {StyledBoxButton, StyledDivider, StyledPriceInfo} from "./styleElements";
import {useNavigate} from "react-router";
import AuthDynamicComponent from '../../components/accessControl/AuthDynamicComponent';

const CartSummary = () => {
  const {t} = useTranslation('checkoutPage')
  const cart = useAppSelector(state => state.cart.cart)
  const navigate = useNavigate()

  
  
  const handleClick = () => {
    navigate('/checkout')
  };


  const renderButton = (content: string) => (
    <StyledBoxButton labelButton={content} handleClick={handleClick}/>
);



  return (
    <Box
      sx={{
        color: primaryColor.shoppingCartTitle,
        fontSize: tableFontSize.contentMain,
        alignSelf: 'end',
        paddingX: {
          xs:'30px',
          sm: '25px',
          md: 'unset'
        },
        margin: {
          xs: '50px auto 0px',
          lg: '90px 55px 52px auto '

        },
        width: '100%',
        maxWidth: {
          xs:'450px',
          md: '500px'
        }
      }}
    >
      <Box>
        <StyledPriceInfo name={t('productCost')} price={formatMoney(cart.total)}/>
        <StyledPriceInfo name={t('taxValue')} price={formatMoney(cart.totalTax)}/>
        <StyledDivider/>
        <StyledPriceInfo name={t('totalCost')} style={{fontWeight: 700}} price={formatMoney(cart.totalWithTax)}/>
        <Box>
          {t('taxDeliveryCost')}
        </Box>
      </Box>
      <Box
        sx={{
          mt: '25px',
        }}
      >
        <Box sx={{
          width: {
            xs: 'inherit',
            md: '350px',
            lg: 'unset'
          }

        }}
          >

        <AuthDynamicComponent 
          children={renderButton(t('confirmation'))}
          fallback={
            <>
              <Typography
                sx={{
                  mt: '45px',
                  mb: '16px',
                  fontSize: tableFontSize.contentMain,
                }}
              >
                {t('pleaseLogin')}
              </Typography>
              {renderButton(t('loginNow'))}
            </>
          }
        />

        </Box>

      </Box>
    </Box>
  )
}

export default (CartSummary)
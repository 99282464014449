import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../hook";
import {SettingElement} from "../../interfaces/backend";
import {StyledTypography} from "../../components/common/StyledComponents";

export default function InvoicingCompanyAddress() {
    const [showInvoicingCompanyAddress, setShowInvoicingCompanyAddress] = useState(false)

    const countries = useAppSelector(state => state.metadata.countries)
    const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)

    const companyName = settings?.find(item => item.name === 'company_name')?.value
    const street = settings?.find(item => item.name === 'company_street_name')?.value
    const streetNumber = settings?.find(item => item.name === 'company_street_number')?.value
    const postalCode = settings?.find(item => item.name === 'company_postal_code')?.value
    const city = settings?.find(item => item.name === 'company_city')?.value
    const countryElement = settings?.find(item => item.name === 'company_country')?.value
    const country = countries.find(item => item.code === countryElement?.toUpperCase())?.name

    useEffect(() => {
        setShowInvoicingCompanyAddress(!!companyName && !!postalCode && !!city && !!country)
    }, [companyName, postalCode, city, country]);


    return (
        showInvoicingCompanyAddress ?
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: {xs: 'center', md: 'start'}
            }}>
            <StyledTypography sx={{textTransform: 'uppercase'}}>{companyName}</StyledTypography>
            <StyledTypography>{street} {streetNumber}</StyledTypography>
            <StyledTypography>{postalCode} {city}</StyledTypography>
            <StyledTypography>{country}</StyledTypography>
        </Box> : null
    )
}
import React from "react";
import {Box, BoxProps, styled, StyledEngineProvider, TextField} from "@mui/material";
import theme, {primaryColor} from "../../../theme";
import Message from "../CustomerSupport/Message";
import {useTranslation} from "react-i18next";
import {CustomerSupportServiceProps} from "../CustomerSupport";

export const ContainerBox = styled((props: BoxProps) => (

    <Box {...props}  />
))(({theme}) => ({
    backgroundColor: primaryColor.backgroundOverlay,
    minWidth: '900px',
    minHeight: '785px',
    position: 'relative',

    //padding: '29px 50px 50px 50px'
}));


const InputBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    marginTop: '20px',
    //alignItems: 'end',
    //opacity: '0.05',
    backgroundColor: primaryColor.textAreaBackground,
    height: 'fit-content',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
}));



const style = {
    messageBox: {
        padding: '29px 50px 50px 50px',
        display: 'flex',
        flexDirection: 'column',
        height: '650px',
        overflow: 'auto',

    },
}

const CustomerSupportServiceDesktop = (props: CustomerSupportServiceProps) => {
    const {t} = useTranslation('myAccount')
    const {setTextMessage, textMessage, handleSend, myMessages, handleUIEvent} = props

    return (
        <ContainerBox>
            <Box id={"messagesList"} onScroll={(event) => handleUIEvent(event)} sx={style.messageBox}>

                {myMessages.slice(0).reverse().map((mm, index) => (
                    <Message key={index} text={mm.message} isInput={!mm.isAuthorUser}
                             createdAt={mm.createdAt}
                    />
                ))}
            </Box>

            <InputBox>
                <Box
                     sx={{display: 'flex', justifyContent: 'flex-end', backgroundColor: primaryColor.chatBackground, width: '100%'}}>
                    <Box sx={{
                        alignSelf: 'end',
                        color: primaryColor.chatImportColor,
                        marginRight: '50px',
                        marginBottom: '12px'
                    }}>
                        {t('importPdf')}
                    </Box>
                </Box>
                <Box sx={{
                    minWidth: '250px',
                    //marginRight: '16px',
                    //position: 'absolute',
                    top: 0,
                    width: '100%',
                    resize: 'none',
                    border: 0,
                    display: 'flex',
                    position: 'relative',
                    maxHeight: 'fit-content',
                    marginY: '24px',
                    [theme.breakpoints.down("md")]: {
                        marginBottom: '20px'
                    }
                }}>
                    <Box
                        component={'img'}
                        src={'/images/icons/document_attach.svg'}
                        sx={{
                            marginX: '28px',
                            height: '30px',
                            width: '30px',
                        }}

                    />
                    <StyledEngineProvider injectFirst>
                        <Box sx={{width: '100%', }}>
                            <TextField
                                placeholder={t('writeAMessage') as string}
                                multiline
                                //rows={3}
                                maxRows={3}
                                sx={{
                                    color: primaryColor.textAreaColor,
                                    width: '95%',
                                    resize: 'none',
                                    border: 0,
                                    backgroundColor: primaryColor.textAreaBackground,
                                    outline: 'none',
                                    fontSize: '1.6rem'
                                }}
                                value={textMessage}
                                onChange={(event) => {
                                    setTextMessage(event.target.value)
                                }}
                            />
                            {/*<TextareaAutosize maxRows={3} minRows={1} style={{
                                color: primaryColor.textAreaColor,
                                width: '90%',
                                resize: 'none',
                                border: 0,
                                backgroundColor: primaryColor.textAreaBackground,
                                outline: 'none',

                            }}

                                              onChange={(event) => {
                                                  setTextMessage(event.target.value)
                                              }}
                                              value={textMessage}
                                              placeholder={t('writeAMessage') as string}/>*/}
                        </Box>
                    </StyledEngineProvider>
                    <Box display={'flex'} mr={'90px'}>
                       {/* <CustomerSupportButtons handleSend={handleSend}/>*/}
                        <Box
                            component={'img'}
                            src={'/images/icons/message_send.svg'}
                            height={'30px'}
                            width={'42px'}
                            onClick={() => handleSend()}

                        />
                    </Box>
                </Box>
            </InputBox>
        </ContainerBox>
    )
}

export default (CustomerSupportServiceDesktop)

import * as React from 'react';
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {Box, SxProps, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import {Article} from "../../interfaces/backend";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Arrow from "../common/svg/arrow";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters={true} elevation={0} square {...props} />
))(({theme}) => ({}))

export default function ArticleBox(props: { article: Article, imageBoxStyling: SxProps }) {
    const {article, imageBoxStyling} = props
    const {t} = useTranslation('startPage')

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const handleChange = () => {
        setExpanded(!expanded)
    }

    const sxAccordionSummary = !mobileView ?
        {
            maxHeight: '150px',
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: '35px',
                '&:hover': {
                    fill: primaryColor.drawerHover
                }
            },
            '& .MuiAccordionSummary-content': {},
            padding: ''
        }
        :
        {
            maxHeight: '78px',
            flexDirection: 'column',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(180deg)',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {},
            '& .MuiAccordionSummary-content': {
                marginBottom: 'unset'
            },
            padding: '0'
        }
    const articleStyling = !mobileView ?
        {
            position: 'relative',
            top: '50%',
            width: '100%',
            minHeight: 'unset',
            height: 'fit-content',
            maxHeight: '385px',
            background: 'transparent',
            paddingLeft: '75px',
            paddingRight: '100px',
            paddingBottom: '80px',
            paddingTop: '80px',
            opacity: 0.95,
            backgroundColor: primaryColor.articleDescriptionBackground,
        }
        :
        {
            width: '100%',
            minHeight: '75px',
            height: 'fit-content',
            maxHeight: 'unset',
            background: 'transparent',
            paddingLeft: '50px',
            paddingRight: '50px',
            paddingBottom: '10px',
            paddingTop: 'unset',
            opacity: expanded ? 1 : 0.8,
            backgroundColor: primaryColor.articleDescriptionBackground
        }

    return (
        <Box
            sx={{
                background: `url('${article.image}')`,
                backgroundSize: 'cover',
                textAlign: 'start',
                ...imageBoxStyling
            }}
        >
            <Accordion
                expanded={expanded}
                sx={articleStyling}
                onClick={handleChange}
            >
                <MuiAccordionSummary
                    expandIcon={
                        <Arrow fill={!expanded ? 'white' : primaryColor.badgeColor}/>
                    }
                    sx={sxAccordionSummary}
                >
                    <Box height={'40px'}></Box>
                    <Typography variant={'h2'}>{article.title}</Typography>
                </MuiAccordionSummary>

                <MuiAccordionDetails
                    sx={!mobileView ? {
                            paddingLeft: '95px',
                            paddingRight: '20%'
                        } :
                        {
                            padding: '30px 0 0 0'
                        }
                    }
                >
                    <Typography
                        variant={'h7'}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            lineHeight: 1.5,
                            maxHeight: '4.5em'
                        }}
                    >
                        {article.description.replace(/<\/?[^>]+(>|$)/g, "")}
                    </Typography>
                    <NavLink
                        to={`/blog/${article.alias}`}
                        target='_blank'
                    >
                        <Typography
                            variant={'h6'}
                            sx={{
                                '&:hover': {
                                    textDecoration: 'underline',
                                    textDecorationColor: primaryColor.textDecorationColor,
                                    textUnderlineOffset: 6,
                                    textDecorationThickness: '1.5px',
                                },
                                color: primaryColor.badgeColor,
                                marginTop: '20px'
                            }}
                        >
                            {t('learnMore')}
                        </Typography>
                    </NavLink>
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    )
}
import {useMediaQuery} from "@mui/material";
import { useAppSelector } from "../../../hook";
import {Machine} from "../../../interfaces/backend";
import theme from "../../../theme";
import Product from "../../../pages/ProductDetails/Product";
import MobileProductDetails from "../../../pages/ProductDetails/mobile/MobileProductDetails";

const MachineInfo = () => {
  const lgView = useMediaQuery(theme.breakpoints.down('lg'))
  const selectedMachine: Machine = useAppSelector(state => state.machine.machine);

  return (
    <>
    {lgView ? 
    <MobileProductDetails product={selectedMachine.product} />
    :
    <Product product={selectedMachine.product} showLongDescription={true}/>
    } 
  </>
  )
}

export default MachineInfo;

import {Box, useMediaQuery} from "@mui/material";
import theme from "../../../../theme";

import DesktopView from "./desktopView";
import MobileView from "./mobileView";
import {useAppSelector} from "../../../../hook";
import Search from "./Search";


const BottomHeader = () => {
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const searchBoxOpen = useAppSelector(state => state.quickSearch.searchBoxOpen)

  return !mobileView ? (
    <DesktopView/>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <MobileView/>
      {searchBoxOpen ? <Search/> : null}
      {/*<ModalSearchBox />*/}
    </Box>
  )
}

export default (BottomHeader)
import {useAllMyAccountTabs} from "../../../components/common/Tabs";
import AllTabs from "../../../components/common/Tabs/AllTabs";
import React, {useEffect, useState} from "react";
import {useMediaQuery} from "@mui/material";
import theme from "../../../theme";
import {useTranslation} from "react-i18next";
import Page from "../../page";
import {useNavigate} from "react-router";

const AccountTabs = () => {
  const [selectedItem, setSelectedItem] = useState<string>("")
  const [selectedName, setSelectedName] = useState<string>()
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const {t} = useTranslation(['myAccount']);
  const navigate = useNavigate();

  useEffect(() => {
   if(!mobileView)
    navigate('/my-account/my-data')
  }, [])

  return (
      <Page>
       <AllTabs allTabs={useAllMyAccountTabs()} selectedItem={selectedItem}
                                setSelectedName={setSelectedName} selectedName={selectedName}
                                isMobile={true} topTitle={String(t("myAccount:myAccount"))}/>
      </Page>
  )
}

export default (AccountTabs)

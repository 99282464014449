import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hook";
import { getStaticPageContent } from "../../reducers/static";
import Page from "../page";
import { primaryColor } from "../../theme";
import { useTranslation } from "react-i18next";
import { BreadCrumbsObject } from "../../interfaces/general";
import RelatedItemsSlider from "../../components/common/relatedItemsSlider";
import ArticleCard from "../../components/common/relatedItemsSlider/articleSliderCard";
import { Article, PageCategory } from "../../interfaces/backend";
import { getArticles } from "../../reducers/start";
import { useNavigate } from "react-router-dom";
import { getLocaleDateString } from "../../utils/localization";
import {  handleNavBack } from "../../utils/productFunctions";
import { CommonStructures } from "./CommonStructures";

export interface staticPageProps {
  category: string;
}

export default function StaticArticlePage(props: staticPageProps) {
  const params = useParams();
  const dispatch = useAppDispatch();
  const staticPageContent = useAppSelector(
    (state) => state.staticContent.staticPageContent
  );
  const navigate = useNavigate();
  const { category } = props;

  const isArticlePage: boolean =
    staticPageContent.category === PageCategory.Article;

    const navBack = () => {handleNavBack(navigate)};

  useEffect(() => {
    const alias = params.alias;
    if (alias) dispatch(getStaticPageContent({ alias, category }));
  }, [dispatch, params.alias, category]);

  useEffect(() => {
    if (isArticlePage) {
      dispatch(getArticles(5));
    }
  }, [dispatch, isArticlePage]);

  const { t } = useTranslation("common");

  const makeBread = (): BreadCrumbsObject[] => {
    const breadCrumbs: BreadCrumbsObject[] = [
      { name: t("home"), link: "/" },
    ];

    if (isArticlePage) {
      breadCrumbs.push({ name: t("blogPageTitle"), link: "/blog" });
    }
    breadCrumbs.push({ name: staticPageContent.title, link: "" });

    return breadCrumbs;
  };

  const articles: Array<Article> = useAppSelector(
    (state) => state.start.articles
  );

  const renderArticleItem = (item: Article) => {
    return <ArticleCard article={item}  />;
  };
  
  let relatedArticles: Array<Article>=[];
  let articleImage: string="";
  let articleDate: string="";
  let customPageBackground: string=""


    if (isArticlePage) {
      articleImage = staticPageContent.image;
      articleDate = getLocaleDateString(staticPageContent.createdAt.toString());
      customPageBackground = primaryColor.articlePageBackground

    relatedArticles= articles.filter(
      (article: Article) => article.alias !== staticPageContent.alias
    );

    while (relatedArticles.length > 4) {
      relatedArticles.pop();
    }
  }

  return (
    <>
      {staticPageContent.alias && staticPageContent.category === category && (
        <Page breadCrumbs={makeBread()}>

          <CommonStructures
            handleNavBack={navBack}
            customPageBackground={customPageBackground}
            content={{
              title: staticPageContent.title,
              description: staticPageContent.description || "",
              image: articleImage,
              createdAt: articleDate
            }}
          />

          {isArticlePage && relatedArticles?.length > 0 && (
            <RelatedItemsSlider
              relatedItems={relatedArticles}
              itemsTitle={t("otherArticles")}
              renderItem={renderArticleItem}
            />
          )}
        </Page>
      )}
    </>
  );
}

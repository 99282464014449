import SvgIcon from '@mui/material/SvgIcon';


const PlusIcon = () => {
  return (
      <SvgIcon>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        width="24px" 
        height="24px"
        d="M11 1.5C11 0.671573 10.3284 0 9.5 0C8.67157 0 8 0.671573 8 1.5V8H1.5C0.671574 8 0 8.67157 0 9.5C0 10.3284 0.671574 11 1.5 11H8V17.5C8 18.3284 8.67157 19 9.5 19C10.3284 19 11 18.3284 11 17.5V11H17.5C18.3284 11 19 10.3284 19 9.5C19 8.67157 18.3284 8 17.5 8H11V1.5Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default PlusIcon;
import {MyAccountState} from "../interfaces/initialStates";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OnMyMessage, OrderHistory, User} from "../interfaces/backend";
import {
  initialOrderHistory,
  initialOrderResult,
  initialUser
} from "./initialCartValues";
import {formatMoney} from "../utils/localization";

const initialState: MyAccountState = {
    orderHistory: initialOrderHistory,
    passwordChangedSuccessfully: false,
    isUserFormOpen: false,
    users: [],
    isDataChanged: false,
    isModeEdit: false,
    newUser: initialUser,
    isNewUserChanged: false,
    myMessages: [],
    orderDetails: initialOrderResult,
    isConfirmationOpen: false,
    sortUsersBy: "userName",
    searchUserByInput: "",
    filteredUsers: [],
}

export const myAccountSlice = createSlice(
    {
      name: "myAccount",
      initialState: initialState,
      reducers: {
        changePassword: (state, action) => {
        },
        setPasswordChangedSuccessfully: (state: MyAccountState, action: PayloadAction<boolean>) => {
            state.passwordChangedSuccessfully = action.payload
        },
        getOrderHistory: (state, action) => {
        },
        getOrderDetails: (state, action) => {
        },
        setOrderDetails: (state, action) => {
          state.orderDetails = action.payload
        },
        cancelOrder: (state, action) => {
        },
        setOrderHistory: (state: MyAccountState, action: PayloadAction<OrderHistory>) => {
            state.orderHistory = action.payload
        },
          getUsers: () => {
          },
          setUsers: (state, action:PayloadAction<User[]>) => {
              state.isUserFormOpen = false
              state.users = action.payload.map((u) => ({
                  ...u,
                  userName: u.firstName + ' ' + u.lastName,
              }))
              state.isDataChanged = true
          },
          setChanged: (state) => {
            state.isDataChanged = false
          },
          openNewUserForm: (state) => {
              state.isUserFormOpen = true
              state.isModeEdit = false
              state.newUser = initialUser
      },
          openEditUserForm: (state, action: PayloadAction<User>) => {
              state.isNewUserChanged = false
              state.isUserFormOpen = true
              state.isModeEdit = true
              state.newUser = {
                  ...action.payload,
                  cartLimit: action.payload.cartLimit ? formatMoney(parseInt(action.payload.cartLimit)) : '',
              }
          },
          closeUserForm: (state) => {
              state.isUserFormOpen = false
              state.newUser = initialUser
          },
          setConfirmationOpen: (state, action: PayloadAction<boolean>) => {
              state.isConfirmationOpen = action.payload
          },
          setUserTitle: (state, action: PayloadAction<string>) => {
              state.isNewUserChanged = true
              state.newUser = {
                  ...state.newUser,
                  title:  action.payload
              }
          },
          setNewUserInput: (state, action: PayloadAction<{ target: EventTarget & HTMLInputElement | {name: string, value: string | boolean} }>) => {
              state.isNewUserChanged = true
              state.newUser = {
                  ...state.newUser,
                  [action.payload.target.name]: action.payload.target.value,
              }
          },
          setNewUserRole: (state, action: PayloadAction<string>) => {
              state.isNewUserChanged = true
              state.newUser = {
                  ...state.newUser,
                  isAdmin: action.payload === 'keyUser',
              }
          },
          addUser: (state, action) => {
          },
          patchUser: (state, action) => {
          },
          deleteUser: (state, action) => {
          },
          setMyMessages: (state, action: PayloadAction<OnMyMessage>) => {
            const allMessageCodes: string[] = state.myMessages.map(m => m.code)
              const messageExists = action.payload.items.find(i => allMessageCodes.indexOf(i.code) > -1)
              if(!messageExists) {
                  if(!state.myMessages.length)
                  {
                      state.myMessages = action.payload.items
                  } else
                  {
                          state.myMessages =
                              !action.payload.isNewMessage ? [
                                      ...state.myMessages,
                                      ...action.payload.items
                                  ] :
                                  [...action.payload.items,
                                      ...state.myMessages]
                  }
              }
          },
          getMyMessages: (state, action) => {
          },
          sendMyMessage: (state, action) => {
          },
          setSortUsersBy: (state, action: PayloadAction<string>) => {
            state.sortUsersBy = action.payload
             },
          setSearchUserByInput: (state, action: PayloadAction<string>) => {
            state.searchUserByInput = action.payload
             },
          setFilteredUsers: (state, action: PayloadAction<User[]>) => {
                state.filteredUsers = action.payload
            }
    }}
)

export const {
  changePassword,
  getOrderHistory,
  setOrderHistory,
  setOrderDetails,
  getOrderDetails,
  setPasswordChangedSuccessfully,
  cancelOrder,
    getUsers,
    setUsers,
    setChanged,
    openEditUserForm,
    openNewUserForm,
    closeUserForm,
    setUserTitle,
    setNewUserInput,
    setNewUserRole,
    addUser,
    patchUser,
    deleteUser,
    getMyMessages,
    sendMyMessage,
    setMyMessages,
    setConfirmationOpen,
    setSortUsersBy,
    setSearchUserByInput,
    setFilteredUsers
} = myAccountSlice.actions

export default myAccountSlice.reducer

import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../theme";
import Button from "@mui/material/Button";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {openErrorSnack } from "../../reducers/start";
import {useAppDispatch, useAppSelector} from "../../hook";
import ShowPasswordInput from "../../pages/Account/ShowPasswordInput";
import {useLocation, useNavigate} from "react-router"
import {resetPassword} from "../../reducers/auth";

export default function ResetPasswordForm() {
    const {t} = useTranslation('startPage')
    const dispatch = useAppDispatch()

    const isSuccessResetPassword = useAppSelector(state => state.auth.isSuccessResetPassword)

    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [state, setState] = useState({
        email: '',
        password: '',
        token: ''
    })

    const location = useLocation()
    const navigate = useNavigate()


    const queryToJSON = (query: string) => {
        if (query === '') return {}
        return query
            .slice(1)
            .split('&')
            .reduce((obj: {[index: string]: string}, item, i) => {
                if (item) {
                    let tempArray: Array<string> = item.split('=')
                    obj[tempArray[0]] = tempArray[1]
                    return obj
                }
                return {}
            }, {})
    }

    useEffect( ()=> {
        try {
            const values = queryToJSON(location.search)
            const info = JSON.parse(window.atob(decodeURIComponent(decodeURI(values.info))))

            setState( {...state, ...info, token: values.token})

        } catch (err) {
            navigate('/', { replace: true})
        }
    }, [])


    useEffect( () => {
        isSuccessResetPassword && navigate('/', { replace: true})
    }, [isSuccessResetPassword])

    const validate = () => {
        let message = ''
        if (repeatPassword !== password) {
            message = t('passwordsNotEqual')
        } else {
            return true
        }
        dispatch(openErrorSnack({ message: message }))
        return false
    }

    const handleSubmit = (e: FormEvent) => { //**todo: check
        e.preventDefault()
        validate() &&
        dispatch(resetPassword({
            email: state.email,
            token: state.token,
            password: password,
        }))
    }
    return (
        <Box mt ='40px' sx={{maxWidth: '295px'}}>
            <form onSubmit={handleSubmit}>
                <ShowPasswordInput
                    value={password}
                    boxStyle={{marginBottom: '23px'}}
                    handleChange={(value) => setPassword(value)}
                    text={t('newPassword')}
                    showHelperText={true}
                />
                <ShowPasswordInput
                    value={repeatPassword}
                    boxStyle={{marginBottom: '23px'}}
                    handleChange={(value) => setRepeatPassword(value)}
                    text= {t('repeatPassword')}
                    showHelperText={true}
                />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Button
                        type={'submit'}
                        variant="contained"
                        disabled={password.length < 8 || repeatPassword.length < 8}
                        sx={{width: '100%',  marginBottom: '13px'}}
                    >
                        {t('save')}
                    </Button>


                    <Box sx={{
                        display: 'flex'
                    }}>
                        <Typography
                            onClick={() => navigate('/')}
                            sx={{
                                cursor: 'pointer',
                                color: primaryColor.textTopHeader,
                                fontWeight: 400,
                                '&:hover': {
                                    color: primaryColor.badgeColor,
                                },
                                mb: '10px'
                            }}
                        >
                            {t('cancel')}
                        </Typography>
                    </Box>
                </Box>
            </form>
        </Box>
    )
}


import {Article} from "../../../interfaces/backend";
import {Box} from "@mui/material";
import {NavLink} from "react-router-dom";
import {style} from "./styles";
import {useTranslation} from "react-i18next";
import {primaryColor} from "../../../theme";
import {cleanHTMLTags, TakeEmptyImage} from '../../../utils/productFunctions';

interface ArticleCardProps {
    article: Article;
}

const ArticleCard = (props: ArticleCardProps) => {
    const {article } = props;
    const {t} = useTranslation("common");
     
    const emptyImageUrl = TakeEmptyImage();

    return (

        <NavLink to={`/blog/${article.alias}`}>
            <Box sx={{
                ...style.productCard,
                maxHeight: '455px'
                }}>
                <Box sx={style.image}>
                    <img alt={article.alias} src={article.image || emptyImageUrl}/>
                </Box>

                <Box sx={{
                    ...style.cardDescription,
                    height: '220px',    
                }}>
                    <Box
                        sx={{
                            textTransform: "uppercase",
                            fontWeight: "500",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                        }}
                    >
                        {article.title}
                    </Box>
                    <Box className = "article-description"
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "5",
                            WebkitBoxOrient: "vertical",
                            textAlign: "start",
                            height: "120px",  
                            
                        }}
                    >
                        {cleanHTMLTags(article.description)}
                    </Box>
                    <Box
                        sx={{
                            fontWeight: 700, color: primaryColor.badgeColor, 
                            mt: "10px",
                        }}
                    >
                        {t("readMore")}
                    </Box>
                </Box>
            </Box>
        </NavLink>
    );
};

export default ArticleCard;
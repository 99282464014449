import TableRow from "@mui/material/TableRow";
import {StyledTableCell} from "../../../Cart/styleElements";
import {Box, Typography} from "@mui/material";
import {TakeFirst2DImage} from "../../../../utils/productFunctions";
import {Link} from "react-router-dom";
import {primaryColor} from "../../../../theme";
import DiscountInfo from "../../../Cart/Table/DiscountInfo";
import {formatMoney} from "../../../../utils/localization";
import TableCell from "@mui/material/TableCell";
import {addCartItem} from "../../../../reducers/cart";
import React from "react";
import {CartItem} from "../../../../interfaces/backend";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../hook";

export default function OrdersTableItemDesktop(props: { item: CartItem }) {
  const dispatch = useAppDispatch()
  const {item} = props;
  const {product} = item;
  const {t} = useTranslation('myAccount')
  const rowPrice = item.discountPrice || item.price;
  const image = TakeFirst2DImage(product?.image);

  return (
    <TableRow>
      <StyledTableCell
        sx={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          component={'img'}
          src={image}
          sx={{
            height: '62px',
          }}
        >
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Link
          to={product ? `/product/${product.alias}` : "javascript:void(0)"}
          style={{cursor: product ? "pointer" : "initial"}}
        >
          <Typography sx=
                        {{
                          fontSize: '2rem',
                          fontWeight: 400,
                          color: primaryColor.shoppingCartTitle,
                        }}>
            {item.name}

          </Typography>
          <Typography
            variant={'h15'}
            sx=
              {{
                color: primaryColor.shoppingCartTitle,
              }}
          >
            {`${t('materialNumber')}: ${item.code}`}
          </Typography>
        </Link>
        <Box display={"flex"}>
          <DiscountInfo discounts={item.discounts}/>
        </Box>
      </StyledTableCell>


      <StyledTableCell sx={{textAlign: 'right', fontWeight: 300}}>
        {item.discountPrice ? (
            <Box>
              <Typography
                variant={'h20'}
                sx={{
                  textDecoration: 'line-through',
                  textDecorationColor: primaryColor.lineThroughPrice,
                  textDecorationThickness: '1px',
                  textDecorationTrim: 'auto',
                  color: primaryColor.lineThroughPrice,
                  opacity: 0.3,

                }}
              >
                {rowPrice
                  ? formatMoney(item.price)
                  : t('requestPrice')}
              </Typography>
              <Typography sx={{color: primaryColor.discountCheckoutPage}}
                          fontSize={'2rem'}
              >
                {formatMoney(rowPrice)}
              </Typography>
            </Box>
          ) :
          <Typography variant={'h20'}>
            {rowPrice
              ? formatMoney(rowPrice)
              : t('requestPrice')}
          </Typography>
        }
      </StyledTableCell>

      <StyledTableCell>
        {
          <Typography sx={{textAlign: 'right'}}>{item.quantity}</Typography>
        }
      </StyledTableCell>
      <StyledTableCell sx={{textAlign: 'right'}}>
        {rowPrice
          ? formatMoney(rowPrice * item.quantity)
          : t('requestPrice')}
      </StyledTableCell>
      {item.isOrderable &&
          <TableCell sx={{
            borderBottom: 'none',
            width: '55px'
          }}>
              <Box
                  component={'img'}
                  alt='cart'
                  onClick={() => dispatch(addCartItem({
                    code: product.code,
                    quantity: item.quantity,
                  }))}
                  src='/images/cartIcon.svg'
                  sx={{
                    cursor: 'pointer'
                  }}
              />
          </TableCell>
      }
    </TableRow>
  );
}
import {Typography, Grid} from "@mui/material";
import {NavLink} from "react-router-dom";
import {primaryColor} from "../../../../theme";
import {useAppDispatch, useAppSelector} from "../../../../hook";
import { getMenu, setMainMenu } from "../../../../reducers/static";
import { useEffect } from "react";
import { MenuItem } from "../../../../interfaces/backend";
  

const StyledLink = (linkContent: MenuItem) => {
	const {title, link, isBlank} = linkContent
 
	 return <NavLink
		 to={link}
		 target={isBlank ? "_blank" : '_self'}
		 style={({isActive}) => ({
			 color: isActive ? primaryColor.textTopHeader : primaryColor.textHeader,
			 textDecoration: !isActive ? 'none' : `underline 3px ${primaryColor.textDecorationColor}`,
			 textUnderlineOffset: 6,
			 fontWeight: isActive ? 700 : 400
		 })}>
		 <Typography variant='h6'>{title}</Typography>
	 </NavLink>
 }

 
 
 const MainMenu = () => {

	const dispatch = useAppDispatch()
	const mainMenuLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.mainMenu)
   
	useEffect(() => {
	   !mainMenuLinks.length && dispatch(getMenu({type: 'main_menu', handler: setMainMenu}))
   }, [dispatch, mainMenuLinks])


	return (
	  <>
		{mainMenuLinks.map((item, index) => (
		  <Grid item key={index}>
			{StyledLink(item)}
		  </Grid>
		))}
	  </>
	);
  };

  
export default MainMenu
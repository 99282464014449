import {callHttp} from "../utils/api";
import {get, getBlob} from "../utils/httpUtil";
import {PRODUCT, PRODUCTBYCODE} from "../constants/api";
import {put, takeEvery} from "redux-saga/effects";
import {setProductDetails} from "../reducers/productdetails";
import {getDocumentAction, getProductAction, getProductByCodeAction} from "../interfaces/actions";
import {ProductDetailsPageContent} from "../interfaces/backend";
import {openErrorSnack, openSimpleErrorSnack} from '../reducers/start';
import {fileNameFromUrl} from "../utils/productFunctions";
import {RequestError} from "../utils/exceptions";


export function* getProductDetails(action: getProductAction) {
  const {alias} = action.payload
  try {
    const details: ProductDetailsPageContent = yield callHttp(get, PRODUCT(alias))
    yield put(setProductDetails(details));
  } catch (err: any) {
    if (err instanceof RequestError) {
      yield put(openErrorSnack({message: err.getErrors()}));
    }
  }
}

export function* getProductDetailsByCode(action: getProductByCodeAction) {
  const {code} = action.payload
  try {
    const details: ProductDetailsPageContent = yield callHttp(get, PRODUCTBYCODE(code))
    yield put(setProductDetails(details));
  } catch (err: any) {
    if (err instanceof RequestError) {
      yield put(openErrorSnack({message: err.getErrors()}));
    }
  }
}

/*export function* addProduct(action: addProductAction) {
  const {code, quantity} = action.payload
  try {
    const data: LoginResponse = yield callHttp(post, PRODUCTS, {code, quantity})

  } catch (err) {
    yield put(openSimpleErrorSnack(i18next.t('messages:Wrong creds')))
  }
}*/

export function* getDocument(action: getDocumentAction) {
  const {url} = action.payload
  try {
    let docs: string = yield callHttp(getBlob, url);
    const name = fileNameFromUrl(url)
    fileDownload(docs, name);

  } catch (err: any) {
    yield put(openSimpleErrorSnack(err.toString()))
  }
}

const fileDownload = (url: string, name: string) => {

  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = url
  link.setAttribute('download', name);
  link.click();
  document.body.removeChild(link);

};

export default function* productDetailsSaga() {
  yield takeEvery('productDetails/getProductDetails', getProductDetails)
  yield takeEvery('productDetails/getProductDetailsByCode', getProductDetailsByCode)
  yield takeEvery('productDetails/getDocument', getDocument)
}
import {Box, Typography} from "@mui/material";
import { highlightedFonts, primaryColor } from '../../../theme';
import FilterArrow from "../../../components/common/svg/filterArrow";
import * as React from "react";
import {useTranslation} from "react-i18next";
import Sorting from "../Sorting";

interface FilterHeaderProps {
  isMobile?: boolean,
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>,
  setSortValue?: React.Dispatch<React.SetStateAction<string>>,
  sortValue?: string,
  clear: Function,
  closeFilter: boolean,
}

export default function FilterHeader(props: FilterHeaderProps) {
  const {isMobile, setCloseFilter, closeFilter, clear, setSortValue, sortValue} = props
  const {t} = useTranslation('searchPage')

  return(
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '39px',
            textOverflow: 'hidden',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
          }}
      >
        <Box
            sx={{
              marginBottom: 'auto',
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              alignItems: 'center'
            }}
            onClick={() => setCloseFilter(closeFilter)}
        >
          <Typography
              sx={{
                color: closeFilter ? primaryColor.drawerText : primaryColor.filterText, 
                fontSize: highlightedFonts,
                fontWeight: {xs: 400, md: 300},
              }}
          >
            {t('filter')}
          </Typography>
          <FilterArrow rotate={closeFilter} isMobile={isMobile}/>
        </Box>

        <Typography
            color={primaryColor.machinePageText}
            sx={{
              fontSize: highlightedFonts,
              display: closeFilter ? 'none' : 'flex',
              transitionDuration: 'visibility 500ms',
              cursor: 'pointer',
              color: primaryColor.clearFilter,
              weight: {xs: 400, md: 300},
              height: 100,
              marginLeft: 'auto'
            }}
            onClick={() => clear()}
        >
          {t('filterClear')}
        </Typography>
        {isMobile && closeFilter && sortValue && setSortValue && <Sorting sortValue={sortValue} setSortValue={setSortValue}/>}

      </Box>
  )
}
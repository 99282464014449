import { Box, BoxProps, Typography, TypographyProps, styled } from "@mui/material";
import { defaultFonts, primaryColor } from "../../../theme";
import React, { forwardRef } from "react";

interface ListingBoxProps extends BoxProps {
  imageUrl: string;
}

export const ListingBox = styled(({ imageUrl, ...otherProps }: ListingBoxProps) => (

<Box
    sx={{
      overflow: 'hidden',
      width: '100%',  
      maxWidth: "390px",
      margin: 'auto',
    }}
  >
  <Box
    {...otherProps}
    sx={{
      paddingBottom: {
        xs: "45%",
        sm: "30%",
        md: "55%",
        xl: "60%",
      },
    }}
  />
  </Box>
))(({ theme, imageUrl }) => ({
  background: "url(" + imageUrl + ") no-repeat center",
  position: "relative",
  width: "100%",
  maxWidth: "390px",
  backgroundSize: "cover",
  "&:hover": {
    transform: "scale(1.1,1.1)",
  },
}));

export const ListingBoxContent = styled((props: BoxProps) => (
  <Box
    {...props}
    sx={{
      padding: { xs: "18px 10px", md: "20px 34px" },
      maxHeight: { xs: "205px", lg: "210px" },
    }}
  />
))(({ theme }) => ({
  background: primaryColor.machinePageDescriptionBackground,
  boxShadow: primaryColor.machinePageDescriptionBackgroundShadow,
  width: "100%",
  margin: "auto",
  maxWidth: "390px",
  textAlign: 'center',
}));

export const ListingBoxWrapper = styled((props: BoxProps) => (
  <Box {...props} sx={{}} />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

export const ListingTypography = styled(
  forwardRef<HTMLDivElement, TypographyProps>((props, ref) => (
    <Typography ref={ref} {...props} />
  ))
)(({ theme }) => ({
  fontWeight: 400,
  color: primaryColor.textTopHeader,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  fontSize: defaultFonts.lg,
  [theme.breakpoints.down('lg')]: {
  fontSize: defaultFonts.md,
  },
  [theme.breakpoints.down('md')]: {
  fontSize: defaultFonts.sm,
  },
  [theme.breakpoints.down('sm')]: {
  fontSize: defaultFonts.xs,
  },
}));

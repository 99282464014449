import { Box, Typography } from "@mui/material";
import ContactsForm from "./ContactsForm";
import { useAppSelector, useAppDispatch } from "../../../hook";
import {
  setContactDataInput,
  setContactsFormEditable,
} from "../../../reducers/address";
import { ContactData } from "../../../interfaces/backend";
import { EditIconBox } from "../../../pages/Checkout/ShippingAddressCardList/style";
import { useTranslation } from "react-i18next";
import { defaultFonts } from "../../../theme";
import { StyledTypography } from "../../common/StyledComponents";

 
const Contacts = () => {
	const dispatch = useAppDispatch();
  const { t } = useTranslation("myData")
	
	const contactData: ContactData = useAppSelector((state) => state.address.contactData);

  const isContactsFormEditMode = useAppSelector(
    (state) => state.address.isContactsFormEditMode
  );
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactDataInput({ target: event.target }));
  };
  
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
	dispatch(setContactsFormEditable(true));
	};


	  
  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: isContactsFormEditMode ? 'column' : 'row',
      mb: '10px'
    }}>
      <Typography sx={{  mr: "10px",  fontSize: defaultFonts, fontWeight: 300 }}>
        {t("customerContacts")}
      </Typography>

      {isContactsFormEditMode ? 
      <ContactsForm contact={contactData} onInputChange={handleInputChange} />
       : <Box sx={{ mt: '2px'}}
            onClick={handleClick}>
            <EditIconBox />
          </Box>
      }
    </Box>

   {!isContactsFormEditMode &&  <Box sx={{ display: "flex", flexDirection: "column", mt: '15px'}}>
        <StyledTypography>{contactData.firstName} {contactData.lastName}</StyledTypography>
        <StyledTypography>{contactData.phone}</StyledTypography>
        <StyledTypography>{contactData.mobile}</StyledTypography>
        <StyledTypography>{contactData.email}</StyledTypography>
      </Box>}
    </>
  );
};

export default Contacts;

import { ReactNode } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { Box, Container, useMediaQuery } from "@mui/material";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/swiper-bundle.min.css";
import { sliderStyle } from "./styles";
import theme, { primaryColor } from "../../../theme";
import ArrowButton, { arrowButtonEffects } from "../ArrowButton";

interface RelatedItemsSliderProps<T> {
  relatedItems: T[];
  itemsTitle: string;
  renderItem: (item: T) => ReactNode;
}

SwiperCore.use([Navigation]);

 
const RelatedItemsSlider = <T,>(props: RelatedItemsSliderProps<T>) => {
  const { relatedItems, itemsTitle, renderItem } = props;
  const lgView = useMediaQuery(theme.breakpoints.down('lg'))
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const maxDisplayedItems = 4;

  const showArrows: boolean = relatedItems.length > maxDisplayedItems && !lgView;

  return (
    <Container maxWidth={showArrows && "xl"}>
      <Box sx={sliderStyle.general}>
        <Box sx={sliderStyle.relatedProductsText}>{itemsTitle}</Box>
        <Box
          sx={{
            ...sliderStyle.relatedProducts,

            "& .swiper-wrapper": {
              display: "flex",
              justifyContent: "space-between",
              maxWidth: 'fit-content',
            },
            '& .swiper-slide': {
              padding: '0 10px',
            },
             
            '& .slider_next, .slider_prev':{
              ...arrowButtonEffects,
            },
            '& .swiper-button-disabled, .swiper-button-disabled:hover': {
                 opacity: 0,
                 cursor: 'unset'
              },
            
          }}
        >
        
        
        {showArrows && (
          <Box
            className="slider_prev"
            sx={{
              paddingTop: {
                xs: "0px",
              },
            }}
          >
            <ArrowButton
              direction={"left"}
              color={primaryColor.arrowButtonColorSlider}
              width={mobileView ? 25 : 40}
            />
          </Box>
        )}


          <Swiper
            direction="horizontal"
            slidesPerView={ "auto"}
            speed={900}
            loopedSlides={relatedItems.length}
            navigation={{
              nextEl: ".slider_next",
              prevEl: ".slider_prev",
            }}
            style={{
              width: '100%',
              display: "flex",
              justifyContent: "center",
            }}
            modules={[Navigation, Thumbs]}
            onSwiper={(swiper) => {
              swiper.params.simulateTouch = false;
              swiper.allowSlidePrev = false;
            }}
          >
            {[
              ...relatedItems,
            ].map((item, i) => (
              <SwiperSlide
                key={i}
                className="swiper-slide"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "fit-content",
                }}
              >
                {renderItem(item)}
              </SwiperSlide>
            ))}
          </Swiper>
          
          {showArrows && (
          <Box
            className="slider_next"
            sx={{
              paddingTop: {
                xs: "0px",
              },
            }}
          >
            <ArrowButton
              direction={"right"}
              color={primaryColor.arrowButtonColorSlider}
              width={mobileView ? 25 : 40}
            />
          </Box>
        )}


        </Box>
      </Box>
    </Container>
  );
};

export default RelatedItemsSlider;

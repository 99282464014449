import {Box, SxProps, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import * as React from "react";
import { ProductDetailsProps} from "../../../interfaces/backend";
import MobileSwiperComponent from "../SwiperComponent/MobileSwiperComponent";
import {Availability} from "../../../components/common/general/availability";
import {formatMoney} from "../../../utils/localization";
import {addCartItem} from "../../../reducers/cart";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";
import MobileInfo from "./MobileInfo";
import MobileQuantity from "./MobileQuantity";
import {useState} from "react";
import AddToCartButton from "../../../components/common/AddToCartButton";


const MobileProductDetails = (props: ProductDetailsProps) => {
  const {product, backgroundColor} = props
  const haveDiscountPrice = product.discountPrice > 0
  const dispatch = useAppDispatch()
  const [quantity, setQuantity] = useState(1)

  const {t} = useTranslation(['productPage']);
  const priceStyles:SxProps = {
      fontSize: '3rem',
      fontWeight: '300',
      color: haveDiscountPrice ? primaryColor.discountCheckoutPage : 'inherit',
  }

  return (
      <Box>
        <Box sx={{
          color: primaryColor.pageTitle,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          marginTop: '-5px',
          fontWeight: '400',
          backgroundColor: backgroundColor,
          paddingBottom: '33px'
        }}>
          <Typography sx={{
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: '400',
            width: '100%',
            padding: '20px',
          }}>
            {`${t('materialNumber')}: ${product.code}`}
          </Typography>
 
          {product.image && product.image.length > 0 &&
          <MobileSwiperComponent images={product.image}/>
          }
          <Box
              sx={{
                justifyContent: 'center',
                fontSize: '1.5rem',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '20px',
                display: "flex"
              }}
          >
            <Availability
                isOrderable={product.isOrderable}
                inStock={product.inStock}
                textSx={{fontSize: '1.5rem'}}
            />
            <Box sx={{
                paddingTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>{product.price ? <>
                  {
                    haveDiscountPrice &&
                      <Box
                          sx={{
                            fontWeight: '300',
                            textDecoration: "line-through",
                            textDecorationThickness: '1px',
                            color: primaryColor.productDetailsPriceInfo,
                            opacity: 0.3,
                          }}>
                        {formatMoney(product.price)}
                      </Box>
                  }
                  <Box sx={{
                    ...priceStyles,
                      '&:after': {
                          content: `'/${product.unit.name}'`,
                          position: "absolute",
                          fontWeight: '400',
                          color: haveDiscountPrice ? primaryColor.discountCheckoutPage : 'inherit',
                          fontSize: '2rem',
                          pt: '12px',
                          pl: '16px',
                      }
                  }}> {formatMoney(product.discountPrice || product.price)}</Box>

                  <Box sx={{
                    color: primaryColor.productDetailsPriceInfo,
                    opacity: 0.5
                  }}>{t('taxInfo')}</Box>
              </>
                :
              <Box
                sx={priceStyles}
              >
                  {t('priceOnInquiry')}
              </Box>
                  }
            </Box>

            <Box sx={{pt: '30px'}}>
              <Typography sx={{    display: 'flex',
                justifyContent: 'center', fontSize: '1.8rem'}}>{t('quantity')}</Typography>
              <Box sx={{paddingTop: '10px'}}>
                  <MobileQuantity quantity={quantity} setQuantity={setQuantity} />
              </Box>
            </Box>
            <Box sx={{
              paddingTop : '25px', 
              }}>
              <AddToCartButton
                  isDisabled={!product.isOrderable}
                  buttonContent={t('addItem') as string}
                  buttonStyles={{ float: 'right'}}
                  onClick={() => {
                    dispatch(addCartItem({
                      code: product.code,
                      quantity: quantity,
                    }))
                  }}
              />
            </Box>
          </Box>
        </Box>
        <MobileInfo product={product}/>
      </Box>
  )
}

export default (MobileProductDetails)

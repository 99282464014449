import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hook";
import Page from "../page";
import { useTranslation } from "react-i18next";
import { BreadCrumbsObject } from "../../interfaces/general";
import { useNavigate } from "react-router-dom";
import { getTerm } from "../../reducers/metadata";
import { CommonStructures } from "./CommonStructures";
import { handleNavBack } from "../../utils/productFunctions";
 

export default function TermPage() {
  const { t } = useTranslation("common");
  
  const dispatch = useAppDispatch();
  const params = useParams();
  
  const termsPageContent = useAppSelector((state) => state.metadata.term);
  
  const navigate = useNavigate();
  const navBack = () => {handleNavBack(navigate)};
   
  useEffect(() => {
    const alias = params.alias;

    if (alias) dispatch(getTerm({ alias }));
  }, [dispatch, params.alias]);

  const makeBread = (): BreadCrumbsObject[] => {
    const breadCrumbs: BreadCrumbsObject[] = [
      { name: t("home"), link: "/" },
      { name: termsPageContent.title, link: "" }
    ];

    return breadCrumbs;
  };
  return (
    <Page breadCrumbs={makeBread()}>
      {termsPageContent.alias && (
        <CommonStructures
          handleNavBack={navBack}
          content={{
            title: termsPageContent.title,
            description: termsPageContent.description || "",
          }}
        />
      )}
    </Page>
  );
}

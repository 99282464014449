import {useTranslation} from "react-i18next";
import {Box, Button, StyledEngineProvider, TextField} from "@mui/material";
import Message from "../Message";
import React from "react";
import CustomerSupportButtons from "../Buttons";
import {CustomerSupportServiceProps} from "../index";
import {primaryColor} from "../../../../theme";

const MobileCustomerSupportService = (props: CustomerSupportServiceProps) => {
  const {t} = useTranslation('myAccount')
  const {setTextMessage, textMessage, handleSend, myMessages, handleUIEvent} = props

  const style = {
    messageBox: {
      padding: '29px 50px 50px 50px',
      display: 'flex',
      flexDirection: 'column',
      height: '650px',
      overflow: 'auto',

    },
    importButtonBox: {
      margin: '30px 0px 80px 0px',
      display: 'flex',
      justifyContent: 'center'
    }
  }
  return (
      <Box>
        <Box id={"messagesList"} onScroll={(event) => handleUIEvent(event)} sx={style.messageBox}>
          {myMessages.slice(0).reverse().map((mm, index) => (
              <Message key={index} text={mm.message} isInput={!mm.isAuthorUser}
                       createdAt={mm.createdAt} isMobile={true}
                      />
          ))}
        </Box>
        <CustomerSupportButtons handleSend={handleSend}/>

        <StyledEngineProvider injectFirst>
          <Box sx={{
            width: '100%', height: '100px',
            backgroundColor: primaryColor.textAreaBackground
          }}>
            {/*<TextareaAutosize maxRows={3} minRows={1} style={{
              marginTop: '15px',
              marginLeft: '50px',
              color: primaryColor.textAreaColor,
              width: '80%',
              resize: 'none',
              border: 0,
              backgroundColor: primaryColor.textAreaBackground,
              outline: 'none',
            }}
                              value={textMessage}
                              onChange={(event) => {
                              setTextMessage(event.target.value)}}
                              placeholder={t('writeAMessage') as string}/>*/}
              <TextField
                  placeholder={t('writeAMessage') as string}
                  multiline
                  maxRows={3}
                  sx={{
                      marginTop: '15px',
                      marginLeft: '50px',
                      color: primaryColor.textAreaColor,
                      width: '80%',
                      resize: 'none',
                      border: 0,
                      backgroundColor: primaryColor.textAreaBackground,
                      outline: 'none',
                  }}
                  value={textMessage}
                  onChange={(event) => {
                      setTextMessage(event.target.value)
                  }}
              />
          </Box>
        </StyledEngineProvider>
        <Box sx={{...style.importButtonBox}}>
          <Button
              variant="contained"

              sx={{
                width: 'fit-content',
                fontSize: '1.5rem',
                backgroundColor: primaryColor.chatButtonBackground
              }}
          >
            {t('importPdf')}
          </Button>
        </Box>
      </Box>

  )
}

export default (MobileCustomerSupportService)

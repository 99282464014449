import React, {ReactElement} from 'react';
import {useAppSelector} from "../../../hook";
import {Navigate} from "react-router-dom";

interface protectedRouteProps {
    children: ReactElement;
    guestCanAccess?: boolean
}

const ProtectedRoute = (props: protectedRouteProps) => {
    const {children, guestCanAccess=true} =props; 

    const token = useAppSelector(state => !!state.auth.token);
    const isGuestAllowed = useAppSelector(state => state.staticContent.guestUser.isAllowed);

    const hasAccess = token || (guestCanAccess && isGuestAllowed);


    return (hasAccess ? (<React.Fragment>{children}</React.Fragment>) :
        (<Navigate replace to="/login"/>))
}

export default (ProtectedRoute)
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import {useAppDispatch, useAppSelector} from "../../hook";
import {closeForgotPasswordModal, closeRegistrationModal} from "../../reducers/start";
import React, {useEffect, useState} from "react";
import CommonDialog from "../common/Dialogs/commonDialog";
import PasswordResetRequirement from "./modalContents/passwordResetRequirement";
import PasswordResetConfirm from "./modalContents/passwordResetConfirm";
import RegistrationSuccessModal from "./modalContents/registrationSuccessModal";
import theme from "../../theme";

export default function LoginArea(props: { containerRef: React.RefObject<HTMLDivElement> }) {

    const {t} = useTranslation('startPage')
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useAppDispatch()

    const isRegistrationSent = useAppSelector(state => state.start.isRegistrationSent)
    const isForgotPassOpen = useAppSelector(state => state.start.isForgotPassOpen)
    const isForgotPassSent = useAppSelector(state => state.start.isForgotPassSent)
    const isRegistrationOpen = useAppSelector(state => state.start.isRegistrationOpen)


    const [registrationFormClosed, setRegistrationFormClosed] = useState(false)

    useEffect(() => {
        const onCancelClick = () => {
            props.containerRef.current?.scrollIntoView();
        }
        onCancelClick()
    }, [props.containerRef, registrationFormClosed])

    const handleClick = () => {
        setRegistrationFormClosed(!registrationFormClosed)
    }

    return (
        <Grid
            item
            container
            xs={12}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            marginTop={mobileView ? '60px' : '0px'}
        >
          {isRegistrationSent &&
            <CommonDialog
               title={t('register')}
                closeAction={()=>{dispatch(closeRegistrationModal())}}
            >
                <RegistrationSuccessModal/>
            </CommonDialog>
            }  

            {isForgotPassOpen &&
            <CommonDialog
                title={t('passwordResetTitle')}
                closeAction={()=>{dispatch(closeForgotPasswordModal())}}
            >
                <PasswordResetRequirement/>
            </CommonDialog>
            }

            {isForgotPassSent &&
            <CommonDialog
                title={t('passwordResetTitle')}
                closeAction={()=>{dispatch(closeForgotPasswordModal())}}
            >
                <PasswordResetConfirm/>
            </CommonDialog>
            }

            {!isRegistrationOpen ?
                <>
                    <Box component="span" mt={'40px'}>
                        <Typography
                            sx={{
                                width: '100%',
                                maxWidth: '770px',
                                padding: {
                                    xs: '10px 30px',
                                    md: '0 10px 0 90px',
                                },
                                textAlign: {xs: 'center', md: 'left'},
                            }}
                            variant='h3'
                        >
                            {t('Order spare parts and devices comfortably')}
                        </Typography>
                    </Box>
                    <LoginForm/>
                </>

                : <RegisterForm onCancelClick={handleClick}/>}
        </Grid>
    )
}

import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {getUsers} from "../../../reducers/myAccount";
import UserEditForm from "../../../components/myData/UserManagement/UserEditForm";
import SortedTable from "../../../components/myData/UserManagement/SortedTable";


export default function UserManagement() {

    const isUserFormOpen = useAppSelector(state => state.myAccount.isUserFormOpen)
    const dispatch = useAppDispatch()


    useEffect(()=> {
        dispatch(getUsers())
    }, [])

    const users = useAppSelector(state => state.myAccount.users)



    return (!isUserFormOpen ?

            <SortedTable users={users}/>
            :
            <UserEditForm  />

    )
}

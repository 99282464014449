import {Grid, useMediaQuery} from "@mui/material"
import {
  FilteredSparePart,
  ListingListTypes,
  Machine,
  MachinesSearch,
  ProductSearchResult,
  StandardMachine
} from "../../interfaces/backend";
import React, {useState} from "react";
import CategoryItem from "./items/CategoryItem";
import MachineItem from "./items/MachineItem";
import theme from "../../theme";
import StandardMachineItem from "./items/StandardMachineItem";

interface MachineListProps {
  machineList:  FilteredSparePart | MachinesSearch,
  type: ListingListTypes,
  isMobile?: boolean,
  closeFilter?: boolean,
}

interface GetListProps {
    type : ListingListTypes,
    item: Machine | ProductSearchResult | StandardMachine,
    // setSelectedMobileItem: React.Dispatch<React.SetStateAction<string>>,
    // selectedMobileItem: string,
    // isMobile?: boolean
}

// const ListItem = (props: GetListProps) => {
//   const {type, item, selectedMobileItem, setSelectedMobileItem, isMobile} = props;

//   switch (type) {
//     case ListingListTypes.Accessory :
//       return <CategoryItem category={item as ProductSearchResult}/>
//     case ListingListTypes.FanShop :
//       return <CategoryItem category={item as ProductSearchResult}/>
//     // case ListingListTypes.MyMachine:
//     //   return <MachineItem machine={item} selectedMobileItem={selectedMobileItem} setSelectedMobileItem={setSelectedMobileItem} isMobile={isMobile}/>
//     case ListingListTypes.Search:
//       return <CategoryItem category={item as ProductSearchResult}/>
//     default:
//       return <MachineItem machine={item as Machine} selectedMobileItem={selectedMobileItem} setSelectedMobileItem={setSelectedMobileItem} isMobile={isMobile}/>

//   }
// }

const ListItem = (props: GetListProps) => {
  const {type, item} = props;

  switch (type) {
    case ListingListTypes.Category:
    case ListingListTypes.Search:
      return <CategoryItem category={item as ProductSearchResult}/>
    case ListingListTypes.StandardMachine:
      return <StandardMachineItem machine={item as StandardMachine}/>
    case ListingListTypes.MyMachine:
      return <MachineItem machine={item as Machine}/>   
  }
}
export default function ListingList(props: MachineListProps) {
  const {machineList,  type, closeFilter} = props
  const [, setSelectedMobileItem] = useState<string>("")
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Grid container 
        rowSpacing={ mobileView ? '45px' : '52px'} 
        columnSpacing={!closeFilter ? '5px' : '10px' } 
        sx={{
          justifyContent:  'flex-start',
          textAlign: '-webkit-center',
          }}>
            {
              machineList.items?.map((item, key) =>
                    <Grid key={key} item xs={12} md={6} 
                    lg={4}  onClick={(event) => (event.target as HTMLSpanElement).classList.contains("MuiGrid-root") && setSelectedMobileItem("")}>
                      {/* <ListItem type={type} item={item} selectedMobileItem={selectedMobileItem} setSelectedMobileItem={setSelectedMobileItem} isMobile={isMobile}/> */}
                   
                      <ListItem type={type} item={item}/>
                    </Grid>
                )
            }
        </Grid>
    )
}
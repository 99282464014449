import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import {useAppSelector} from "../../hook";
import React from "react";
import CartTableDesktop from "./Table/CartTableDesktop";

import Page from "../page";
import {useTranslation} from "react-i18next";
import CartSummary from "./CartSummay";
import {CheckoutStageType} from "../../interfaces/backend";
import CartTableMobile from "./Table/CartTableMobile";

export default function CartPage() {
  const {t} = useTranslation('checkoutPage')
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const cart = useAppSelector(state => state.cart.cart)

  return (
    <Page>
      <Typography
        sx={{
          color: primaryColor.shoppingCartTitle,
          textAlign: 'center',
          fontWeight: 700,
          marginY: '38px',  
          fontSize: {
            xs: '2.6rem',
            md: '5rem',
            lg: '7rem'
        },
        }}
      >
        {t('cart')}
      </Typography>
      {cart.items && cart.items.length ?
        <Box
          sx={{
            color: primaryColor.shoppingCartTitle,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: {
              lg: primaryColor.backgroundOverlay
            },
            mb: {
              xs: '100px',
              md: '189px'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingY: {
                md: '90px'
              },
              width: 'fit-content',
              minWidth: {xs: '100%', md: 'fit-content'},
              maxWidth: {md: '75%', lg: '60%'},
              margin: 'auto'
            }}
          >
           {!mobileView ? <CartTableDesktop stage={CheckoutStageType.ShoppingCart} data={cart} />
           : <CartTableMobile data={cart}/>
           }
            <CartSummary/>
          </Box>
        </Box>
        :
        <Typography
          variant={'h3'}
          sx={{textAlign: 'center'}}
        >
          {t('cartIsEmpty')}
        </Typography>
      }
    </Page>
  )
}
import React from "react";
import { Link } from "react-router-dom";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { defaultFonts } from "../../../theme";

interface ProductDetailsOverviewProps {
  iconSx?: SxProps;
  containerSx?: SxProps;
  linkTo: string;
  iconText?: string;
}

const ProductDetailsOverview = (props: ProductDetailsOverviewProps) => {
  const { linkTo, iconSx, containerSx, iconText } =
    props;
  const { t } = useTranslation("productPage");

  return (
 
      <Tooltip title={t("goToProductDetails")}>
        <Link to={`/product/${linkTo}`} target="_blank" rel="noopener noreferrer">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",

              ...containerSx,
            }}
          >
            <Box
              className="clickable-element"
              component={"img"}
              src="/images/icons/product_information.svg"
              sx={{ cursor: "pointer", ...iconSx }}
            />

            {iconText && (
              <Typography sx={{ fontSize: defaultFonts }} className="clickable-element">
                {iconText}
              </Typography>
            )}
          </Box>
        </Link>
      </Tooltip>
    
  );
};

export default ProductDetailsOverview;
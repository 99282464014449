import React from 'react';
import {SvgIcon, SxProps} from '@mui/material';

interface ArrowButtonProps {
  direction?: 'up' | 'down' | 'left' | 'right';
  color?: string;
  size?: number;
  width?: number;
  sx?: SxProps;
}

export const arrowButtonEffects = {

  opacity: 0.5,
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 1,
    transform: 'scale(1.1)',
    cursor: 'pointer',
  },
  '&:active': {
    opacity: 0.5,
    transform: 'scale(0.9)',
  },
  '&:focus': {
    outline: 'none',
  },
}

const ArrowButton = (props: ArrowButtonProps) => {
  const {direction = "down", color = "white", width, sx = {}} = props;
  const finalWidth = width ? width.toString().concat("px") : "100%";
  const directionAngleMap = {
    up: 180,
    right: -90,
    left: 90,
    down: 0
  };
  const rotationAngle = directionAngleMap[direction];

  return (
    <SvgIcon
      sx={{
        transform: `rotate(${rotationAngle}deg)`,
        transition: 'transform 500ms',
        width: `${finalWidth}`,
        height: 'auto',
        fill: `${color}`,
        cursor: "pointer",
        ...sx
      }}
      viewBox="0 0 80 40"
    >
      <path
        d="M40.0058 39.6738C38.6818 39.6763 37.3986 39.2152 36.3791 38.3704L2.37913 10.0371C1.2219 9.07525 0.494162 7.69308 0.35601 6.19466C0.217858 4.69625 0.680609 3.20432 1.64246 2.04709C2.60431 0.889866 3.98648 0.16213 5.48489 0.0239786C6.98331 -0.114173 8.47523 0.348575 9.63246 1.31043L40.0058 26.6971L70.3791 2.2171C70.9588 1.74639 71.6257 1.39488 72.3416 1.18276C73.0575 0.970651 73.8083 0.90212 74.5508 0.981108C75.2933 1.0601 76.0129 1.28505 76.6682 1.64303C77.3234 2.00102 77.9015 2.48498 78.3691 3.0671C78.8881 3.64975 79.2811 4.3333 79.5236 5.07492C79.7661 5.81653 79.8528 6.60024 79.7783 7.37693C79.7039 8.15362 79.4698 8.90657 79.0908 9.58859C78.7118 10.2706 78.196 10.867 77.5758 11.3404L43.5758 38.7104C42.527 39.4217 41.27 39.7609 40.0058 39.6738Z"
      />
    </SvgIcon>
  )
};

export default ArrowButton;
import {call, put} from 'redux-saga/effects';
import {openErrorSnack} from '../reducers/start';
import {RequestError} from './exceptions';
import {logout} from "../reducers/auth";
import {refreshToken} from "../sagas/authSaga";
import {toastr} from "react-redux-toastr";
import {addTask, deleteTask} from "../reducers/styleControls";

export function* callHttp(method: (...args: any[]) => any, url: string, data?: any, arrayKey?: string, useSpinner: boolean = true): any {
    let args: Array<any> = [url];
    if (data) {
        args.push(data)
    }
    if (arrayKey) {
        args.push(arrayKey)
    }
    // const tokenExpired = localStorage.getItem('sessionExpired')
    //
    // if (tokenExpired && tokenExpired < Date.now()) {
    //   yield put(logout())
    //   return;
    // }
    //let checkSpinner = false;

    // if (
    //     args[args.length - 1] !== undefined &&
    //     args[args.length - 1] !== null &&
    //     args[args.length - 1].constructor === Object &&
    //     args[args.length - 1].useWithoutSpinner
    // )
    //     checkSpinner = true;
    /* if (process.env.REACT_APP_MOCK) return; */

    try {
        if (useSpinner) yield put(addTask());
        return yield call(method, ...args);
    } catch (err: any) {
        if (err.status === 401) {
            // For ExpiredTokenException case
            if (err.type === 'ApiException' || err.type === 'UsernameNotFoundException' || err.type === 'BadCredentialsException') {
                yield put(openErrorSnack(err.message));
            }
            if (err.type === 'ExpiredTokenException' || err.type === 'AccessDeniedException' /*token cookie is expired and we don't have it at all*/) {
                yield call(refreshToken);
                try {
                    if (useSpinner) yield put(addTask());
                    const data: any = yield call(method, ...args);
                    return data;
                } catch (err2: any) {
                    // probably err2.status should stay here
                    if (err2.status === 502)
                        toastr.info('', 'Server is offline. Please wait for api to be up.');
                } finally {
                    if (useSpinner) yield put(deleteTask());
                }
            } else {
                // For AuthenticationException, InvalidTokenException, AccessDeniedException
                yield put(logout());
                return;
            }
        } else if (err.status === 303) {
            //throw new RequestError(err.status, err.message); - old way, should be checked for Articles when language is changed
            throw new RequestError(err)
        } else {
            yield put(openErrorSnack(err.message));
            throw err;
        }
    } finally {
        if (useSpinner) yield put(deleteTask());
    }
}

export function* callHttpWithoutSpinner(method: (...args: any[]) => any, url: string, data?: any, arrayKey?: string): any {
    return yield callHttp(method, url, data, arrayKey, false);

    /* const tokenExpired = localStorage.getItem('sessionExpired');

    if (tokenExpired && tokenExpired < Date.now()) {
      yield put(logout());
      return;
    }

    try {
      const data = yield call(...args);
      return data;
    } catch (err) {
      if (err.status === 401) {
        // For ExpiredTokenException case
        if (err.message.type === 'ExpiredTokenException') {
          yield call(refreshToken);
          try {
            yield put(addTask());
            const data = yield call(...args);
            return data;
          } catch (err2) {
            // probably err2.status should stay here
            if (err2.status === 502)
              toastr.info('Server is offline', 'Please wait for api to be up.');
          } finally {
            yield put(deleteTask());
          }
        } else {
          // For AuthenticationException, InvalidTokenException, AccessDeniedException
          yield put(logout());
          return;
        }
      } else {
        if (
          err.status &&
          err.status === 300 &&
          err.message &&
          err.message.alias !== undefined
        ) {
          throw new RequestError(err.status, err.message);
        }
        yield put(openErrorSnack(err.message));
      }
    } */
}

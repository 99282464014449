import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  SxProps,
} from "@mui/material";
import React, { useState } from "react";
import { primaryColor } from "../../theme";
import { useTranslation } from "react-i18next";

interface PasswordFieldProps {
  boxStyle?: SxProps;
  handleChange: (value: string) => void;
  text: string;
  value?: string;
  required?: boolean;
  showHelperText?: boolean;
}

const ShowPasswordInput = (props: PasswordFieldProps) => {
  const { t } = useTranslation("myAccount");

  const [fieldVisible, setFieldVisible] = useState(false);
  const {
    text,
    handleChange,
    value = "",
    required = true,
    boxStyle = {},
    showHelperText
  } = props;

  return (
    <FormControl
      sx={{
        width: "100%",
        ...boxStyle,
      }}
      variant="standard"
    >
      <InputLabel
        sx={{
          marginRight: "10px",
          opacity: 0.5,
        }}
      >
        {text}
      </InputLabel>

      <Input
        type={fieldVisible ? "text" : "password"}
        onChange={(event) => handleChange(event.target.value)}
        required={required}
        value={value}
        sx={{
          borderBottom: "1.5px solid" + primaryColor.iconColor,
        }}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{
              cursor: "pointer",
            }}
          >
            <Box
              component={"img"}
              src={`/images/icons/myAccount/${
                fieldVisible ? "passwordEyeActive.svg" : "passwordEye.svg"
              }`}
              onClick={() => setFieldVisible(!fieldVisible)}
            />
          </InputAdornment>
        }
      />
     {showHelperText &&
     <FormHelperText
        sx={{
          opacity: 0.5,
        }}
      >
       {t("passwordLengthNote")}
      </FormHelperText>
       } 
    </FormControl>
  );
};
export default ShowPasswordInput;
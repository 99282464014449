import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import OrdersAddress from "../../common/myOrders/OrdersAddress";
import theme, {primaryColor} from "../../../../theme";
import React from "react";
import {useTranslation} from "react-i18next";
import MyOrderFooter from "../../common/myOrders/MyOrderFooter";
import {useAppDispatch} from "../../../../hook";
import {Order } from "../../../../interfaces/backend";
import OrderSummary from "../../common/myOrders/OrderSummary";
import MyOrderItemTablet from "../../common/myOrders/mobile/MyOrderItemTablet";
import OrdersTableTablet from "../../common/myOrders/mobile/OrdersTableTablet";
import {useNavigate} from "react-router";
import { reOrder } from "../../../../components/myData/MyOrders/orderUtils";
import { addCartMultipleItems, addCartItem } from "../../../../reducers/cart";
import {countTotalItems} from "../../../../utils/productFunctions";
import {ItemsCount} from "../../common/myOrders/MyOrderItem";

export interface OrderDetailsTabletProp {
  order: Order,
}

const MyOrderDetailsTablet = (props: OrderDetailsTabletProp) => {
  const {order} = props
  const {t} = useTranslation('myAccount')
  const dispatch = useAppDispatch()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()


  const confirmOrder = (order: Order) => {
    const items = order.items;
    const productsToAdd = reOrder(items);

    if (productsToAdd.length > 0) {
      dispatch(addCartMultipleItems({ items: productsToAdd }));
    }else{
      dispatch(addCartItem(items))
    }
  };


  return (
      <Box bgcolor={primaryColor.ordersListBackgroundColor} color={primaryColor.accountTextColor}>
        {!mobileView && <Box
            component={'img'}
            src={'/images/icons/arrowCarouselLeft.svg'}
            alt='image'
            onClick={() => {
              navigate({
                pathname: '/my-account/my-orders',
                search: ``,
              })
            }
            }
            sx={{
              position: 'initial',
              height: '34px',
              width: '17px',
              objectFit: 'cover',
              marginLeft: '20px',
              marginTop: '28px',
              cursor: 'pointer',
              color:primaryColor.accountTextColor
            }}/>}
        <MyOrderItemTablet order={order}/>
        {<Typography padding={'0px 0px 30px 30px'}><ItemsCount length={countTotalItems(order.items)} /></Typography>}

        <Box sx={{marginX: {xs:'30px', md:'20px'}}}>

            <Grid display={'flex'} container>
              {order.billingAddress && <Grid item xs={12} md={6}>
                <OrdersAddress addressType={'billingAddress'} address={order.billingAddress} />
              </Grid>}
              {order.shippingAddress && <Grid item mt={{xs: '36px', md: '0px'}} xs={12} md={6}>
                <OrdersAddress addressType={'shippingAddress'}
                               address={order.shippingAddress}/>
              </Grid>}
            </Grid>
        </Box>
          <Box mt={'70px'}>
            <OrdersTableTablet order={order}/>
          </Box>

        <Box sx={{marginX: {xs:'30px', md:'20px'}}}>

          <OrderSummary order={order} />
          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}> <Button
              sx={{
                mt: '80px',
                width: '60%'
              }}
              variant="contained"
              onClick={() => {
                confirmOrder(order)
              }}
          >{t('reOrderProducts')}</Button></Box>
          <Box pb={'53px'} display={'flex'} >
            <MyOrderFooter discounts={order.discounts} paymentMethod={order.paymentMethod}
                           internalOrderNumber={order.internalOrderNumber} documents={order.documents} comment={order.comment}/>
          </Box>
        </Box>
      </Box>
  )
}

export default (MyOrderDetailsTablet)

import { Badge, Box, Tooltip, useMediaQuery } from "@mui/material";
import { openSearchBox } from "../../../../../reducers/quickSearch";
import * as React from "react";
import { useEffect,  } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hook";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MiniCart from "../MiniCart";
import {
  setMiniCartOpen,
} from "../../../../../reducers/styleControls";
import theme, { primaryColor } from "../../../../../theme";
import AuthDynamicComponent from "../../../../accessControl/AuthDynamicComponent";
import { getCart } from "../../../../../reducers/cart";
import { useAccessRights } from "../../../../accessControl/accessControlUtils";
 
import { AccountDrawerIcon } from "../drawerListItem";
import AccountMenu from "./AccountMenu";
import {useLocation} from "react-router";

 

const LeftIcons = () => {
  const searchBoxOpen = useAppSelector(
    (state) => state.quickSearch.searchBoxOpen
  );
  const cart = useAppSelector((state) => state.cart.cart);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");
  const isMiniCartOpened = useAppSelector(
    (state) => state.styleControls.isMiniCartOpened
  );
  const isMiniCartOpenNeeded = useAppSelector(
    (state) => state.styleControls.isMiniCartOpenNeeded
  );

  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const isAuthenticatedOrGuestAllowed = useAccessRights();
  const location = useLocation();
  const isCartPage = location.pathname === '/cart';
  const isMyAccountPage = location.pathname === '/my-account';

  useEffect(() => {
    if (isAuthenticatedOrGuestAllowed) {
      dispatch(getCart());
    }
  }, [isAuthenticatedOrGuestAllowed]);

  return (
 
   <Box 
    sx={{
      width: {xs: '125px', lg: '150px'},
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: "center"
    }}
    >
      <AuthDynamicComponent children={
            <Tooltip title={t("search")}>
              <Box
                component="img"
                src={
                  !searchBoxOpen
                    ? "/images/whiteSearch.svg"
                    : "/images/coloredSearch.svg"
                }
                onClick={() => dispatch(openSearchBox(!searchBoxOpen))}
                sx={{
                  cursor: "pointer",
                  height: { xs: "20px", md: '26px', lg: "28px" },
                  width: "auto"
                }}
              />
              
            </Tooltip>
        }
      />

      <AuthDynamicComponent
        children= {<AccountMenu/>}
        fallback={
            <Tooltip title={t("login")}>
              <Link
                  to={"/login"}
                  style={{
                      lineHeight: 0 //remove additional vertical space
                  }}
              >
                <AccountDrawerIcon isMyAccountPage={isMyAccountPage}/>
              </Link>
            </Tooltip>
        }
      />

      <AuthDynamicComponent
        children={
          <Box
            sx={{
              position: "relative",
              lineHeight: 0 //remove additional vertical space
          }}
            onMouseLeave={() => {
              !isMiniCartOpenNeeded && dispatch(setMiniCartOpen(false));
            }}
            onMouseEnter={() => {
              if (cart?.items && cart.items.length > 0 && !mobileView) {
                dispatch(setMiniCartOpen(true));
              }
            }}
          >
            <Link to={"/cart"}>
              <Badge
                badgeContent={cart?.itemsQuantity}
                slotProps={{
                  badge: {
                    style: {
                      fontSize: "1rem",
                      backgroundColor: primaryColor.orangeHoverColor,
                    },
                  },
                }}
                color={"primary"}
                onClick={() => {
                  dispatch(setMiniCartOpen(false));
                }}
              >
                <Tooltip title={t("cart")}>
                  <Box
                    component={"img"}
                    src={
                      isMiniCartOpened || (mobileView && isCartPage)
                        ? "/images/icons/OrangeCart-icon.svg"
                        : "/images/icons/WhiteCart-icon.svg"
                    }
                    sx={{
                      height: { xs: "20px", md:"26px", lg: "28px" },
                      width: 'auto',
                    }}
                  />
                </Tooltip>
              </Badge>
            </Link>
            <MiniCart />
          </Box>
        }
      />
    </Box>
  );
};
export default React.memo(LeftIcons);
import {RequestError} from './exceptions';
import {Cookies} from "react-cookie-consent";
import {IndexString} from "../interfaces/general";
import {Error} from "../interfaces/backend";

export const headers = () => {
  const customerEmail = localStorage.getItem('serviceCustomerEmail');
  const token = Cookies.get('token')

  let headers: IndexString = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token || ''}`,
  }

  if (customerEmail) {
    headers['X-Switch-User'] = customerEmail;
  }

  return headers;
};

export const headersMultipart = () => {
  let initialHeaders = headers();
  delete initialHeaders['Content-Type'];

  return initialHeaders;
};

export const makePostOptions = (data: any): RequestInit => ({
  method: 'POST',
  mode: 'cors',
  headers: headers(),
  body: JSON.stringify(data),
});

export const makeMultipartPostOptions = (data: any): RequestInit => ({
  method: 'POST',
  mode: 'cors',
  headers: headersMultipart(),
  body: data.formData,
});

export const makePatchOptions = (data: any): RequestInit => ({
  method: 'PATCH',
  mode: 'cors',
  headers: headers(),
  body: JSON.stringify(data),
});

export const getOptions = (): RequestInit => ({
  method: 'GET',
  mode: 'cors',
  headers: headers(),
});

export const deleteOptions = (): RequestInit => ({
  method: 'DELETE',
  mode: 'cors',
  headers: headers(),
});

export const putOptions = (): RequestInit => ({
  method: 'PUT',
  mode: 'cors',
  headers: headers(),
});

export const deleteManyUrl = (url: string, data: Array<any>) => `${url}?${data.map((v, i) => 'users[]=' + v).join('&')}`;
// May be use arrayUrl? And another Many func
export const arrayUrl = (url: string, key: string, data: Array<any>) => `${url}?${data.map((v) => `${key}[]=${v}`).join('&')}`;

export function extractTokensFromTempAuthData(tempAuthDataString: string | null): {
  token: string;
  refreshToken: string;
  expire: number;
  storedUrls: Array<string>
} | null {
  if (!tempAuthDataString) {
    return null;
  }

  let tempAuthData;
  try {
    tempAuthData = JSON.parse(tempAuthDataString);
  } catch (error) {
    return null;
  }

  if (tempAuthData?.data?.token && tempAuthData?.data?.refreshToken && tempAuthData?.data?.expire && Array.isArray(tempAuthData?.storedUrls)) {
    return {
      token: tempAuthData.data.token,
      refreshToken: tempAuthData.data.refreshToken,
      expire: tempAuthData.data.expire,
      storedUrls: tempAuthData.storedUrls
    }
  }

  return null;
}

const request = (url: string, options: RequestInit): Object => {

  const extractedTokens = extractTokensFromTempAuthData(sessionStorage.getItem('temporaryAuthData'));
  if (extractedTokens) {
    const storedToken = extractedTokens.token
    const storedUrl = extractedTokens.storedUrls.find(storedUrl => storedUrl === url)

    if (storedToken && storedUrl) {
      options.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      }
    }
  }

  return fetch(adjustUrl(url, options), options).then(response => {
    const {status} = response;

    if (status === 204) return {};

    const json = response.json().catch((ce) => {
      throw new RequestError({
        code: 500,
        message: ce,
        fields: [],
        type: "ServerError"
      } as Error); // as fail response is not valid json when logging in we should use this
    });
    if (status >= 200 && status < 300) return json;

    return json.then((message) => {
      throw new RequestError(message);
    });
  });
};

const requestBlob = (url: string, options: RequestInit | undefined) =>
  fetch(url, options)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });

export const getBlob = (url: string) => requestBlob(url, getOptions());

export const get = (url: string) => request(url, getOptions());

export const getArray = (url: string, data: any, key: string) => request(arrayUrl(url, key, data), getOptions());

export const post = (url: string, data: any) => request(url, data?.multipart ? makeMultipartPostOptions(data) : makePostOptions(data));

export const del = (url: string) => request(url, deleteOptions());

export const put = (url: string) => request(url, putOptions());

export const delBody = (url: string, data: any) => request(deleteManyUrl(url, data), deleteOptions());

export const patch = (url: string, data: any) => request(url, makePatchOptions(data));

const adjustUrl = (url: string, options: RequestInit) => {
  if (options.method !== 'GET') {
    return url;
  }

  const CACHE_BUILD = localStorage.getItem('cacheBuild') || null;

  const urlObject = new URL(url);
  if (CACHE_BUILD) {
    urlObject.searchParams.set('c', CACHE_BUILD);
  }

  urlObject.searchParams.set('u', randomString());
  return urlObject.toString();
};

function randomString(length: number = 8) {
  let currentKey = localStorage.getItem('userKey');
  if (!currentKey) {
    const half = length / 2;
    currentKey =
      Math.random()
        .toString(36)
        .substring(2, half + 2) +
      Math.random()
        .toString(36)
        .substring(2, length - half + 2);
    localStorage.setItem('userKey', currentKey);
  }

  return currentKey;
}

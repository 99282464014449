import {put, takeEvery} from 'redux-saga/effects';
import {callHttp} from '../utils/api';
import {del, get, patch, post} from '../utils/httpUtil';
import {BILLING_ADDRESS, ME, SHIPPING_ADDRESS, SHIPPING_ADDRESS_ID,} from '../constants/api';
import {openErrorSnack} from '../reducers/start'
import {
  getShippingAddresses,
  setBillingAddress,
  setBillingFormOpen,
  setContactData,
  setContactsFormEditable,
  setShippingAddresses,
  successChangeShippingAddress
} from "../reducers/address";
import {BillingAddress, Me, ShippingAddress} from "../interfaces/backend";
import {setShippingAddress} from "../reducers/cart";
import {addBilingAddressAction, addContactDataAction, addShippingAddressAction} from "../interfaces/actions";


export function* getBillingAddress() {
  try {
    const billingAddress: BillingAddress = yield callHttp(get, BILLING_ADDRESS)
    yield put(setBillingAddress(billingAddress))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* patchBillingAddress(action: addBilingAddressAction) {
  try {
    const {billingAddress} = action.payload;
    yield callHttp(patch, BILLING_ADDRESS, billingAddress);
    yield put(setBillingFormOpen(false));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getShippingAddressesSaga() {
  try {
    const shippingAddressList: Array<ShippingAddress> = yield callHttp(get, SHIPPING_ADDRESS)
    yield put(setShippingAddresses(shippingAddressList))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* patchShippingAddress(action: addShippingAddressAction) {
  try {
    const {shippingAddress} = action.payload;
    yield callHttp(patch, SHIPPING_ADDRESS_ID(shippingAddress.id as number), shippingAddress)
    yield put(getShippingAddresses());
    yield put(setShippingAddress(shippingAddress))
    yield put(successChangeShippingAddress());
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* postShippingAddress(action: addShippingAddressAction) {
  try {
    const {shippingAddress} = action.payload;
    yield callHttp(post, SHIPPING_ADDRESS, shippingAddress);
    yield put(getShippingAddresses());
    yield put(setShippingAddress(shippingAddress))
    yield put(successChangeShippingAddress());
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* deleteShippingAddress(action: addShippingAddressAction) {
  try {

    const {shippingAddress} = action.payload;
    yield callHttp(del, SHIPPING_ADDRESS_ID(shippingAddress.id as number), shippingAddress)
    yield put(getShippingAddresses());
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}


export function* getContactData() {
  try {
    const contactData: Me = yield callHttp(get, ME);
    yield put(setContactData(contactData));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}


export function* patchContactData(action: addContactDataAction) {
  try {
    const {contact} = action.payload;
    yield callHttp(patch, ME, contact);
    yield put(setContactsFormEditable(false));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export default function* addressSaga() {
  yield takeEvery('address/getBillingAddress', getBillingAddress)
  yield takeEvery('address/getShippingAddresses', getShippingAddressesSaga)
  yield takeEvery('address/postShippingAddress', postShippingAddress)
  yield takeEvery('address/patchShippingAddress', patchShippingAddress)
  yield takeEvery('address/deleteShippingAddress', deleteShippingAddress)
  yield takeEvery('address/getContactData', getContactData)
  yield takeEvery('address/patchContactData', patchContactData)
  yield takeEvery('address/patchBillingAddress', patchBillingAddress)

}


import TableRow from "@mui/material/TableRow";
import {StyledTableCell} from "../../../../Cart/styleElements";
import {Box, Grid, Typography} from "@mui/material";
import {TakeFirst2DImage} from "../../../../../utils/productFunctions";
import React from "react";
import {CartItem} from "../../../../../interfaces/backend";
import {useAppDispatch} from "../../../../../hook";
import {primaryColor} from "../../../../../theme";
import {formatMoney} from "../../../../../utils/localization";
import {addCartItem} from "../../../../../reducers/cart";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const ItemDataRow = (props: { cartItem: CartItem }) => {
  const {t} = useTranslation('myAccount')
  const {cartItem} = props
  const {product} = cartItem
  const rowPrice = cartItem.discountPrice || cartItem.price
  const dispatch = useAppDispatch()

  return (
    <Box>
      <Box sx={{paddingBottom: '15px'}}>
        <Link
          to={product ? `/product/${product.alias}` : "javascript:void(0)"}
        >
          <Typography>{cartItem.name}</Typography>
          <Typography>{cartItem.code}</Typography>
        </Link>
      </Box>
      <Grid container sx={{justifyContent: 'space-between'}}>
        <Grid item xs={6}>

          {cartItem.discountPrice ? (
              <Box sx={{maxWidth: 'max-content'}}>
                <Typography
                  sx={{
                    textDecoration: 'line-through',
                    textDecorationColor: primaryColor.lineThroughPrice,
                    textDecorationThickness: '1px',
                    textDecorationTrim: 'auto',
                    color: primaryColor.lineThroughPrice,
                    opacity: 0.3
                  }}
                >
                  {rowPrice
                    ? formatMoney(cartItem.price)
                    : t('requestPrice')}
                </Typography>
                <Typography sx={{color: primaryColor.discountCheckoutPage}}>
                  {formatMoney(rowPrice)}
                </Typography>
              </Box>
            ) :
            <Typography sx={{width: 'auto'}}>
              {rowPrice
                ? formatMoney(rowPrice)
                : t('requestPrice')}
            </Typography>
          }
        </Grid>

        <Grid item xs={6} sx={{
          display: 'grid',
          justifyItems: 'flex-end',
          alignItems: 'end'
        }}>
          {cartItem.isOrderable && <Box
              component={'img'}
              alt='cart'
              onClick={() => dispatch(addCartItem({
                code: product.code,
                quantity: cartItem.quantity,
              }))}
              src='/images/cartIcon.svg'
              sx={{
                cursor: 'pointer',
                verticalAlign: 'bottom'
              }}
          />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default function OrdersTableItemTablet(props: { item: CartItem }) {
  const {t} = useTranslation('myAccount')
  const {item} = props;
  const {product} = item
  const image = TakeFirst2DImage(product?.image);

  return (
    <TableRow>
      <StyledTableCell
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'table-cell',
          flexDirection: 'column',
          paddingLeft: {xs: '0px', md: '20px'}
        }}
      >
        <Box
          component={'img'}
          src={image}
          sx={{
            height: '62px',
          }}
        >
        </Box>
        <Box sx={{display: 'flex', marginTop: '10px', width: 'max-content'}}>
          <Box>{t('quantity')}: {item.quantity}</Box>
        </Box>
      </StyledTableCell>
      <StyledTableCell
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <ItemDataRow cartItem={item}/>
      </StyledTableCell>
    </TableRow>
  );
}
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import CustomDrawer from "./leftIcons/customDrawer";
import { getMenu, setMainMenu } from "../../../../reducers/static";
import {MenuItem} from "../../../../interfaces/backend";
import { normalDrawerList } from "./drawerListItem";
import MenuListItem from "./leftIcons/AccountMenu/menuListItem";
import { ClickAwayListener } from "@mui/material";

 
export default function MainMenuMobile() {
  const [open, setOpen] = React.useState(false);
 
  const dispatch = useAppDispatch()
  const mainMenuLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.mainMenu)

  React.useEffect(() => {
	!mainMenuLinks.length && dispatch(getMenu({type: 'main_menu', handler: setMainMenu}))
}, [dispatch, mainMenuLinks])


  const list=normalDrawerList(mainMenuLinks);
                             
  return (
    <ClickAwayListener
    onClickAway={() => setOpen(false)}
>
    <Box>
      <Button
        sx={{
          paddingRight: "12px",
          minWidth: "unset",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "unset",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
          <Box
            component={"img"}
            src={!open ? "/images/drawerIcon.svg" : "/images/clearIcon.svg"}
            height={"18px"}
            width={"25px"}
            alt={"drawer icon"}
          />
      </Button>
	<CustomDrawer
		open={open}
		onClose={() => setOpen(false)}
		drawerMenuList={<MenuListItem 
      listItems={list} />
    }
	/>
       
    </Box>
    </ClickAwayListener>
  );
}

import React from "react";
import {Box} from "@mui/material";
import { TakeEmptyImage } from "../../../utils/productFunctions";

const ImageContent = (props: { image: string }) => {
    const {image} = props;
    return (
        <Box
            component={'img'}
            src={image || TakeEmptyImage()}
            alt='image'
            sx={{
                height: '358px',
                width: '100%',
                objectFit: 'cover',
                objectPosition: "center"
            }}
        />
    )
}
export default ImageContent;
import {File, OrderDiscount, PaymentMethod} from "../../../../interfaces/backend";
import React from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import UploadFilePreview, {UploadFilesStyleProps} from "../../../Checkout/UploadFilePreview";

export interface FooterProps {
  discounts: Array<OrderDiscount>,
  paymentMethod: PaymentMethod,
  internalOrderNumber: string,
  comment: string,
  documents: File[]
}

export const discountsString = (discounts: Array<OrderDiscount>):string => {
  return  discounts.map((discountValue) => {
    const {discount} = discountValue
      return discount.discountCode
  }).join(", ")

}

const MyOrderFooter = (props: FooterProps) => {
  const {t} = useTranslation('myAccount')
  const {discounts, paymentMethod, internalOrderNumber, documents, comment} = props
  const uploadFilePreviewStyles: UploadFilesStyleProps = {
    fileNameBox : {
      maxWidth: '100%'
    },
    fileName: {
      fontSize: '1.6rem'
    }
  }
  return (
      <Box mt={'51px'} width={'100%'}>
          {discounts && <>
            <Box>
              {t('promotionCodes')}
            </Box>
            <Box>
              {discountsString(discounts)}
            </Box>
          </>}
          <Box mt={'45px'}>
            <Box>
              {t('paymentMethod')}
            </Box>
            <Box>
              {paymentMethod.name}
            </Box>
          </Box>
          {internalOrderNumber && <Box mt={'45px'}>
            <Box>
              {t('internalOrderNumber')}
            </Box>
            <Box>
              {internalOrderNumber}
            </Box>
          </Box>}
        {comment && <Box mt={'45px'}>
          <Box>
            {t('comment')}
          </Box>
          <Box>
            {comment}
          </Box>
        </Box>}
        {documents && documents.length &&

            <Box sx={{pt: '36px'}}>
              <Typography sx={{pb: '22px', fontSize: '1.6rem'}}>{t('document')}</Typography>
              <UploadFilePreview
                  filesToShow={documents}
                  canDelete={false}
                  style={uploadFilePreviewStyles}
              />
            </Box>
        }
      </Box>
  )
}

export default MyOrderFooter

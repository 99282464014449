import BottomHeader from "./BottomHeader/bottomHeader";
import TopHeader from "./topHeader";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { useEffect } from "react";
import { getServiceUserFromStorage } from "../../../reducers/auth";
import {Box, useMediaQuery} from "@mui/material";
import theme from "../../../theme";

const Header = () => {
    const dispatch = useAppDispatch();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);
 
  
  useEffect(() => {
    dispatch(getServiceUserFromStorage());
}, []);

    return (
        <>
        <TopHeader />
        {mobileView &&  <Box minHeight={serviceUserEmail ? '120px' : '45px'} />}
        <BottomHeader />
        </>
    )
}

export default (Header)

import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { getSvg } from "./TabImages";
import { useAppDispatch } from "../../../hook";
import { TabData } from "../../../pages/Account/AccountPage";
import { setOpenedDrawerID } from "../../../reducers/static";
import theme, { primaryColor, defaultFonts } from "../../../theme";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

const Tab = ({ text, selected, isLast, item, isHeader,setSelectedItem }: TabData) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const toHighlightTab = selected && (!mobileView || isHeader);
  const style = {

    selectedStyle: toHighlightTab && {
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "11px",
        backgroundColor: primaryColor.myAccountTabSelected,
      },
    },

    selectedText: !toHighlightTab &&
      !mobileView && {
        opacity: "0.5",
      },
  };

  const conditionalBackgroundColor = {
    backgroundColor: toHighlightTab
      ? primaryColor.myAccountTabSelectedBackground
      : primaryColor.myAccountDataBackground,
  };

  return (
    <Box
      sx={{
        ...style.selectedStyle,
        backgroundColor: conditionalBackgroundColor,
        color: primaryColor.myAccountTabText,
        fontSize: defaultFonts,
        height: isHeader ? "74px" : "90px",
        display: "inline-flex",
        width: "100%",
        cursor: "pointer",
      }}
    >
      {isHeader && (
        <Box
          component="img"
          src="/images/icons/arrowCarouselLeft.svg"
          alt="image"
          onClick={() => {setSelectedItem? navigate(`${location.pathname}`) :
              !mobileView ? dispatch(setOpenedDrawerID("account-menu")) : navigate('/my-account')
          }}
          sx={{
            height: "34px",
            width: "17px",
            objectFit: "cover",
            cursor: "pointer",
            color: primaryColor.accountTextColor,
            marginRight: "0px",
            marginLeft: "31px",
            alignSelf: "center",

          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          width: "75px",
          alignItems: "center",
          justifyContent: isHeader ? "end" : 'center',
          backgroundColor: conditionalBackgroundColor,
          ...style.selectedText,
        }}
      >
        {getSvg(item)}
      </Box>
      <Box
        sx={{
          ...style.selectedText,
          borderBottom: !isLast
            ? `0.3px solid ${primaryColor.myAccountTabBorder}`
            : "unset",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          paddingLeft: isHeader ? "10px" : "0px",
          paddingRight: isHeader ? "0px" : "15px",
          fontSize: defaultFonts,
          fontWeight: 400,
          textTransform: "uppercase",
          textAlign: "start",
          wordBreak: 'break-word'
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default Tab;
import {TechnicalDetails} from "../../../../interfaces/backend";
import {Box, Divider, Typography} from "@mui/material";
import * as React from "react";
import {primaryColor} from "../../../../theme";

const Info = (props: { info: string, value: string, isLast?: boolean }) => {
  const {info, value, isLast} = props
  return (
      <>
        <Box sx={{display: 'flex', paddingX: '20px', justifyContent: 'space-between', paddingY: '10px'}}>
          <Typography>{info}</Typography>
          <Typography>{value}</Typography>
        </Box>
        {!isLast && <Divider sx={{backgroundColor: primaryColor.technicalDataDividerColor}}></Divider>}
      </>
  )
}
export const TechnicalData = (props: {technicalDetails: TechnicalDetails[] }) => {
  const {technicalDetails} = props
  return(
      <Box sx={{paddingBottom: '30px'}}>
        {technicalDetails.map(
            (p, i)  => <Info key={i} info={p.attribute.name} value={p.content?p.content:'-'} isLast={i === technicalDetails.length-1}/>
        )}
      </Box>
  )
}
export default (TechnicalData)

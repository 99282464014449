import {Box, BoxProps, Button, styled, Typography, useMediaQuery} from "@mui/material";
import InvoicingCompanyAddress from "./InvoicingCompanyAddress";
import BillingAddress from "../../components/myData/Addresses/BillingAddress";
import OrderOptions from "./OrderOptions";
import ShippingOptions from "./ShippingOptions";
import ShippingAddressComp from "./ShippingAddressComp";
import PaymentMethods from "./PaymentMethods";
import AdditionalInformation from "./AdditionalInfo";
import Terms from "../../components/term/Terms";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hook";
import {CheckoutSummaryProps} from "./CheckoutSummary";
import CheckoutAccordion from "../../components/checkout/CheckoutAccordion/CheckoutAccordion";
import theme, { primaryColor } from "../../theme";
// import { RowFlexBox, CustomTypography } from "./styledElement";

const ButtonContainer = styled((props: BoxProps) => (
  <Box {...props}  />
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default function CheckoutOptions({handleClick, enableBuyButton, checkoutCart, validate}: CheckoutSummaryProps) {
  const {t} = useTranslation('checkoutPage')
  const settings = useAppSelector(state => state.staticContent.settings)
  const paymentIsLoading = useAppSelector(state => state.cart.paymentIsLoading)
  const isStripeExist = useAppSelector(state => state.cart.isStripeExist)
  const isElementsExist = useAppSelector(state => state.cart.isElementsExist)
  const selectedMethod = useAppSelector(state => state.cart.checkoutCart.paymentMethod )

  const showOrderOptions = settings && settings.find(
    (x) => x.name === 'show_order_options' && x.value !== null && parseInt(x.value) === 1
  );
  const isInternalOrderNumberMandatory = !!(settings && settings.find(e => e.name === 'is_internal_order_number_mandatory' && e.value === '1'))
  const isOrderOptionProduct = checkoutCart?.orderOption === "product" || !showOrderOptions;

  const [showShippingAddress, setShowShippingAddress] = useState(false)

  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (isOrderOptionProduct && checkoutCart?.shippingMethod?.isAddressRequired) setShowShippingAddress(true)
    else setShowShippingAddress(false)
  }, [isOrderOptionProduct, checkoutCart?.shippingMethod?.isAddressRequired])

  const ref = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current && showShippingAddress) {
      ref.current.scrollIntoView();
    }
  }, [showShippingAddress]);

  const invoicingCompanyAddress = InvoicingCompanyAddress()

  return (
      <Box
        sx={{
          margin: '0 auto',
          mb: {xs: '20px', md: '20px'},
          width: {xs: '100%', lg: '70%'}
        }}
      >
        {invoicingCompanyAddress &&
            <CheckoutAccordion
                title={t('invoicingCompanyHeader')}
                content={invoicingCompanyAddress}
                mandatory={false}
            />
        }

        <CheckoutAccordion
          title={t('billingAddress')}
          content={<BillingAddress/>}
          mandatory={false}
        />

        {showOrderOptions &&
            <CheckoutAccordion
                title={t('orderOptions')}
                content={<OrderOptions/>}
                mandatory={true}
            />
        }

        <Box ref={ref}>
          {isOrderOptionProduct &&
              <CheckoutAccordion
                  title={t('shippingOptions')}
                  content={<ShippingOptions/>}
                  mandatory={true}
              />
          }
        </Box>

        {showShippingAddress &&
            <CheckoutAccordion
                title={t('shippingAddress')}
                content={<ShippingAddressComp/>}
                mandatory={true}
            />
        }

        <CheckoutAccordion
          title={t('payment')}
          content={<PaymentMethods validate={() => validate()}/>}
          mandatory={true}
        />

        <CheckoutAccordion
          title={t('additional')}
          content={<AdditionalInformation/>}
          mandatory={isInternalOrderNumberMandatory}
        />

        <Box
          sx={{
            mt: '60px',
            padding: {
              xs: '0px 40px',
              lg: 0
            },
          }}
        >
          <Terms category='checkout' type='checkbox'/>

        {!mobileView && <ButtonContainer
            sx={{
              mt: '60px',
            }}>
          {selectedMethod && selectedMethod.paymentProvider === 'payment-stripe' ?
              <Button
                  form={'payment-form'}
                  variant="contained"
                  disabled={paymentIsLoading || !isStripeExist || !isElementsExist || !enableBuyButton}
                  type='submit'
              >
                {t('buyNow')}
              </Button>
:
            <Button
                variant="contained"
                onClick={() => handleClick()}
                disabled = {!enableBuyButton}
            >
              {t('buyNow')}
            </Button>}
      {/*      <RowFlexBox*/}
      {/*  sx={{*/}
      {/*    mt: "13px",*/}
      {/*    mb: "70px",*/}
      {/*  }}*/}
      {/*>*/}
        <Typography
          sx={{
            // width: "100%",
            // marginRight: {md: 'auto'},
            mt: "13px",
            color: primaryColor.discountCheckoutPage,
          }}
        >
          {t("pleaseFill")}
        </Typography>
      {/*</RowFlexBox>*/}

        </ButtonContainer>
        }
           </Box>

      </Box>
  )
}
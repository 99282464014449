import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hook";
import { Machine } from "../../../interfaces/backend";
import MachineViewer from "./machineViewer";
import SparePartsTree from "./SparePartsTree";

const SpareParts = () => {
  const selectedMachine: Machine = useAppSelector(
    (state) => state.machine.machine
  );

  const has3DImage = selectedMachine.product.image3d?.url;

  const [scrollToViewer, setScrollToViewer] = useState(false);

  useEffect(() => {
    const bottomHeader = document.getElementById("bottomHeader");
    if (bottomHeader) {
      bottomHeader.scrollIntoView({ block: "start", inline: "nearest" });
    }
  }, []);

  useEffect(() => {
    const bottomHeader = document.getElementById("bottomHeader");
    if (scrollToViewer && bottomHeader) {
      bottomHeader.scrollIntoView({ block: "start", inline: "nearest" });
      setScrollToViewer(false);
    }
  }, [scrollToViewer]);

  useEffect(() => {
    setScrollToViewer(true);
  }, []);

  return (
    <>
      {has3DImage ? (
        <MachineViewer />
      ) : (
        <SparePartsTree />
      )}
    </>
  );
};
export default SpareParts;
import {Box, SxProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {formatMoney} from "../../../../utils/localization";
import {Order} from "../../../../interfaces/backend";
import {StyledDivider} from "../../../Cart/styleElements";
import {primaryColor} from "../../../../theme";

export interface OrderSummaryProps {
  order: Order,
}
export interface SummaryItemProps {
  name: string,
  value: number,
  style?: SxProps
}

const SummaryItem = (props: SummaryItemProps) => {
  const {name, value, style} = props;
  const summaryItemStyle = style?style:{}

  return (
      <Box display={'flex'} sx={summaryItemStyle}>
        <Box  fontSize={'2rem'}  width={'50%'}>{name}</Box>
        <Box width={'50%'} textAlign={'end'} pl={'20px'} letterSpacing={'0.05em'}
                                  fontSize={'2rem'}  justifyContent={'flex-end'}>{formatMoney(value)}</Box>
      </Box>
  )
}
const OrderSummary = (props: OrderSummaryProps) => {
  const {order} = props;
  const {t} = useTranslation('myAccount')

  return(
      <Box sx={{
          width: '100%',
          display: 'inline-block',
          marginTop: '10px',


      }}>
        <SummaryItem name={t("subtotal")} value={order.totalWithoutDiscount} />
        {order.totalDiscount && order.totalDiscount > 0 ? <SummaryItem name={t("discount")} value={-order.totalDiscount} style={{color: primaryColor.discountCheckoutPage}}/> : <></>}
        <SummaryItem name={t("shippingCost")} value={order.shippingMethod ? order.shippingMethod.price : 0}/>
        <SummaryItem name={t('Tax{{taxValue}}', {taxValue: order.tax,})} value={order.totalTax}/>
        <StyledDivider sx={{ mt: '30px', mb: '30px'}}/>
        <SummaryItem name={t("orderSum")} value={order.totalWithTaxAndShipping} style={{"fontSize":"2rem", fontWeight: "700"}}/>
      </Box>
  )
}

export default OrderSummary
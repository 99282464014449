import {Box, Tooltip} from "@mui/material"
import {defaultFonts} from "../../../theme";
import {StandardMachine} from "../../../interfaces/backend";
import {Link} from "react-router-dom";
import React from "react";
import {TakeFirst2DImage} from "../../../utils/productFunctions";
import {ListingBox, ListingBoxContent, ListingBoxWrapper, ListingTypography} from "./style";
import {Availability} from "../../../components/common/general/availability";
import {useLocation} from "react-router";
import PriceRendered from "../../../components/common/priceRendered";

interface StandardMachineItemProps {
  machine: StandardMachine,
}

const StandardMachineItem = (props: StandardMachineItemProps) => {
  const {machine} = props
  const machineProduct = machine.product;
  const Image2D = TakeFirst2DImage(machineProduct.image)
  const {pathname} = useLocation();

  return (
    <ListingBoxWrapper>
      <Link to={`/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
        <ListingBox imageUrl={Image2D} sx={{minHeight: '190px'}}/>
      </Link>

      <ListingBoxContent>
        <Link to={`/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
          <Tooltip title={machineProduct.name} placement="top-start">
            <ListingTypography>{machineProduct.name}</ListingTypography>
          </Tooltip>

          <Tooltip title={machineProduct.code} placement="top-start">
            <ListingTypography>{machineProduct.code}</ListingTypography>
          </Tooltip>
        </Link>
        <Availability inStock={machineProduct.inStock} isOrderable={machineProduct.isOrderable} containerSx={{
          height: {xs: 65, md: 60}
        }} textSx={{maxWidth: '70%', minWidth: 'fit-content', fontSize: defaultFonts}}/>


        <Box sx={{
          height: {xs: 65, md: 60},
          display: "flex",
          alignItems: "end",
          justifyContent: "center"
        }}>
          <PriceRendered productPrice={machineProduct.price} productDiscountPrice={machineProduct.discountPrice}/>
        </Box>

      </ListingBoxContent>
    </ListingBoxWrapper>
  )
}

export default (StandardMachineItem)
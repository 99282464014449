import * as React from "react";
import {Dispatch} from "react";
import {Box, Typography} from "@mui/material";

interface MobileQuantityProps {
  quantity: number,
  setQuantity: Dispatch<React.SetStateAction<number>>,
}

export const MobileQuantity = (props: MobileQuantityProps) => {
  const {quantity, setQuantity} = props
  return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box
            onClick={() => quantity > 1 && setQuantity(quantity-1)}
            sx={{height: '65%'}}
            component={"img"}
            alt={"arrow carousel left"}
            src={"/images/icons/mobileLeftArrow.svg"}
        />

        <Typography sx={{width: '45px', fontSize: '1.7rem',
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'center'}}>{quantity}</Typography>
          <Box
              onClick={() => setQuantity(quantity+1)}
              sx={{height: '65%'}}
              component={"img"}
              alt={"arrow carousel left"}
              src={"/images/icons/mobileRightArrow.svg"}
          />
      </Box>
  )
}

export default (MobileQuantity)

import { Box, BoxProps, styled } from "@mui/material";

interface TooltipIconProps extends BoxProps {
  iconSrc?: string;
}

const TooltipIcon = styled(({ iconSrc, ...otherProps }: TooltipIconProps) => (
  <Box
    {...otherProps}
    component={"img"}
    src={iconSrc || "/images/icons/infoIconCheckout.svg"}
    alt="info"
  />
))(({ theme }) => ({
  height: "100%",
  verticalAlign: "middle",
}));

export default TooltipIcon;

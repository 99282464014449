import {Grid, useMediaQuery} from "@mui/material";
import theme from "../theme";
import ResetPasswordArea from "../components/login/ResetPasswordArea";
import LoginHeader from "../components/login/header";
import Articles from "../components/login/articles";
import Footer from "../components/common/footer";
interface ResetPasswordProps {
    passwordAction: string;
  }
   

const ResetPassword = (props: ResetPasswordProps) => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const { passwordAction } = props;

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <LoginHeader/>
                    <ResetPasswordArea type={passwordAction}/>
                </Grid>
                {(!mobileView) ?
                    <Grid item xs={12} md={7}>
                        <Articles/>
                    </Grid>
                    : <></>
                }
            </Grid>
            <Footer/>
        </>
    )
}

export default (ResetPassword)

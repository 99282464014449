import {Box, Container, Grid} from "@mui/material";
import Logo from "../logo";
import {primaryColor} from "../../../../theme";
import * as React from "react";
import LeftIcons from "./leftIcons";
import ModalSearchBox from "./modalSearchBox";
import {useAppSelector} from "../../../../hook";
import {useHeaderImage} from "../../contexts/HeaderImageContext";
import AuthDynamicComponent from "../../../accessControl/AuthDynamicComponent";
import MainMenu from "./mainMenu";

export default function DesktopView() {
  const showHeaderImage = useHeaderImage();
  const backgroundUrl = useAppSelector(state => state.staticContent.backgroundUrl)
  const backgroundBlurred = useAppSelector(state => state.styleControls.backgroundBlurred)

  return (
    <>
      <Box id="bottomHeader" sx={{
        paddingY: '28px',
        backgroundColor: primaryColor.headerColor,
        zIndex: 3,
        position: 'relative'
      }}>
        <Container maxWidth={'2xl'}>
          <Grid container
                justifyContent='space-between'
          >
            <Grid item sm={2}>
              <Logo mobileView={false}/>
            </Grid>
            <Grid item sm={8} container
                  alignContent='stretch'
                  alignItems='center'
                  justifyContent='space-evenly'
                  columnSpacing={1}
                  rowSpacing={{sm: 1, md: 0}}
            >
              <AuthDynamicComponent
                children={<MainMenu/>}
              />
            </Grid>
            <Grid item
                  sm={2}
                  alignItems='center'
                  justifyContent='end'
                  display={"flex"}
            >
                <LeftIcons/>
                <ModalSearchBox/>
 
            </Grid>
          </Grid>
        </Container>
      </Box>
      {showHeaderImage &&
          <Box sx={{
            height: '155px',
            width: '100%',
            background: `url('${backgroundUrl}') no-repeat 0% 0% / 100%`,
            filter: backgroundBlurred ? 'blur(25px)' : 'unset',
            zIndex: 1
          }}/>
      }
    </>
  )
}

import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../../../../theme";
import { ProductDetailsPageContent } from "../../../../interfaces/backend";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { addCartItem } from "../../../../reducers/cart";
import { formatMoney } from "../../../../utils/localization";
import { Availability } from "../../../common/general/availability";
import { setContextMenuOpen } from "../../../../reducers/viewerReducer";
import AddToCartButton from "../../../common/AddToCartButton";
import ProductDetailsOverview from "../../../common/general/productDetailsOverview";

const MenuContent = () => {
  const { t } = useTranslation("productPage");
  const bigText = "1.6rem";
  const smallText = "1.33rem";
/*
  const productLinkStyle = {
    display: "flex",
    width: "100%",
    alignItems: "center",
  };*/

  const dispatch = useAppDispatch();
  const productDetails: ProductDetailsPageContent = useAppSelector(
    (state) => state.productDetails.productDetails
  );

  const handleContextMenuClose = () => {
    dispatch(setContextMenuOpen(false));
  };
  const handleAddItem = () => {
    dispatch(
      addCartItem({
        code: productDetails.code,
      })
    );
  };

  return (
    <Box
      sx={{
        background: primaryColor.machineViewerPopupBackground,
        color: primaryColor.machineViewerPopupTextColor,
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          height: "55.36px",
          boxShadow:
            "4.07095px 4.07095px 11.3986px rgba(0, 0, 0, 0.5), inset 4.88513px 4.88513px 8.14189px rgba(59, 59, 59, 0.5), inset -4.88513px -4.88513px 8.14189px rgba(20, 20, 20, 0.5)",
          padding: "15px 40px",
          borderRadius: "0.8px",
        }}
      >
        <Box sx={{ fontSize: bigText, cursor: "pointer" }}>
          {t("openGroup")}
        </Box>
      </Box>

      <Box
        sx={{
          height: "190px",
          boxShadow:
            "4.07px 4.07px 11.4px 0 rgba(0, 0, 0, 0.5), inset -4.89px -4.89px 8.14px 0 rgba(20, 20, 20, 0.5),inset 4.89px 4.89px 8.14px 0 rgba(59, 59, 59, 0.5)",
          "& > *": {
            paddingX: "40px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: bigText,
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: bigText,
              paddingTop: '5px'
            }}
          >
            {productDetails.name}
          </Typography>

          <IconButton
          className="clickable-element"
            sx={{
              backgroundColor: "transparent",
              width: "13.57px",
              marginRight: "-20px",
              color: primaryColor.machineViewerPopupCloseIconColor,
                paddingY: 0
            }}
            onClick={handleContextMenuClose}
          >
            <CloseIcon sx={{ width: "13.57px" }} />
          </IconButton>
        </Box>

        <Typography
          sx={{
            fontSize: smallText,
            paddingTop: 0,
          }}
        >
          {productDetails.code}
        </Typography>

        <ProductDetailsOverview 
        linkTo={productDetails.alias} 
        iconSx={{height: '19px', 
        marginRight: '15px'}} 
        iconText={t("goToProductDetails") as string}
        containerSx={{
          height: "35px",
          backgroundColor: primaryColor.machineViewerPopupGoToProductBackground,
          paddingX: "40px",
          }}/>

        <Box
          sx={{
            fontSize: smallText,
            display: "grid",
            gridTemplateColumns: "auto auto",
            justifyContent: "space-between",
            // alignContent: "center",
            // alignItems: "center",
            // justifyItems: "end",
            // height: "65px",
          }}
        >
          <Box sx={{ gridRow: "1" }}>
            <Availability
              inStock={productDetails.inStock}
              isOrderable={productDetails.isOrderable}
              iconSx={{ height: "15px" }}
              textSx={{ fontSize: smallText, marginLeft: "15px" }}
            />
          </Box>

          <Box sx={{ gridRow: "2", paddingLeft: "30px" }}>
            <Typography sx={{ fontSize: bigText }}>
                {productDetails.price ? formatMoney(productDetails.price) : t('priceOnInquiry')}
            </Typography>
          </Box>

          <Box
          className="clickable-element"
            sx={{
              // width: "45px",
              // height: "45px",
              gridRow: "1 / 3",
              marginRight: "-15px"
            }}
          >
            <AddToCartButton
              onClick={handleAddItem}
              isDisabled={!productDetails.isOrderable}
              buttonStyles={{minWidth: "unset"}}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuContent;

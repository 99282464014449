import Tab from "./Tab";
import {Box, Typography} from "@mui/material";
import React, {useEffect} from "react";
import Paper from "@mui/material/Paper";
import {useAppDispatch} from "../../../hook";
import {TabData} from "../../../pages/Account/AccountPage";
import {logout} from "../../../reducers/auth";
import {setOpenedDrawerID} from "../../../reducers/static";
import {setMyDataOpened} from "../../../reducers/styleControls";
import {primaryColor} from "../../../theme";
import {Link} from 'react-router-dom';
import {useLocation} from "react-router";

export interface AllTabsProps {
  allTabs: Array<Array<TabData>>,
  selectedItem: string,
  setSelectedName: React.Dispatch<React.SetStateAction<string | undefined>>,
  selectedName: string | undefined,
  isMobile?: boolean,
  useParameter?: boolean,
  topTitle?: string
}

const AllTabs = (props: AllTabsProps) => {
  const {allTabs, selectedItem, setSelectedName, selectedName, isMobile, useParameter, topTitle} = props
  const dispatch = useAppDispatch()
  const location = useLocation()

  // updating selectedName after the rendering
  useEffect(() => {
    if (isMobile) {
      const tab = allTabs.find((tabsList) =>
          tabsList.some((tab) => tab.item === selectedItem)
      );
      // updating selectedName only  when it is needed

      if (tab && tab[0].text !== selectedName) {
        setSelectedName(tab[0].text);
      }
    }
  }, [allTabs, isMobile, selectedItem, selectedName, setSelectedName]);

  return (
      <>
        {isMobile && topTitle && <Box sx={{
          color: primaryColor.myAccountTabText,
          height: '70px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography sx={{fontSize: '2.6rem', fontWeight: '600'}}>{topTitle}</Typography>
        </Box>}
        {allTabs.map((tabsList, index) => {
              return (
                  <Box key={index} sx={{marginBottom: isMobile ? '17px' : '70px'}}>
                    <Paper sx={{
                      backgroundColor: primaryColor.background,
                      borderRadius: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      '&.MuiPaper-root': {
                        boxShadow: 'none',
                      },
                    }}>
                      {tabsList.map((tab, i) => {
                            const params: TabData = {...tab, selected: selectedItem === tab.item, isLast: tabsList.length === i+1}

                            if (tab.item !== 'exit')
                              return (
                      /*            <Box key={i}
                                      component="a"
                                      href={`/my-account/${tab.item}`}
                                      onClick={() => isMobile && dispatch(setMyDataOpened(false)) && dispatch(setOpenedDrawerID(""))}>
                                    <Tab {...params} />location.pathname + '?tab=' + tab.item
                                  </Box>*/
                                  /*              <Box key={i} onClick={()=>    navigate({
                                        pathname: `${location.pathname}`,
                                        search: `?tab=${tab.item}`,
                                      })}>*/
                                  useParameter?
                                        <Link key={i} to={ location.pathname + '?tab=' + tab.item
                                        } >
                                          <Tab {...params} />
                                        </Link>
                                        :<Link key={i} to={`/my-account/${tab.item}`} onClick={()=> isMobile &&  dispatch(setMyDataOpened(false)) && dispatch(setOpenedDrawerID(""))}>
                                          <Tab {...params} />
                                        </Link>
                              )
                            else
                              return (
                                  <Box key={i} onClick={() => {
                                    dispatch(logout())
                                    dispatch(setOpenedDrawerID(""))
                                  }
                                  }>
                                    <Tab {...params} />
                                  </Box>
                              )
                          }
                      )}
                    </Paper>
                  </Box>
              )
            }
        )}
      </>
  )
}
export default (AllTabs)

import {Box, Container, Tab, Tabs} from "@mui/material";
import {primaryColor} from "../../theme";
import {plainDataExists} from "../../utils/productFunctions";
import Product from "./Product";
import Documents from "./Documents";
import Description from "./Description";
import RelatedItemsSlider from "../../components/common/relatedItemsSlider";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ProductDetailsPageContent} from "../../interfaces/backend";
import ProductCard from "../../components/common/relatedItemsSlider/productSliderCard";
import qs from "qs";
import {useLocation, useNavigate} from "react-router";

export interface DesktopProductDetailsProps {
  productDetails: ProductDetailsPageContent,

}
const DesktopProductDetails = (props: DesktopProductDetailsProps) => {
  const {t} = useTranslation(['productPage']);
  const {productDetails} = props
  const location = useLocation()
  const [tabValue, setTabValue] = useState("product")
  const search = qs.parse(location.search.replace('?', ''))
  const navigate = useNavigate()

  const tabChange = (alias: string, tab: string) => {

    navigate({
      pathname: `/product/${alias}`,
      search: `?tab=${tab}`,
    });
    setTabValue(tab)
    //window.location.reload();

  }
  useEffect(() => {
    if(!!search.tab)
    {
      setTabValue(String(search.tab))
    }
  }, [])
  const renderProductItem = (item: ProductDetailsPageContent) => {
    return <ProductCard product={item} />;
  };
  return (
      <>
        {productDetails.code && <Box sx={{
          paddingTop: {xs: 2, md: 5},
          paddingBottom: 2,
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          <Tabs
              value={tabValue}
              onChange={(event: SyntheticEvent, tabValue: string) => tabChange(productDetails.alias, tabValue)}
          >
            <Tab value={'product'} label={t("product")}/>
            {productDetails.document.length > 0 &&
                <Tab value={'documents'} label={t("documents")}/>}
            {productDetails.details?.longDescription && plainDataExists(productDetails.details.longDescription) &&
                <Tab value={'description'} label={t("description")}/>}

          </Tabs>
          <Box sx={{
            backgroundColor: primaryColor.backgroundOverlay,
            width: '100%'
          }}>
            <Container maxWidth={'lg'}>
              <Box sx={{
                color: primaryColor.textHeader,
                margin: '60px 0',
                //padding: '0px 10% 0px 10%'
              }}>
                {/* <Box sx={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  margin: '40px 0px',
                  fontSize: '3rem',
                  fontWeight: '300'
                }}>
                  {productDetails.name}
                </Box> */}
                {tabValue === 'product' && <Product product={productDetails}/>}
                {tabValue === 'documents' && <Documents documents={productDetails.document}/>}
                {tabValue === 'description' &&
                    <Description description={productDetails.details.longDescription}/>}
              </Box>
            </Container>
          </Box>
          {productDetails.relatedProducts.length > 0 && (
              <RelatedItemsSlider
                  relatedItems={productDetails.relatedProducts}
                  itemsTitle={t('relatedProducts')}
                  renderItem={renderProductItem}
              />
          )}
        </Box>}
      </>
  )
}

export default (DesktopProductDetails)

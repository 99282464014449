import {primaryColor} from "../../../theme";


const tableStyles = {
    "& tbody > tr > td": {
        borderBottom: primaryColor.tableBottomBorderLineColor,

        "&:nth-of-type(1), &:nth-of-type(5)": {
            textAlign: "center",
        },
    },

    "& thead > tr > th": {
        "&:nth-of-type(1)": {
            justifyContent: "start",
        },
    },

    "& tbody > tr > td, thead > tr > th": {
        "&:last-of-type": {
            borderBottom: "none",
        },
        "&:nth-of-type(2)": {
            maxWidth: "450px",
        },
        "&:nth-of-type(3), &:nth-of-type(4)": {
            textAlign: "end",
            maxWidth: "200px",
        },
        "&:nth-of-type(5)": {
            padding: "0 55px",
            textAlign: "center",
        },

        "&:nth-of-type(1)": {
            maxWidth: "50px",
        },
    },
};

export default tableStyles;
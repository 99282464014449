import {CartItem, DiscountValueType, Image, SettingElement} from "../interfaces/backend";
import {useAppSelector} from "../hook";
import {NavigateFunction} from 'react-router-dom';

export function TakeFirst2DImage(imageList?: Array<Image>) {
  const noImageUrl: string = TakeEmptyImage()//useAppSelector(state => state.staticContent.noImageUrl)
  return imageList && imageList.length ? imageList[0].url : noImageUrl
}

export function TakeEmptyImage(): string {
  return useAppSelector(state => state.staticContent.noImageUrl);
}

export const checkEmptyTag = (description: string): boolean => {
  const plainText = cleanHTMLTags(description)
  return plainText.length > 0
}

export const cleanHTMLTags = (htmlString: string): string => {
  return htmlString.replace(/<\/?[^>]+(>|$)/g, "").trim();
};

export const plainDataExists = (description: string): boolean => {
  const excludeTags = ["img", "embed", "iframe", "video", "source", "track", "audio"]
  let parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  let result = excludeTags.find(et => doc.getElementsByTagName(et).length > 0)
  if (result) {
    return true
  }
  return checkEmptyTag(description)
}

export const countTotalItems = (items:Array<CartItem>) => {
  const allItems = items.reduce((allItems, current) => allItems + current.quantity, 0)
  return allItems
}

export default function getShowDiscount(settings: SettingElement[]) {
  if (settings && settings.length) {
    const showDiscount = settings.find(x => x.name === 'show_discount');
    if (showDiscount && showDiscount.value === '1') return true
  } else return false
}

export const fileNameFromUrl = (url: string): string => {
  const parts = url.split('/');
  return parts[parts.length - 1];
}

export const isGoBackActive = (locationKey: string) => {
  return locationKey !== 'default';
}

export const handleNavBack = (navigate: NavigateFunction) => {
  return navigate(-1);
}

export const calculateCartPriceDetails = (item: CartItem, settings: SettingElement[]) => {
  const showDiscountPerItem = settings.find(x => x.name === 'show_discount_per_item' && parseInt(x.value) === 1);

  let lastPrice = item.discountPrice || item.price;
  let prices = [item.price];
  if (item.discountPrice) prices.push(item.discountPrice);

  item.discounts.forEach(discount => {
    discount.discount.valueType === DiscountValueType.Percentage && showDiscountPerItem && prices.push(lastPrice -= discount.discountAmountPerItem);
  });

  return {prices, lastPrice}
}

 
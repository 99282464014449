import React from "react";
import { Box, SxProps, Tooltip } from "@mui/material";

interface AddToCartIconProps {
  iconSx?: SxProps;
  onClick: () => void;
  toolTipTitle: string | null

}

const AddToCartIcon = (props: AddToCartIconProps) => {
  const { iconSx, onClick, toolTipTitle } = props;

  return (
    <Tooltip title={toolTipTitle}>
      <Box
        onClick={onClick}
        component={"img"}
        src={ "/images/icons/WhiteCart-icon.svg"}
        sx={{ ...iconSx }}
      />
    </Tooltip>
  );
};

export default AddToCartIcon;
import {createSlice} from "@reduxjs/toolkit";


export const searchSlice = createSlice({
        name: "search",
        initialState: {
            searchString: '',
            searchResult: {},
            categories: {}
        },
        reducers:
            {
                setSearchString: (state, {payload}) => {
                    state.searchString = payload
                },
                setSearchResult: (state, {payload}) => {
                    state.searchResult = payload
                },
              setCategory: (state, {payload}) => {
                  state.categories = payload
                },
            }
    }
)

export const {
    setSearchString,
    setSearchResult,
} = searchSlice.actions

export default searchSlice.reducer

import Page from "./page";
import {Box, Grid} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import {primaryColor} from "../theme";
import QuickSearch from "./SpareParts/QuickSearch";
import MachineSpareParts from "./SpareParts/MachineSpareParts";
import {BreadCrumbsObject} from "../interfaces/general";
import {useTranslation} from "react-i18next";

export const style = {
  textStyle: {
    fontWeight: 300,
    fontSize: '2rem',
    lineHeight: '24px',
    letterSpacing: '0.05em'
  },

}

const SparePartsPage = () => {
  const {t} = useTranslation('spareParts')
  const commonPaperStyles ={
 
    backgroundColor: primaryColor.sparePartsBackground,
    borderRadius: '0px',
    minHeight: '100%',
    padding: {
      xs: '60px 45px',
      md: '60px 20px',
      lg: '75px 35px',
      xl: '75px',

    }   
  }
  const makeBread = (): Array<BreadCrumbsObject> => {
    return [
      {name: t('common:home'), link: '/'},
      {name: t('common:spareParts'), link: '/spare-parts'},
    ]
  };
 
  return (
      <Page breadCrumbs={makeBread()}>
        <Box sx={{
          fontSize: {
            xs: '2.6rem',
            sm: '5rem',
            md: '7rem'
          },
          color: primaryColor.pageTitle,
          textAlign: 'center',
          marginTop: {
            xs: '15px',
            md: '-5px'
          } ,
          fontWeight: '700'
        }}>
          {t('common:spareParts')}
        </Box>

        
        
      <Box sx={{margin: '40px 0px 73px 0px'}}>
        <Grid container justifyContent="center" spacing={3}>
        
            <Grid key={1} xs={12} md={6} item >
              <Paper sx={commonPaperStyles}>
                <MachineSpareParts/>
              </Paper>
            </Grid>

            <Grid key={2} xs={12} md={6} item>
              <Paper sx={commonPaperStyles}>
                <QuickSearch/>
              </Paper>
            </Grid>

          </Grid>
        </Box>
     
      </Page>
  )
}

export default (SparePartsPage)
import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import {
  getSchemas,
  setSparePartSchemaOpen,
} from "../../../../reducers/sparePart";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { Schema } from "../../../../interfaces/backend";
import SchemaSlider from "./schemaSlider";
import { primaryColor } from "../../../../theme";

interface SparePartSchemaProps {
  schemas: Schema[];
}

const SparePartSchema = (props: SparePartSchemaProps) => {
  const { schemas } = props;
  const dispatch = useAppDispatch();

  const isModalOpen = useAppSelector(
    (state) => state.sparePart.isSparePartSchemaOpen
  );
  const sparePartSchema = useAppSelector((state) => state.sparePart.schemas);

  const handleClose = () => {
    dispatch(setSparePartSchemaOpen(false));
  };

  useEffect(() => {
    dispatch(getSchemas({ schemas }));
  }, [dispatch, schemas]);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    
    >
     <Box
        sx={{
          width: {
            xs: "95vw",
            md: "90vw",
            lg: '80vw',
            xl: '60vw'
          },

          backgroundColor: primaryColor.backgroundSchemaModalOverlay,
          boxShadow: 24,
          height: "auto",
          position: "relative",
     
        }}
      >
        <Box
          component="img"
          src="/images/clearIcon.svg"
          onClick={handleClose}
          alt="Close"
          sx={{
            position: "absolute",
            zIndex: 3,
            top: {xs: -25, md: -20},
            right: {xs: 10, md: -40},
            height: {xs: "1.5rem", md: "1.8rem", lg: "2.5rem"},
            cursor: "pointer",
            opacity: 0.75,
            "&:hover": {
              height: "1.95rem",
              opacity: 1,
            },
          }}
        />
        {sparePartSchema.length && <SchemaSlider items={sparePartSchema} />}
      </Box>
    </Modal>
  );
};
export default SparePartSchema;

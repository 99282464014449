// here we provide a list of exceptional language names which can have multiple countries
import {DelimiterObject, LocalObject} from "../interfaces/general";

export const countriesException = [{name: 'en', value: 'GB'}]

export const locales = () => {
    const languagesArray = localStorage.languages ? localStorage.languages.split(',') : '';
    return languagesArray ? generateLocales(languagesArray) : {};
}

const generateLocales = (localesList: Array<string>) => {
    let result: LocalObject = {}
    localesList.forEach(l => {
        const ex = countriesException.find((e) => e.name === l)
        const nameValue = ex ? ex.value : l.toUpperCase()
        result[l] = {
            delimiters: delimitersForLocation(nameValue),
            name: nameValue,
            label: l.toUpperCase()
        };
    })
    return (result)
}

const delimitersForLocation = (locale: string): DelimiterObject => {
    //here we would get delimiters of thousands and decimal using 1111.1 as a base value
    const parts = new Intl.NumberFormat(locale).formatToParts(1111.1);
    const group = parts.find(part => part.type === 'group')?.value;
    const decimal = parts.find(part => part.type === 'decimal')?.value;
    return {'thousands': group as string, 'decimal': decimal as string}
}

export const lngList = (): Array<string> => Object.keys(locales());

export const selectLng = (): string => {
    const lng = localStorage.getItem('i18nextLng') as string;
    if (lngList().indexOf(lng) > -1) {
        return lng;
    }

    //Save temporary flag that a fallback language was loaded
    sessionStorage.setItem("fallback_lng_loaded", "1");
    return 'de';
};

export const getLocaleTimeString = (date: string) =>
    new Date(Date.parse(date)).toLocaleTimeString(selectLng());

export const getLocaleDateString = (date: string) =>
    new Date(Date.parse(date)).toLocaleDateString(selectLng());

export const getLocaleDateStringLong = (date: string) =>
    new Date(Date.parse(date)).toLocaleDateString(selectLng(), {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

export const capitalizeFirstLetter = (s: string) =>
    s.charAt(0).toUpperCase() + s.slice(1);

export const parseLocaleFloat = (x: string): number => {
    const localesList = locales();
    const options = localesList[selectLng()].delimiters;

    // We can get 2 different spaces (with codes 32 and 160)
    // Space with code 160 (&nbsp;) is coming when field is autofilled
    const res = x
        .split(String.fromCharCode(160))
        .join(' ')
        .split(options.thousands)
        .join('');

    return parseFloat(res.split(options.decimal).join('.'));
};

export const formatMoney = (x: number, code?: string): string => {
    let cashSymbol = code;
    if (/*cashSymbol === null || */cashSymbol === undefined)
        cashSymbol = sessionStorage.getItem('currency') as string
    if (!cashSymbol) cashSymbol = 'EUR';

    try {
        return (
            new Intl.NumberFormat(
                `${selectLng()}`,
                {
                    style: 'currency',
                    currency: cashSymbol,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
                .format(x)
        )
    } catch (e) {
        return (
            new Intl.NumberFormat(
                `${selectLng()}`,
                {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
                .format(x)
        )
    }
};
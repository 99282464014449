import { CartItem, AddToCartItem, Order } from "../../../interfaces/backend";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hook";


export const getStatus = (statusCode: string): number => {
  if (statusCode === "pending") return 1;
  else if (statusCode === "inprogress") return 25;
  else if (statusCode === "shipped") return 75;
  else if (statusCode === "ready") return 75;
  else if (statusCode === "completed") return 100;
  else return 0;
};

export const reOrder = (items: Array<CartItem>) => {
  let productsToAdd: Array<AddToCartItem | undefined> = items
    .filter((item) => item.isOrderable)
    .map((item) => {
      return {
        product: item.code,
        quantity: item.quantity,
      };
    });
  return productsToAdd.filter(
    (product) => product !== undefined
  ) as AddToCartItem[];
};

export interface OrderItemProp {
  order: Order;
  setSelectedOrder?: Function;
  cancelOrder?: Function;
}


export const useMyOrderCommon = (props: OrderItemProp) => {
  const { order } = props;
  const CANCELLABLE_ORDERS = ["pending"];
  const { firstName, lastName } = order.user;
  const { t } = useTranslation("myAccount");
  const dispatch = useAppDispatch();
  const isConfirmationOpen = useAppSelector(
    (state) => state.myAccount.isConfirmationOpen
  );

  const navigate = useNavigate();

  const style = {
    element: {
      marginTop: "10px",
      display: "flex",
    },
    value: {
      fontWeight: 300,
      marginLeft: "6px",
    },
    description: {
      fontWeight: 700,
    },
  };

  return {
    t,
    isConfirmationOpen,
    dispatch,
    navigate,
    CANCELLABLE_ORDERS,
    style,
    firstName,
    lastName,
  };
};


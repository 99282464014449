import React from 'react';
import ButtonWithIcon, { BaseProps } from '../ButtonWithIcon';

const AddToCartButton = (props: BaseProps) => {
    const { buttonContent, buttonStyles, onClick, isDisabled, variant="contained" } = props;

    return (
        <ButtonWithIcon
            icon={'/images/cartIcon.svg'}
            baseProps={{
                buttonContent,
                buttonStyles,
                onClick,
                isDisabled,
                variant 
            }}
        />
    );
};

export default React.memo(AddToCartButton);
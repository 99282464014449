import {Box} from "@mui/material";
const MachineSensorData = () => {
  return (
      <Box>
        MachineSensorData
      </Box>
  )
}

export default MachineSensorData;

import {Document} from "../../../../interfaces/backend";
import {Box} from "@mui/material";
import * as React from "react";
import {getDocument} from "../../../../reducers/productdetails";
import {primaryColor} from "../../../../theme";
import {useAppDispatch} from "../../../../hook";

const MobileDocument = (props: { doc: Document }) => {
  const {doc} = props
  const dispatch = useAppDispatch()

  return (
        <Box
            onClick={() =>
                dispatch(getDocument({url: doc.url}))
            }
            sx={{
              cursor: 'pointer',
              marginBottom: "15px",
              float: "left",
              clear: "both"
            }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center"
          }}>
            <Box
                component={'img'}
                src={'/images/icons/icon-pdf-document.svg'}
                sx={{
                  marginRight: '11px',
                  maxHeight: '34px'
                }}
            />
            <Box
                sx={{
                  color: primaryColor.textHeader,
                }}
            >
              {doc.title}
            </Box>
          </Box>
        </Box>
  )
}
export const DocumentsData = (props: {documents: Document[] }) => {
  const {documents} = props
  return(
      <Box sx={{justifyContent: 'center', display: 'inline-block'}}>
        {documents.map(
            (doc, i)  => <MobileDocument key={i} doc={doc} />
        )}
      </Box>
  )
}
export default (DocumentsData)

import {Box, Tooltip, Typography} from "@mui/material"
import {defaultFonts, highlightedFonts, primaryColor} from "../../../theme";
import {ProductSearchResult} from "../../../interfaces/backend";
import {Link} from "react-router-dom";
import React from "react";
import {Availability} from "../../../components/common/general/availability";
import {formatMoney} from "../../../utils/localization";
import {useTranslation} from "react-i18next";
import {TakeEmptyImage} from "../../../utils/productFunctions";
import { ListingBoxWrapper, ListingBoxContent, ListingBox, ListingTypography } from "./style";


   
interface CategoryItemProps {
  category: ProductSearchResult,
}

 
 
const link = (alias: string) => `/product/${alias}`

const CategoryItem = (props: CategoryItemProps) => {
  const {code,image,alias,name,inStock,price, isOrderable, discountPrice} = props.category
  const {t} = useTranslation('productPage')
  const emptyImage = TakeEmptyImage();
  const Image2D = image || emptyImage


  return (
      <ListingBoxWrapper>
        <Link to={link(alias)}>
            <ListingBox imageUrl={Image2D} sx={{minHeight: '190px'}}/>
        </Link>

        <ListingBoxContent>
          <Link to={link(alias)}>
              <Tooltip title={name} placement="top-start">
                <ListingTypography>{name}</ListingTypography>
              </Tooltip>
              <Tooltip title={code} placement="top-start">
                <ListingTypography>{code}</ListingTypography>
              </Tooltip>
          </Link>
        
            <Availability inStock={inStock} isOrderable={isOrderable} containerSx={{ 
              height: {xs: 65, md: 60}}} textSx={{maxWidth: '70%', minWidth: 'fit-content', fontSize: defaultFonts}}/>
          <Box sx={{
          height: {xs: 65, md: 60}, 
          display: "flex", alignItems: "end", justifyContent: "center"}}>
            {discountPrice ?
                <Box sx={{/*marginTop: '9px',*/ width: 'fit-content'}}>
                  <Typography
                      variant={'h20'}
                      sx={{
                        textDecoration: 'line-through',
                        textDecorationThickness: '1px',
                        color: primaryColor.noDiscountPrice,
                        fontSize: defaultFonts,
                        fontWeight: 300,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        opacity: '0.3'
                      }}
                  >
                    {formatMoney(price)}
                  </Typography>
                  <Box sx={{
                    color: primaryColor.discountCheckoutPage,
                  fontSize: highlightedFonts,
                  fontWeight: 300}}>
                    {formatMoney(discountPrice)}
                  </Box>
                </Box>
                :
            <Typography sx={{
              fontSize: highlightedFonts,
              fontWeight: 300,
            }}>{price ? formatMoney(price) : t('priceOnInquiry')}</Typography>}
          </Box>
  
        </ListingBoxContent>
      </ListingBoxWrapper>
  )
}

export default (CategoryItem)

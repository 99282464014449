import BlogContent from "./BlogContent";
import { Article } from "../../interfaces/backend";
import DesktopComponent from "../../components/common/ContentWithAnimation/DesktopComponent";

interface DesktopBlogProps {
  items: Array<Article>;
}

const DesktopBlog = (props: DesktopBlogProps) => {
  return (
    <DesktopComponent<Article>
      items={props.items}
      contentComponent={BlogContent}
    />
  );
};

export default DesktopBlog;

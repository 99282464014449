import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ViewerSliceState {
  isMenuOpen: boolean;
}

const initialState:  ViewerSliceState = {
  isMenuOpen: false,
};

const viewerReducerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setContextMenuOpen: (state, action: PayloadAction<boolean>)=>{state.isMenuOpen = action.payload
    },
  },
});


export const { 
  setContextMenuOpen
} = viewerReducerSlice.actions;

export default (viewerReducerSlice.reducer);
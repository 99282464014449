import {Box, FormControl, styled, SxProps, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import Button from "@mui/material/Button";
import React, {FormEvent, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hook";
import Terms from "../term/Terms";
import {openErrorSnack, openRegisterForm} from "../../reducers/start";
import {registerUser} from "../../reducers/auth";
import {isEmailValid} from "../../utils/validation";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import CustomAutocomplete from "../common/customAutoComplete";
import {Country, Title} from "../../interfaces/backend";

interface FieldProps {
    type?: string,
    handleChange: (value: string) => void,
    label?: string | null,
    required?: boolean,
    style?: SxProps
}

const StyledBox = styled((props: FieldProps) => (
    <Box
        mt={2}
        width={'95%'}
        sx={props.style}
    >
        <StyledTextField handleChange={props.handleChange} label={props.label} required={props.required}/>
    </Box>
))(({theme}) => ({}))

export const StyledTextField = styled((props: FieldProps) => (
    <TextField
        required={props.required}
        label={props.label}
        type={props.type}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.handleChange(event.target.value)
        }
    />
))(({theme}) => ({}))

export default function RegisterForm(props: { onCancelClick: () => void }) {
    const countries = useAppSelector(state => state.metadata.countries)
    const titles = useAppSelector(state => state.metadata.titles)
    const reCaptchaSiteKey = useAppSelector(state => state.staticContent.reCaptchaSiteKey)
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const dispatch = useAppDispatch()

    const {t} = useTranslation(['startPage', 'Validation Messages'])

    const [title, setTitle] = useState<Title|null>(null)
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [company, setCompany] = useState<string>('')

    const [extraInfo, setExtraInfo] = useState<string>('')

    const [street, setStreet] = useState<string>('')
    const [houseNumber, setHouseNumber] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')

    const [city, setCity] = useState<string>('')
    const [country, setCountry] = useState<Country | null>(null)

    const [email, setEmail] = useState<string>('')
    const [customerNumber, setCustomerNumber] = useState<string>('')
    const [captchaToken, setCaptchaToken] = useState<string>('')
    const [canSubmitForm, setCanSubmitForm] = useState<boolean>(false)

    const generalWidth = '95%'
    useEffect(() => {
        if (canSubmitForm) {
            register()
            setCanSubmitForm(false)
        }
    }, [canSubmitForm])

    const {executeRecaptcha} = useGoogleReCaptcha()

    const handleVerifyRecaptcha = async () => {
        if (!executeRecaptcha) {
            return;
        }

        let iterations = 0;
        let waitCaptcha = setInterval(async () => {
            iterations++;
            let token;
            try {
                token = await executeRecaptcha()
                setCaptchaToken(token)
                if (token) {
                    setCanSubmitForm(true)
                    clearInterval(waitCaptcha);
                } else {
                    throw new Error('ReCaptcha token fail')
                }
            } catch (e) {
                console.error(e);
                if (iterations > 10) {
                    clearInterval(waitCaptcha);
                    alert('Can not access reCaptcha source site or Invalid site key');
                }
            }

        }, 1000);
    };

    const register = () => {
        const data = {
            user: {
                title: title?.code,
                firstName: firstName,
                lastName: lastName,
                email: email
            },
            billingAddress: {
                streetName: street,
                streetNumber: houseNumber,
                postalCode: postalCode,
                city: city,
                country: country?.code
            },
            companyName: company,
            code: customerNumber,
            additional: extraInfo,
            captchaToken: captchaToken
        };
        dispatch(registerUser({data: data}))
    }

    const validateForm = () => {
        let message = '';
        if (!title) {
            message = t('Validation Messages:Salutation is Required!')
        } else if (!firstName) {
            message = t('Validation Messages:First Name is Required!')
        } else if (!lastName) {
            message = t('Validation Messages:Last Name is Required!');
        } else if (!company) {
            message = t('Validation Messages:Company Name is Required!');
        } else if (!street) {
            message = t('Validation Messages:Street Name is Required!');
        } else if (!postalCode) {
            message = t('Validation Messages:Postal Code is Required!');
        } else if (!city) {
            message = t('Validation Messages:City is Required!');
        } else if (!country) {
            message = t('Validation Messages:Country is Required!');
        } else if (!email) {
            message = t('Validation Messages:Email is Required!');
        } else if (!isEmailValid(email)) {
            message = t('Validation Messages:Wrong email format!');
        } else {
            return true;
        }
        dispatch(openErrorSnack({message}))
        return false
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            if (reCaptchaSiteKey) {
                handleVerifyRecaptcha()
            } else {
                setCanSubmitForm(true)
            }
        }
    }

    return (
        <Box mt={'30px'}
             sx={{
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
             }}
        >
            <FormControl
                sx={{
                    width: '70%',
                }}
            >
                <form onSubmit={handleSubmit} noValidate>
                    <Typography
                        sx={{
                            width: '100%',
                            fontSize: '1.8rem',
                            mb: '25px',
                            color: primaryColor.textTopHeader
                        }}
                    >
                        {t('register')}
                    </Typography>

                    <Box
                        sx={{
                            maxHeight: !mobileView ? '500px' : 'unset',
                            overflow: !mobileView ? 'scroll' : 'unset',
                        }}
                    >
                        <Box sx={{
                            width: '95%'
                        }}>

                        <CustomAutocomplete
                            options={titles}
                            handle={(title: Title | null) => setTitle(title)}
                            label={t('title')}
                            required={true}
                            value={title}
                        />
                        </Box>

                        <StyledBox
                            label={t('firstName')}
                            handleChange={(value) => setFirstName(value)}
                            required={true}
                        />
                        <StyledBox
                            label={t('lastName')}
                            handleChange={(value) => setLastName(value)}
                            required={true}
                        />
                        <StyledBox
                            label={t('company')}
                            handleChange={(value) => setCompany(value)}
                            required={true}
                        />
                        <StyledBox
                            label={t('additional')}
                            handleChange={(value) => setExtraInfo(value)}
                            required={false}
                        />
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            mt={0} mb={0}
                            width={generalWidth}
                        >
                            <StyledBox
                                label={t('street')}
                                handleChange={(value) => setStreet(value)}
                                required={true}
                                style={{
                                    marginRight: '20px',
                                    width: '70%'
                                }}
                            />
                            <StyledBox
                                label={t('no')}
                                handleChange={(value) => setHouseNumber(value)}
                                style={{
                                    width: '30%'
                                }}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            mt={0} mb={0}
                            width={generalWidth}
                        >
                            <StyledBox
                                label={t('postalCode')}
                                handleChange={(value) => setPostalCode(value)}
                                required={true}
                                style={{
                                    marginRight: '20px',
                                    width: '40%'
                                }}
                            />
                            <StyledBox
                                label={t('city')}
                                handleChange={(value) => setCity(value)}
                                required={true}
                                style={{
                                    width: '60%'
                                }}
                            />
                        </Box>
                        <Box sx={{
                            width: '95%'
                        }}>

                        <CustomAutocomplete
                            options={countries}
                            handle={(country: Country | null) => setCountry(country)}
                            label={t('country')}
                            required={true}
                            value={country}
                        />
                        </Box>

                        <StyledBox
                            label={t('email')}
                            handleChange={(value) => setEmail(value)}
                            required={true}
                        />
                        <StyledBox
                            label={t('customerNumber')}
                            handleChange={(value) => setCustomerNumber(value)}
                            required={false}
                        />
                        <Box mt={2} mb={'25px'} width={generalWidth}>
                            <Terms category="registration"/>
                        </Box>
                    </Box>
                    <Box mt={2}
                         mb={1}
                         width={generalWidth}
                    >
                        <Typography
                            color={primaryColor.iconColor}
                        >
                            {t('pleaseFill')}
                        </Typography>
                    </Box>
                    <Button
                        type={'submit'}
                        variant="contained"
                        sx={{
                            mb: 1,
                            // fontSize: '1.6rem',
                            width: generalWidth,
                        }}
                    >
                        {t('Register')}
                    </Button>
                    <Box
                        width={generalWidth}
                        textAlign='start'
                    >
                        <Typography
                            onClick={() => {
                                dispatch(openRegisterForm(false))
                                props.onCancelClick()
                            }}
                            sx={{
                                cursor: 'pointer',
                                color: primaryColor.linkColor,
                                '&:hover': {
                                    color: primaryColor.badgeColor,
                                },
                                mb: '10px'
                            }}
                        >
                            {t('Cancel')}
                        </Typography>
                    </Box>
                </form>
            </FormControl>
        </Box>
    )
}


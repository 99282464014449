import {useAppSelector} from "../../hook";
import {SettingElement} from "../../interfaces/backend";
import Favicon from "react-favicon";
import React from "react";

const FaviconComp = () => {
    const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)
    const favicon: SettingElement = settings.find((x) => x.name === 'favicon_url') as SettingElement;

    return (
        <>
            {favicon?.value && <Favicon url={favicon.value}></Favicon>}
        </>
    )
}

export default React.memo(FaviconComp);
import {Box, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {formatMoney, getLocaleDateString} from "../../../../utils/localization";
import OrderStatusBar from "./OrderStatusBar";
import {defaultFonts, primaryColor} from "../../../../theme";
import * as React from "react";
import {getSvg} from "../../../../components/common/Tabs/TabImages";
import ConfirmationDialog, { ConfirmationContent } from "../../../../components/common/Dialogs/ConfirmationDialog";
import { OrderItemProp, getStatus, useMyOrderCommon } from "../../../../components/myData/MyOrders/orderUtils";
import { setConfirmationOpen } from "../../../../reducers/myAccount";
import {countTotalItems} from "../../../../utils/productFunctions";


interface ItemProp {
  length: number;
}


export const ItemsCount = (prop: ItemProp) => {
  const { t } = useTranslation("myAccount");

  return (
    <Box  sx={{
      display: "flex",
      justifyContent: {
        lg: 'flex-end',
        xs: 'flex-start'
      }
    }}
    >
      {getSvg("my-orders")}{" "}
      <Box
        pl={"20px"}
        fontWeight={300}
        letterSpacing={"0.05em"}
        fontSize={defaultFonts}
      >
        {prop.length} {t("items")}
      </Box>
    </Box>
  );
};

const MyOrderItem = (props: OrderItemProp) => {
  const { order, setSelectedOrder, cancelOrder } = props;

  const { t, isConfirmationOpen, dispatch, navigate, CANCELLABLE_ORDERS, style, firstName, lastName } = useMyOrderCommon(props);

  return (
    <Box color={primaryColor.accountTextColor} fontWeight={700} p={'26px 10%'} mb={'12px'} bgcolor={primaryColor.ordersListBackgroundColor}>
      {isConfirmationOpen && (
        <ConfirmationDialog
          onConfirm={() => {
            !!cancelOrder && cancelOrder(order.orderId);
            dispatch(setConfirmationOpen(false));
          }}
          handleCancel={() => {
            dispatch(setConfirmationOpen(false));
          }}
          modalContent={<ConfirmationContent dataContent={t('sureToCancelOrder')} />}
        />
      )}
      <Typography>{t(order.status.code)}</Typography>
      <Box display={'flex'} mt={'25px'}>
        <Box display={'flex'} width={!setSelectedOrder ? '50%' : '100%'}>{t('orderDate')}:
          <Typography sx={style.value}>
            {getLocaleDateString(order.createdAt)}
          </Typography>
        </Box>

        {!setSelectedOrder && <Typography width={'50%'}><ItemsCount length={countTotalItems(order.items)} /></Typography>}

        {cancelOrder && CANCELLABLE_ORDERS.findIndex(code => code === order.status.code) !== -1 && (
          <Box width={'50%'}>
            <Box
              sx={{ opacity: 0.7, cursor: 'pointer', justifyContent: 'flex-end', display: 'flex', marginTop: '30px' }}
              onClick={() =>{
                dispatch(setConfirmationOpen(true))
              }
              }>
              {t('cancelOrder')}
            </Box>
          </Box>
        )}
      </Box>

      <OrderStatusBar isRejected={order.status.code === 'rejected'} percent={getStatus(order.status.code)} />
      <Grid container justifyContent='space-between'>
        <Grid item sm={8}>
          <Box sx={style.element}>{t('orderId')}:
            <Typography sx={style.value}>{order.orderId}</Typography>
          </Box>
          <Box sx={style.element}>{t('orderedBy')}:
            <Typography sx={style.value}>{firstName} {lastName}</Typography>
          </Box>
          <Box sx={style.element}>{t('orderTotal')}:
            <Typography sx={style.value}>{formatMoney(order.total)}</Typography>
          </Box>
        </Grid>
        {setSelectedOrder && (
          <Grid item sm={4}>
            <Box sx={{
              paddingTop: '16px',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
              <ItemsCount length={countTotalItems(order.items)} />
              <Box display={'flex'} mt={'30px'} justifyContent={'flex-end'}
                sx={{ opacity: 0.7, cursor: 'pointer' }}
                onClick={() => {
                  navigate({
                    pathname: `/my-account/my-orders/${order.orderId}`,
                  });
                  setSelectedOrder(order);
                }}
              >
                {t('viewOrder')}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default MyOrderItem;

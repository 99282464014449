import * as React from "react";
import {User} from "../../../interfaces/backend";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {StyledHeadTableCell} from "../../Cart/styleElements";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import {userManagementColumns} from "../../../constants/table";
import TableCell from "@mui/material/TableCell";
import {useTranslation} from "react-i18next";
import {SearchIconBox, getHeadCellStyle} from "./style";
import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import { useAppSelector } from "../../../hook";

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof User | string) => void;
    order: Order;
    orderBy: string;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    setFilteredData: React.Dispatch<React.SetStateAction<User[]>>;

}


const headCells  = userManagementColumns

export default function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, handleSearch, setFilteredData} = props;
    const {t} = useTranslation('myAccount')
    const users = useAppSelector(state => state.myAccount.users)

    // useEffect(() => {
    //     document.getElementById("BOXID")?.addEventListener("focusout", () => setOpenSearchField(false));
    // }, [])
    const [openSearchField, setOpenSearchField] = useState(false);

    const createSortHandler =
        (property: keyof User | string) => (event: React.MouseEvent<unknown>) => {
            property && onRequestSort(event, property);
        };

        const handleBlur = () => {
            setOpenSearchField(false);
            setFilteredData(users);
          };
        
          useEffect(() => {
              const textField = document.getElementById('BOXID');
            if(openSearchField && textField){
                textField.focus();
            } 
          }, [openSearchField]); 
        
 
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{
                    borderBottom: 'none',
                    width: '40px',
                    paddingX: '0px'
                }}>
                    <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                        <SearchIconBox
                            onClick={() => setOpenSearchField(!openSearchField)}
                        />
                        {openSearchField &&
                        <TextField
                            onBlur={handleBlur}
                            tabIndex={0}
                            sx={{
                                position: 'absolute',
                                width: '100px',
                                marginLeft: '20px',
                                border: 'unset',
                                zIndex: openSearchField ? 10 : -10,
                                '& .MuiInputBase-input.MuiInput-input': {
                                    paddingLeft: '8px',
                                    position: 'relative',
                                    bottom: '6px'
                                },
                            }}
                            id={'BOXID'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSearch(event)}
                        />
                        }
                    </Box>

                </TableCell>
                {headCells.map((headCell, index) => (
                    headCell.id !== '' ?
                        <StyledHeadTableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={getHeadCellStyle(headCell.id, openSearchField)}
                    >
                        <TableSortLabel
                            active={ orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'desc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={
                                (props) =>
                                    headCell.id ?
                                        <Box
                                        component={'img'}
                                        src={ '/images/arrowDownSort.svg'}
                                        sx={{
                                            '&.MuiTableSortLabel-icon': {
                                                paddingX: '10px',
                                                opacity: 0.5
                                            }
                                            }}

                                        {...props}
                                         />
                                        : <></>
                            }
                            sx={{
                                '&.Mui-active': {
                                    color: 'white',
                                    '& .MuiTableSortLabel-icon': {
                                        color: 'white',
                                    }
                                },
                                '& .MuiTableSortLabel-icon': {
                                    color: 'white',
                                    opacity: 1,
                                    '&:hover': {
                                        color: 'white',
                                        opacity: 1
                                    },
                                },
                                '&:hover': {
                                    color: 'white',
                                    opacity: 1
                                },
                                wordBreak: 'break-word',

                            }}
                        >
                            {t(headCell.label)}
                        </TableSortLabel>
                    </StyledHeadTableCell>
                        :
                        <TableCell 
                        key={`empty-cell-${index}`} 
                        sx={{
                            borderBottom: 'none',
                            width: '55px',
                        }}/>
                ))}
            </TableRow>
        </TableHead>
    );
}

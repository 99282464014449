import {Box} from "@mui/material"
import * as React from "react";
import {useCallback, useState} from "react";
import {Image} from "../../../interfaces/backend";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Thumbs, FreeMode} from "swiper";
import {mobileSwiperStyles} from "../mobile/styles"
import {TakeEmptyImage} from "../../../utils/productFunctions";

const MobileSwiperComponent = (props: { images: Array<Image> }) => {
  const {images} = props;
  const [imagesNavSlider, setImagesNavSlider] = useState({"isEnd": false, "isBeginning": true});
  //const swiperRef = useRef<SwiperCore>()
  // const prevRef = useRef(null);
  // const nextRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);
  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);
  return (
      <Box sx={mobileSwiperStyles.slider__flex}>
        <Box sx={mobileSwiperStyles.slider__images}>
          <Swiper
              thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed  ? thumbsSwiper : null}}
              onSwiper={setSwiperRef}
              //thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed && thumbsSwiper.thumbs?.swiper?.el ? thumbsSwiper : null}}
              direction="horizontal"
              onSlideChange={(event) => {
                setImagesNavSlider({isEnd: event.isEnd, isBeginning: event.isBeginning})
              }}
              style={{    alignItems: 'center'}}
              zoom={false}
              slidesPerView={1}
              spaceBetween={32}
              mousewheel={true}

           /*   navigation={{
                nextEl: ".slider__next",
                prevEl: ".slider__prev"
              }}*/
              modules={[Navigation, Thumbs, FreeMode]}
          >
            {images.length > 0 ? images.map((image: Image, index: number) => {
              return (
                  <SwiperSlide key={index} style={{
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'center'
                  }}>
                    <Box sx={mobileSwiperStyles.slider__images__slider__image}>
                      <img
                          onClick={() => window.open(image.url, '_blank')}
                          alt={image.url}
                          src={image.url}/>
                    </Box>
                  </SwiperSlide>
              );
            }) : <SwiperSlide>
              <Box sx={mobileSwiperStyles.slider__images__slider__image}>
                <Box component={"img"}
                     sx={{cursor: 'unset'}}
                     src={TakeEmptyImage()}
                     alt={'product_image'}/>
              </Box>
            </SwiperSlide>}
          </Swiper>
        </Box>
        {images.length > 0 &&
            <Box sx={{display: 'flex', justifyContent: 'center', maxWidth: 'calc(65vw)', margin: 'auto'}}>
          <Box sx={mobileSwiperStyles.slider__col}>
          <Box className={"slider__prev"} onClick={handlePrevious} sx={{...mobileSwiperStyles.slider__nav, mr: '5%'}}>
            {!imagesNavSlider?.isBeginning && images.length > 1 &&
                <Box
                    component={'img'}
                    src='/images/icons/mobileLeftArrowScroll.svg'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',

                    }}
                />
            }
          </Box>
          <Box sx={{...mobileSwiperStyles.slider__thumbs, ...{'& img': {width: images.length > 1 ?'100%':'50%'}
          }}}>
            <Swiper
/*                 onSwiper={(swiper) => {
                     swiper.animating = false;
                     swiperRef.current = swiper
                 }}*/
                onSwiper={setThumbsSwiper}
                direction="horizontal"
                freeMode={true}
                //spaceBetween={20}
                slidesPerView={images.length>1?2:1}
/*                navigation={{
                  nextEl: ".slider__next",
                  prevEl: ".slider__prev"
                }}*/
                style={mobileSwiperStyles.slider__swiper_container}
                // Responsive breakpoints
                breakpoints = {{
                  // when window width is >= 320px
                    [100]: {
                    //slidesPerView: 2,
                    spaceBetween: 20
                  }
                }}
                modules={[Navigation, Thumbs]}
            >
              {images.length > 0 && images.map((image: Image, index) => {
                return (
                    <SwiperSlide key={index}>

                      <Box sx={{...mobileSwiperStyles.slider__image, ...{'& img': {objectFit: "cover", width: images.length > 1 ?'100%':'50%'}
                      }}}>
                        <img
                            alt={image.url}
                            src={image.url}/>
                      </Box>
                    </SwiperSlide>
                );
              })/*: <SwiperSlide>
                          <Box sx={swiperStyles.slider__image}>
                            <img
                                alt={'product_example_image'}
                                src={'/images/product_example_image.png'}/>
                          </Box>
                        </SwiperSlide>*/}
            </Swiper>
          </Box>

          <Box className={"slider__next"} onClick={handleNext}
               sx={{...mobileSwiperStyles.slider__nav, ml: '5%'}}>{!imagesNavSlider?.isEnd && images.length > 1 &&
              <Box
                  component={'img'}
                  src='/images/icons/mobileRightArrowScroll.svg'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
              />
          }
          </Box>
        </Box></Box>}
      </Box>
  )
}

export default (MobileSwiperComponent)

import * as React from "react";
import {Box, styled, SxProps, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {isNumber} from "../../../utils/validation";
import { defaultFonts } from "../../../theme";

export interface PricesFilter {
  minPrice: number | undefined,
  maxPrice:number | undefined
}

interface PricesProps {
  setPricesFilter: React.Dispatch<React.SetStateAction<PricesFilter | undefined>>,
  pricesFilter: PricesFilter | undefined,
  closeFilter: boolean
}

const PriceTextField = styled((props: FieldProps) => (
    <TextField
        autoComplete="off"
        label={props.label}
        value={props.value?props.value:""}
        type={props.type}
        sx={props.sx}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.handleChange(event.target.value)
        }
    />
))(({theme}) => ({}))

interface FieldProps {
  type?: string,
  value?: number,
  handleChange: (value: string) => void,
  label?: string | null,
  sx?: SxProps
}

const Prices = (props: PricesProps) => {
  const {t} = useTranslation('searchPage')
  const {setPricesFilter, pricesFilter, closeFilter} = props

  return (
      <Box sx={{
      visibility: closeFilter ? 'hidden' : 'visible',  
      marginBottom: '77px', 
      fontSize: defaultFonts,
      }}>
        <Typography sx={{marginBottom: '11px', fontSize: defaultFonts}}>
          {(t('priceIn{{currency}}', {currency: sessionStorage.getItem('currency') as string}))}
        </Typography>
        <PriceTextField
            value={pricesFilter?.minPrice && pricesFilter?.minPrice > 0 ? pricesFilter?.minPrice : undefined}
            label={t('minPrice')}
            sx={{marginBottom: '16px'}}
            handleChange={(value) =>  isNumber(value) &&  setPricesFilter({
              minPrice: Number(value),
              maxPrice: pricesFilter?.maxPrice && pricesFilter?.maxPrice > 0 ? pricesFilter.maxPrice : undefined
            })}
        />

        <PriceTextField
            value={pricesFilter?.maxPrice && pricesFilter?.maxPrice > 0 ? pricesFilter?.maxPrice : undefined}
            label={t('maxPrice')}
            handleChange={(value) =>  isNumber(value) && setPricesFilter({
              minPrice: pricesFilter?.minPrice ? pricesFilter.minPrice : undefined,
              maxPrice: Number(value)
            })}
        />
      </Box>
  )
}

export default Prices

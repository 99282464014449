import {useAppDispatch, useAppSelector} from "../../hook";
import React, {ChangeEvent, useEffect} from "react";
import {addCartOrderOption, getOrderOptions} from "../../reducers/cart";
import RadioGroup, {RadioGroupItem} from "./RadioGroup";
import {OrderOption} from "../../interfaces/backend";


export default function OrderOptions () {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getOrderOptions())
    }, [])
    const orderOptions = useAppSelector(state => state.cart.orderOptions)

    const orderOption = useAppSelector(state => state.cart.checkoutCart.orderOption)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const result: OrderOption | undefined = orderOptions.find((option) => option.code === event.target.value)
        result && dispatch(addCartOrderOption({code:result.code}))
    };

    const radioGroupOptions: RadioGroupItem[] = orderOptions?.map((option): RadioGroupItem => ({id: option.code, content: option.name})) || [];

    return (
        <RadioGroup
            options={radioGroupOptions}
            value= {orderOption}
            handleChange = {handleChange}
        />
    )
}

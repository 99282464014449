import {Box, Button, Typography} from "@mui/material";
import OrderStatusBar from "../OrderStatusBar";
import {defaultFonts, primaryColor} from "../../../../../theme";
import * as React from "react";
import ItemHeader from "../../../data/mobile/ItemHeader";
import ConfirmationDialog, {ConfirmationContent} from "../../../../../components/common/Dialogs/ConfirmationDialog";
import {setConfirmationOpen} from "../../../../../reducers/myAccount";
import {ItemsCount} from "../MyOrderItem";
import {getStatus, OrderItemProp, useMyOrderCommon} from "../../../../../components/myData/MyOrders/orderUtils";
import {countTotalItems} from "../../../../../utils/productFunctions";


const MyOrderItemTablet = (props: OrderItemProp) => {
  const {order, setSelectedOrder, cancelOrder} = props;

  const {
    t,
    isConfirmationOpen,
    dispatch,
    navigate,
    CANCELLABLE_ORDERS,
    style,
    firstName,
    lastName
  } = useMyOrderCommon(props);


  return (
    <Box color={primaryColor.accountTextColor} fontWeight={700} p={{xs: '30px', md: '26px 20px'}} mb={'12px'}
         bgcolor={primaryColor.ordersListBackgroundColor} fontSize={defaultFonts}
    >
      {isConfirmationOpen &&
          <ConfirmationDialog
              onConfirm={() => {
                // due to the problem with cancelOrder this part temporary commented out
                !!cancelOrder && cancelOrder(order.orderId)
                dispatch(setConfirmationOpen(false))
              }}
              handleCancel={() => {
                dispatch(setConfirmationOpen(false))
              }}
              modalContent={<ConfirmationContent
                dataContent={t('sureToCancelOrder')}
              />}
          />}
      <Typography sx={{textAlign: {xs: 'center', md: 'start'}}}>{t(order.status.code)}</Typography>

      <Box sx={style.element}>{t('orderStatus')} :</Box>
      <OrderStatusBar isRejected={order.status.code === 'rejected'}
                      percent={getStatus(order.status.code)}/>
      <Box display={'flex'} flexDirection={'column'} fontSize={defaultFonts}>
        <ItemHeader order={order} firstName={firstName} lastName={lastName}/>
        {setSelectedOrder &&
            <Box sx={{
              paddingTop: '16px',
              alignItems: 'center',
            }}>
                <ItemsCount length={countTotalItems(order.items)}/>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '17px',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                          setSelectedOrder(order)
                          navigate({
                            pathname: `/my-account/my-orders/${order.orderId}`,
                          });
                        }}
                        sx={{
                          width: '60%',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                    >
                      {t('viewOrder')}
                    </Button>
                  {cancelOrder && CANCELLABLE_ORDERS.findIndex(code => code === order.status.code) !== -1 &&
                      <Button
                          variant="contained"
                          onClick={() => {
                            dispatch(setConfirmationOpen(true))
                          }}
                          sx={{
                            marginTop: '8px',
                            width: '60%',
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: primaryColor.cancellationButtonBackground
                          }}
                      >
                        {t('cancelOrder')}
                      </Button>}
                </Box>
            </Box>
        }
      </Box>
    </Box>
  )
}
export default MyOrderItemTablet
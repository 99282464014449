import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../hook";
import {BillingAddress, ShippingAddress} from "../../../../interfaces/backend";

export interface AddressProps {
  addressType: string,
  address: ShippingAddress | BillingAddress
}
const OrdersAddress = (props: AddressProps) => {
  const {t} = useTranslation('myAccount')
  const countries = useAppSelector(state => state.metadata.countries)
  const {addressType, address} = props
  const country = address && address.country ? countries.find(item => item.code === address?.country.toUpperCase())?.name : ""

  return(
      <Box>
        <Box sx={{textTransform: 'uppercase', marginBottom: '8px'}}>{t(addressType)}</Box>
        {"firstName" in address && "lastName" in address && address.firstName && address.lastName && <Box>{address.firstName} {address.lastName}</Box> }
        {address.companyName && <Box>{address.companyName}</Box>}
        {address.streetName && <Box>{address.streetName} {address.streetNumber} </Box>}
        {address.postalCode && address.city && <Box>{address.postalCode}, {address.city} </Box>}
        {country && <Box>{country}</Box>}
        {address.phone && <Box>{address.phone}</Box>}
      </Box>
  )
}

export default OrdersAddress

import {Box, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material"
import {useTranslation} from "react-i18next"
import {useAppDispatch, useAppSelector} from "../../../../../hook"
import {deleteCartItem} from "../../../../../reducers/cart"
import {CartItem} from "../../../../../interfaces/backend";
import {Link} from "react-router-dom";
import {primaryColor} from "../../../../../theme";
import {formatMoney} from "../../../../../utils/localization";
import {TableCellProps} from "@mui/material/TableCell";
import React from "react";
import getShowDiscount from "../../../../../utils/productFunctions";
import { getIsMiniCartOpenNeeded } from "../../../../../reducers/styleControls";


export const StyledHeadTableCell = styled((props: TableCellProps) => (
    <TableCell {...props} />
))(({theme}) => ({
    color: primaryColor.shoppingCartTitle,
    fontSize: '2rem',
    '&.MuiTableCell-root': {
        fontWeight: 400
    },
    borderBottom: '1px solid' + primaryColor.filterText,
    backgroundColor: primaryColor.headerColor,
    paddingTop: '24px'
}))

export const StyledTableCell = styled((props: TableCellProps) => (
    <TableCell {...props}/>
))(({theme}) => ({
    color: primaryColor.shoppingCartTitle,
    fontSize: '1.8rem',
    fontWeight: 300,
    borderBottom: '1px solid' + primaryColor.borderMiniCart,
    paddingTop: '4px',
    paddingBottom: '4px',
}))


export default function DesktopMiniCartTable() {
    const {t} = useTranslation('checkoutPage')
    const cart = useAppSelector(state => state.cart.cart)
    const settings = useAppSelector(state => state.staticContent.settings)
    const showDiscount = getShowDiscount(settings)
    const dispatch = useAppDispatch()

    return cart.items.length > 0 ? (
            <Box
                sx={{
                    maxHeight: '167px',
                    overflow: 'auto',
                }}
                onMouseLeave={() => dispatch(getIsMiniCartOpenNeeded(false))}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledHeadTableCell sx={{minWidth: '230px'}}>{t('data')}</StyledHeadTableCell>
                            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('quantity')}</StyledHeadTableCell>
                            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('productPrice')}</StyledHeadTableCell>
                            <StyledHeadTableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            backgroundColor: primaryColor.miniCartBackground,
                            width: '100%'
                        }}
                    >
                        {cart.items.map((row: CartItem, i: number) => {
                            const rowPrice = row.product.discountPrice || row.product.price
                            return (
                                <TableRow key={i}>
                                    <StyledTableCell
                                    >
                                        <Link
                                            to={`/product/${row.product.alias}`}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '1.8rem',
                                                    fontWeight: 300,
                                                    color: primaryColor.shoppingCartTitle,
                                                }}>
                                                {row.product.name}
                                            </Typography>
                                        </Link>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography sx={{textAlign: 'right'}}>{row.quantity}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{textAlign: 'right'}}>
                                        {row.product.discountPrice ? (
                                            <Box>
                                                {showDiscount && rowPrice ? (
                                                    <Box sx={{color: primaryColor.whiteText}}>
                                                        {formatMoney(rowPrice)}
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        ) : rowPrice ? (
                                            formatMoney(rowPrice)
                                        ) : (
                                            t('requestPrice')
                                        )}
                                    </StyledTableCell>
                                    
                                    <TableCell sx={{
                                        width: '55px',
                                        borderBottom: '1px solid' + primaryColor.whiteText,
                                        paddingTop: '5px',
                                        paddingBottom: '5px'
                                    }}>
                                        <Box
                                            component={'img'}
                                            alt='cart'
                                            onClick={() => {
                                                dispatch(deleteCartItem({ code: row.code }));
                                                if (cart.itemsQuantity > 0) {
                                                    dispatch(getIsMiniCartOpenNeeded(true));
                                                }
                                              }
                                            }
                                            src='/images/icons/delete-icon-new.svg'
                                            sx={{
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Box>
        )
        :
        (<></>)
}


import { MenuItem, Typography, TypographyVariant, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { locales } from "../../../../utils/localization";
import { IndexString, LocalObject } from "../../../../interfaces/general";

interface SelectLanguageProps{
	variant: TypographyVariant;
	sx: SxProps;
 
}
const SelectLanguage = (props: SelectLanguageProps) => {

	const {variant, sx} = props
 
	const { i18n} = useTranslation();
	const localesList: LocalObject = { ...locales() };
	let langs: IndexString = {};
  
	Object.keys(localesList).forEach((key) => {
	  langs[key] = localesList[key].label;
	});
	

  return (
 	<>
      {Object.keys(langs).map((key) => (
        <MenuItem
          key={key}
          value={key}
          onClick={() => {
            i18n.changeLanguage(langs[key].toLowerCase());
            localStorage.setItem("i18nextLng", langs[key].toLowerCase());
            window.location.reload();
          }}
          sx={{
			...sx
          }}
        >
          <Typography
            sx={{
              margin: "auto",
            }}
            variant={variant}
          >
            {langs[key]}
          </Typography>
        </MenuItem>
      ))}
 
	</>
  );
};

export default SelectLanguage;

import React from "react";
import ButtonWithIcon, { BaseProps } from "../../common/ButtonWithIcon";
import { primaryColor, defaultFonts } from "../../../theme";

const AddNewUserButton = (props: BaseProps) => {
  const { buttonContent, onClick } = props;

  const buttonStyles={
    backgroundColor: primaryColor.addNewUserButtonBackground,
    borderRadius: "1.065px",
    width: "100%",
    paddingY: "15px",
    marginY: "20px",
    fontSize: defaultFonts,
  }

  return (
    <ButtonWithIcon
      icon={"/images/icons/plus-Icon-white.svg"}
      baseProps={{
        buttonContent,
        buttonStyles,
        onClick,
      }}
    />
  );
};

export default React.memo(AddNewUserButton);

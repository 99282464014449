import React from 'react';

import {Link} from 'react-router-dom';
import {Box, Divider, Typography, TypographyProps, styled} from "@mui/material";
import theme, {defaultFonts, primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";
import getShowDiscount, {calculateCartPriceDetails, TakeFirst2DImage} from "../../../utils/productFunctions";
import {CartItem} from "../../../interfaces/backend";
import {formatMoney} from "../../../utils/localization";
import DiscountInfo from "./DiscountInfo";
import {useAppSelector} from "../../../hook";



const CustomTypography = styled((props: TypographyProps) => (
  <Typography {...props} fontSize={defaultFonts} />
))(({ theme }) => ({
  display: "-webkit-box",  
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2, 
  overflow: "hidden",  
  textOverflow: "ellipsis",  
  wordBreak: "break-word",  
  maxWidth: "100%",  
  fontWeight: 400,
  color: primaryColor.shoppingCartTitle,

}));

export default function CheckoutCartTableItemMobile(props: { item: CartItem, useDivider: boolean }) {
  const {item, useDivider} = props;
  const {t} = useTranslation('checkoutPage')
  const settings = useAppSelector(state => state.staticContent.settings)
  const showDiscount = getShowDiscount(settings)
  //const dispatch = useAppDispatch()
  const {prices} = calculateCartPriceDetails(item, settings);



  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          // paddingX: '30px'
        }}
      >
        <Box
          sx={{
            minWidth: '123px',
            height: '77px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            marginRight: '20px'
          }}
        >
          <Box
            component={'img'}
            src={TakeFirst2DImage(item.product.image)}
            sx={{
              height: '77px'
            }}
          />
        </Box>
        <Link
          to={`/product/${item.product.alias}`}
          style={{
            width: '100%'
          }}
        >
          <CustomTypography sx={{
            maxHeight: '47px',
            marginBottom: '16px',
            height: 'fit-content',

            }}>
            {item.product.name}  
          </CustomTypography>
          <Box
            sx={{
              // marginTop: '16px',
            }}
          >
          <CustomTypography>
            {item.product.code}
          </CustomTypography>
          </Box>
          <CustomTypography>
            {`${t('quantity')}: ${item.quantity}`}
          </CustomTypography>
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          // paddingX: '30px',
          width: 'fit-content',
          marginY: '16px',
        }}
      >
     
      <Box sx={{
        width: '123px', 
        marginRight: '20px', 
        marginTop: 'auto',
        
        }}>
          {item.discounts.length > 0 ?
            <DiscountInfo discounts={item.discounts}/>
            : null}
        </Box>

        {showDiscount && prices.length > 1 ?
          <Box>
            {
              prices.map((price, index) =>
                <Box key={index}>
                  <Typography
                    sx={(index < prices.length - 1) ? {
                      textDecoration: 'line-through',
                      textDecorationColor: primaryColor.lineThroughPrice,
                      textDecorationThickness: '1px',
                      textDecorationTrim: 'auto',
                      color: primaryColor.lineThroughPrice,
                      opacity: 0.3,
                      fontSize: defaultFonts
                    } : {
                      fontSize: defaultFonts,
                      color: primaryColor.discountCheckoutPage}}
                  >
                    {formatMoney(price)}
                  </Typography>
                </Box>
              )
            }
          </Box>
          :
          <CustomTypography>
            {formatMoney(prices.pop() as number)}
          </CustomTypography>
        }
      </Box>
      {useDivider && <Divider
          sx={{
            backgroundColor: primaryColor.textHeader,
            marginBottom: '16px',
            marginX: {xs: '-40px', md: 'unset'}
          }}
      />}
    </Box>
  )
}
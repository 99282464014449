import { FormControl, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { locales } from "../../../../utils/localization";
import { LocalObject } from "../../../../interfaces/general";
import { primaryColor } from "../../../../theme";
import { ArrowDropDown } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { setSelectLanguageOpen } from "../../../../reducers/styleControls";
import SelectLanguage from ".";

const SelectLanguageDeskTop = () => {

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isSelectLanguageOpen = useAppSelector(
    (state) => state.styleControls.isSelectLanguageOpen
  );

  const handleBoxClick = () => {
    dispatch(setSelectLanguageOpen(!isSelectLanguageOpen));
  };


  const renderValue=()=>{
    return i18n.language.toUpperCase();
  }


  return (
    <FormControl onClick={handleBoxClick}>
      <Select
        value={i18n.language} 
        open={isSelectLanguageOpen}
        renderValue={renderValue}
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
          localStorage.setItem("i18nextLng", e.target.value);
          window.location.reload();
        }}
        IconComponent={(props) => <ArrowDropDown {...props} />}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiList-root.MuiMenu-list": {
                paddingY: "0",
              },
              borderRadius: "unset",
              width: "fit-content",
            },
          },
        }}
        sx={{
          "& .MuiInputBase-input": {
            fontSize: '1.2rem',

          },
          "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input":
            {
              display: "flex",
              alignItems: "center",
              width: "100%",
            },
          "& .MuiSelect-icon": {
            color: primaryColor.whiteText,
            padding: 0,
          },
        }}
      >
          <SelectLanguage 
          variant={'subtitle2'} 
          sx={{
            width: "100%",
            paddingX: "unset",
          }}
          />

      </Select>
    </FormControl>
  );
};

export default SelectLanguageDeskTop;

import { Box, ListItemButton, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../../../theme";
import * as React from "react";
import {NavLink} from "react-router-dom";
import { accountMenu} from "../../../../data";
import {Translation, useTranslation} from "react-i18next";
import {logout} from "../../../../reducers/auth";
import { setOpenedDrawerID} from "../../../../reducers/static";
import { MenuItem } from "../../../../interfaces/backend";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { setSelectLanguageOpen } from "../../../../reducers/styleControls";
import ArrowButton from "../../ArrowButton";
import AuthDynamicComponent from "../../../accessControl/AuthDynamicComponent";
 
interface AccountDrawerIconProps {
    isOpen?: boolean;
    isMyAccountPage?: boolean;
}  

export const AccountDrawerIcon = (props: AccountDrawerIconProps) => {
    
    const {isOpen, isMyAccountPage} = props;


    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box
            component='img'
            src={ isOpen || (mobileView && isMyAccountPage) ? "/images/account.svg" : "/images/whiteAccount.svg"}
            sx={{
                height: {xs: '20px', md: '26px', lg: '28px'},
                width:  'auto'
            }}
            alt={"image account"}
           
        />
    )
}

const ListItemSample = ({title, id, isActive}: { title: string; id?: string; isActive: boolean }) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation("common");

 
    const isSelectLanguageOpen = useAppSelector((state) => state.styleControls.isSelectLanguageOpen);

    const handleBoxClick = () => {
        dispatch(setSelectLanguageOpen(!isSelectLanguageOpen))
    };
    
    const bgColor = !isActive ? primaryColor.drawerBackground : primaryColor.drawerHover;
    return <ListItemButton
                sx={{
                    paddingLeft: '65px',
                    paddingRight: '30px',
                    backgroundColor: bgColor,
                    '&:hover': {
                        color: primaryColor.drawerText,
                        backgroundColor: primaryColor.drawerHover
                    },
                }}
                onClick={() => {
                    if (id === "logout-button") {
                        dispatch(logout());
                    } else if (id === "language-button") {
                        handleBoxClick();
                    } else {
                        dispatch(setOpenedDrawerID(""))
                        return null
                    }
                }}
            >
                {id !== 'language-button' ?
                    <Typography
                        variant='h6'
                        sx={{
                            paddingLeft: '4px',
                            color: primaryColor.drawerText
                        }}
                    >
                        {title}
                    </Typography>
                    :

                    <Box 
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                    }}>
                        <Typography
                            variant={'h6'}
                            sx={{
                                paddingLeft: '4px',
                                width: '50%',
                                color: primaryColor.drawerText
                            }}
                        >
                            {t('language')}:
                        </Typography>
                        
 
                       {!isSelectLanguageOpen && 
                       <Typography 
                       variant={'h6'}
                       sx={{
                            color: primaryColor.drawerText,
                            marginRight: 'auto',
                            width: '100%'
                            }}> 
                           {i18n.language.toUpperCase()}
                        </Typography>}
                        <ArrowButton
                            direction={isSelectLanguageOpen ? 'down' : 'up'}
                            color={primaryColor.arrowButtonColorSlider}
                            width={15}
                        />
                            
                    </Box>
                }
            </ListItemButton>
       
}

const StyledLink = ({link, title, id}: { link: string, title: string, id: string }) => {
    return (
        <NavLink to={link} style={{width: '100%'}}>
            {({isActive}) => (
                <ListItemSample
                    title={title} id={id} isActive={isActive}/>
            )}
        </NavLink>

    )
}



export const accountDrawerList: Array<React.ReactElement> = accountMenu.map(item =>
    <Translation ns='common'>{ t => (  
        <StyledLink 
        key={item.id} 
        id={item.id}
        link={item.route} 
        title={t(item.text)}
        />
        )
    }
    </Translation>

)


export const normalDrawerList = (mainMenuLinks: MenuItem[]) => {
    
    const drawerMenu =[...mainMenuLinks, {link: "language", title: 'language', id: 'language-button'}];


      return drawerMenu.map(item => {
        
        return item.id !== 'language-button' 
        ? <StyledLink key={item.id} id={item.id} link={item.link} title={item.title} />
        : <ListItemSample key={item.id} id={item.id} title={ item.title} isActive={false} />
    });
};
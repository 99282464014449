import React, {useEffect, useState} from "react";
import {Box, useMediaQuery} from "@mui/material";
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hook";
import {getQuickSearchOptions} from "../../reducers/quickSearch";
import {ProductDetailsPageContent, SearchSuggestionElement} from "../../interfaces/backend";
import {useNavigate} from "react-router";
import {commonStyles, MiddleContent, NavigateBox, TopContent} from "./subComponents";
import {getProductDetails} from "../../reducers/productdetails";


const MachineSpareParts = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedProduct: ProductDetailsPageContent = useAppSelector(state => state.productDetails.productDetails);
  const {t} = useTranslation('spareParts')
  const lgView = useMediaQuery(theme.breakpoints.down('lg'))
  const [selectedSearchedElement, setSelectedSearchedElement] = useState<SearchSuggestionElement | null>(null)

  useEffect(() => {
    const path =
      selectedSearchedElement &&
      selectedProduct?.alias &&
      selectedSearchedElement.alias === selectedProduct.alias ?
        (selectedProduct.isIndividualMachine ? '/my/machine' : '/machine')
        : '';
    if (path && selectedSearchedElement) {
      navigate(`${path}/${selectedSearchedElement.alias}`);
    }
  }, [dispatch, selectedSearchedElement, selectedProduct])


  const handleInputChange = (searchValue: string) => dispatch(getQuickSearchOptions({
    searchValue: searchValue,
    onlyMachines: true
  }));

  const handleOnSelect = (item: SearchSuggestionElement) => {
    if (item) {
      dispatch(getProductDetails({alias: item.alias}));
    }
    setSelectedSearchedElement(item);
  }


  const navigationItems = [
    {
      content: t("myMachine"),
      navigateTo: "/my/machines",
      imageSrc: '/images/my_machine_image.png'
    },
    {
      content: t("standardMachine"),
      navigateTo: "/machines",
      imageSrc: '/images/standard_machine_image.png'
    },
  ];


  return (
    <Box sx={commonStyles.card}>

      <TopContent
        numberContent={1}
        title={t("chooseMachine")}
        description={t("chooseMachineDescription")}
      />

      <MiddleContent
        handleInputChange={handleInputChange}
        handleOnSelect={handleOnSelect}
        placeHolder={t("searchMachine")}
      />

      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: 'row'
        },
        paddingY: {
          md: '75px',
          lg: '25px'
        },
        justifyContent: 'space-evenly',
      }}>

        {navigationItems.map((item, index) => (
          <NavigateBox
            key={index}
            buttonProps={{content: item.content, navigateTo: item.navigateTo}}
            variant={lgView ? "button" : "image"}
            imageSrc={item.imageSrc}
          />
        ))}

      </Box>
    </Box>
  )
}

export default (MachineSpareParts)
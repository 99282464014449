import React from "react";
import { Box, Typography } from "@mui/material";
import {tableFontSize, primaryColor } from "../../../theme";
import {  TableRowComponentProps } from "./DesktopTableRow";
import ProductQuantityPriceBox  from "./ProductQuantityPriceBox";

const MobileTableRow = (props: TableRowComponentProps) => {
  const { product, onDelete, index, onChangeQuantity } = props;

  return (
    <Box
      key={product.code}
      sx={{
        maxWidth: '450px',
        marginX: "auto",
        marginY: index !== 0 ? '40px' : '0',
        paddingX: '25px', 
        
      }}>

    <Box sx={{
        borderTop: index !== 0 ? `0.05px solid ${primaryColor.cartTableDividerColor}` : 'none',
        marginY: '-20px',
        position: 'absolute',
        left: 0,
        right: 0,
      }}> 
      </Box>
 
        <Typography
          sx={{
            paddingY: "1.5px",
            textAlign: "center",
            fontSize: tableFontSize.contentMain
          }}
        >
          {product.name}
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            paddingY: "1.5px",
            mb: '30px',
            fontSize: tableFontSize.contentMain,
          }}
        >
          {product.code}
        </Typography>
        <Box 
        sx={{
            width: "100%",
            margin: 'auto',
            }}
            >
      <ProductQuantityPriceBox 
        product={product} 
        onChangeQuantity={onChangeQuantity} 
        onDelete={onDelete} />

      </Box>
    </Box>
  );
};

export default MobileTableRow;

import React, { createContext } from 'react';

interface ViewerContextValue {
  viewerContainerRef: React.RefObject<HTMLDivElement>;
}

const ViewerContext = createContext<ViewerContextValue>({
  viewerContainerRef: { current: null },
});

export const ViewerBoundingBox = createContext<ViewerContextValue>({
  viewerContainerRef: { current: null },
});

export default ViewerContext;

import React from "react";
import { Box } from "@mui/material";
import { primaryColor } from "../../theme";
import { Article } from "../../interfaces/backend";
import BlogContent from "./BlogContent";
import ImageContent from "../../components/common/ContentWithAnimation/ImageContent";

interface MobileBlogProps {
  items: Array<Article>;
}

const MobileBlog = (props: MobileBlogProps) => {
  const { items } = props;

  return (
    <Box
      sx={{
        paddingY: "20px",
      }}
    >
      {items.map((item, index: number) => (
        <Box
          sx={{
            color: primaryColor.animatedMenuText,
            overflow: "hidden",
            position: "relative",
            paddingY: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: "4%",
              backgroundColor: primaryColor.animatedMenuBackGround,
              opacity: 0.8,
              height: "78px",
              zIndex: 1,
              position: "absolute",
              top: 107,
              left: 0,
              right: 0,
            }}
          >
            <BlogContent data={item} />
          </Box>

          <Box
            sx={{
              height: "100%",
              maxHeight: "145px",
            }}
          >
            <ImageContent image={item.image} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MobileBlog;

import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../theme";
import Button from "@mui/material/Button";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmailValid} from "../../utils/validation";
import {StyledTextField} from "./registerForm";
import {openForgotPasswordModal, openRegisterForm} from "../../reducers/start";
import {useAppDispatch, useAppSelector} from "../../hook";
import {login, setSelectUserModalOpen} from "../../reducers/auth";
import CommonDialog from "../common/Dialogs/commonDialog";
import ServiceSelectUser from "./ServiceSelectUser";

const textStyle = {
  cursor: 'pointer',
  color: primaryColor.linkColor,
  '&:hover': {
    color: primaryColor.badgeColor
  }
}


export default function LoginForm() {
  const {t} = useTranslation('startPage')
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [enableLogin, setEnableLogin] = useState<boolean>(false)
  const isSelectUserModalOpen = useAppSelector(state => state.auth.isSelectUserModalOpen)

  useEffect(() => {
    setEnableLogin(validate())
  }, [email, password])

  const validate = (): boolean => {
    return isEmailValid(email) && password.length > 0
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validate() &&
    dispatch(login({
        email: email,
        password: password,
      })
    )
  };


  return (
    <Box mt={8}>
      <Typography
        variant="h6"
        sx={{
          fontSize: {xs: "1.8rem"},
          color: primaryColor.textTopHeader
        }}
      >
        {t('access for existing customers')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={2} mb={5}>
          <StyledTextField
            label={t('username')}
            handleChange={(value) => setEmail(value)}
          />
        </Box>
        <Box mt={6} mb={5}>
          <StyledTextField
            label={t('password')}
            type="password"
            handleChange={(value) => setPassword(value)}/>

        </Box>
        <Button
          type={'submit'}
          variant="contained"
          disabled={!enableLogin}
          sx={{width: "100%"}}
        >
          {t('login')}
        </Button>
      </form>
      <Box mt={2} mb={20} display={'flex'}>
        <Typography
          variant='h7'
          sx={textStyle}
          onClick={() => dispatch(openForgotPasswordModal())}
        >
          {t('forgotPassword')}
        </Typography>
        <Typography color={primaryColor.textHeader} mx={1}>
          |
        </Typography>
        <Typography
          variant='h7'
          onClick={() => dispatch(openRegisterForm(true))}
          sx={textStyle}
        >
          {t('Register')}
        </Typography>
      </Box>

      {isSelectUserModalOpen &&
          <CommonDialog
              title={t('startPage:chooseACustomer')}
              closeAction={() => {
                dispatch(setSelectUserModalOpen(false));
              }}
          >
              <Box sx={{marginTop: '6px'}}>
                  <ServiceSelectUser/>
              </Box>
          </CommonDialog>
      }
    </Box>
  )
}
import { primaryColor } from "../../../theme";


export const style = {
  productCard: {
    boxShadow: primaryColor.relatedItemsCardBoxShadow,
    background: primaryColor.relatedItemsCardBackground,
    color: primaryColor.relatedProductsTextColor,
    width: "335px",
    height: "100%",

    '& * > :not(.article-description)': {
      fontSize: {
        xs: '1.5rem',
        md: '1.6rem',
        lg: '1.8rem'
      }
  },

  },

  cardDescription: {
    paddingY: "10px",
    paddingX: "25px",
    "& > div": {
      paddingY: '4px',
    },
  },
  image: {
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "200px",
    },
  },
};

export const sliderStyle = {
  general: {
    height: 'fit-content',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "100px",
    margin: "auto",
    width: '100%'

  },

  relatedProducts: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: '30px 0',
  },

  relatedProductsText: {
    color: primaryColor.relatedProductsTextColor,
    fontSize: "3rem",
    textTransform: "uppercase",
    margin: "40px 0",
  },
};

import {Box} from "@mui/material"
import * as React from "react";
import {useCallback, useState} from "react";
import {Image} from "../../../interfaces/backend";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {FreeMode, Navigation, Thumbs} from "swiper";
import theme from "../../../theme";
import {swiperStyles} from "./styles";
import {TakeEmptyImage} from "../../../utils/productFunctions";

const SwiperComponent = (props: { images: Array<Image> }) => {
    const {images} = props;
    const emptyImage = TakeEmptyImage();
    const [swiperRef, setSwiperRef] = useState<SwiperCore>();


    const [imagesNavSlider, setImagesNavSlider] = useState({"isEnd": false, "isBeginning": true});
    //const swiperRef = useRef<SwiperCore>()
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
    const handleNext = useCallback(() => {
      swiperRef?.slideNext();
    }, [swiperRef]);
    const handlePrevious = useCallback(() => {
      swiperRef?.slidePrev();
    }, [swiperRef]);
    return (
        <Box sx={swiperStyles.slider__flex}>
            <Box sx={swiperStyles.slider__col}>
                <Box  sx={swiperStyles.slider__nav} onClick={handlePrevious}>
                    {!imagesNavSlider?.isBeginning && images.length > 1 && <ArrowDropUp sx={{marginTop: "-24px"}}/>}
                </Box>
                <Box sx={swiperStyles.slider__thumbs}>
                    <Swiper
                        // onSwiper={(swiper) => {
                        //     swiper.animating = false;
                        //     swiperRef.current = swiper
                        // }}
                        freeMode={true}
                        onSwiper={setThumbsSwiper}
                        direction="horizontal"
                        spaceBetween={24}
                        slidesPerView={5}
                       /* navigation={{
                            nextEl: ".slider__next",
                            prevEl: ".slider__prev"
                        }}*/
                        style={swiperStyles.slider__swiper_container1}
                        breakpoints={{
                            [theme.breakpoints.values.md]: {
                                direction: "vertical"
                            }
                        }}
                        modules={[Navigation, Thumbs, FreeMode]}
                    >
                        {images.length>0 && images.map((image: Image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <Box sx={swiperStyles.slider__image}>
                                        <img
                                            alt={image.url}
                                            src={image.url}/>
                                    </Box>
                                </SwiperSlide>
                            );
                        })/*: <SwiperSlide>
                          <Box sx={swiperStyles.slider__image}>
                            <img
                                alt={'product_example_image'}
                                src={'/images/product_example_image.png'}/>
                          </Box>
                        </SwiperSlide>*/}
                    </Swiper>
                </Box>

                <Box
                     sx={swiperStyles.slider__nav} onClick={handleNext}>{!imagesNavSlider?.isEnd && images.length > 1 &&
                    <ArrowDropDown/>}</Box>
            </Box>

            <Box sx={swiperStyles.slider__images}>
                <Swiper
                    thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed  ? thumbsSwiper : null}}
                    direction="vertical"
                    onSlideChange={(event) => {
                        setImagesNavSlider({isEnd: event.isEnd, isBeginning: event.isBeginning})
                    }}
                    onSwiper={setSwiperRef}
                    zoom={false}
                    slidesPerView={1}
                    spaceBetween={32}
                    mousewheel={true}
/*                    navigation={{
                        nextEl: ".slider__next",
                        prevEl: ".slider__prev"
                    }}*/
                    style={swiperStyles.slider__swiper_container2}
                    modules={[Navigation, Thumbs, FreeMode]}
                >
                    {images.length>0 ? images.map((image: Image, index: number) => {
                        return (
                            <SwiperSlide key={index}>
                                <Box sx={swiperStyles.slider__images__slider__image}>
                                    <img
                                        onClick={() => window.open(image.url, '_blank')}
                                        alt={image.url}
                                        src={image.url}/>
                                </Box>
                            </SwiperSlide>
                        );
                    }) : <SwiperSlide>
                      <Box sx={swiperStyles.slider__images__slider__image}>
                        <Box component={"img"}
                             sx={{cursor: 'unset'}}
                             src={emptyImage}
                             alt={'product_image'}/>
                      </Box>
                    </SwiperSlide>}
                </Swiper>
            </Box>
        </Box>
    )
}

export default React.memo(SwiperComponent)

import {Box, useMediaQuery} from "@mui/material";
import {ProductDetailsPageContent,} from "../../../interfaces/backend";
import {Availability} from "../general/availability";
import {formatMoney} from "../../../utils/localization";
import {Link} from "react-router-dom";
import {style,  } from "./styles";
import {TakeFirst2DImage} from "../../../utils/productFunctions";
import theme from "../../../theme";


interface ProductCardProps {
  product: ProductDetailsPageContent,
}

const ProductCard = (props: ProductCardProps) => {
  const { product } = props;
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const mobileStyle = {
 
    imageSize: mobileView ? {
      height: '19px',
      width: '19px'
    }:{
      height: '22px',
      width: '22px'
    },
  }

  const link = (product: ProductDetailsPageContent) => {
    const linkPath = !product.isMachine
      ? "product"
      : product.isIndividualMachine
      ? "my/machines"
      : "machine";
    return `/${linkPath}/${product.alias}`;
  };

  return (
    <Link to={link(product)}>
      <Box
        sx={{
          ...style.productCard,
          maxHeight: {xs: '360px', md: '380px'},
        }}
      >
        <Box sx={style.image}>
          <img alt={product.code} src={TakeFirst2DImage(product.image)} />
        </Box>

        <Box sx={{ 
          textAlign: {
            xs: "center",
            lg: 'left',
            },
            ...style.cardDescription,
            height: '170px',    
        }}>

        <Box
          sx={{
            maxWidth: "100%",
            height: "22px",  
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "uppercase",
            fontWeight: "500",
            wordBreak: 'break-all',
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            
          }}
        >
              {product.name} 
                        
            </Box>
          <Box>{product.code}</Box>
          <Availability
            iconSx={mobileStyle.imageSize}
            inStock={product.inStock}
            isOrderable={product.isOrderable}
          />
          <Box >{formatMoney(product.price)}</Box>
        </Box>
      </Box>
    </Link>
  );
};

export default ProductCard;

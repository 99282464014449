import React, {useEffect, useMemo, useState} from "react";
import theme, {defaultFonts, primaryColor, secondaryFonts} from "../../../theme";
import {Box, BoxProps, styled, Typography, TypographyProps, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DiscountObject, DiscountValueType} from "../../../interfaces/backend";

interface DiscountInfoProps {
  discounts: DiscountObject[]
}
interface ContainerBoxProps extends BoxProps {
  isOpenInMobile: boolean;
  dynamicMargin?: number;
}

const ContainerBox = styled(({ isOpenInMobile, dynamicMargin, ...otherProps }: ContainerBoxProps) => (
  <Box {...otherProps} />
))(({ theme, isOpenInMobile, dynamicMargin }) => ({
  
  display: 'flex',
 
  flexDirection: isOpenInMobile ? 'column' : 'row',
  paddingTop: isOpenInMobile ? '5px' : '0',
  marginTop: isOpenInMobile ?  `-${dynamicMargin}px` : '0px',
  marginRight:   isOpenInMobile ? '-15px' : 0,
  alignItems: 'center',
  cursor: 'pointer',
  color: primaryColor.whiteText,
  width: '100%',
  '&:hover': {
    color: primaryColor.orangeHoverColor
  },
  [theme.breakpoints.down("lg")]: {
    '&:hover': {
      color: 'unset'
    }
  },

}));
const IconBox =  styled(({ isOpenInMobile, ...otherProps }: ContainerBoxProps) => (
  <Box {...otherProps} 
       component={'img'}
       src={'/images/icons/percentageIcon.svg'}
  />
  ))(({ theme, isOpenInMobile }) => ({  
  marginRight: isOpenInMobile ? '100px' : '10px',
  marginBottom: 'auto',
  height: '22px',

  [theme.breakpoints.down("md")]: {
    height: '20px',
  },
}));
 

export default function DiscountInfo({discounts}: DiscountInfoProps) {

  const [opened, setOpened] = useState(false)
  const {t} = useTranslation('checkoutPage')
  let discountInfo = useMemo(() =>
      discounts.filter((item) => item.discount.valueType === DiscountValueType.Percentage)
        .map(item => ` ${item.discount.discountCode} (${-item.discount.value}%)`).toString(),
    [discounts]);
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    
    const [dynamicMargin, setDynamicMargin] = useState(0);
     


    useEffect(() => {
        if (mobileView && opened) {
          
          const totalCharacters = discounts.reduce((sum, item) => {
            return sum + (item.discount ? item.discount.discountCode.length : 0);
          }, 0);
          // const margin = Math.min(Math.ceil(discounts.length * 12), 40);  
          
          if(totalCharacters >= 6){
            //one line is 12 symobols, for each line add approx. neqative 10px margin. Max. -40px
            const margin = Math.min(Math.ceil(totalCharacters / 12 * 15), 25);
            setDynamicMargin(margin);
          }
          

        } 
      }, [discounts.length, mobileView, opened])
 


      
  return (
    discountInfo.length > 0 ? <ContainerBox isOpenInMobile = {mobileView && opened} onClick={() => setOpened(!opened)} dynamicMargin={dynamicMargin}>
      
      <IconBox isOpenInMobile={mobileView && opened} />
      {!opened ?
        <Typography 
        sx={{
          fontSize: {
            xs: defaultFonts.xs,
            sm: defaultFonts.sm,
            lg: defaultFonts.md,
          }
        }}
        >{t('info')}
        </Typography>
        :
        <Typography sx={{
          color: primaryColor.orangeHoverColor, 
          width: '100%',
          wordBreak: 'break-word',
          fontSize: secondaryFonts,
        }}> {discountInfo
        }</Typography>
      }
    </ContainerBox> : <></>
  )
}
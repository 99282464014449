import React from "react";
import { Box, Typography } from "@mui/material";
import {primaryColor, secondaryFonts} from "../../../theme";
import {Discount } from "../../../interfaces/backend";
import {removeCheckoutVoucher} from "../../../reducers/cart";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hook";
import InfoToolTip from "../../../components/common/CustomTooltips/InfoTooltip";
import { checkEmptyTag } from "../../../utils/productFunctions";
 

interface VoucherInfoProps {
    setDiscountCode: React.Dispatch<React.SetStateAction<string>>,
    discount: Discount
}

export default function VoucherInfo({
  discount,
  setDiscountCode,
}: VoucherInfoProps) {
  const { t } = useTranslation("checkoutPage");
  const dispatch = useAppDispatch();
  const removeVoucher = (code: string) => {
    dispatch(removeCheckoutVoucher({ code }));
    setDiscountCode("");
  };

  return (
    <Box sx={{
      marginY: '10px',
      marginRight: {xs: 0, md: '10px'}
    }} >
        <Typography
            sx={{
              color: primaryColor.discountCheckoutPage,
              fontSize: secondaryFonts,
              marginRight: "5px",
              display: "inline",
              wordBreak: 'break-all',

            }}
          >
            {discount.discountCode}
          </Typography>

          {checkEmptyTag(discount.description) &&
              <InfoToolTip
              toolTipContent={
                  <Box dangerouslySetInnerHTML={{__html: discount.description}}/>
              }
          />
          }
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: secondaryFonts,
            marginLeft: "5px",
            display: "inline",
          }}
          onClick={() => removeVoucher(discount.discountCode)}
        >
          {t("delete")}
        </Typography>
        </Box>

  );
}

import {Box, Checkbox, FormControl} from "@mui/material";
import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hook";
import CustomAutocomplete from "../../components/common/customAutoComplete";
import { StyledContainer, GroupTwoBox, StyledBox, StyledTypography} from "../../components/common/StyledComponents";

import {
    closeShippingAddressForm,
    patchShippingAddress,
    postShippingAddress,
    setShippingAddressInput, setShippingCountry
} from "../../reducers/address";
import ButtonsBox, { buttonsBoxMainWrapper } from "../../components/common/ButtonsBox";
import {Country} from "../../interfaces/backend";
 
interface ShippingAddressFormProps{
  isMyAccountPage?: boolean;
}

export default function ShippingAddressForm(props: ShippingAddressFormProps) {
    const {isMyAccountPage } = props

    const isEditMode = useAppSelector(state => state.address.isEditMode)

    const countries = useAppSelector(state => state.metadata.countries)

    const shippingAddress = useAppSelector(state => state.address.shippingAddress)
    const isShippingAddressFormOpened = useAppSelector(state => state.address.isShippingAddressFormOpened)

    const {t} = useTranslation(['checkoutPage', 'Validation Messages'])
    const dispatch = useAppDispatch()

    const handleCountryChange = (country: Country | null) => {
      country && dispatch(setShippingCountry({country: country.code}))
    }

    const [isInputFieldEditing, setInputFieldEditing] = useState(false);
    const [isCheckBoxSet, setCheckBox] = useState(shippingAddress.isDefault);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputFieldEditing(true);
        dispatch(setShippingAddressInput({target: event.target}))
    }

    const [formValid, setFormValid] = useState(false);
    
    const selectedCountry = countries.find(
      (v) => v.code === shippingAddress?.country
    ) || null;

    useEffect(() => {
      const validateForm = () => {
  
        if (
          !shippingAddress.companyName ||
          !shippingAddress.streetName.trim() ||
          !shippingAddress.city ||
          !shippingAddress.city.trim() ||
          !shippingAddress.postalCode ||
          !shippingAddress.postalCode.trim() ||
          !shippingAddress.country ||
          !shippingAddress.country.trim()
        ) {
          return false;
        } else {
          return true;
        }
      };
      setFormValid(validateForm());
    }, [shippingAddress]);



    const handleConfirm = (event: React.FormEvent) => {
      event.preventDefault();
    
      const updatedShippingAddress = {
        ...shippingAddress,
        isDefault: isCheckBoxSet,
      };
    
      if (!isEditMode) {
        dispatch(postShippingAddress({ shippingAddress: updatedShippingAddress }));
      } else  {
        dispatch(patchShippingAddress({ shippingAddress: updatedShippingAddress }));
      }
      setInputFieldEditing(false);
      
    };
    
    const handleCancel = () => {
        dispatch(closeShippingAddressForm())
       setCheckBox(shippingAddress.isDefault)
    }


    useEffect(() => {
      if (!isInputFieldEditing) {
          setCheckBox(shippingAddress.isDefault);
      }
       
  }, [isInputFieldEditing, shippingAddress]);
  
 
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;
      setCheckBox(newValue);
    };

    return (
    <FormControl sx={{
      margin: 'auto',
      width: '100%',
    }}>
    <form onSubmit={handleConfirm}>
      <Box
        sx={{
          display: isShippingAddressFormOpened ? "flex" : "none",
          flexDirection: "column",
        }}
      >
        <StyledContainer>
          <StyledBox
            label={t("firstName")}
            name="firstName"
            handleChange={handleInputChange}
            required={false}
            value={(shippingAddress.firstName || "")}
          />
          <StyledBox
            label={t("lastName")}
            name="lastName"
            handleChange={handleInputChange}
            required={false}
            value={(shippingAddress?.lastName || "")}
          />
        </StyledContainer>
        <StyledContainer>
          <StyledBox
            label={t("company")}
            required={true}
            name="companyName"
            handleChange={handleInputChange}
            value={(shippingAddress?.companyName || "")}
          />
          <StyledBox
            label={t("additional")}
            required={false}
            name="additional"
            handleChange={handleInputChange}
            value={(shippingAddress?.additional || "")}
          />
        </StyledContainer>

        <StyledContainer>
          <GroupTwoBox>
            <StyledBox
              label={t("street")}
              style={{
                width: '70%',
              }}
              required={true}
              name="streetName"
              handleChange={handleInputChange}
              value={(shippingAddress?.streetName || "")}
            />
            <StyledBox
              label={t("no")}
              style={{
                width: '25%'
              }}
              required={false}
              name="streetNumber"
              handleChange={handleInputChange}
              value={(shippingAddress?.streetNumber || "")}
            />
          </GroupTwoBox>
          <GroupTwoBox>
            <StyledBox
              label={t("postalCode")}
              style={{
                width: '25%'
              }}
              required={true}
              name="postalCode"
              handleChange={handleInputChange}
              value={(shippingAddress?.postalCode || "")}
            />
            <StyledBox
              label={t("city")}
              style={{
                width: '70%'
              }}
              required={true}
              name="city"
              handleChange={handleInputChange}
              value={(shippingAddress?.city || "")}
            />
          </GroupTwoBox>
        </StyledContainer>
        <StyledContainer>
        <Box sx={{ 
          width: {
            xs: '100%',
            lg: '48%'
          },
        }}>
          <CustomAutocomplete
            options={countries}
            handle={handleCountryChange}
            label={t("country")}
            required={true}
            value={selectedCountry}
          />
        </Box>
          <StyledBox
            label={t("phone")}
            required={false}
            name="phone"
            handleChange={handleInputChange}
          />
        </StyledContainer>
       

       { isMyAccountPage && 
        <StyledContainer>
         <Box sx={{
         display: 'flex',  
        alignItems: 'flex-end',
        paddingTop: '40px',
        }}>
          <Checkbox
          checked={isCheckBoxSet}
          sx={{
            paddingLeft: 0,
            paddingBottom: '5px',
 
          }}
          onChange={handleCheckboxChange}
        />
      <StyledTypography>{'setShippingAddressDefault'}</StyledTypography>
          </Box>
        </StyledContainer>}

        <StyledContainer>


        <Box sx={{...buttonsBoxMainWrapper}}>

        <ButtonsBox
          handleCancel={handleCancel}
          confirmButtonText={
            isEditMode ? t("confirmAddress") : t("saveNewAddress")
          }
          disabled={!formValid}
        />
         </Box>

        </StyledContainer>

      </Box>
        </form>
    </FormControl>
    );
}
import {  Box } from "@mui/material";
import { primaryColor } from "../../../../theme";
import SelectLanguage from ".";

const SelectLanguageMobile = () => {

  
  return (
    <Box
      sx={{
        backgroundColor: primaryColor.drawerBackground,
        color: primaryColor.drawerText,
        width: "100%",
        marginTop: '-3px'
      }}
    >
      <SelectLanguage 
      variant={'h6'} 
      sx={{
          paddingY: "2.5px",
        }}
        />

    </Box>
  );
};

export default SelectLanguageMobile;
